import { takeUntil } from 'rxjs/operators';
import { Component, OnInit} from '@angular/core';
import { User } from 'src/app/core/models/users/user.models';
import { LoginService } from 'src/app/core/services/login';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserModel } from 'src/app/core/models/courses';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/models/store.model';
import { Subject } from 'rxjs';
import { LocalStorage } from 'src/app/core/utils';
import { MastersService } from 'src/app/core/services/masters';

@Component({
	selector: 'app-modal-ajustes-usuario',
	templateUrl: './modal-ajustes-usuario.component.html',
	styleUrls: ['./modal-ajustes-usuario.component.scss']
})
export class ModalAjustesUsuarioComponent implements OnInit {

	public parentsList: UserModel[] = [];
	screenView: number = 1;
	user: User;
	profile: string;
	public formulario: UntypedFormGroup;
	cargando: boolean;
	private destroy$ = new Subject();

	constructor(
		public loginService: LoginService,
		private formBuild: UntypedFormBuilder,
		public activeModal: NgbActiveModal,
		private store: Store<State>,
		private localStorage: LocalStorage,
		private masterService: MastersService,
	) {
		this.formulario = this.formBuild.group({
			filtrado: [''],
		});
	}

	ngOnInit() {
		this.store.select(store => store.selectedProfile).pipe(takeUntil(this.destroy$)).subscribe((selectedProfile) => {
			this.profile = selectedProfile['selectedProfile']
		})
		this.user = this.loginService.getUser();
		//definimos variable para tutoriales
		let idTutorialContext = 0;
		if(this.loginService.esProfesor()){
			idTutorialContext = 50;
		} else if(this.loginService.esEstudiante()){
			idTutorialContext = 81;
		}
		this.localStorage.setItem("idTutorialContext", idTutorialContext);
		if(this.localStorage.getItem("TutorialesON") == "true"){
			this.masterService.openModalTutorial(idTutorialContext);
		}
	}

	changeScreen(screen: number){
		this.screenView = screen;		
		//definimos variable para tutoriales
		let idTutorialContext = 0;
		if(this.screenView === 1){				
			if(this.loginService.esProfesor()){
				idTutorialContext = 50;
			} else if(this.loginService.esEstudiante()){
				idTutorialContext = 81;
			}
		} else if(this.screenView === 2){
			idTutorialContext = 82;
		}
		this.localStorage.setItem("idTutorialContext", idTutorialContext);
		if(this.localStorage.getItem("TutorialesON") == "true"){
			this.masterService.openModalTutorial(idTutorialContext);
		}
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

}
