import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NbWindowService } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'src/app/core/services/login';
import { SosService } from "./../../core/services/sos/sos.service";
import { MeetingsComponent } from '../meetings/meetings.component';
import { ModalAceptarCancelarComponent } from 'src/app/shared/components/modal';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from 'src/app/core/utils';
import { MastersService } from 'src/app/core/services/masters';
@Component({
	selector: "app-meetings-list",
	templateUrl: "./meetings-list.component.html",
	styleUrls: ["./meetings-list.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class MeetingsListComponent implements OnInit, AfterViewInit {
	user: any;
	helpPetitions: any;
	helpPetitionsFromOthers: any;
	helpPetitionsGiven: any;
	helpPetitionsReceived: any;
	constructor(
		public activeModal: NgbActiveModal,
		private router: Router,
		private loginService: LoginService,
		private windowService: NbWindowService,
		private SosService: SosService,
		private modalService: NgbModal,
		private toaster: ToasterService,
		private translateService: TranslateService,
		private localStorage: LocalStorage,
		private masterService: MastersService,
	) {
		this.user = this.loginService.getUser();
	}

	ngOnInit() {
		this.getHelpPetitions();
		//definimos variable para tutoriales
		if(this.loginService.esEstudiante()){
			this.localStorage.setItem("idTutorialContext", 73);
			if(this.localStorage.getItem("TutorialesON") == "true"){
				this.masterService.openModalTutorial(73);
			}
		}
	}

	ngAfterViewInit(): void {}

	closeModal(sendData?: any) {
		this.activeModal.close();
	}

	giveHelp(helpPetition) {
		const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});

		modalRef.componentInstance.mensaje = this.translateService.instant(
			"SOS.YOUGONNAGIVEHELP"
		);

		modalRef.result.then((result: boolean) => {
			if (result == true) {
				let updateData = {
					...helpPetition,
					idTutor: this.user.idUser,
					helped: true,
				};
				this.SosService.updateHelpPetition(updateData).subscribe(
					(result) => {
						if (result.data) {
							this.SosService.setRoomName(
								updateData.idTutor +
									"-" +
									updateData.idNode +
									"-" +
									updateData.idUser
							);
							this.SosService.currentHelpPetition = updateData.idSos;
							this.windowService.open(MeetingsComponent, {
								title: ` ${this.translateService.instant(
									"SOS.GIVINGHELPON"
								)}: ${helpPetition.tittle}`,
							});

							setTimeout(() => {

								this.SosService.getHelpPetitionById(helpPetition.idSos).subscribe((res: any) => {
									if(res.data.connected == false){
										const modalRef = this.modalService.open(
											ModalAceptarCancelarComponent,
											{
												scrollable: true,
												windowClass: MODAL_DIALOG_TYPES.W30,
											}
										);

										modalRef.componentInstance.mensaje = this.translateService.instant(
											"SOS.USERNOTCONNECTING"
										);
									}

								})

							}, 35000);

						} else
							this.toaster.error(
								this.translateService.instant("SOS.PETITIONEXISTS")
							);
					},
					(err) => {
						this.toaster.error("errorMessage");
					}
				);
			}
		});
	}

	getHelpPetitions() {
		this.SosService.getHelpPetitions().subscribe(
			(data) => {
				this.helpPetitions = data.data.sosListGeneral.filter(
					(item) => item.idUser === this.user.idUser && item.connected === false
				);

				this.helpPetitionsFromOthers = data.data.sosListGeneral.filter(
					(item) => item.idUser !== this.user.idUser && item.connected === false
				);

				this.helpPetitionsGiven = data.data.sosListSkilled.filter(
					(item) => item.idTutor === this.user.idUser && item.connected === true
				);

				this.helpPetitionsReceived = data.data.sosListGeneral.filter(
					(item) => item.idUser === this.user.idUser && item.connected === true
				);
			},
			(error) => {
				console.log(error);
			}
		);
	}

	obtenerDiasRestantes(desde) {
		const fecha = new Date(desde);
		// Clonar la fecha de inicio
		const fechaInicio = new Date(fecha.getTime());

		// Sumar quince días a la fecha de inicio
		fechaInicio.setDate(fechaInicio.getDate() + 15);

		// Calcular la diferencia en milisegundos entre la fecha actual y la fecha de inicio
		const diferencia = fechaInicio.getTime() - Date.now();

		// Calcular el número de días restantes redondeando hacia abajo
		const diasRestantes = Math.floor(diferencia / (1000 * 60 * 60 * 24));

		// Construir el string de retorno
		const resultado = `${this.translateService.instant(
			"SOS.REMAIN"
		)} ${diasRestantes}  ${this.translateService.instant("SOS.DAY")}${
			diasRestantes !== 1 ? "s" : ""
		}`;

		return resultado;
	}

	deleteHelpPetition(id){

		const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});

		modalRef.componentInstance.mensaje = this.translateService.instant(
			"SOS.YOUGONNADELETEHELPPETITION"
		);

		modalRef.result.then((result: boolean) => {
			if (result == true) {
				this.SosService.deleteHelpPetition(id).subscribe(
					(result) => {
						if (result.data) {
							this.toaster.success(
								this.translateService.instant("SOS.DELETEDHELPPETITION")
							);
							this.getHelpPetitions();
						}
					},
					(err) => {
						this.toaster.error("errorMessage");
					}
				);
			}
		});
	}
}

