import { User } from "./../../../core/models/users/user.models";
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
import * as moment from "moment";

// Utils
import { LangUtils, LocalStorage } from "src/app/core/utils";

// Models
import {
	UserProfileValidationModel,
	UserSettingsProfileModel,
} from "src/app/core/models/users";

// Services
import { TranslateService } from "@ngx-translate/core";
import { AlertService, TitleService } from "src/app/core/services/shared";
import { LoginService } from "src/app/core/services/login";
import { UsersService } from "src/app/core/services/users";
import { MastersService } from "src/app/core/services/masters";

// Mapper
import { UsersRegisterMapperService } from "src/app/core/services/mapper";
import { IdiomaModel } from "src/app/core/models/masters/idioma.model";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DateAdapter } from "@angular/material/core";
import { GetDataService } from "src/app/core/services/get-data/get-data.service";
import { LOCALSTORAGESTRINGS } from "src/app/core/models/masters/localstorage.enum";
import {
	DEFAULTMOBILEPREFIX,
	DomainTypes,
} from "src/app/core/models/masters/masters.enum";
import { Observable } from "rxjs";
import { PrefixCountry } from "src/app/core/models/shared/prefixCountry.model";
import { finalize, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { ModalAjustesUsuarioComponent } from "src/app/shared/components/modal-ajustes-usuario/modal-ajustes-usuario.component";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { Store } from "@ngrx/store";
import { State } from "src/app/store/models/store.model";
import { selectProfile } from "src/app/store/actions/selected-profile.action";

@Component({
	selector: "app-user-data",
	templateUrl: "./user-data-general.component.html",
	styleUrls: ["./user-data-general.component.scss"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default,
})
export class UserDataGeneralComponent implements OnInit {
	@Output() idiomaSeleccionado: EventEmitter<number> = new EventEmitter();

	formularioDatos: UntypedFormGroup;

	$phonePrefixes: Observable<PrefixCountry[]>;

	validationMessages = {
		firstName: [],
		surname: [],
		mail: [],
		birthdate: [],
		mobile: [],
		gender: [],
		prefix: [],
	};

	isDemo: boolean = environment.domain === DomainTypes.DEMO ? true : false;

	userData: User;

	usersSettingsProfileData: UserSettingsProfileModel;

	usersProfileValidationData: UserProfileValidationModel[];

	genres: any[] = [];

	public loading = false;

	idioma: string;
	langList: IdiomaModel[];
	availableLangs: String[];
	langChanguedBool: boolean = false;
	private _maxLength: number = 15;
	private currentLang: string;
	screenView: number = 1;
	year: number;
	showBirthdate: any;
	isAdult: boolean;

	constructor(
		private formBuild: UntypedFormBuilder,
		public translateService: TranslateService,
		private titleService: TitleService,
		private alertService: AlertService,
		private localStorage: LocalStorage,
		public loginService: LoginService,
		public usersService: UsersService,
		private usersRegisterMapperService: UsersRegisterMapperService,
		private dateAdapter: DateAdapter<Date>,
		private lang: LangUtils,
		private activeModal: NgbActiveModal,
		private dataService: GetDataService,
		private mastersService: MastersService,
		private modalService: NgbModal,
		private toaster: ToasterService,
		private store: Store<State>,
		private masterService: MastersService,
	) {}

	ngOnInit(): void {
		// Obtengo los maestros
		this.obtenerDatosMaestros();

		// Lanzo las traducciones
		this.traducirOpciones();

		// Creo el formulario
		this.crearFormulario();

		// Cargo las validaciones del formulario
		this.cargarValidacionesFormulario();

		// Y obtengo la informacion del usuario
		this.obtenerDatosUsuario();

		this.currentLang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
		this.dateAdapter.setLocale(this.currentLang);
	}

	private obtenerDatosMaestros() {
		this.mastersService.getAllGenders().subscribe((data) => {
			this.genres = data;
		});

		//Obtener listado de idiomas
		this.getAllLanguages();

		this.$phonePrefixes = this.loginService.getPhonePrefix();
		//definimos variable para tutoriales
		this.localStorage.setItem("idTutorialContext", 99);
			if(this.localStorage.getItem("TutorialesON") == "true"){
				this.masterService.openModalTutorial(99);
			}
	}

	getAllLanguages() {
		this.langList = this.dataService.appLanguages;
		this.availableLangs = this.langList.map((e) =>
			e.idLanguageIso.toLowerCase()
		);
		this.lang.setAppLang(this.availableLangs);
		//this.idioma = `../../../../assets/images/flags/${this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG)}.png`;
	}

	validateIdUserIsAdult(birthDate) {
		const currentYear = new Date().getFullYear();
		this.year = birthDate.substr(-20, 4);
		const isAdults = +currentYear - this.year;
		this.showBirthdate = true;
		if (isAdults <= 17) {
			this.isAdult = false;
		} else {
			this.isAdult = true;
		}
	}

	private traducirOpciones() {
		// Titulo pagina
		this.translateService
			.get("USERDATA.CONFIGURACION")
			.subscribe((res: string) => {
				this.titleService.setHTMLTitle(res);
				this.titleService.setBarTitle(res);
			});
	}

	private crearFormulario() {
		// default data and form groups
		this.formularioDatos = this.formBuild.group({
			idUser: [this.loginService.getUser().idUser],
			firstName: ["", [Validators.minLength(2), Validators.required]],
			surname: [""],
			pictureUser: [""],
			mail: [""],
			birthdate: [""],
			mobile: new UntypedFormControl("", [Validators.maxLength(this._maxLength)]),
			idGenre: [""],
			idLanguageIso: [""],
			picture: [""],
			prefix: ["", Validators.required],
			profileEditor: [true, Validators.required],
			profileStudent: [false],
			profileTeacher: [false],
			profileParent: [false],
			profileCenter: [false],
		});
	}

	get birthdateControl(): UntypedFormControl {
		if (this.formularioDatos) {
			return this.formularioDatos.get("birthdate") as UntypedFormControl;
		} else {
			return null;
		}
	}
	get genderControl(): UntypedFormControl {
		if (this.formularioDatos) {
			return this.formularioDatos.get("birthdate") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get prefixControl() {
		if (this.formularioDatos) {
			return this.formularioDatos.get("prefix") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get profileEditor(): UntypedFormControl {
		if (this.formularioDatos) {
			return this.formularioDatos.get("profileEditor") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get profileStudent(): UntypedFormControl {
		if (this.formularioDatos) {
			return this.formularioDatos.get("profileStudent") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get profileTeacher(): UntypedFormControl {
		if (this.formularioDatos) {
			return this.formularioDatos.get("profileTeacher") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get profileParent(): UntypedFormControl {
		if (this.formularioDatos) {
			return this.formularioDatos.get("profileParent") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get profileCenter(): UntypedFormControl {
		if (this.formularioDatos) {
			return this.formularioDatos.get("profileCenter") as UntypedFormControl;
		} else {
			return null;
		}
	}

	private cargarValidacionesFormulario() {
		this.translateService
			.get("VALIDACIONES.ELNOMBREESOBLIGATORIO")
			.subscribe((res: string) => {
				this.validationMessages.firstName.push({
					type: "required",
					message: res,
				});
			});

		this.translateService
			.get("VALIDACIONES.LOSAPELLIDOSSONOBLIGATORIOS")
			.subscribe((res: string) => {
				this.validationMessages.surname.push({
					type: "required",
					message: res,
				});
			});

		this.translateService
			.get("VALIDACIONES.ELEMAILESOBLIGATORIO")
			.subscribe((res: string) => {
				this.validationMessages.mail.push({ type: "required", message: res });
			});

		this.translateService
			.get("VALIDACIONES.ELTELEFONOMOVILESOBLIGATORIO")
			.subscribe((res: string) => {
				this.validationMessages.mobile.push({ type: "required", message: res });
			});

		this.translateService
			.get("VALIDACIONES.MAXLENGTH")
			.subscribe((res: string) => {
				this.validationMessages.mobile.push({
					type: "maxlength",
					message: res.replace("{1}", `${this._maxLength}`),
				});
			});

		this.translateService
			.get("VALIDACIONES.LAFECHADENACIMIENTOESOBLIGATORIA")
			.subscribe((res: string) => {
				this.validationMessages.birthdate.push({
					type: "required",
					message: res,
				});
			});
		this.translateService
			.get("VALIDACIONES.ESCOGEUNAOPCION")
			.subscribe((res: string) => {
				this.validationMessages.gender.push({ type: "required", message: res });
			});
	}

	// Mostrar Aviso
	public mostrarMensajeAviso() {
		// Para poder hacer las comparaciones
		//this.userData.usersProfileValidation = this.usersProfileValidationData;

		const resp = this.usersService.checkIfUserDataAreRegistered(
			this.userData,
			"general"
		);

		if (resp.result !== true) {
			this.alertService.info(
				this.translateService.instant(resp.mensaje),
				AlertService.AlertServiceContextValues.UserData
			);
		} else {
			this.alertService.clear();
		}
	}
	// Fin mostrar aviso

	private cargarDatosFormulario() {
		let birthDate: string = this.userData.birthdate
			? moment(this.userData.birthdate).format("YYYY-MM-DD")
			: "";

		this.formularioDatos.controls.idUser.setValue(this.userData.idUser);
		this.formularioDatos.controls.pictureUser.setValue(
			this.userData.pictureUser
		);
		this.formularioDatos.controls.firstName.setValue(this.userData.firstName);
		this.formularioDatos.controls.surname.setValue(this.userData.surname);
		this.formularioDatos.controls.mail.setValue(this.userData.mail);
		this.formularioDatos.controls.birthdate.setValue(birthDate);
		this.formularioDatos.controls.mobile.setValue(this.userData.mobile);
		this.formularioDatos.controls.idGenre.setValue(this.userData.idGenre);
		this.formularioDatos.controls.idLanguageIso.setValue(
			this.userData.idLanguageIso.toLocaleUpperCase()
		);
		this.formularioDatos.controls.picture.setValue(this.userData.pictureUser);
		this.formularioDatos.controls.prefix.setValue(
			this.userData.extension
				? "+" + this.userData.extension
				: DEFAULTMOBILEPREFIX
		);
		this.formularioDatos.controls.profileEditor.setValue(true);
		this.formularioDatos.controls.profileStudent.setValue(
			this.userData.profileStudent
		);

		this.formularioDatos.controls.profileTeacher.setValue(
			this.userData.profileTeacher
		);
		this.formularioDatos.controls.profileParent.setValue(
			this.userData.profileParent
		);
		this.formularioDatos.controls.profileCenter.setValue(
			this.userData.profileCenter
		);
		this.validateIdUserIsAdult(birthDate);
	}

	private obtenerDatosUsuario() {
		this.loading = true;

		this.usersService
			.getUserByIdUser(this.loginService.getUser().idUser)
			.subscribe(
				(response: any) => {
					if (response.idUser != 0) {
						// Mapeo los datos del usuario
						this.userData = this.usersRegisterMapperService.transform(response);
						// Cargo los user settings profile para ver que menus van en gris
						this.usersSettingsProfileData = response.usersSettingsProfiles;

						// Cargo los perfiles de el usuario y relleno el formulario
						this.usersService
							.getListUserProfile()
							.subscribe((response: any) => {
								response.data.forEach((element) => {
									if (element.idProfile == 1) {
										this.userData.profileEditor = true;
									}
									if (element.idProfile == 2) {
										this.userData.profileStudent = true;
									}
									if (element.idProfile == 3) {
										this.userData.profileTeacher = true;
									}
									if (element.idProfile == 4) {
										this.userData.profileParent = true;
									}
									if (element.idProfile == 6) {
										this.userData.profileCenter = true;
									}
								});
								// Cargo los datos
								this.cargarDatosFormulario();
							});

						// Y el mensaje de aviso en funcion de si ha sido validado o no.
						this.mostrarMensajeAviso();

						this.loading = false;
					} else {
						// tslint:disable-next-line: max-line-length
						this.alertService.error(
							this.translateService.instant("ERROR.HAOCURRIDOUNERROR"),
							AlertService.AlertServiceContextValues.UserData
						);
					}
				},
				(error) => {
					this.loading = false;
					// tslint:disable-next-line: max-line-length
					this.alertService.error(
						this.translateService.instant("ERROR.HAOCURRIDOUNERROR"),
						AlertService.AlertServiceContextValues.UserData
					);
				}
			);
	}

	public grabarDatos() {
		if (this.formularioDatos.invalid) {
			return;
		}

		// Recupero los datos del formulario
		this.loading = true;

		const campos = this.formularioDatos.value;
		this.userData.firstName = campos.firstName;
		this.userData.idGenre = campos.idGenre;
		this.userData.mail = campos.mail;
		this.userData.mobile = campos.mobile;
		this.userData.surname = campos.surname;
		this.userData.birthdate = campos.birthdate;
		this.userData.idLanguageIso = campos.idLanguageIso.toLowerCase();
		this.userData.extension = (campos.prefix as string).replace("+", "").trim();
		this.userData.profileEditor = campos.profileEditor;
		this.userData.profileStudent = campos.profileStudent;
		this.userData.profileTeacher = campos.profileTeacher;
		this.userData.profileParent = campos.profileParent;
		this.userData.profileCenter = campos.profileCenter;
		this.userData.identification = this.loginService.getUser().identification;

		this.usersService
			.updateUser(this.userData)
			.pipe(finalize(() => (this.loading = false)))
			.subscribe(
				(resp: any) => {
					this.changeLang(this.userData.idLanguageIso); //hardcodeado, cambiar luego 060723 jaime y arevalo
					/*if (this.langChanguedBool) {
            this.changeLang(this.userData.idLanguageIso);
            this.langChanguedBool = false;
        }*/
					// tslint:disable-next-line: max-line-length
					this.alertService.success(
						this.translateService.instant(
							"USERDATA.LOSDATOSSEHANGRABADOCORRECTAMENTE"
						),
						AlertService.AlertServiceContextValues.UserData
					);
				},
				(error) => {
					console.log(error);
					// tslint:disable-next-line: max-line-length
					this.alertService.error(
						this.translateService.instant("ERROR.HAOCURRIDOUNERROR"),
						AlertService.AlertServiceContextValues.UserData
					);
				}
			);

		this.usersService.updateUserProfile(campos).subscribe(
			(resp: any) => {},
			(error) => {}
		);
	}

	public guardarPerfil() {
		const campos = this.formularioDatos.value;
		this.usersService.updateUserProfile(campos).subscribe(
			(resp: any) => {},
			(error) => {}
		);
	}

	public changeLang(lang: string) {
		this.localStorage.setItem(LOCALSTORAGESTRINGS.LANG, lang);
		this.lang.setAppLang(this.availableLangs);
		//this.idioma = `../../../../assets/images/flags/${this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG)}.png`;
		this.idiomaSeleccionado.emit(
			this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG)
		);
	}

	selectLang(lang) {
		this.langChanguedBool = true;
	}

	closeModal(sendData?: any) {
		//guardamos los cambios antes de cerrar
		this.grabarDatos();
		this.usersService
			.getListUserProfile()
			.pipe(take(1))
			.subscribe((res: any) => {
				this.usersService._listUserProfile = res.data;
				let orderedProfilesArray = [];
				res.data.forEach((element) => {
					if (element.idProfile == 2) {
						orderedProfilesArray[0] = element;
					}
					if (element.idProfile == 3) {
						orderedProfilesArray[1] = element;
					}
					if (element.idProfile == 4) {
						orderedProfilesArray[2] = element;
					}
					if (element.idProfile == 1) {
						orderedProfilesArray[3] = element;
					}
					if (element.idProfile == 5) {
						orderedProfilesArray[4] = element;
					}
				});
				this.setDefaultProfile(orderedProfilesArray);
			});
		this.activeModal.close(sendData);
	}

	private setDefaultProfile(orderedArray) {
		var BreakException = {};
		try {
			orderedArray.forEach((element) => {
				if (element.idProfile == 2) {
					this.store.dispatch(
						new selectProfile({ selectedProfile: "ESTUDIANTE" })
					);
					throw BreakException;
				}
				if (element.idProfile == 3) {
					this.store.dispatch(
						new selectProfile({ selectedProfile: "PROFESOR" })
					);
					throw BreakException;
				}
				if (element.idProfile == 4) {
					this.store.dispatch(new selectProfile({ selectedProfile: "PADRE" }));
					throw BreakException;
				}
				if (element.idProfile == 1) {
					this.store.dispatch(new selectProfile({ selectedProfile: "AUTOR" }));
					throw BreakException;
				}
				if (element.idProfile == 5) {
					this.store.dispatch(new selectProfile({ selectedProfile: "ADMIN" }));
					throw BreakException;
				}
			});
		} catch (e) {
			if (e !== BreakException) throw e;
		}
	}

	changePass() {
		this.screenView = 2;
	}

	changeMobile() {
		this.screenView = 3;
	}

	changeMail() {
		this.screenView = 4;
	}

	dropOut() {
		this.screenView = 5;
	}

	backToGeneral(change) {
		if (!change) {
			this.screenView = 1;
		} else {
			this.screenView = 1;
			this.toaster.success(this.translateService.instant("CAMBIARPASS.OK"));
		}
	}

	ajustes() {
		this.modalService.open(ModalAjustesUsuarioComponent, {
			scrollable: true,
			windowClass: `${MODAL_DIALOG_TYPES.W90}`,
		});
		this.closeModal("");
	}

	onClickSelect(event: MouseEvent) {
		//el evento se comento debido a que no se ejecutaban los SELECT del formulario

		// event.stopPropagation();
	}
}
