import { PdfService } from './../../../core/services/pdf.service';

import { CommonModule } from '@angular/common';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbButtonModule, NbCardModule, NbFormFieldModule, NbIconModule, NbProgressBarModule, NbSelectModule, NbSpinnerModule, NbTooltipModule, NbWindowRef, NbWindowService } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { API_KEY_CHAT_GTP, PROMPT_SHAPE_GENERATIONS } from 'src/app/core/models/masters/masters.enum';
import { ModalGeneratedIdeasComponent } from './modal-generated-ideas/modal-generated-ideas.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { LocalStorage } from 'src/app/core/utils';
import { ComponentsModule } from '../components.module';
import { SigmaCanvasService } from '../nodes/sigma-canvas/sigma-canvas.service';
import { OpenAIService } from './openai.service';
import { PaisModel } from 'src/app/core/models/masters';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { CoursesService } from 'src/app/core/services/courses';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
@Component({
	selector: "app-salwizar-contenido",
	standalone: true,
	imports: [
		CommonModule,
		NbFormFieldModule,
		NbCardModule,
		NbIconModule,
		NbTooltipModule,
		NbSelectModule,
		NbButtonModule,
		FormsModule,
		ReactiveFormsModule,
		NbSpinnerModule,
		TranslateModule,
		NbProgressBarModule,
	],
	templateUrl: "./salwizar-contenido.component.html",
	styleUrls: ["./salwizar-contenido.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class SalwizarContenidoComponent {

	value: number = 0; // Initialize the value property
	selectedFile: any;
	notCountryLevelsForThisCountry: boolean = false;
	countriesList: PaisModel[];
	levelsOfCountry: any;
	setValue(newValue) {
		this.value = Math.min(Math.max(newValue, 0), 100)
	}

	get status() {
		if (this.value <= 25) {
			return 'danger';
		} else if (this.value <= 50) {
			return 'warning';
		} else if (this.value <= 75) {
			return 'info';
		} else {
			return 'success';
		}
	}

	private httpClient: HttpClient;
	salwizarForm: any;
	@ViewChild("inputFile") myInputVariable: ElementRef;
	pruebaGenerarIdeas: {
		Numero: number;
		Titulo_de_la_Idea: string;
		Cuerpo_Explicativo_de_la_Idea: string;
		Resumen: string;
		Coordenada_X: number;
		Coordenada_Y: number;
	}[];
	loading: boolean = false;
	PDFtoAnalize: string;
	fileSelected: boolean = false;
	enableGenerateButton: boolean = false;
	constructor(
		private formBuild: FormBuilder,
		private activeModal: NgbActiveModal,
		public http: HttpClient,
		private modalService: NgbModal,
		private localStorage: LocalStorage,
		private pdf: PdfService,
		private sigmaCanvasService: SigmaCanvasService,
		handler: HttpBackend,
		private openAIService: OpenAIService,
		private getDataService: GetDataService,
		public coursesService: CoursesService,
		private toaster: ToasterService,
	) {
		this.buildForm();
		this.salwizarForm.get("topic").valueChanges.subscribe((value) => {
			if (value !== "") {
				this.enableGenerateButton = true;
			}
			if (value === "") {
				this.enableGenerateButton = false;
			}
		});
		this.httpClient = new HttpClient(handler);
	}

	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.countriesList = this.getDataService.appCountries;
		this.coursesService
			.getCountryLevelsByCountry("ESP")
			.subscribe((countryLevels) => {
				this.levelsOfCountry = countryLevels.data;
			});
	}

	searchLevels(country) {
		this.coursesService
			.getCountryLevelsByCountry(country)
			.subscribe((countryLevels) => {
				if (countryLevels.data.length == 0) {
					this.notCountryLevelsForThisCountry = true;
				} else {
					this.notCountryLevelsForThisCountry = false;

				}
				this.levelsOfCountry = countryLevels.data;
			});
	}

	buildForm(): void {
		this.salwizarForm = this.formBuild.group({
			topic: [""],
			level: ["Primaria"],
			idCountry: "ESP",
			idCountryLevel: "",
			lang: ["Español"],
			extra: [""],
			words: [200],
			cant: [5],
		});

	}

	closeModal(sendData?: any): void {
		this.activeModal.close(sendData);
	}

	clearFile(): void {
		this.salwizarForm.get("topic").setValue("");
		this.fileSelected = false;
	}

	onFileSelected(event: any): void {
		this.fileSelected = true;
		this.enableGenerateButton = true;
		this.salwizarForm.get("topic").value = event.target.files[0].name;
		const file: File = event.target.files[0];

		if (file) {
			this.selectedFile = event.target.files[0];
			this.pdf.getPdfText(file).then(
				(text: string) => {
					this.PDFtoAnalize = text;
				},
				(error) => {
					console.error(error);
				}
			);
		}
	}

	convertFileToBase64(file: File): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve((reader.result as string).split(",")[1]);
			reader.onerror = (error) => reject(error);
		});
	}

	async generateProposals() {
		this.loading = true;
		this.value = 0;
		const interval = setInterval(() => {
			if (this.value < 95) {
				this.value += 5;
			}
		}, 1000);
		const pdfUrl = "http://www.bnm.me.gov.ar/giga1/documentos/EL002315.pdf";
		const apiKey = API_KEY_CHAT_GTP;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${apiKey}`,
		});
		const formData = this.salwizarForm.value
		const prompt = `${PROMPT_SHAPE_GENERATIONS}, Actua como un profesor de el pais ${formData.idCountry} nivel educativo: ${formData.idCountryLevel},
		el objetivo es desarrollar las diferentes ideas necesarias para explicar un tema, estas ideas estaran escritas
		en el idioma ${formData.lang}, pero solo el contenido de las mismas, los nombres de las propiedades mantenlos
		en español, en este caso el tema en cuestion es el siguiente: ${formData.topic}, encuentra material educativo acerca de el tema para generar las ideas,
	  ademas de eso te vas a apoyar en el siguiente texto solo si es distinto de 'undefined': ${this.PDFtoAnalize} para generar las ideas,
		debes generar un json que contendra multimples datos,
		sera un array que tendra multiples objetos, como primera propiedad el Tipo_Forma, luego otra con la Razon de eleccion de la forma,
		y por ultimo Estructura_Ideas donde estaran cada una de las ideas generadas, estas ideas deben tener las siguientes propiedades,
		el primero sera el numero de idea, este numero sera el orden de las ideas, ya que en un futuro podrian conectarse
		a modo de mapa mental por eso deben tener un orden establecido,
		el segundo sera el titulo de la idea, el tercero sera el cuerpo explicativo de la idea el cual tendra el numero de palabras
		suficientes para explicar todo el contenido relativo a esta idea del documento que se te suministro y almenos cada idea tendra que tener desde 200 hasta 500 palabras dependiento de cuantas veas necesarias
		para explicar perfectamente la idea en cuestion,
		de acuerdo al nivel educativo mencionado anteriormente, el cuarto sera un breve resumen de la idea, los nombres de las propiedades seran
		las siguientes: Numero, Titulo_de_la_Idea, Cuerpo_Explicativo_de_la_Idea, Resumen, la cantidad de ideas que consideres necesarias para la
		explicación de el tema, estas ideas seran temas explicativos de el tema,
		ya que luego haremos pequeños quizzes sobre los temas explicados, por lo que las ideas deben ser claras y concisas, ademas ten en cuenta
		las siguientes instrucciones: ${formData.extra}, es necesario que tengas en
		cuenta la informacion que te di acerca de las forma que tendra el ideografo, es decir, si determinas que la forma sera de tipo
		jerarquico entonces las ideas deben estar ordenadas de manera jerarquica existiendo sub-ideas, si determinas que la forma sera de tipo lineal entonces
		las ideas deben estar ordenadas de manera lineal y asi...
		no le des formato markdown a la respuesta ni agregues ningun tipo de explicacion solo responde con los JSON que necesito, quiero el JSON crudo el cual sera un array con las ideas directamente dentro,
		es decir que no quiero que metas las ideas dentro de ninguna propiedad previa asegurate de que el JSON generado sea valido, ya que luego hare operaciones como JSON.parse con el mismo.
		por ningun motivo empieces tu respuesta abriendo las comillas para un json solo dame el array directamente
		pero ten en cuenta que en dentro de Cuerpo_Explicativo_de_la_Idea si puedes usar fomrato markdown para darle formato a la respuesta. usando titulos, subtittulos, negritas, cursivas, listas, ,ejmeplos con codigo etc...`;

		const apiEndpoint = "https://api.openai.com/v1/chat/completions";
		const body = {
			model: "gpt-4o",
			messages: [
				{
					role: "system",
					content: prompt,
				},
			],
			max_tokens: 4096, // Cantidad máxima de tokens en la respuesta
			temperature: 0.7,
		};
		try {
			const respuesta = await this.httpClient
				.post<any>(apiEndpoint, body, { headers })
				.toPromise();
			if (respuesta) {

					clearInterval(interval);
					this.value = 100;
					this.loading = false;
					this.localStorage.setItem("IAGeneratedIdeas", JSON.stringify(respuesta.choices[0].message.content));

					this.localStorage.setItem("ShapedGeneratedByIa", true);

					this.activeModal.close();

			}
		} catch (error) {
			clearInterval(interval);
			this.value = 0;
			this.loading = false;
			console.error("Error al hacer la consulta a ChatGPT:", error.message);
		}
	}

	async processFile() {
		if (!this.selectedFile) {
			alert('Please select a file first.');
			return;
		}

		const formData = this.salwizarForm.value

		try {
			// Step 1: Upload file
			const uploadResponse = await this.openAIService.uploadFile(this.selectedFile).toPromise();
			const fileId = uploadResponse.id;

			// Step 2: Create thread
			const threadResponse = await this.openAIService.createThread().toPromise();
			const threadId = threadResponse.id;

			// Step 3: Get or create assistant
			const assistants = await this.openAIService.getAssistant().toPromise();
			let assistant = assistants.find((a: any) => a.name === 'Asistente de profesor');

			if (!assistant) {
				assistant = await this.openAIService.createAssistant().toPromise();
			}

			// Step 4: Send message
			const prompt = `${PROMPT_SHAPE_GENERATIONS} Actua como un profesor de el nivel educativo: ${formData.level},
		el objetivo es desarrollar las diferentes ideas necesarias para explicar un tema, estas ideas estaran escritas
		en el idioma ${formData.lang}, pero solo el contenido de las mismas, los nombres de las propiedades mantenlos
		en español, en este caso el tema en cuestion es el siguiente: ${formData.topic}, encuentra material educativo acerca de el tema para generar las ideas,
	  ademas de eso te vas a apoyar en el archivo adjuntado para generar las ideas,
		debes generar un json que contendra multimples datos,
		sera un array que tendra multiples objetos, como primera propiedad el Tipo_Forma, luego otra con la Razon de eleccion de la forma,
		y por ultimo Estructura_Ideas donde estaran cada una de las ideas generadas, estas ideas deben tener las siguientes propiedades,
		el primero sera el numero de idea, este numero sera el orden de las ideas, ya que en un futuro podrian conectarse
		a modo de mapa mental por eso deben tener un orden establecido,
		el segundo sera el titulo de la idea, el tercero sera el cuerpo explicativo de la idea el cual tendra el numero de palabras
		que consideres necesario para explicar perfectamente la idea en cuestion de acuerdo al nivel educativo mencionado anteriormente, el cuarto sera un breve resumen de la idea, los nombres de las propiedades seran
		las siguientes: Numero, Titulo_de_la_Idea, Cuerpo_Explicativo_de_la_Idea, Resumen, la cantidad de ideas que consideres necesarias para la
		explicación de el tema, estas ideas seran temas explicativos de el tema,
		ya que luego haremos peqeños quizzes sobre los temas explicados, por lo que las ideas deben ser claras y concisas, ademas ten en cuenta
		las siguientes instrucciones: ${formData.extra}, es necesario que tengas en
		cuenta la informacion que te di acerca de las forma que tendra el ideografo, es decir, si determinas que la forma sera de tipo
		jerarquico entonces las ideas deben estar ordenadas de manera jerarquica existiendo sub-ideas, si determinas que la forma sera de tipo lineal entonces
		las ideas deben estar ordenadas de manera lineal y asi...
		no le des formato markdown a la respuesta ni agregues ningun tipo de explicacion solo responde con los JSON que necesito, quiero el JSON crudo el cual sera un array con las ideas directamente dentro,
		es decir que no quiero que metas las ideas dentro de ninguna propiedad previa asegurate de que el JSON generado sea valido, ya que luego hare operaciones como JSON.parse con el mismo.`;
			await this.openAIService.createMessage(threadId, fileId, prompt).toPromise();

			// Step 5: Run the thread
			const runResponse = await this.openAIService.runThread(threadId, assistant.id).toPromise();

			// Step 6: Fetch result
			const messages = await this.openAIService.listMessages(threadId).toPromise();
			let resultadoo = messages[0]?.content?.[0]?.text?.value || 'No result';



			// Step 7: Delete file
			await this.openAIService.deleteFile(fileId).toPromise();
		} catch (error) {
			console.error('Error processing file:', error);
		}
	}
}
