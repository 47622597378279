import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SurveysService } from 'src/app/core/services/surveys/surveys.service';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { MatomoAnalyticsUtils } from 'src/app/core/utils/matomo-analytics.utils';
import { SurveyModel } from 'src/app/core/models/surveys/survey.model';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { SurveysDetailsComponent } from '../surveys-details/surveys-details.component';
import { QuestionModel } from 'src/app/core/models/surveys/question.model';
import { LocalStorage } from 'src/app/core/utils';
import { MastersService } from 'src/app/core/services/masters';

interface NavigatorWithConnection extends Navigator {
  connection?: {
    downlink: number;
  };
}
@Component({
  selector: 'app-surveys-list',
  templateUrl: './surveys-list.component.html',
  styleUrls: ['./surveys-list.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SurveysListComponent implements OnInit {

	viewList: boolean = false;
	cargando = false;
	cameFromCalendar = false;

	surveysList: any[] = [];
	networkSpeed = 1;

	constructor(
		public activeModal: NgbActiveModal,
		public surveysService: SurveysService,
		private modalService: NgbModal,
		private ma: MatomoAnalyticsUtils,
		public translateService: TranslateService,
		private toaster: ToasterService,
		private localStorage: LocalStorage,
		private masterService: MastersService,
	){}

	ngOnInit(): void{
		this.networkSpeed = this.getNetworkSpeed(); //primero verificamos la velocidad de internet
		if(this.cameFromCalendar){
			this.surveysService.getAllSurveys().subscribe(res => {
				this.surveysList = res.data;
			});
		} else{
			this.surveysService.getAllSurveysForEditor().subscribe(res => {
				this.surveysList = res.data;
			});
		}
		//definimos variable para tutoriales
        this.localStorage.setItem("idTutorialContext", 95);
        if(this.localStorage.getItem("TutorialesON") == "true"){
            this.masterService.openModalTutorial(95);
        }
	}

	getNetworkSpeed() {
		const navigatorWithConnection = navigator as NavigatorWithConnection;
		if (navigatorWithConnection.connection) {
			return navigatorWithConnection.connection.downlink;
		} else {
			return 10; // No se pudo obtener la velocidad de conexión
		}
  }

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	openSurvey(survey: any){
		if(this.cameFromCalendar){
			this.closeModal(survey);
		}else{
			this.ma.event("click", "view_item", "Curso");
			const modalRef = this.modalService.open(SurveysDetailsComponent, {
				scrollable: false,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			});
			modalRef.componentInstance.idSurvey = survey.idSurvey;
			modalRef.componentInstance.survey = survey;
			modalRef.result.then(
				(resp) => {
					this.surveysService.getAllSurveysForEditor().subscribe((res) => {
						this.surveysList = res.data;
					});
				},
				(err) => {}
			);
		}
	}

	updateUrl(event: any) {
		event.target.src = "../../assets/images/no-image.png";
	}

	newSurvey(){
		let survey: SurveyModel = new SurveyModel(
			0,
			this.translateService.instant("ENCUESTAS.NEWTITLE"),
			this.translateService.instant("ENCUESTAS.NEWDESCRIPTION"),
			38,
			2,
		);
		survey.image = "";
		this.surveysService.createUpdateSurvey(survey).subscribe(res => {
			if(res.data){
				this.toaster.success(this.translateService.instant('ENCUESTAS.CREATEOK'));
				this.ma.event("click", "view_item", "Curso");
				const modalRef = this.modalService.open(SurveysDetailsComponent, {
					scrollable: false,
					windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
				});
				modalRef.componentInstance.idSurvey = res.data.idSurvey;
				modalRef.componentInstance.survey = res.data;
				modalRef.result.then(
					(resp) => {
						this.surveysService.getAllSurveysForEditor().subscribe(res => {
							this.surveysList = res.data;
						});
					},
					(err) => {}
				);
			} else{
				this.toaster.error(this.translateService.instant('ENCUESTAS.CREATEKO'));
			}
		});
	}

}
