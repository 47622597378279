import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	OnDestroy,
	OnInit,
	Output,
	ViewEncapsulation,
	Input,
	ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { ActionModel } from "src/app/core/models/shared/actions.model";
import { SigmaToolbarsService } from "src/app/core/services/sigma-toolbars/sigma-toolbars.service";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { ModalContactComponent } from "../modal-contact/modal-contact.component";
import { ModalImageComponent } from "../modal-image/modal-image.component";
import { NbDialogService } from "@nebular/theme";
import { ModalSendCommentsComponent } from "../modal-send-comments/modal-send-comments.component";
import { DialogService } from "src/app/core/services/shared/dialog.service";
import { LOCALSTORAGESTRINGS } from "src/app/core/models/masters/localstorage.enum";
import { LocalStorage } from "src/app/core/utils";
import { MastersService } from "src/app/core/services/masters";

declare var document;
@Component({
	selector: "app-graph-lateral-icons",
	templateUrl: "./graph-lateral-icons.component.html",
	styleUrls: ["./graph-lateral-icons.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class GraphLateralIconsComponent implements OnInit, OnDestroy {
	loadInitialGraph: boolean;
	@HostListener("document:click", ["$event"])
	clickout(event) {
		if (!this.eRef.nativeElement.contains(event.target)) this.closeNav();
	}

	@ViewChild("mySidebar") mySidebar: ElementRef;
	@ViewChild("menuIzquierdo") menuIzquierdo: ElementRef;

	@Input() isShowLabels: boolean = false;
	@Input() isShowPads: boolean = true;
	@Output() action: EventEmitter<ActionModel> = new EventEmitter<ActionModel>();
	isFullscreen: boolean = false;

	private subs = new Subscription();

	constructor(
		private modalService: NgbModal,
		private eRef: ElementRef,
		private localStorage: LocalStorage,
		private toolsService: SigmaToolbarsService,
		private nebularDialogService: NbDialogService,
		private dialogService: DialogService,
		private masterService: MastersService,
	) {
		this.subs = this.toolsService.hideElements.subscribe((val) => {
			this.closeNav();
		});
	}

	ngOnInit() {
		this.isShowLabels = false;
		this.loadInitialGraph = JSON.parse(
			this.localStorage.getItem("loadInitialGraph")
		);
		//definimos variable para tutoriales
		this.localStorage.setItem("idTutorialContext", 100);
		if(this.localStorage.getItem("TutorialesON") == "true"){
			this.masterService.openModalTutorial(100);
		}
	}

	ngOnDestroy(): void {
		if (this.subs) this.subs.unsubscribe();
	}

	switchLoadInitialGraph() {
		this.loadInitialGraph = !this.loadInitialGraph;
		this.localStorage.setItem(
			LOCALSTORAGESTRINGS.LOADINITIALGRAPH,
			JSON.stringify(this.loadInitialGraph)
		);
		window.location.reload();
	}

	showHidePads() {
		this.isShowPads = !this.isShowPads;
		let action = new ActionModel("showHidePads", this.isShowPads);
		this.action.emit(action);
	}

	showHideLabels() {
		this.isShowLabels = !this.isShowLabels;
		let action = new ActionModel("showHideLabels", this.isShowLabels);
		this.action.emit(action);
	}

	showFullscreen() {
		this.isFullscreen = !this.isFullscreen;
		if (this.isFullscreen) document.documentElement.requestFullscreen();
		else document.exitFullscreen();
	}

	openModal(option: string, title: string): void {
		const modalRef = this.modalService.open(ModalImageComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W80,
		});

		modalRef.componentInstance.option = option;
		modalRef.componentInstance.title = title;
	}

	openContact() {
		const modalRef = this.nebularDialogService.open(ModalContactComponent, {
			dialogClass: MODAL_DIALOG_TYPES.W60,
		});
	}

	openNav() {
		this.menuIzquierdo.nativeElement.style.display = "none";
		this.mySidebar.nativeElement.style.width = "300px";
		this.mySidebar.nativeElement.style.height = "360px";
		this.mySidebar.nativeElement.style.paddingTop = "40px";
	}

	closeNav() {
		this.mySidebar.nativeElement.style.height = "0";
		this.mySidebar.nativeElement.style.width = "0";
		this.mySidebar.nativeElement.style.paddingTop = "0";
		setTimeout(() => {
			this.menuIzquierdo.nativeElement.style.display = "block";
		}, 500);
	}

	print() {
		let action = new ActionModel("print", true);
		this.action.emit(action);
	}

	showDialogPolicy(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();
		this.dialogService.openDialogPolicy();
	}
}
