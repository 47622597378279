<nb-card class="mb-0 no-edge-card">

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal()"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>

	<nb-card-body>
		<div class="m-3 m-md-5 d-flex justify-content-center">
			<div class="iframe-container">
				<iframe [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
			</div>
		</div>
	</nb-card-body>
</nb-card>
