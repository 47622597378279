import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { MastersService } from "src/app/core/services/masters";
import { LoginService } from "src/app/core/services/login";
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalSchedulesComponent } from "../modal-schedules/modal-schedules.component";
import { ModalStructureComponent } from "../modal-structure/modal-structure.component";
import { finalize, forkJoin } from 'rxjs';
import { ReportService } from 'src/app/core/services/report/report.service';
import { ChatAssistantComponent } from '../chat-assistant/chat-assistant.component';
import { Profiles } from 'src/app/core/utils/profiles.enum';
import { Utils } from 'src/app/core/utils/utils';
import { CenterService } from 'src/app/core/services/center/center.service';
import { ModalAceptarCancelarComponent } from '../modal';
import { TableInformeComponent } from 'src/app/pages/table-informe/table-informe.component';
import { User } from 'src/app/core/models/users/user.models';
import { TableInformeIndividualComponent } from 'src/app/pages/table-informe-individual/table-informe-individual.component';
import { ModalCreateStructureComponent } from '../modal-create-structure/modal-create-structure.component';
import { ModalTutorialManagerComponent } from '../modal-tutorial-manager/modal-tutorial-manager.component';

@Component({
	selector: "app-control-panel-admin",
	templateUrl: "./control-panel-admin.component.html",
	styleUrls: ["./control-panel-admin.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ControlPanelAdminComponent implements OnInit {
	user: User;
	selectedGroupInfo: any;
	initialData: any;
	//variables profesores
	teachersRegistered = 0;
	teachersRegisteredLastMonth = 0;
	teachersOnlineLastWeek = 0;
	//variables estudiantes
	studentsRegistered = 0;
	studentsRegisteredLastMonth = 0;
	studentsOnlineLastWeek = 0;
	//variables editores
	editorsRegistered = 0;
	editorsRegisteredLastMonth = 0;
	editorsOnlineLastWeek = 0;
	//variables grupos
	groupsTotal = 0;
	groupsAverageStudentsPerGroup = 0.0;
	//variables proyectos
	projectsTotal = 0;
	projectsCreatedLastMonth = 0;
	projectsUsedLastWeek = 0;
	//variables padres
	parentsRegistered = 0;
	parentsRegisteredLastMonth = 0;
	parentsOnlineLastWeek = 0;
	//variables asignaturas
	subjectsTotal = 0;
	//variables centros
	centersTotal = 0;

	//variables viejas, buscar reemplazo
	texto1Centro: any = {
		title: `${this.translateService.instant("CENTRO.ACIERTOS")}<br>${"0"}`,
		backgroundColor: "#B3E5FC",
		color: "#003B64",
	};
	texto2Centro: any = {
		title: `${this.translateService.instant("CENTRO.CONOCIMIENTO")}<br>${"0"}`,
		backgroundColor: "#E1BEE7",
		color: "#4A0072",
	};
	assignedCenter: any;

	constructor(
		public activeModal: NgbActiveModal,
		private translateService: TranslateService,
		private modalService: NgbModal,
		public loginService: LoginService,
		private mastersService: MastersService,
		private reportService: ReportService,
		private utils: Utils,
		private centerService: CenterService
	) {}

	ngOnInit() {
		this.user = this.loginService.getUser();
		localStorage.removeItem("selectedGroupInfo");
		this.cargarDatosIniciales();
	}

	ngOnDestroy() {}

	closeModal(sendData?: any) {
		this.activeModal.close();
	}

	//============================================
	//========== DATOS INICIALES ==========

	cargarDatosIniciales(){
		this.reportService.datosInicialesPanelControlAdmin().subscribe( res => {
			if(res){
				this.initialData = res.data;
			}
		})
	}

	//=========================================================
	//========== FUNCIONES BOTONES SUPERIORES ==========

	openTable() {
		this.modalService.open(TableInformeComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
		//Creamos el log
		const bodyLog = {
			action: "globales",
			actionData: "Ha entrado en el menú de Globales.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	openTableIndividual() {
		this.modalService.open(TableInformeIndividualComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
		//Creamos el log
		const bodyLog = {
			action: "individuales",
			actionData: "Ha entrado en el menú de Individuales.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	openModalStructure() {
		this.modalService.open(ModalCreateStructureComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
		//Creamos el log
		const bodyLog = {
			action: "estructura",
			actionData: "Ha entrado en el menú de Estructura.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService.createActivityHistory(bodyLog.action, bodyLog.actionData, bodyLog.startDate, bodyLog.endingDate, bodyLog.role, bodyLog.idGroup).subscribe();
	}

	openModalTutorial() {
		this.modalService.open(ModalTutorialManagerComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
	}

}
