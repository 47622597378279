import { filter } from 'rxjs/operators';
import { Profiles } from "src/app/core/utils/profiles.enum";
import { LoginService } from "src/app/core/services/login";
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewEncapsulation,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { QuizElementTypes } from "src/app/core/models/quizzes/quiz-element-types.enum";
import { QuizTypes } from "src/app/core/models/quizzes/quiz-types.enum";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { ModalMicrofonoAudioComponent } from "src/app/shared/components/modal-microfono-audio/modal-microfono-audio.component";
import { QuizElementsPipe } from "src/app/shared/pipes/quiz-elements.pipe";
import { environment } from "src/environments/environment";
import { HypermediaTypes } from "../../../quiz-hypermedia/hypermedia-types.enum";
import { QuizHypermediaComponent } from "../../../quiz-hypermedia/quiz-hypermedia.component";
import { SOCKETMESSAGES } from "src/app/core/services/groups/grupos.service";
import { ListQuizzesStackChallengesModel, QuizzesstackService } from 'src/app/core/services/quizzesstack/quizzesstack.service';
import { LocalStorage } from 'src/app/core/utils';

@Component({
	selector: "app-quiz-play-body-option",
	templateUrl: "./quiz-play-body-option.component.html",
	styleUrls: ["./quiz-play-body-option.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class QuizPlayBodyOptionComponent implements OnInit, OnChanges {
	@Input() quiz;
	@Input() quizType: QuizTypes;
	@Input() options: any[] = [];
	@Input() loadingResponses: any;
	@Input() answered: boolean = false;
	@Input() modeSocket: boolean;
	@Input() selectedType: string = SOCKETMESSAGES.OPEN
	@Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() saveOptionChallenge: EventEmitter<boolean> = new EventEmitter<boolean>();
	quizElementsPipe: QuizElementsPipe = new QuizElementsPipe();
	@Input() filesUploadsViewContent = {
		answersImagen: [],
		answersDocs: [],
		answersAudio: [],
		answersVideo: [],
	};
	@Input() arrayFileView = [];
	@Output() emitAudio: EventEmitter<File> = new EventEmitter<File>();

	public optionForm: UntypedFormGroup;
	public optionFormTexto: UntypedFormGroup;
	@Output() saveOption: EventEmitter<any> = new EventEmitter<any>();
	@Output() saveOptionVideoCamera: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSaveOptionWebCam: EventEmitter<any> = new EventEmitter<any>();
	@Output() saveOptionMultiple: EventEmitter<any> = new EventEmitter<any>();
	@Output() saveOptionText: EventEmitter<any> = new EventEmitter<any>();
	@Output() emitText: EventEmitter<string> = new EventEmitter<string>();

	public O_VIDEOS = QuizElementTypes.O_VIDEOS;
	public O_PICTURES = QuizElementTypes.O_PICTURES;
	public O_AUDIOS = QuizElementTypes.O_AUDIOS;
	public O_PDFS = QuizElementTypes.O_PDFS;
	public O_TEXTS = QuizElementTypes.O_TEXTS;
	public O_MICRO = QuizElementTypes.O_MICRO;
	imagePlay: string;
	isLoading: boolean;
	viewAudio: boolean;
	hideResponse = false; //Si el user es profesor, tenemos que ocultar que pueda contestar el quiz
	hideCounter = true; // Si el user es profesor y está en modo Socket, tengo que mostarle el contador de lo que ha respondido la gente

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: NgbModal,
		private loginService: LoginService,
		private quizzesstackService: QuizzesstackService,
		private localStorage: LocalStorage,
	) {}

	ngOnInit() {
		if (this.loginService.getProfile() === Profiles.Teacher){
			this.hideResponse = true;
			this.answered = false;
		}
		if (this.loginService.getProfile() === Profiles.Teacher && JSON.parse(this.localStorage.getItem('modePractice'))){
			this.hideResponse = false;
			this.answered = false;
		}

		if(this.loginService.getProfile() === Profiles.Teacher && this.modeSocket){
			this.hideCounter = false
		}

		this.optionForm = this.fb.group({
			options: this.fb.array([]),
			correctOptionId: null,
		});
		this.optionFormTexto = this.fb.group({
			answer: [{ value: "", disabled: this.hideResponse }],
		});

		if (this.quizType === QuizTypes.SINGLE) {
			this.optionForm.get("correctOptionId").valueChanges.subscribe((id) => {
				const control: UntypedFormControl = this.optionsArray.controls.filter(
					(c) => c.get("id").value === id
				)[0] as UntypedFormControl;

				if (control) {
					control.get("checked").patchValue(!control.get("checked").value);
					this.optionsArray.controls
						.filter((c) => c.get("id").value !== id)
						.forEach((c) => {
							c.get("checked").patchValue(false);
						});
				}
			});
		}

		this.optionsArray.clear();
		this.options.forEach((a) => {
			let val:ListQuizzesStackChallengesModel[] = []
			if(this.selectedType === SOCKETMESSAGES.ORDERMODLIST)
				val = this.quiz.selectedResponses.filter(e=> e === a.idQuizzesDataElements) as ListQuizzesStackChallengesModel[] //Esto sólo vale para os checkbox
			this.addOption(a, val.length ? true : false);
		});

		if(this.quizType === this.QuizTypes.SINGLE && this.selectedType === SOCKETMESSAGES.ORDERMODLIST){
			this.correctOptionControl.setValue(this.quiz.selectedResponses[0])
		}

		if(this.selectedType === SOCKETMESSAGES.ORDERMODLIST || this.selectedType === SOCKETMESSAGES.ORDERMODAUTO){
			this.optionForm.valueChanges.subscribe(data => {
				this.saveOptionChallenge.emit(true)
			})
		}


		this.vewFileContent();
	}

	ngOnChanges(changes: SimpleChanges): void {

		if (changes.answered && changes.answered.currentValue) {
			this.optionForm.disable();
		}
		//Cuando cambia el array de opciones con los resultados marcados por los alumnos
		if (changes.options && changes.options.previousValue) {
			this.optionsArray.clear();
			this.options = changes.options.previousValue;
			this.options.forEach((a) => {
				this.addOption(a);
			});
		}
	}

	public get optionsArray(): UntypedFormArray {
		return this.optionForm.get("options") as UntypedFormArray;
	}

	public get optionsArrayData(): UntypedFormControl[] {
		return this.optionsArray.controls.map((q) => q.get("data") as UntypedFormControl);
	}

	public get optionsArrayIsCorrect(): UntypedFormControl[] {
		return this.optionsArray.controls.map(
			(q) => q.get("isCorrect") as UntypedFormControl
		);
	}

	public get optionsArrayType(): UntypedFormControl[] {
		return this.optionsArray.controls.map((q) => q.get("type") as UntypedFormControl);
	}

	public get optionsArrayChecked(): UntypedFormControl[] {
		return this.optionsArray.controls.map(
			(q) => q.get("checked") as UntypedFormControl
		);
	}

	public get optionsArrayAnswer(): UntypedFormControl[] {
		return this.optionsArray.controls.map(
			(q) => q.get("answer") as UntypedFormControl
		);
	}

	public get optionsArrayWidth(): UntypedFormControl[] {
		return this.optionsArray.controls.map((q) => q.get("width") as UntypedFormControl);
	}

	public get optionsArrayHeight(): UntypedFormControl[] {
		return this.optionsArray.controls.map(
			(q) => q.get("height") as UntypedFormControl
		);
	}

	public get optionsArrayTotalResponses(): UntypedFormControl[] {
		return this.optionsArray.controls.map(
			(q) => q.get("totalResponses") as UntypedFormControl
		);
	}

	public get correctOptionControl(): UntypedFormControl {
		return this.optionForm.get("correctOptionId") as UntypedFormControl;
	}

	public get QuizTypes() {
		return QuizTypes;
	}

	public get elementTypes() {
		return QuizElementTypes;
	}

	private addOption(option: any, isChecked = false): void {
		const newOption: UntypedFormGroup = this.createOption(option, isChecked);
		this.optionsArray.push(newOption);
	}

	clickedOptionDiv(correctOption: any, clickedFromControl, value) {
		if(correctOption.value != value){
			correctOption.setValue(value,{onlySelf: false, emitEvent: true});
			clickedFromControl.setValue(value,{onlySelf: false, emitEvent: true});
			clickedFromControl.markAsDirty();
			clickedFromControl.markAllAsTouched();
			clickedFromControl.updateValueAndValidity({onlySelf: false, emitEvent: true});
		}
	}

	private createOption(option: any, isChecked:boolean): UntypedFormGroup {
		return this.fb.group({
			id: option ? option.idQuizzesDataElements : "",
			auxId: this.optionsArray.controls.length,
			data: {
				value: option.data,
				disabled:
					this.quizType !== QuizTypes.TEXT ||
					option.elementType !== this.elementTypes.O_TEXTS,
			},
			originalData: option.data,
			type: option.elementType,
			isCorrect: {
				value: !!option.responseCheck,
				disabled: !option || !option.idQuizzesDataElements,
			},
			order: option.yPosition,
			answer: null,
			checked: isChecked,
			width: option ? option.xSize : 0,
			height: option ? option.ySize : 0,
			totalResponses: option.responsesArray ? option.responsesArray.length : 0,
		});
	}

	public emitAnswer() {}

	public onOpenHypermedia(
		event: MouseEvent,
		index: number,
		insert: boolean = true,
		type?: number
	) {
		event.stopPropagation();
		const currentControl = this.optionsArray.controls[index] as UntypedFormGroup;

		const modalRef = this.modalService.open(QuizHypermediaComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W95,
		});
		modalRef.componentInstance.insert = insert;

		modalRef.componentInstance.fileSelected.subscribe((data: any) => {
			modalRef.close();
			const file = data.file;
			const id = currentControl.get("id").value;

			currentControl.get("answer").patchValue(file);
			currentControl.get("data").patchValue(URL.createObjectURL(file));

			this.saveOption.emit({
				element: { ...currentControl.getRawValue() },
				file: data.file,
			});
		});
	}

	closeModal(sendData?: any) {
		this.close.next(true);
	}

	public onFileSelected(index: number, event: any, type: HypermediaTypes) {
		if (+type === this.O_TEXTS) {
			const currentControl = this.optionFormTexto.value.answer;
			this.saveOptionText.emit(currentControl);
		} else {
			const currentControl = this.optionsArray.controls[index] as UntypedFormGroup;
			const file = event.target.files[0];
			currentControl.get("answer").patchValue(file);
			currentControl.get("data").patchValue(URL.createObjectURL(file));
			this.saveOption.emit({
				element: { ...currentControl.getRawValue() },
				file,
				index,
			});
		}
	}

	//activar click de los botones de hypermedia para los compu-correct
	openFile(i: number, type) {
		let current = this.optionsArray.controls[i];
		if (!this.answered) {
			if (
				type.type === this.O_VIDEOS &&
				this.optionsArray.controls[i].value.answer === null
			)
				document.getElementById("" + i).click();
			else if (type.type === this.O_PICTURES)
				document.getElementById("" + i).click();
			else if (
				type.type === this.O_AUDIOS &&
				this.optionsArray.controls[i].value.answer === null
			)
				document.getElementById("" + i).click();
			else if (
				type.type === this.O_PDFS &&
				this.optionsArray.controls[i].value.answer === null
			)
				document.getElementById("" + i).click();
		}
	}

	vewFileContent() {
		let url = `${environment.quizzesContent}`;
		for (let index = 0; index < this.optionsArray.controls.length; index++) {
			const element = this.optionsArray.controls[index];
			if (index !== 0) {
				if (element.value.type === this.O_PICTURES) {
					this.arrayFileView.push({ url: url + "picture/", view: false });
				} else if (element.value.type === this.O_VIDEOS) {
					this.arrayFileView.push({ url: url + "video/", view: false });
				} else if (element.value.type === this.O_PDFS) {
					this.arrayFileView.push({ url: url + "pdf/", view: false });
				} else if (element.value.type === this.O_AUDIOS) {
					this.arrayFileView.push({ url: url + "audio/", view: false });
				}
			} else {
				this.arrayFileView.push("");
			}
		}
	}

	//Remplazar contenido
	onFileSelectedReplace(i: number, type) {
		let current = this.optionsArray.controls[i];
		if (!this.answered) {
			if (type.type === this.O_VIDEOS) document.getElementById("" + i).click();
			else if (type.type === this.O_PICTURES)
				document.getElementById("" + i).click();
			else if (type.type === this.O_AUDIOS)
				document.getElementById("" + i).click();
			else if (type.type === this.O_PDFS)
				document.getElementById("" + i).click();
		}
	}

	//activar click de los botones de hypermedia para los compu-correct
	grabarAudio(index: number, type) {
		const modalRef = this.modalService.open(ModalMicrofonoAudioComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
		modalRef.componentInstance.soloAudio = true;
		modalRef.result.then((res) => {
			const currentControl = this.optionsArray.controls[index] as UntypedFormGroup;
			currentControl.get("answer").patchValue(res);
			currentControl.get("data").patchValue(res);
			this.saveOption.emit({
				element: { ...currentControl.getRawValue() },
				res,
				index,
			});
		});
	}

	_grabarAudio(index: number, type) {
		this.viewAudio = true;
	}

	//activar click de los botones de hypermedia para los compu-correct
	grabarVideo(index: number, type) {
		const modalRef = this.modalService.open(ModalMicrofonoAudioComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
		modalRef.componentInstance.soloVideo = true;
		modalRef.result.then((res) => {
			const currentControl = this.optionsArray.controls[index] as UntypedFormGroup;
			currentControl.get("answer").patchValue(res.files);
			currentControl.get("data").patchValue(res.files);
			this.saveOptionVideoCamera.emit({
				element: { ...currentControl.getRawValue() },
				res,
				index,
			});
		});
	}
	//activar click de los botones de hypermedia para los compu-correct
	tomarFoto(index: number, type) {
		const modalRef = this.modalService.open(ModalMicrofonoAudioComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
		modalRef.componentInstance.soloFoto = true;
		modalRef.result.then((res) => {
			const currentControl = this.optionsArray.controls[index] as UntypedFormGroup;
			currentControl.get("answer").patchValue(res.file);
			currentControl.get("data").patchValue(res.file);
			this.onSaveOptionWebCam.emit({
				element: { ...currentControl.getRawValue() },
				res,
				index,
			});
		});
	}

	closeElement(event) {
		this.viewAudio = event;
	}

	emitAudioEvent($event: File) {
		this.emitAudio.emit($event);
	}

	readText() {
		let text = this.optionFormTexto.value;
		this.emitText.emit(text);
	}
}
