import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { PrefixCountry } from 'src/app/core/models/shared/prefixCountry.model';
import { LoginService } from 'src/app/core/services/login';
import { HttpClient } from '@angular/common/http';
import { NbDialogRef } from '@nebular/theme';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { MastersService } from 'src/app/core/services/masters';

@Component({
	selector: 'app-modal-send-comments',
	templateUrl: './modal-send-comments.component.html',
	styleUrls: ['./modal-send-comments.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ModalSendCommentsComponent implements OnInit {
	sendCommentsForm: UntypedFormGroup;
	private _maxLength: number = 15
	$phonePrefixes: Observable<PrefixCountry[]>
	validationMessages = {
		name: [],
		phone: [],
		extension: [],
		email: [],
		comentary: [],
		prefix: []
  };


	constructor(public activeModal: NgbActiveModal,
		public loginService: LoginService,
		private formBuild: UntypedFormBuilder,
		public translateService: TranslateService,
		private http: HttpClient,
		private dialogRef: NbDialogRef<any>,
		private toaster: ToasterService,
		private masterService: MastersService,
		) {
		this.sendCommentsForm = this.formBuild.group({
			name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
			email: ['', [Validators.required, Validators.email]],
			comentary: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(500)]],
			phone: ['', [Validators.required]],
			extension: ['']
		});
	}


	ngOnInit() {
		this.$phonePrefixes = this.loginService.getPhonePrefix()
		this.cargarValidacionesFormulario();
		//definimos variable para tutoriales
		this.masterService.openModalTutorial(4);
	}

	closeModal(sendData?: any) {
		this.dialogRef.close(sendData);
	}

	sendFormData(data):Observable<any> {
		return this.http.post<any>("user/supportEmail/outside",data)
	}

	showStatus() {
	}

	sendComments() {
		if (this.sendCommentsForm.valid) {
			let data = this.sendCommentsForm.value
			data.extension = Number(data.extension.replace('+',''))
			data.phone = Number(data.phone)
			this.sendFormData(data).subscribe(res => {
				this.toaster.success(this.translateService.instant(res.error.msg));
				this.closeModal(true)
			})
		}
	}

	private cargarValidacionesFormulario() {

    this.translateService.get('VALIDACIONES.ELEMAILESOBLIGATORIO').subscribe((res: string) => {
      this.validationMessages.email.push({type: 'required', message: res});
  	});

    this.translateService.get('VALIDACIONES.ELTELEFONOMOVILESOBLIGATORIO').subscribe((res: string) => {
      this.validationMessages.phone.push({type: 'required', message: res});
    });

    this.translateService.get('VALIDACIONES.MAXLENGTH').subscribe((res: string) => {
        this.validationMessages.phone.push({ type: 'maxlength', message: res.replace('{1}', `${this._maxLength}`) });
    });

	this.translateService.get('VALIDACIONES.MAXLENGTH').subscribe((res: string) => {
		this.validationMessages.name.push({ type: 'maxlength', message: res.replace('{1}', `${this._maxLength}`) });
	});

	this.translateService.get("VALIDACIONES.PREFIXREQUIRED").subscribe((res: string) => {
		this.validationMessages.prefix.push({ type: "required", message: res });
	});

  }

}
