import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalMicrofonoAudioComponent } from 'src/app/shared/components/modal-microfono-audio/modal-microfono-audio.component';
import { HypermediaItem } from './hypermedia-item.model';
import { hypermedias, hypermediasFamiliaIdeas } from './hypermedia-types';
import { HypermediaTypes } from './hypermedia-types.enum';

@Component({
    selector: 'app-quiz-hypermedia',
    templateUrl: './quiz-hypermedia.component.html',
    styleUrls: ['./quiz-hypermedia.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuizHypermediaComponent implements OnInit {
    @Input() insert: boolean = true;
    @Output() fileSelected: EventEmitter<any> = new EventEmitter<any>();
    public mostrarMic: boolean = true;
    public familiaIdeas: boolean = false;
    hypermedias: HypermediaItem[] = hypermedias;
    hypermediasFamilia: HypermediaItem[] = hypermediasFamiliaIdeas;
    constructor(private activeModal: NgbActiveModal, private modalService: NgbModal) { }

    ngOnInit() {
    }

    public get hypermediaFiles() {
        return this.hypermedias.filter(h => h.isFile);
    }

    public get hypermediaNotFiles() {
        return this.hypermedias.filter(h => !h.isFile);
    }

    public onCloseModal(sendData?: any) {
        this.activeModal.close(false);
    }

    public onFileSelected(event: any, type: HypermediaTypes) {
        if (this.insert) {
            const file = event.target.files[0];
            this.fileSelected.emit({ file: file, type: type });
        }
    }

    public onTypeSelected(type: HypermediaTypes) {

        this.fileSelected.emit({ file: null, type: type });
    }

    //activar click de los botones de hypermedia para los compu-correct
    grabarAudio(){
        const modalRef = this.modalService.open(ModalMicrofonoAudioComponent,
            {
                scrollable: true,
                windowClass: MODAL_DIALOG_TYPES.W30
            }
        );
        modalRef.componentInstance.soloAudio = true;
        modalRef.componentInstance.soloVideo = false;
        modalRef.result.then(res => {
            if (res !== undefined) {
                this.fileSelected.emit({ file: res, type: HypermediaTypes.AUDIO });
            }
        })
    }
    //activar click de los botones de hypermedia para los compu-correct
    grabarVideo(){
        const modalRef = this.modalService.open(ModalMicrofonoAudioComponent,
            {
                scrollable: true,
                windowClass: MODAL_DIALOG_TYPES.W30
            }
        );
        modalRef.componentInstance.soloAudio = false;
        modalRef.componentInstance.soloVideo = true;
        modalRef.result.then(res => {
            if (res !== undefined) {
                this.fileSelected.emit({ file: res, type: HypermediaTypes.VIDEO });
                // if (res.reload) {
                //     setTimeout(() => {
                //         this.grabarVideo();
                //     }, 500);
                // }
                // else {
                //     this.fileSelected.emit({ file: res, type: HypermediaTypes.VIDEO });
                // }
            }
        },
        (reason)=>{
            console.log('reason :>> ', reason);
        });
    }

    tomarFoto(){
        const modalRef = this.modalService.open(ModalMicrofonoAudioComponent,
            {
                scrollable: true,
                windowClass: MODAL_DIALOG_TYPES.W30
            }
        );
        modalRef.componentInstance.soloFoto = true;
        modalRef.componentInstance.soloAudio = false;
        modalRef.componentInstance.soloVideo = false;
        modalRef.result.then(res => {
            if (res !== undefined) {
                this.fileSelected.emit({ file: res, type: HypermediaTypes.IMAGE });
            }
        },
        (reason)=>{
            console.log('reason :>> ', reason);
        });
    }
}
