
import { catchError, concatMap, delay, filter } from 'rxjs/operators';
import { SigmaToolbarsService } from './../../../../core/services/sigma-toolbars/sigma-toolbars.service';
import { CourseEditorsModel } from './../../../../core/models/courses/course-editors.model';
import { gEdge } from './../../../../core/models/graph/gEdge.model';
import { BackgroundUtils } from './utils/background.utils';
import { NodeOnTopResponse, QuizModel } from 'src/app/core/models/quizzes';
import { NodeUtils } from './utils/node.utils';
import { QuizUtils } from './utils/quiz.utils';
import { StickerUtils } from './utils/sticker.utils';
import { SigmaCanvasUtils } from './utils/canvas.utils';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SigmaUtils } from 'src/app/core/utils/sigma-utils';
import { GraphService } from 'src/app/core/services/graph/graph.service';
// Components
import { gNode, ObjetoNodoAuto } from 'src/app/core/models/graph/gNode.model';
import { NodeService } from 'src/app/core/services/node/node.service';
import { PowerService } from 'src/app/core/services/power/power.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { LoginService } from 'src/app/core/services/login';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ModalAceptarCancelarComponent, ModalComponent } from 'src/app/shared/components/modal';
import { finalize, map, take } from 'rxjs/operators';
import { NodeLinkComponent } from '../node-link/node-link.component';
import { TranslateService } from '@ngx-translate/core';
import { SIGMA_CONSTANTS, MULTIPLEXEDQUIZZES } from 'src/app/core/utils/sigma-constants';
import { SigmaCanvasService } from './sigma-canvas.service';
import { forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';
import { NodeCoverPipe } from 'src/app/shared/pipes/node-cover.pipe';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ActionModel } from 'src/app/core/models/shared/actions.model';
import { NodeModeAutoComponent } from '../../../../shared/components/nodes/node-auto/node-mode-auto.component';
import { StickersService } from 'src/app/core/services/stickers/stickers.service';
import { QuizEditMultipleComponent } from '../quiz-edit-multiple/quiz-edit-multiple.component';
import { CoursesService } from 'src/app/core/services/courses';
import { User } from 'src/app/core/models/users/user.models';
import { MastersService } from 'src/app/core/services/masters';
import { PointNodeService } from 'src/app/core/services/point-node/point-node.service';
import { Profiles } from 'src/app/core/utils/profiles.enum';
import { CanvasData, Estado } from 'src/app/core/models/tuturial/tutorial.model';
import { Utils } from 'src/app/core/utils/utils';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
	Event,
} from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { ModalCursosListadoComponent } from 'src/app/shared/components/cursos/modal-cursos-listado';
import { environment } from 'src/environments/environment';
import { DomainTypes } from 'src/app/core/models/masters/masters.enum';
import { NodeConnectModel, OptionsNodeConnectModel } from './sigma-canvas.model';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ACTIONS } from 'src/app/core/utils/actions';
import { PadsUtils } from 'src/app/core/utils/pads.utils';
import { ModalProfileActionsComponent } from '../../../../shared/components/modal-profile-actions/modal-profile-actions.component';
import { UsersService } from 'src/app/core/services/users';
import { LocalStorage } from 'src/app/core/utils';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { InactivityService } from 'src/app/core/services/inactivity/inactivity.service';
import { settings } from 'cluster';
import { TargetsService } from 'src/app/core/services/targets';
import { SosService } from 'src/app/core/services/sos/sos.service';
import { MeetingsComponent } from 'src/app/pages/meetings/meetings.component';
import { NbWindowService } from '@nebular/theme';
import { ModalIdeasOrderComponent } from '../../modal-ideas-order/modal-ideas-order.component';
import { ModalCursoDetailComponent } from '../../cursos/modal-curso-detail/modal-curso-detail.component';
import { SalwizarContenidoComponent } from '../../salwizar-contenido/salwizar-contenido.component';
import { ModalGeneratedIdeasComponent } from '../../salwizar-contenido/modal-generated-ideas/modal-generated-ideas.component';
import { SalwizarQuizzesComponent } from '../../salwizar-quizzes/salwizar-quizzes.component';
import { ControlPanelComponent } from '../../control-panel/control-panel.component';
import { ModalYoutubeIframeComponent } from '../../modal/modal-youtube-iframe/modal-youtube-iframe.component';
import { ManageGraphShapeComponent } from '../../manage-graph-shape/manage-graph-shape.component';
import { ManageIdeasOptionsComponent } from '../../manage-ideas-options/manage-ideas-options.component';
import { ControlPanelAdminComponent } from '../../control-panel-admin/control-panel-admin.component';

declare var $: any;
@Component({
	selector: "app-sigma-canvas",
	templateUrl: "./sigma-canvas.component.html",
	styleUrls: ["./sigma-canvas.component.scss"],
	providers: [ImagenPipe],
})
export class SigmaCanvasComponent implements OnInit, OnChanges {
	@ViewChild("modalConnect") modalConnect: ModalComponent;
	@ViewChild("modalAlert") modalAlert: ModalComponent;
	@Output() nextEvenCopilot = new EventEmitter<number>();

	@Input() idCurso: number;
	@Input() tutorialGrafo: CanvasData;
	@Input() idMapa: number;
	@Input() canEdit: boolean = false;
	@Input() isGuest: boolean = false;
	@Input() background: string;
	@Input() changedToEditor: boolean;
	@Input() graphTitle: string;
	@Input() defaultViewType: number;

	isDemo: boolean = environment.domain === DomainTypes.DEMO ? true : false;
	loginRoute = "/auth/login";
	register = "/auth/sign-up";

	private sigmaUtils: SigmaUtils;
	private eventSubscriptions = [];
	public currentGraph;
	private selection = [];
	listQuiz: any = [];
	public ModoAuto: ObjetoNodoAuto;
	public powerListString = {
		power0: "power0",
		power1: "power1",
		power2: "power2",
		power3: "power3",
		powerNegative1: "powerNegative1",
		powerNegative2: "powerNegative2",
		powerNegative3: "powerNegative3",
	};
	public nextBackNode = { avanzar: false, regresar: false };
	zoomNode: number = 0;
	isFooterOpen = true;
	explicacionesQuizzes: any = [];
	ordenSeleccionado: number[] = [0];
	paginaSeleccionada: number = 0;
	usuario: User;
	tutorialSW: any;
	version: string = "";
	imagePlaceHolder: any;
	description: string;
	infoNodeTitlePlaceHolder: string;
	idElements: gNode[];
	anteriorNodo: gNode;
	nodeClickPopover: gNode;
	countHelp: number = 0;
	mostrarAyuda: boolean;
	mostrarAyudaQuiz: boolean;
	countHelpQuiz: number = 0;
	buscarNodo: boolean = false;
	isNode: boolean = false;
	isRented: boolean = false;
	isModalOpen: boolean = false;
	modoTraerNodo: boolean = false;
	modoTraerActividad: boolean = false;
	public formulario: UntypedFormGroup;
	lastNodeId: any = "";
	viewTypeGraph = { viewType: 0, set: false };
	user: User;
	userIsEditor: boolean;
	nextEnabled: boolean = true;
	backEnabled: boolean = false;
	selectedGraph: any;
	userIsBeingQuestioned: boolean = false;
	keepCheckingSos: boolean;
	intervalId: NodeJS.Timeout;
	savedInterval: any;
	generatedQuizzes: {
		Numero: number;
		Titulo: string;
		Pregunta: string;
		Opciones: { Correcta: string; Incorrecta1: string; Incorrecta2: string };
	}[];
	previewNodesSubscription: Subscription;
	designIdeasWindowOpen: boolean = false;
	openedWindow: import("@nebular/theme").NbWindowRef<any, any>;
	realIndex = [];
	isSOSClicked: boolean = false;
	generatedIdeasExists: boolean = false;
	initialPositions: any;
	selectedShape: any;
	/**QUE ES ESTO REY?? JAJAJA */
	//courseInfo: import("c:/Users/Rey/Documents/dev/salware/rep-angular-desa/src/app/core/models/courses/course-detail-response.model").CourseDetailResponseModel;
	courseInfo: any;

	public get selectedNodes(): any[] {
		return this.selection;
	}

	// Mode
	addMode: boolean = false;
	infoMode: boolean = false;
	selectionMode: boolean = true;
	connectMode: boolean = false;
	disconnectMode: boolean = false;

	infoNodeTitle: string = "";
	infoNodeDesc: string = "";
	overNode: boolean = false;
	clickNode: boolean = false;
	rightclickNode: boolean = false;
	clickEdge: boolean = false;
	overEdge: boolean = false;

	startDragCoords: any;
	private showStaircase: boolean = false;

	isShowPads: boolean = false;
	isShowLabels: boolean = false;

	// Detectar si se hace drag del fondo del canvas.
	isDragging: boolean = false;
	clickStageCoords: any;

	// Dibujar Ejes
	drawAxis: boolean = false;

	public viewType = {
		nodes: true,
		quizzes: true,
	};

	private hiddedSticker = null;
	public progress: number = 0;

	// HTML vars
	public connectN1: NodeConnectModel = {
		id: "",
		idOriginal: 0,
		url: "",
		label: "",
	};
	public connectN2: NodeConnectModel = {
		id: "",
		idOriginal: 0,
		url: "",
		label: "",
	};

	public connectIcon = "link";
	public loading = false;
	public g;

	//INI QUIZ TIENE TEMPLATE
	templateDatos: any[] = [];
	isLoading: boolean = false;
	courseTitle: string = "";
	actualProfile: string;
	profiles = Profiles;
	private _profileSubscription: Subscription;
	private _showModalSubscription: Subscription;
	private _updateGraph: Subscription;

	private _sizeInterval: any;
	modePractice = false;
	public loadInitialGraph: boolean;
	initialGraphs = [889797, 888958];
	proyectoControl: boolean = false;

	@ViewChild("sigmaContainer", { static: true })
	sigmaContainer: ElementRef<HTMLElement>;
	@ViewChild("nodeQuizzEditPopover", { static: true })
	nqEditPopover: ElementRef<HTMLElement>;
	@ViewChild("nodeQuizzPlaceHolderPopover", { static: true })
	nqPlaceholderPopover: ElementRef<HTMLElement>;
	@ViewChild("pActions", { static: true }) pActions: ElementRef<HTMLElement>;
	constructor(
		private graphServ: GraphService,
		private powerServ: PowerService,
		private pointNodeService: PointNodeService,
		private nodeService: NodeService,
		private quizService: QuizzesService,
		private courseService: CoursesService,
		private router: Router,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		private toolsService: SigmaToolbarsService,
		public loginService: LoginService,
		public dialog: MatDialog,
		private toaster: ToasterService,
		private translateService: TranslateService,
		private sigmaCanvasService: SigmaCanvasService,
		private nodeCoverPipe: NodeCoverPipe,
		private stickersServices: StickersService,
		private masterServices: MastersService,
		private utils: Utils,
		public scUtils: SigmaCanvasUtils,
		public nodeUtils: NodeUtils,
		private stickerUtils: StickerUtils,
		public quizUtils: QuizUtils,
		public bgUtils: BackgroundUtils,
		private formBuild: UntypedFormBuilder,
		private padsUtils: PadsUtils,
		public usersService: UsersService,
		private localStorage: LocalStorage,
		private inactivityService: InactivityService,
		private sosService: SosService,
		public targetsService: TargetsService,
		private windowService: NbWindowService,
		private quizzesService: QuizzesService,
		private cdr: ChangeDetectorRef,
		private masterService: MastersService,
		//public discoverOrder: DiscoverOrder
	) {
		this.user = this.loginService.getUser();
		this.inactivityService.startInactivityTimer();
	}
	// Escuchar eventos de actividad en el componente y reiniciar el temporizador.
	@HostListener("document:click", ["$event"])
	//@HostListener('document:mousemove', ['$event'])
	@HostListener("document:keypress", ["$event"])
	onActivity(event: MouseEvent | KeyboardEvent) {
		this.inactivityService.resetInactivityTimer();
	}

	ngOnInit() {
		this.proyectoControl = this.localStorage.getItem("proyectoControl") ?
			JSON.parse(this.localStorage.getItem("proyectoControl")) : false;
		this.designIdeasWindowOpen = false;
		this.loadInitialGraph = JSON.parse(
			this.localStorage.getItem("loadInitialGraph")
		);
		this.sigmaCanvasService.getIsLoading().subscribe((res: boolean) => {
			this.isLoading = res;
			this.cdr.detectChanges();
		});
		this.initComponent();
		this.formulario = this.formBuild.group({ certificadoQuiz: [false] });
		this.showProfileActionsModal();
		this.actualProfile = this.loginService.getProfile();
		this.nodeService.getRedirectedNode().subscribe((node: gNode) => {
			if (Object.keys(node).length != 0) {
				this.playNode(node);
			}
		});

		this.nodeService.getRedirectedQuiz().subscribe((quiz: any) => {
			if (Object.keys(quiz).length != 0) {
				this.playQuiz(quiz);
			}
		});

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				// Show loading indicator
			}

			if (event instanceof NavigationEnd) {
				this.powerServ.emitPowerActive([-3, -2, -1, 0, 1, 2, 3]);
				//this.initComponent()
			}

			if (event instanceof NavigationError) {
				// Hide loading indicator

				// Present error to user
				console.log(event.error);
			}
		});
		this.checkIfUserHasPendingPetitions();
		this.previewNodesSubscription = this.sigmaCanvasService.getPreviewNode().subscribe((res: string) => {
			if(res == "none"){
				return;
			}

			if (res != "none") {
				if(res == "single"){
					this.addSelectedIANodeToCanvas();
				} else {
					this.previewNodesInCanvas(res);
				}
			}

		});

		this.sigmaCanvasService
			.getOpenDesignIdeasWindow()
			.subscribe((res: boolean) => {
				if(res == true){
					this.openDesignIdeas();
				}
			});

			this.generatedIdeasExists = this.localStorage.getItem("IAGeneratedIdeas") ? true : false;
	}

	sosIntervalHandler() {
		this.intervalId = setInterval(() => {
			if (this.userIsBeingQuestioned == false) {
				this.checkIfSomeoneWannaHelp();
			}
		}, 3000);
	}

	checkIfUserHasPendingPetitions() {
		this.sosService.userHasPendingPetitions().subscribe((res: any) => {
			this.sosIntervalHandler();
			if (res.data == true) {
				this.keepCheckingSos = res.data;
				if (this.keepCheckingSos == true) {
				} else if (this.keepCheckingSos == false) {
					clearInterval(this.intervalId);
				}
			} else if (res.data == false) {
				clearInterval(this.intervalId);
			}
		});
	}

	addSticker() {
		this.stickerUtils.addSticker();
	}

	addText() {
		this.stickerUtils.addText();
	}

	fixZoom(action?: string) {
		if (action == "fix") {
			this.selectedGraph.zoomX = this.sigmaUtils.sigma.camera.x;
			this.selectedGraph.zoomY = this.sigmaUtils.sigma.camera.y;
			this.selectedGraph.zoomRatio = this.sigmaUtils.sigma.camera.ratio;
			this.sigmaCanvasService.zoomX = this.sigmaUtils.sigma.camera.x;
			this.sigmaCanvasService.zoomY = this.sigmaUtils.sigma.camera.y;
			this.sigmaCanvasService.zoomRatio = this.sigmaUtils.sigma.camera.ratio;
			this.selectedGraph.userResolution = `${
				window.screen.height * window.devicePixelRatio
			}`;
		} else if (action === "unfix") {
			this.selectedGraph.zoomX = 0;
			this.selectedGraph.zoomY = 0;
			this.selectedGraph.zoomRatio = 0;
			this.sigmaCanvasService.zoomX = 0;
			this.sigmaCanvasService.zoomY = 0;
			this.sigmaCanvasService.zoomRatio = 0;
		}
		this.targetsService
			.setTarget(
				this.selectedGraph,
				this.selectedGraph.targetImage,
				this.idCurso
			)
			.subscribe(
				(event: HttpEvent<any>) => {
					switch (event.type) {
						case HttpEventType.UploadProgress:
							this.progress = Math.round((event.loaded / event.total) * 100);
							break;
						case HttpEventType.Response:
							setTimeout(() => {
								this.scUtils.hidePopWindows();
								if (action == "fix") {
									this.toaster.success(
										this.translateService.instant("SIGMACOMPONENT.FIXEDZOOM")
									);
								} else {
									this.toaster.success(
										this.translateService.instant("SIGMACOMPONENT.UNFIXEDZOOM")
									);
								}
							}, 500);
					}
				},
				(error) => {
					this.toaster.error(
						this.translateService.instant("EDITARCURSO.KOSAVE")
					);
				}
			);
	}

	tutorialCanvasGrafo(step: number): void {
		this.nextEvenCopilot.emit(step);

		if (step === 5) {
			this.tutorialGrafo.nodoNodo = true;
		}
		if (step === 6) {
			this.tutorialGrafo.quizQuiz = true;
		}
	}

	setViewType() {
		switch (this.defaultViewType) {
			case 1:
				setTimeout(() => {
					this.toolsService.setHideElements();
				}, 500);
				this.viewTypeGraph.set = true;
				break;
			case 2:
				setTimeout(() => {}, 500);
				this.viewTypeGraph.set = true;
				break;
			case 3:
				setTimeout(() => {
					this.toolsService.changeVisibility({ nodes: true, quizzes: false });
				}, 200);
				this.viewTypeGraph.set = true;
				break;
			case 4:
				setTimeout(() => {
					this.toolsService.changeVisibility({ nodes: false, quizzes: true });
				}, 200);
				this.viewTypeGraph.set = true;
				break;
		}
	}

	private _getInfoCourse(): void {
		this.courseService.getCourseById(this.idCurso).subscribe((course) => {
			this.courseInfo = course;
			//se hace que si el titulo es LanguageRounded, se acorta y se ponen ... suspensivos
			const title = course.data.courses.courseTittle;
			if (title.length > 24) this.courseTitle = title.substring(0, 22) + "...";
			else this.courseTitle = title;
		});
	}

	modAutoPrototype(listQuiz: any) {
		//definimos variable para tutoriales
		let idTutorialContext = 0;
		let filtroInicial = this.localStorage.getItem("filtroInicial");
		let lastSelectedProfile = this.localStorage.getItem("lastSelectedProfile");
		if (filtroInicial == "explicacionAsociativa" && this.loginService.esProfesor()) {
			idTutorialContext = 17;
		} else if(filtroInicial == "practicar" && this.loginService.esProfesor()){
			idTutorialContext = 26;
		} else if(filtroInicial == "pruebaNivel" && this.loginService.esProfesor()){
			idTutorialContext = 30;
		} else if (filtroInicial == "abpCasos" && this.loginService.esProfesor()) {
			idTutorialContext = 34;
		} else if (filtroInicial == "pensarCreando" && this.loginService.esProfesor()) {
		} else if (filtroInicial == "pensarCreando(ver)" && this.loginService.esProfesor()) {
			idTutorialContext = 42;
		}
		if(lastSelectedProfile === "AUTOR" && this.loginService.esProfesor()){
			idTutorialContext = 87;
		} else if(lastSelectedProfile === "AUTOR" && !this.loginService.esProfesor()){
			idTutorialContext = 91;
		} else if(lastSelectedProfile === "PROFESOR"){
			idTutorialContext = 46;
		} else if(lastSelectedProfile === "ESTUDIANTE"){
			idTutorialContext = 77;
		}
        this.localStorage.setItem("idTutorialContext", idTutorialContext);
        if(this.localStorage.getItem("TutorialesON") == "true"){
            this.masterService.openModalTutorial(idTutorialContext);
        }
		let currentPower = this.powerServ.getPower();

		//devuelve el objeto ya ordenado para recorrer en modo auto

		this.ModoAuto = new ObjetoNodoAuto(listQuiz.nodes, currentPower);

		this.idElements = this.ModoAuto.explicacionesPower0;
		this.graphServ.getFlickNode(this.idCurso, this.idMapa).subscribe(
			(res) => {
				if (res.data.idNode !== 0) {
					this.lastNodeId = "n" + res.data.idNode;
				}
				this.susbcribeChangedPointNode();
			},
			(err) => {
				this.susbcribeChangedPointNode();
			}
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.userIsEditor = this.loginService.esAutor();
		if (changes.background) {
			this.bgUtils.background = changes.background.currentValue;
			this.bgUtils.setGraphBackground(changes.background.currentValue);
		} else if (changes.canEdit) {
			this.scUtils.hidePopWindows();
			$("#pActions").hide();
		}
	}

	ngOnDestroy() {
		this.previewNodesSubscription.unsubscribe();
		this.loginService.currentProfile.next(null);
		this._profileSubscription.unsubscribe();
		this._updateGraph != undefined ? this._updateGraph.unsubscribe() : "";
		this._showModalSubscription != undefined
			? this._showModalSubscription.unsubscribe()
			: "";
		this.eventSubscriptions.forEach((s) => s.unsubscribe());
	}

	private triggerInitSigma() {
		this.sigmaCanvasService.triggerInitSigma.subscribe((res) => {
			if (res == true) {
				this.initComponent(true);
			}
		});
	}

	// ---------------------------------------
	//  TOOLBARS SUBSCRIPTIONS
	// ---------------------------------------
	private subscribeToolbars() {
		this.toolsService.visibility.subscribe((visibility) => {
			this.changeView(visibility);
		});

		this._showModalSubscription = this.toolsService.showModalCourses.subscribe(
			(value) => {
				//Se muestra el modal de los cursos con el modo listado o en detalle del curso con los grafos
				if (value === "inlineList"){
					this.scUtils.showListCourses(this.idCurso);
				} else if(value == "gridList"){
					this.scUtils.showCourseGraphs(this.idCurso, 0);
				} else if(value == "verLista"){
					this.scUtils.showCourseGraphs(this.idCurso, this.idMapa);
				}
			}
		);

		this._updateGraph = this.toolsService.updateGraph.subscribe((value) => {
			if (this._sizeInterval) {
				clearInterval(this._sizeInterval);
			}
			this.initComponent(value);
		});
	}

	private subscribePowerActive() {
		this.powerServ.powerActive.subscribe((powersActive: Array<number>) => {
			this.sigmaCanvasService.discoverModeOn.subscribe((res) => {
				if (res == false) {
					this.ordenSeleccionado = powersActive;
					this.zoomNode = 0;
					let filter =
						this.powerServ.convertNumberArrayToPowerObject(powersActive);
					this.changeView({ nodes: true, quizzes: true });
					let vReturn: boolean = false;

					//Escondo todo y luego muestro las cosas
					this.sigmaUtils.allNodes().map((node) => {
						if (
							node.nodeType === SIGMA_CONSTANTS.NODE_TYPE ||
							node.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE
						)
							node.hidden = true;
					});

					let nodes: any[] = this.sigmaUtils
						.allNodes()
						.filter((n) => n.nodeType === SIGMA_CONSTANTS.NODE_TYPE);

					//Si el editor decide crear un quiz sin tener creada ninguna idea, hay que mostrarlo
					if (!nodes.length)
						this.sigmaUtils
							.allNodes()
							.filter((n) => n.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE)
							.map((quiz) => (quiz.hidden = false));
					else {
						nodes.map((node) => {
							if (node.power0 && filter[this.powerListString.power0]) {
								this.idElements = this.ModoAuto.explicacionesPower0;
								vReturn = true;
								node.hidden = !vReturn;
							} else if (node.power1 && filter[this.powerListString.power1]) {
								this.idElements = this.ModoAuto.explicacionesPower1;
								vReturn = true;
								node.hidden = !vReturn;
							} else if (node.power2 && filter[this.powerListString.power2]) {
								this.idElements = this.ModoAuto.explicacionesPower2;
								vReturn = true;
								node.hidden = !vReturn;
							} else if (node.power3 && filter[this.powerListString.power3]) {
								this.idElements = this.ModoAuto.explicacionesPower3;
								vReturn = true;
								node.hidden = !vReturn;
							} else if (
								node.powerNegative1 &&
								filter[this.powerListString.powerNegative1]
							) {
								this.idElements = this.ModoAuto.explicacionesPowerMinus1;
								vReturn = true;
								node.hidden = !vReturn;
							} else if (
								node.powerNegative2 &&
								filter[this.powerListString.powerNegative2]
							) {
								this.idElements = this.ModoAuto.explicacionesPowerMinus2;
								vReturn = true;
								node.hidden = !vReturn;
							} else if (
								node.powerNegative3 &&
								filter[this.powerListString.powerNegative3]
							) {
								this.idElements = this.ModoAuto.explicacionesPowerMinus3;
								vReturn = true;
								node.hidden = !vReturn;
							} else {
								vReturn = false;
								node.hidden = !vReturn;
							}

							if (vReturn) this.scUtils.showQuizzes(node.id); // Mostramos los quizzes asociados a los nodos que estén en la potencia/s selecciona/s
						});
					}

					this.sigmaUtils.refresh();
				}
			});
		});
	}

	private subscribeModeDiscover() {
		this.sigmaCanvasService.discoverModeOn.subscribe(
			(discoverMode: boolean) => {
				if (discoverMode == true) {
					this.changeView({ nodes: true, quizzes: true });

					//Escondo todo y luego muestro las cosas
					this.sigmaUtils.allNodes().map((node) => {
						if (
							(node.nodeType === SIGMA_CONSTANTS.NODE_TYPE ||
								node.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE) &&
							node.ordinalPower0 != 1
						) {
							node.hidden = true;
						}
					});
				} else {
					this.sigmaUtils.allNodes().map((node) => {
						if (
							node.nodeType === SIGMA_CONSTANTS.NODE_TYPE ||
							node.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE
						)
							node.hidden = false;
					});
				}
				//Si el editor decide crear un quiz sin tener creada ninguna idea, hay que mostrarlo
				this.sigmaUtils.refresh();
			}
		);
	}

	private susbcribeChangedPointNode() {
		let start = false;
		let idNode;

		let pos: number =
			this.lastNodeId !== ""
				? this.idElements.findIndex((element) => {
						return element.id === this.lastNodeId;
				  })
				: 0;

		this.zoomNode = pos;

		setTimeout(() => {
			if (this.loginService.esEstudiante() || this.loginService.esProfesor) {
				start = true;
				if (this.idElements[pos]) {
					idNode = this.idElements[pos].id;
					this.actionInCanvas(idNode, this.anteriorNodo);
				}
			}
		}, 1000);

		this.pointNodeService.pointNodeActive.subscribe(
			(values: {
				avanzar: boolean;
				regresar: boolean;
				initialDiscover: boolean;
			}) => {
				if (this._sizeInterval) {
					if (values.initialDiscover == true) {
						this.savedInterval = this._sizeInterval;
						if (start == false) {
							clearInterval(this._sizeInterval);
						}
					} else {
						clearInterval(this.savedInterval);
						this.savedInterval = null;
						clearInterval(this._sizeInterval);
					}
				}

				this.nextBackNode = values;
				if (
					!start &&
					this.nextBackNode.avanzar &&
					this.scUtils.existsElement(pos, this.idElements)
				) {
					this.zoomNode = pos;
					start = true;
					idNode = this.idElements[pos].id;
					this.actionInCanvas(idNode, this.anteriorNodo);
				}
				//**Metodo que verifica si existe un siguiente elemento this.existsElement(pos)*/
				else if (
					start &&
					this.nextBackNode.avanzar &&
					this.scUtils.existsElement(pos + 1, this.idElements)
				) {
					pos = pos + 1;
					//si es el ultimo bloqueamos el boton derecho
					let pos2 = pos;
					while (this.scUtils.existsElement(pos2 + 1, this.idElements)) {
						pos2 += 1;
					}
					if (pos === pos2) {
						this.sigmaCanvasService.setNextEnabled(false);
					}
					this.sigmaCanvasService.setBackEnabled(true);
					this.zoomNode = pos;
					idNode = this.idElements[pos].id;

					this.actionInCanvas(idNode, this.anteriorNodo);
				} else if (
					start &&
					this.nextBackNode.regresar &&
					this.scUtils.existsElement(pos - 1, this.idElements)
				) {
					pos = pos - 1;
					//si es el primero bloqueamos el boton izquierdo
					if (pos === 0) {
						this.sigmaCanvasService.setBackEnabled(false);
					}
					this.sigmaCanvasService.setNextEnabled(true);
					this.zoomNode = pos;
					idNode = this.idElements[pos].id;
					this.actionInCanvas(idNode, this.anteriorNodo);
				}
			}
		);
	}
	actionInCanvas(idNode: string, anteriorNodo: gNode) {
		if (this.lastNodeId !== "") {
			if (anteriorNodo !== undefined && anteriorNodo.id !== idNode) {
				// idNode = this.lastNodeId;
				this.lastNodeId = idNode;
			} else {
				idNode = this.lastNodeId;
			}
		}
		if (anteriorNodo !== undefined) {
			this.sigmaUtils
				.allNodes()
				.filter((sigmaNode) => sigmaNode.id === anteriorNodo.id)
				.map((value) => {
					this.sigmaUtils.decrementNodeSize(value);
				});
		}
		this.sigmaUtils
			.allNodes()
			.filter((sigmaNode) => sigmaNode.id === idNode)
			.map(
				(value) => {
					clearInterval(this._sizeInterval);
					this._sizeInterval = this.sigmaUtils.nodeAnimation(value);
					this.anteriorNodo = { ...value };
				},
				(err) => {
					console.log(err);
				}
			);
	}

	// ---------------------------------------
	//  JQUERY EVENT SUBSCRIPTIONS
	// ---------------------------------------
	private subscribejQueryClickEvent() {
		let self = this;

		$("canvas").click(
			function (e) {
				if (this.loginService.esAutor() && this.canEdit) {
					$(".popover, .popover2").hide();
					if (!this.isDragging && !this.clickNode && !this.clickEdge) {
						this.addMode = true;
						// if ((this.addMode) && (!this.isDragging) && (!this.clickNode)) {    // ADD NEW NODE PANEL
						var pos = $("canvas").offset(); // $('canvas').position();
						var left = e.clientX; //e.pageX;
						var top = e.clientY; //e.pageY;
						var height = $("#pActions").height();

						$("#pActions").css("left", left - pos.left + 15 + "px");
						$("#pActions").css("top", top - height / 2 - 10 + "px");

						$("#pActions").show();

						var width = $("#pActions").width();
						self._resizePopover(width);
					} else {
						this.addMode = false;
						this.sigmaUtils.dropNode("temp");
						if (this.showStaircase) this.sigmaUtils.drawAxis();
					}
				}
				self.toolsService.setHideElements();
				self.toolsService.setHideMenuLateral();
			}.bind(this)
		);
	}

	private _resizePopover(width: number) {
		let currentClass = "";
		$("#pActions").find(".w-20").length !== 0
			? (currentClass = ".w-20")
			: (currentClass = ".w-25");

		if (width >= 250 && width < 300) {
			$(`#pActions ${currentClass}`).addClass("w-50");
			$(`#pActions ${currentClass}`).removeClass("w-100");
		} else if (width < 250) {
			$(`#pActions ${currentClass}`).removeClass("w-50");
			$(`#pActions ${currentClass}`).addClass("w-100");
		} else $(`#pActions ${currentClass}`).removeClass("w-100 w-50");
	}

	private subscribeJQueryMouseUp() {
		$("canvas").on(
			"mouseup",
			function (e) {
				this.isDragging = false;
				if (
					Math.abs(e.clientX - this.clickStageCoords.x) > 5 ||
					Math.abs(e.clientY - this.clickStageCoords.y) > 5
				)
					this.isDragging = true;
			}.bind(this)
		);
	}

	private subscribeJQueryMouseDown() {
		$("canvas").on(
			"mousedown",
			function (e) {
				this.clickStageCoords = { x: e.clientX, y: e.clientY };
			}.bind(this)
		);
	}

	private subscribejQueryMouseMoveEvent() {
		$("canvas").on(
			"mousemove",
			function (e) {
				$("#pConnect").hide();
				if (!this.addMode && this.infoMode) {
					$("#pNodeInfo").hide();
					$("#pActions").hide();
					this.sigmaUtils.dropNode("temp");

					if (
						this.overNode &&
						(!$("#pNodeMenu").is(":visible") ||
							!$("#pConnectInfo").is(":visible"))
					) {
						var left = e.pageX;
						var top = e.pageY;
						var pos = $("canvas").offset(); // $("canvas").position();
						var height = $("#pNodeInfo").height();
						$("#pNodeInfo").css("left", left - pos.left + 30 + "px");
						$("#pNodeInfo").css("top", top - height / 2 - 10 + "px");
						$("#pNodeInfo").show();
					}
				}

				if (this.connectMode) {
					var pos = $("canvas").position();
					var left = e.pageX;
					var top = e.pageY;
					var height = $("#pConnect").height();

					$("#pConnect").css("left", left - pos.left + "px");
					$("#pConnect").css("top", top - height / 2 - 10 + "px");
					$("#pConnect").show();
				}
			}.bind(this)
		);
	}

	private jqueryContextMenu() {}

	private subscribeUpdateCoords() {
		var c = this.sigmaUtils.sigma.camera;
		let self = this;
		c.bind("coordinatesUpdated", function () {
			if (self.showStaircase)
				self.scUtils.viewStaircaseChart(
					self.showStaircase,
					self.sigmaUtils,
					self.viewType
				);
		});
	}

	private subscribeNewNode() {
		this.nodeService.newNode.subscribe((newNode) => {
			this.sigmaUtils.addNode(newNode);
			this.sigmaUtils.refresh();
		});
	}

	private subscribeNewQuiz() {
		this.quizService.newQuiz.subscribe((newQuiz) => {
			this.sigmaUtils.addNode(newQuiz);
			this.sigmaUtils.refresh();
		});
	}

	// ---------------------------------------
	//  SIGMAJS EVENT SUBSCRIPTIONS
	// ---------------------------------------

	// Cuando se hace click en una parte vacia del canvas crea un nodo temporal que se ve como un punto
	private subscribeClickStageEvent() {
		this.eventSubscriptions["clickStageEvent"] = this.sigmaUtils
			.getemitclickStageEvent()
			.subscribe((e) => {
				$(".popover3").hide();
				this.rightclickNode = false;
				this.connectMode = false;

				if (!this.isDragging && this.loginService.esAutor() && this.canEdit) {
					var cx = e.data.captor.x;
					var cy = e.data.captor.y;
					var node = this.graphServ.emptygNode();
					this.sigmaUtils.addTempNode(
						node,
						cx,
						cy,
						this.loginService.getUser()
					);
					this.clickNode = false;
					var positionClick = { clientX: cx, clientY: cy };
					var canvas = document.getElementById("sigma-container");
					var pos = this.sigmaUtils.getMousePos(canvas, positionClick);
					localStorage.setItem("mouseClick", JSON.stringify(pos));
				}
			});
	}

	private subscribedoubleClickNodeEvent() {
		this.eventSubscriptions["doubleclickNodeEvent"] = this.sigmaUtils
			.getemitdoubleclickNodeEvent()
			.subscribe((e) => {
				this.clickNode = true;
				this.rightclickNode = false;
				this.connectMode = false;
				if (e.data.node.nodeType === SIGMA_CONSTANTS.NODE_TYPE) {
					$(".pActions").hide();
					this.scUtils.hidePopWindows();
					if (this.canEdit) {
						this.editNode(e.data.node);
					} else {
						this.playNode(e.data.node);
					}
				}
			});
	}

	// Cuando se situa el cursor sobre un nodo cambia el estilo del cursor y rellena las propiedades infoNodeTitle y infoNodeDesc si se está en modo info. Marca overNode a true
	//y muestra un popover con info del nodo.
	private subscribeOverNodeEvent() {
		this.eventSubscriptions["overNodeEvent"] = this.eventSubscriptions.push(
			this.sigmaUtils.getemitoverNodeEvent().subscribe((e) => {
				this.sigmaContainer.nativeElement.style.cursor = "pointer";
				if (e.data.node.nodeType !== SIGMA_CONSTANTS.QUIZ_TYPE) {
					this.overNode = true;

					//Esconder sticker cuando el usuario vaya a interactuar con un nodo y un sticker esté por debajo
					if (!this.loginService.esAutor()) {
						if (
							e.data.node.nodeType == SIGMA_CONSTANTS.STICKER_TYPE &&
							e.data.node.link === ""
						) {
							this.hiddedSticker = e.data.node;
							e.data.node.hidden = true;
						}
					}

					if (this.infoMode && e.data.node) {
						this.infoNodeTitle = e.data.node.label;
						if (e.data.node.description) {
							this.infoNodeDesc = e.data.node.description;
						} else {
							this.infoNodeDesc = "...";
						}
					}
				} else {
					if (!this.loginService.esAutor()) {
						if (e.data.node.nodeType == SIGMA_CONSTANTS.STICKER_TYPE) {
							this.hiddedSticker = e.data.node;
							e.data.node.hidden = true;
						}
					}
				}
			})
		);
	}

	// Al quitar el curso de un nodo marca overNode a false y devuelve el cursor a estilo default
	private subscribeOutNodeEvent() {
		this.eventSubscriptions["outNodeEvent"] = this.sigmaUtils
			.getemitoutNodeEvent()
			.subscribe((e) => {
				this.overNode = false;
				this.sigmaContainer.nativeElement.style.cursor = "default";

				if (!this.loginService.esAutor()) {
					if (this.hiddedSticker) {
						if (this.currentGraph.stickers.length) {
							this.currentGraph.stickers.forEach((e) => {
								this.sigmaUtils.sigma.graph.nodes(e.id).hidden = false;
							});
							this.hiddedSticker = null;
							this.sigmaUtils.refresh();
						}
					}
				}
			});
	}

	/**
	 * CLICK ON AN EDGE
	 */
	private subscribeClickEdgeEvent() {
		this.eventSubscriptions["clickEdgeEvent"] = this.sigmaUtils
			.getemitclickEdgeEvent()
			.subscribe((e) => {
				this.connectMode = false;
				this.clickNode = false;
				this.rightclickNode = false;
				this.clickEdge = true;
				this.scUtils.hidePopWindows();

				let edge: gEdge = e.data.edge;
				let nodeFrom: gNode,
					nodeTo: gNode = null;

				nodeFrom = this.sigmaUtils.sigma.graph.nodes(edge.source);
				nodeTo = this.sigmaUtils.sigma.graph.nodes(edge.target);

				let connectN1: NodeConnectModel = {
					id: nodeFrom.id,
					idOriginal: nodeFrom.idOriginal,
					url: nodeFrom.url,
					label: nodeFrom.label,
				};
				let connectN2: NodeConnectModel = {
					id: nodeTo.id,
					idOriginal: nodeTo.idOriginal,
					url: nodeTo.url,
					label: nodeTo.label,
				};
				let options: OptionsNodeConnectModel = {
					type: edge.type,
					color: edge.color,
					size: edge.size,
					label: edge.label,
					id: edge.id,
				};

				if (
					edge.connectionType === SIGMA_CONSTANTS.NODES_NODES &&
					this.loginService.esAutor() &&
					this.canEdit &&
					nodeFrom.nodeType === SIGMA_CONSTANTS.NODE_TYPE &&
					nodeTo.nodeType === SIGMA_CONSTANTS.NODE_TYPE
				) {
					this.conectarNodos(connectN1, connectN2, options);
				}
			});
	}

	private subscribeOverEdgeEvent() {
		this.eventSubscriptions["overEdgeEvent"] = this.eventSubscriptions.push(
			this.sigmaUtils.getemitoverEdgeEvent().subscribe((e) => {
				let edge: gEdge = e.data.edge;
				if (
					edge.connectionType === SIGMA_CONSTANTS.NODES_NODES &&
					this.loginService.esAutor() &&
					this.canEdit
				)
					this.sigmaContainer.nativeElement.style.cursor = "pointer";
			})
		);
	}

	// Al quitar el curso de un nodo marca overNode a false y devuelve el cursor a estilo default
	private subscribeOutEdgeEvent() {
		this.eventSubscriptions["outEdgeEvent"] = this.sigmaUtils
			.getemitoutEdgeEvent()
			.subscribe((e) => {
				let edge: gEdge = e.data.edge;
				this.overEdge = false;
				this.clickEdge = false;
				if (
					edge.connectionType === SIGMA_CONSTANTS.NODES_NODES &&
					this.loginService.esAutor() &&
					this.canEdit
				)
					this.sigmaContainer.nativeElement.style.cursor = "default";
			});
	}

	// Cuando se hace click en un nodo o quizz llama a playNode si tiene nodeType Node o a playQuizz si no
	private subscribeClickNodeEvent() {
		this.eventSubscriptions["clickNodeEvent"] = this.sigmaUtils
			.getemitclickNodeEvent()
			.subscribe((e) => {
				this.connectMode = false;
				this.clickNode = true;
				this.rightclickNode = false;

				if (!this.loginService.esAutor()) {
					if (this.hiddedSticker) {
						if (this.currentGraph.stickers.length) {
							this.currentGraph.stickers.forEach((e) => {
								this.sigmaUtils.sigma.graph.nodes(e.id).hidden = false;
							});
							this.hiddedSticker = null;
							this.sigmaUtils.refresh();
						}
					}
				}

				if (!this.isDragging && !this.isLoading) {
					if (e.data.node.nodeType === SIGMA_CONSTANTS.NODE_TYPE) {
						$(".pActions").hide();
						$(".popover3").hide();
						this.openPopoverInfoNodo(e);
					} else if (e.data.node.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE) {
						if (this.canEdit) {
							//ASI Sabemos si el quiz lo ha creado el dueño del grafo o es un quiz alquilado

							//if (e.data.node.user.idUser !== this.loginService.getUser().idUser) {
							//Alex: recoger la lista de editores y permitir editar, si no, entonces si es alquilado
							//se hardcodea en false hasta que se cambie
							if (false) {
								/**
								 * Mientras se desallora el backen se desarrolla Si el
								 * quiz es alquilado de momento mostraremos un mensaje  que no se puede editar
								 **/
								this.toaster.success(
									this.translateService.instant(
										"SIGMACOMPONENT.ACTIVITYSTUDENTMODE"
									)
								);
								this.playQuiz(e.data.node);
							} else {
								if (
									e.data.node.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE &&
									e.data.node.isMultiplexed === MULTIPLEXEDQUIZZES.ISMULTIPLEXED
								) {
									this.editQuizMultiple(e.data.node);
								} else if (
									e.data.node.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE &&
									e.data.node.isMultiplexed ===
										MULTIPLEXEDQUIZZES.ISNOTMULTIPLEXED
								) {
									this.isModalOpen = true;
									this.quizUtils
										.editQuiz(e.data.node, this.countHelpQuiz)
										.finally(() => (this.isModalOpen = false));
								}
							}
						} else {
							if (
								e.data.node.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE &&
								e.data.node.isMultiplexed === MULTIPLEXEDQUIZZES.ISMULTIPLEXED
							) {
								this.playQuiz(e.data.node);
							} else if (
								e.data.node.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE &&
								e.data.node.isMultiplexed ===
									MULTIPLEXEDQUIZZES.ISNOTMULTIPLEXED
							) {
								this.playQuiz(e.data.node);
							}
						}
					} else if (
						e.data.node.nodeType === SIGMA_CONSTANTS.STICKER_TYPE ||
						e.data.node.nodeType === SIGMA_CONSTANTS.TEXT_TYPE
					) {
						const link: string = e.data.node.link;
						if (link) {
							const url = new URL(link);
							const courseRegex = /\/course\/(\d+)$/; // Expresión regular para encontrar el ID del curso
							const match = link.match(courseRegex); // Buscar el patrón en la URL

							if (match) {
								const idCurso = parseInt(match[1]); // Extraer el ID del curso del resultado de la coincidencia
								const modalRef = this.modalService.open(
									ModalCursoDetailComponent,
									{
										scrollable: false,
										windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
									}
								);
								modalRef.componentInstance.id = idCurso;
								modalRef.componentInstance.modoTraerNodo = undefined;
								modalRef.componentInstance.modoTraerActividad = undefined;
								modalRef.componentInstance.profileTeacher = false;
								modalRef.componentInstance.idGroup = 0;
								modalRef.componentInstance.actividadesFlash = false;
								modalRef.componentInstance.fromSticker_grafo = false;
								modalRef.result.then(
									(result) => {
										// Manejar el resultado del modal si es necesario
									},
									(reason) => {
										// Manejar la razón del cierre del modal si es necesario
									}
								);
							} else {
								if (window.location.hostname === url.hostname) {
									window.open(link, "_self");
								} else {
									if (url.hostname.includes("youtube.com") || url.hostname.includes("youtu.be")) {
										const modal = this.modalService.open(ModalYoutubeIframeComponent, {
											scrollable: false,
											windowClass: MODAL_DIALOG_TYPES.W30,
										});
										modal.componentInstance.setVideoUrl(link);
									} else {
										window.open(link, "_blank");
									}
								}
							}
						}
					}
				}
			});
	}

	openPopoverInfoNodo(e: any) {
		$(".popover3").hide();
		window.oncontextmenu = function () {
			return false;
		};
		if (e.data.node.id !== "temp") {
			this.nodeClickPopover = e.data.node as gNode;
			if (e.data.node.nodeType === SIGMA_CONSTANTS.NODE_TYPE) {
				var nqPlaceholderElement = this.nqPlaceholderPopover.nativeElement;
				nqPlaceholderElement.style.display = "block";
				this.imagePlaceHolder = `url(${e.data.node.url})`;

				this.description =
					e.data.node.description.trim() == ""
						? this.translateService.instant("SIGMACOMPONENT.WITHOUTDESCRIPTION")
						: e.data.node.description;
				this.infoNodeTitlePlaceHolder =
					!e.data.node.label ||
					e.data.node.label == "" ||
					e.data.node.label.length < 1
						? this.translateService.instant("SIGMACOMPONENT.WITHOUTTITLE")
						: e.data.node.label;
				let top: number;
				let left: number;
				var pos = this.sigmaUtils.getMousePos(
					this.sigmaContainer.nativeElement,
					e.data.captor
				);
				if (pos.y < 100) {
					top = 20;
				} else if (
					this.sigmaContainer.nativeElement.clientHeight - pos.y <
					150
				) {
					top = this.sigmaContainer.nativeElement.clientHeight / 2;
				} else {
					top = nqPlaceholderElement.clientHeight / 2;
				}
				nqPlaceholderElement.style.top = `${top}px`;

				if (this.sigmaContainer.nativeElement.clientWidth - pos.x < 350) {
					left =
						this.sigmaContainer.nativeElement.clientWidth -
						(nqPlaceholderElement.clientWidth + 30);
					nqPlaceholderElement.style.left = left + "px";
				} else {
					nqPlaceholderElement.style.left = pos.x + 20 + "px";
				}
			}
		}
	}

	// Al hacer click derecho sobre un nodo o quizz muestra un popover con diferentes acciones
	private subscriberightClickNodeEvent() {
		this.eventSubscriptions["rightclickNodeEvent"] = this.sigmaUtils
			.getemitrightClickNodeEvent()
			.subscribe((e) => {
				if (this.canEdit) {
					$(".popover").hide();
					$(".popover3").hide();
					window.oncontextmenu = function () {
						return false;
					};
					this.clickNode = false;
					this.overNode = false;
					this.connectMode = false;
					this.rightclickNode = true;
					this.isNode = false;
					if (!this.isDragging && e.data.node.id !== "temp") {
						var nqEditPopoverElement = this.nqEditPopover.nativeElement;
						nqEditPopoverElement.style.display = "block";

						if (e.data.node.nodeType === SIGMA_CONSTANTS.NODE_TYPE) {
							this.isNode = true;
							this.isRented =
								this.idMapa === e.data.node.idTargetCreation ? false : true; // asi sabemos si el nodo es alquilado
							this.infoNodeTitle =
								!e.data.node.label ||
								e.data.node.label == "" ||
								e.data.node.label.length < 1
									? this.translateService.instant("SIGMACOMPONENT.COURSENONAME")
									: this.translateService.instant("SIGMACOMPONENT.COURSE") +
									  "'" +
									  e.data.node.label +
									  "'";
						} else if (e.data.node.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE) {
							this.infoNodeTitle =
								!e.data.node.quizTittle ||
								e.data.node.quizTittle == "" ||
								e.data.node.quizTittle.length < 1
									? this.translateService.instant("SIGMACOMPONENT.QUIZNONAME")
									: this.translateService.instant("SIGMACOMPONENT.QUIZ") +
									  "'" +
									  e.data.node.quizTittle +
									  "'";
							this.formulario.patchValue({
								certificadoQuiz: e.data.node.certifiedQuiz === 1 ? true : false,
							});
						} else if (e.data.node.nodeType === SIGMA_CONSTANTS.STICKER_TYPE) {
							this.infoNodeTitle = SIGMA_CONSTANTS.STICKER_TYPE;
						}

						var pos = this.sigmaUtils.getMousePos(
							this.sigmaContainer.nativeElement,
							e.data.captor
						);
						nqEditPopoverElement.style.left = pos.x + "px";
						nqEditPopoverElement.style.top = pos.y + "px";
						$(".popover").placement = "auto";
						localStorage.setItem("nodeData", JSON.stringify(e.data.node));
					}
				}
			});
	}

	// Evita que el click derecho en el lienzo vacío haga algo
	private subscriberightClickStageEvent() {
		this.eventSubscriptions["rightclickStageEvent"] = this.sigmaUtils
			.getemitrightClickStageEvent()
			.subscribe((e) => {
				this.addMode = false;
				this.connectMode = false;
				this.rightclickNode = false;
				this.scUtils.hidePopWindows();
			});
	}

	// Controla cuando un nodo se está arrastrando si se encuentra encima de otro o no para mostrar la opción de crear un enlace
	private subscribeDragEvent() {
		this.eventSubscriptions["dragEvent"] = this.sigmaUtils
			.getemitDragEvent()
			.subscribe((e) => {
				$(".popover3").hide();
				this.connectMode = false;
				var nodes = this.sigmaUtils.getNodesOnDropEvent(e);
				let result: NodeOnTopResponse = null;

				if (
					nodes &&
					nodes.length &&
					e.data.node.nodeType !== SIGMA_CONSTANTS.STICKER_TYPE
				) {
					if (
						this.canEdit &&
						(result = this.sigmaCanvasService.isNodeOnTopOfAnother(
							e.data.node,
							nodes
						)).value
					) {
						//If the elment is a node or a quiz, to connect it
						if (result.node.nodeType !== SIGMA_CONSTANTS.STICKER_TYPE) {
							result.node.color = "red";
							e.data.node.color = "red";
							this.connectMode = true;

							if (this.sigmaUtils.checkConnection(e.data.node, result.node)) {
								this.connectIcon = "link_off";
							} else {
								this.connectIcon = "link";
							}
						} else this.sigmaUtils.restoreAllOriginalNodeColors();
					} else {
						this.sigmaUtils.restoreAllOriginalNodeColors();
					}
				} else {
					this.connectMode = false;
				}
			});
	}

	// Para asociar un nodo con otro nodo
	private subscribeAsociarNodoEvent() {
		this.eventSubscriptions["clickNodeEvent"] = this.sigmaUtils
			.getemitclickNodeEvent()
			.subscribe((e) => {
				this.connectMode = false;
				this.clickNode = true;
				this.rightclickNode = false;
				if (!this.isDragging && !this.isLoading) {
					if (this.buscarNodo) {
						let n1 = JSON.parse(
							localStorage.getItem(LOCALSTORAGESTRINGS.N1)
						) as any;
						this.conectarNodosAss(n1, e.data.node);
					} else {
						this.conectarNodos(this.connectN1, this.connectN2);
					}
				}
			});
	}

	// Controla cuando se suelta un nodo, si está encima de otro al cual no está conectado muestra un modal para añadir la conexión
	private subscribeDropEvent() {
		this.eventSubscriptions["dropEvent"] = this.sigmaUtils
			.getemitDropEvent()
			.subscribe((e) => {
				$(".popover3").hide();
				this.scUtils.hidePopWindows();
				this.connectMode = false;
				// Recupera si se ha soltado sobre otro para crear una conexion
				let result: NodeOnTopResponse = null;
				var nodes = this.sigmaUtils.getNodesOnDropEvent(e);
				if (
					nodes &&
					nodes.length &&
					e.data.node.nodeType !== SIGMA_CONSTANTS.STICKER_TYPE
				) {
					if (
						this.canEdit &&
						(result = this.sigmaCanvasService.isNodeOnTopOfAnother(
							e.data.node,
							nodes
						)).value &&
						result.node.nodeType !== SIGMA_CONSTANTS.STICKER_TYPE
					) {
						this.connectN1 = {
							id: e.data.node.id,
							idOriginal: e.data.node.idOriginal,
							url: e.data.node.url,
							label: e.data.node.label,
						};
						this.connectN2 = {
							id: result.node.id,
							idOriginal: result.node.idOriginal,
							url: result.node.url,
							label: result.node.label,
						};

						//Revisar cuando se crea uno nuevo
						if (
							!this.sigmaUtils.checkConnection(e.data.node, result.node) &&
							this.loginService.esAutor() &&
							this.canEdit
						) {
							if (
								e.data.node.nodeType === SIGMA_CONSTANTS.NODE_TYPE &&
								result.node.nodeType === SIGMA_CONSTANTS.NODE_TYPE
							) {
								this.conectarNodos(this.connectN1, this.connectN2);
							} else {
								if (
									this.scUtils.checkAlternativeQuizzes(
										e.data.node,
										result.node,
										this.sigmaUtils.sigma.graph.nodes(),
										this.sigmaUtils.sigma.graph.edges()
									)
								) {
									e.data.node.x = this.startDragCoords.x;
									e.data.node.y = this.startDragCoords.y;
									this.sigmaUtils.restoreAllOriginalNodeColors();
									this.sigmaUtils.refresh();
									return false;
								} else {
									this.sigmaCanvasService
										.connectQuizzes(e.data.node, result.node)
										.subscribe(
											(res) => {
												res.data != null ? (e.data.node.ordinal = res.data) : 0;
												if (
													this.loginService.getUser().tutorialSW.estado ==
													Estado.PENDIENTE
												) {
													if (
														!this.tutorialGrafo.quizQuiz &&
														e.data.node.nodeType ===
															SIGMA_CONSTANTS.QUIZ_TYPE &&
														result.node.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE
													) {
														this.tutorialCanvasGrafo(6);
													}
												}
												this.toaster.success(
													this.translateService.instant("NODOS.LINKOK")
												);
												this.sigmaUtils.restoreAllOriginalNodeColors();
											},
											(err) => {
												this.toaster.error(
													this.translateService.instant("NODOS.LINKNOK")
												);
												this.sigmaUtils.restoreAllOriginalNodeColors();
											}
										);
								}
							}
						} else if (this.canEdit) {
							this.sigmaCanvasService
								.deleteEdge(e.data.node, result.node)
								.pipe(
									finalize(() => this.sigmaUtils.restoreAllOriginalNodeColors())
								)
								.subscribe(
									(res: any) => {
										this.toaster.success(
											this.translateService.instant("NODOS.UNLINKOK")
										);
									},
									(err) => {
										this.toaster.error(
											this.translateService.instant("NODOS.UNLINKNOK")
										);
									}
								);
						}
						if (this.startDragCoords !== undefined) {
							e.data.node.x = this.startDragCoords.x;
							e.data.node.y = this.startDragCoords.y;
							this.sigmaUtils.refresh();
						}
					} else this.sigmaUtils.restoreAllOriginalNodeColors();
				} else this.sigmaUtils.restoreAllOriginalNodeColors();

				this.startDragCoords = undefined;

				//UPDATE ELEMENT POSITION
				if (this.canEdit) {
					this.sigmaCanvasService.savePosition(
						e.data.node.idOriginal,
						e.data.node.x,
						e.data.node.y,
						e.data.node.nodeType,
						this.loginService.getUser().idUser,
						e.data.node
					);
				}
			});
	}

	// Almacena las coordenadas originales de un nodo cuando se empieza a arrastrar
	private subscribeStartDragEvent() {
		this.eventSubscriptions["startDragEvent"] = this.sigmaUtils
			.getemitstartDragEvent()
			.subscribe((e) => {
				this.startDragCoords = { x: e.data.node.x, y: e.data.node.y };
			});
	}

	// ---------------------------------------
	//  ACTIONS
	// ---------------------------------------

	getActionFromRightMenu(action: ActionModel) {
		switch (action.name) {
			case ACTIONS.SHOWHIDEPADS:
				if (action.value) {
					if (this.isShowLabels) this.showHideLabels(true);
					else this.showHideLabels(false);
				}
				this.isShowPads = this.utils.padsStatus.showPadsGraph = action.value;
				this.utils.savePadsLabelsStatus(LOCALSTORAGESTRINGS.PADSLOCALCATEGORY);
				break;
			case ACTIONS.SHOWHIDELABELS:
				this.isShowLabels = this.utils.labelsStatus.showLabelsGraph =
					action.value;
				this.utils.savePadsLabelsStatus(
					LOCALSTORAGESTRINGS.LABELSLOCALCATEGORY
				);
				break;
			case ACTIONS.PRINT:
				this.sigmaUtils.adjustGraphToPrint();
				setTimeout(() => {
					window.print();
					this.sigmaUtils.centerGraph();
				}, 0);
				break;
		}
	}

	getActionFromPads(event: ActionModel) {
		switch (event.name) {
			case ACTIONS.ZOOMIN:
				this.sigmaUtils.zoomIn();
				break;
			case ACTIONS.ZOOMOUT:
				this.sigmaUtils.zoomOut();
				break;
			case ACTIONS.CENTERGRAPH:
				if (event.value != true) {
					this.sigmaUtils.setCustomZoomAnimation(
						event.value.x,
						event.value.y,
						event.value.ratio
					);
				} else {
					this.sigmaUtils.centerGraph();
				}
		}
	}

	// --------------------------------------
	// TOOLBAR MENU RECEIVED EVENTS
	// --------------------------------------

	private changeView($event) {
		this.isLoading = true;
		this.cdr.detectChanges();
		if ($event.nodes !== this.viewType.nodes) {
			this.scUtils.changeView(
				SIGMA_CONSTANTS.NODE_TYPE,
				$event.nodes,
				this.sigmaUtils
			);
		}
		if ($event.quizzes !== this.viewType.quizzes) {
			this.scUtils.changeView(
				SIGMA_CONSTANTS.QUIZ_TYPE,
				$event.quizzes,
				this.sigmaUtils
			);
		}
		this.viewType = $event;
		setTimeout(() => {
			this.isLoading = false;
			this.cdr.detectChanges();
		}, 300);

	}

	onRightClick() {
		// Disable context menu.
		return false;
	}

	onResize($event) {
		if (this.showStaircase)
			this.scUtils.viewStaircaseChart(
				this.showStaircase,
				this.sigmaUtils,
				this.viewType
			);
	}

	toggleFooter() {
		this.isFooterOpen = !this.isFooterOpen;
	}

	public registrarNodo() {
		this.nodeUtils.registrarNodo().subscribe(
			(res) => {
				switch (res.type) {
					case HttpEventType.Response:
						this.sigmaUtils.dropNode("temp");
						this.sigmaUtils.addNode(res.body.data);
						if (
							this.loginService.getUser().tutorialSW.estado === Estado.PENDIENTE
						) {
							this.countHelp++;
							if (this.countHelp === 2) {
								this.mostrarAyuda = true;
							}
						}
						this.editNode(res.body.data, true);
				}
			},
			(err) => {
				this.toaster.error(
					this.translateService.instant("NODEFORMCOMPONENT.ERROR")
				);
			}
		);
	}

	previewNodesInCanvas(res) {
		this.initialPositions = JSON.parse(this.localStorage.getItem("mouseClick"));
		this.selectedShape = this.localStorage.getItem("SelectedShape");
		this.sigmaCanvasService.setPreviewNode("none");
		localStorage.removeItem("temporalNodes");
		this.sigmaUtils.allNodes().forEach((element) => {
			this.sigmaCanvasService.deleteSigmaNode(element).subscribe();
		});

		// get selected shape from localStorage
		let shape = this.localStorage.getItem("SelectedShape");
		let iaGeneratedContent = JSON.parse(this.localStorage.getItem("IAGeneratedIdeas"));
		let arrayShapes = JSON.parse(iaGeneratedContent);
		//from iaGeneratedIdeas we going to get the item that has codigo == to shape

		let selectedIdea = arrayShapes.find((element) => element.codigo == shape);

		let selectedIdeasArray = selectedIdea.Estructura_Ideas;

		//ideas sorted by Numero

		let ideas = selectedIdeasArray.sort((a, b) => a.Numero - b.Numero);

		switch (this.selectedShape) {
			case '7':
				ideas = this.calculateCircularPositions(
					ideas,
					400,
					this.initialPositions.x,
					this.initialPositions.y
				);
				break;
			case '2':
				ideas = this.calculateStarPositions(
					ideas,
					400,
					this.initialPositions.x,
					this.initialPositions.y
				);
				break; // Added break here
			case '3':
				ideas = this.calculateVerticalTreePositions(
					ideas,
					this.initialPositions.x,
					this.initialPositions.y,
					400,
					300
				);
				break; // Added break here
			case '9':
				ideas = this.calculateLinearPositions(
					ideas,
					this.initialPositions.x,
					this.initialPositions.y,
					this.initialPositions.x + 400,
					this.initialPositions.y
				);
				break;
			default:
    		// Handle case when selectedShape is '20' or any other unhandled shape
				ideas = this.calculateCircularPositions(
					ideas,
					400,
					this.initialPositions.x,
					this.initialPositions.y
				);
				break; // Added break here
		}

		setTimeout(() => {

			ideas.forEach((element, index) => {
			var node = this.graphServ.emptygNode();
			node.label = element.Titulo_de_la_Idea;
			node.description = element.Resumen;
			node.Number = element.Numero;
			node.ordinalPower0 = element.Numero
			let texto = element.Cuerpo_Explicativo_de_la_Idea;
			let numero = element.Numero;
			this.sigmaUtils.addTempNode(
				node,
				element.x,
				element.y,
				this.loginService.getUser()
			);
			if (index === ideas.length - 1) {
				const temporalNodes = JSON.parse(
					localStorage.getItem("temporalNodes") || "[]"
				).sort((a, b) => a.Number - b.Number);

				const delayTime = 100; // 1 second delay

			from(temporalNodes)
				.pipe(
					concatMap((element, i) => {
						this.realIndex.push(i);
						return this.nodeUtils.registrarNodoCustom(element).pipe(
							delay(delayTime) // Add delay between each request
						);
					})
				)
				.subscribe(
					(res) => {
						switch (res.type) {
							case HttpEventType.Response:
								ideas[this.realIndex[0]].idOriginal = res.body.data.idOriginal;
			 					ideas[this.realIndex[0]].id = res.body.data.id;
								this.sigmaUtils.dropNode("temp");

								// Remove the first element of realIndex array
								this.realIndex.shift();

								//this.localStorage.setItem("IAGeneratedIdeas", JSON.stringify(ideas));
								this.sigmaCanvasService.setPreviewNode("none");
								this.nodeService
									.postNodeFile(
										res.body.data.idNodesFile,
										"textAdd",
										undefined,
										undefined,
										texto
									)
									.subscribe((response) => {
										if (
											this.loginService.getUser().tutorialSW.estado ===
											Estado.PENDIENTE
										) {
											this.countHelp++;
											if (this.countHelp === 2) {
												this.mostrarAyuda = true;
											}
										}

									});

								this.toaster.success(
									this.translateService.instant("NODEFORMCOMPONENT.SAVED")
								);

								this.localStorage.setItem(
									"nodesInCanvas",
									JSON.stringify(this.sigmaUtils.allNodes())
								);
								localStorage.removeItem("temporalNodes");

								this.toolsService.changeUpdateGraph(true);

						}
					},
					(err) => {
						this.toaster.error(
							this.translateService.instant("NODEFORMCOMPONENT.ERROR")
						);
					}
				);
			}
		});
		}, 200);

		setTimeout(() => {
			this.autoLinkIdeas();
			// setTimeout(() => {
			// 	window.location.reload();
			// }, 800);
		}, 3000);
	}

	addSelectedIANodeToCanvas(){
		this.isLoading = true;
		this.cdr.detectChanges();
		var node = this.graphServ.emptygNode();
		let nodeSelected = JSON.parse(this.localStorage.getItem("selectedIAIdea"));

		node.label = nodeSelected.Titulo_de_la_Idea;
		node.description = nodeSelected.Resumen;
		let texto = nodeSelected.Cuerpo_Explicativo_de_la_Idea;
		let ideaNumber = nodeSelected.Numero;
		this.sigmaUtils.addTempNode(
			node,
			nodeSelected.x,
			nodeSelected.y,
			this.loginService.getUser()
		);

		this.nodeUtils.registrarNodoCustom(node).subscribe(
			(res) => {
				switch (res.type) {
					case HttpEventType.Response:
						//this.sigmaCanvasService.setPreviewNode("none");
						let ideasToModify = JSON.parse(
							this.localStorage.getItem("IAGeneratedIdeas")
						);
						//find idea by ideaNumber
						let i = ideasToModify.findIndex((idea) => idea.Numero === ideaNumber);
						ideasToModify[i].idOriginal = res.body.data.idOriginal;
						ideasToModify[i].id = res.body.data.id;
						this.localStorage.setItem(
							"IAGeneratedIdeas",
							JSON.stringify(ideasToModify)
						);
						this.sigmaUtils.addNode(res.body.data);
						this.nodeService
							.postNodeFile(
								res.body.data.idNodesFile,
								"textAdd",
								undefined,
								undefined,
								texto
							)
							.subscribe((response) => {

								this.isLoading = false;
								this.cdr.detectChanges();
								//localStorage.removeItem("temporalNodes");

								if (
									this.loginService.getUser().tutorialSW.estado ===
									Estado.PENDIENTE
								) {
									this.countHelp++;
									if (this.countHelp === 2) {
										this.mostrarAyuda = true;
									}
								}
							});
							this.sigmaUtils.dropNode("temp");
							this.toaster.success(
								this.translateService.instant("NODEFORMCOMPONENT.SAVED")
							);
							this.localStorage.setItem(
								"nodesInCanvas",
								JSON.stringify(this.sigmaUtils.allNodes())
							);

					//this.toolsService.changeUpdateGraph(true);
				}
			},
			(err) => {
				this.toaster.error(
					this.translateService.instant("NODEFORMCOMPONENT.ERROR")
				);
			}
		);

	}

	public createQuizMultiple() {
		this.scUtils.hidePopWindows();

		var tempSigmaNode = this.sigmaUtils.sigma.graph.nodes("temp");
		tempSigmaNode.nodeType = SIGMA_CONSTANTS.QUIZ_TYPE;
		tempSigmaNode.type = SIGMA_CONSTANTS.QUIZ_NODE_TYPE;
		tempSigmaNode.quizTittle = this.translateService.instant(
			"QUIZZES.DEFAULTTITLEMULTIPLE"
		);
		tempSigmaNode.isMultiplexed = MULTIPLEXEDQUIZZES.ISMULTIPLEXED;
		this.quizService
			.createQuiz(this.idCurso, this.idMapa, tempSigmaNode)
			.pipe(map((res: any) => res.data))
			.subscribe((res) => {
				this.sigmaUtils.dropNode("temp");
				this.sigmaUtils.addNode(res);
				this.editQuizMultiple(res);
			});
	}

	private editQuizMultiple(quiz: any) {
		this.isModalOpen = true;
		this.scUtils.hidePopWindows();
		this.quizService
			.getQuizMultiple(quiz.idOriginal, this.idCurso, this.idMapa)
			.subscribe((res) => {
				const modalRef = this.modalService.open(QuizEditMultipleComponent, {
					scrollable: false,
					windowClass: MODAL_DIALOG_TYPES.W100,
					backdrop: "static",
				});

				modalRef.componentInstance.quiz = {
					...res.quiz,
					user: quiz.user,
					idOriginal: quiz.idOriginal,
					id: quiz.id,
					ordinal: quiz.ordinal,
					originalX: quiz.originalX,
					originalY: quiz.originalY,
					size: quiz.size,
					sizeQuiz: quiz.sizeQuiz,
					x: quiz.x,
					y: quiz.y,
				};
				modalRef.componentInstance.elements = res.elements;
				modalRef.componentInstance.quizFiles = res.quizFiles;
				modalRef.componentInstance.quizzes = res.quizzes;
				modalRef.componentInstance.courseId = this.idCurso;
				modalRef.componentInstance.graphId = this.idMapa;

				modalRef.result.then().finally(() => (this.isModalOpen = false));
			});
	}

	public createQuiz() {
		this.quizUtils.createQuiz().subscribe((res) => {
			this.sigmaUtils.dropNode("temp");
			this.sigmaUtils.addNode(res);
			if (this.loginService.getUser().tutorialSW.estado === Estado.PENDIENTE) {
				this.countHelp++;
				this.countHelpQuiz++;
				if (this.countHelp === 2) {
					this.mostrarAyuda = true;
				}
			}
			this.isModalOpen = true;
			this.quizUtils.editQuiz(res, this.countHelpQuiz).finally(() => this.isModalOpen = false)
		})
	}

	private playQuiz(quiz) {
		this.scUtils.hidePopWindows();
		this.customModeAuto("quiz", quiz);
	}

	private conectarNodos(
		connectN1: NodeConnectModel,
		connectN2: NodeConnectModel,
		options?: OptionsNodeConnectModel | null
	) {
		const modalRef = this.modalService.open(NodeLinkComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W65,
		});
		modalRef.componentInstance.nodeFrom = connectN1;
		modalRef.componentInstance.nodeTo = connectN2;
		modalRef.componentInstance.courseId = this.idCurso;
		modalRef.componentInstance.graphId = this.idMapa;
		modalRef.componentInstance.options = options || null;
		modalRef.result.then(
			(response) => {
				this.buscarNodo = JSON.parse(
					localStorage.getItem(LOCALSTORAGESTRINGS.SEARCHNODE)
				);
				if (this.buscarNodo) {
					localStorage.setItem(LOCALSTORAGESTRINGS.SEARCHNODE, "false");
					this.initComponent();
				}
				switch (response.result) {
					case "OK":
						if (!options)
							this.nodeUtils.conectarNodo(response.datos, this.sigmaUtils);
						else this.sigmaUtils.updateEdge(options.id, response.datos); //Actualizar el edge
						this.sigmaUtils.restoreAllOriginalNodeColors();
						break;
					case "ERROR":
						this.sigmaUtils.restoreAllOriginalNodeColors();
						break;
				}
			},
			(reason) => {
				this.sigmaUtils.restoreAllOriginalNodeColors();
			}
		);
	}
	private conectarNodosAss(connectN1: any, connectN2: any) {
		const modalRef = this.modalService.open(NodeLinkComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W65,
		});
		modalRef.componentInstance.nodeFrom = connectN1;
		modalRef.componentInstance.nodeTo = connectN2;
		modalRef.componentInstance.courseId = this.idCurso;
		modalRef.componentInstance.graphId = this.idMapa;
		modalRef.result.then(
			(response) => {
				this.buscarNodo = JSON.parse(
					localStorage.getItem(LOCALSTORAGESTRINGS.SEARCHNODE)
				);
				localStorage.setItem(LOCALSTORAGESTRINGS.SEARCHNODE, "false");
				this.initComponent();
				const id = Math.floor(Math.random() * 10000);
				// Y lo mando a dashboard
				this.router.navigate(["/dashboard", id]);
				let idNode_Course: number = JSON.parse(
					localStorage.getItem(LOCALSTORAGESTRINGS.COURSEID)
				);
				let idNode_Target: number = JSON.parse(
					localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHID)
				);
				this.router.navigate([
					`/course/${idNode_Course}/graph/${idNode_Target}`,
				]);
			},
			(reason) => {
				this.sigmaUtils.restoreAllOriginalNodeColors();
			}
		);
	}

	public editNode(node: gNode, isNewNode?: boolean) {
		this.isModalOpen = true;
		this.nodeUtils
			.editNode(
				node,
				isNewNode,
				this.canEdit,
				this.mostrarAyuda,
				this.tutorialGrafo
			)
			.then((value) => {
				if (value) this.tutorialCanvasGrafo(value);
			})
			.finally(() => (this.isModalOpen = false));
	}

	public playNode(node: gNode) {
		this.scUtils.hidePopWindows();
		this.registerNodeLastTable(node);
		this.customModeAuto("node", node);
	}

	registerNodeLastTable(node: gNode) {
		this.nodeService
			.registerLastNodeTable(
				this.idCurso,
				this.idMapa,
				this.loginService.getUser().idUser,
				node.idOriginal
			)
			.subscribe((res) => {});
	}

	public editElement() {
		var e: any = JSON.parse(localStorage.getItem("nodeData"));
		if (!this.isLoading) {
			if (e.nodeType === SIGMA_CONSTANTS.NODE_TYPE) {
				this.editNode(e);
			} else if (e.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE) {
				if (this.canEdit) {
					if (e.isMultiplexed === MULTIPLEXEDQUIZZES.ISMULTIPLEXED) {
						this.editQuizMultiple(e);
					} else {
						this.isModalOpen = true;
						this.quizUtils
							.editQuiz(e, this.countHelpQuiz)
							.finally(() => (this.isModalOpen = false));
					}
				} else {
					this.playQuiz(e);
				}
			} else if (e.nodeType === SIGMA_CONSTANTS.STICKER_TYPE) {
				if (this.canEdit) this.stickerUtils.editSticker(e);
			} else if (e.nodeType === SIGMA_CONSTANTS.TEXT_TYPE) {
				if (this.canEdit) this.stickerUtils.editText(e);
			}
		}
	}

	// ----------------------------------------------------------------------
	//   M A P     A C T I O N S
	// ----------------------------------------------------------------------

	public newImage(file) {
		this.bgUtils.addBackground(file);
		this.bgUtils.progress.subscribe((value) => {
			this.progress = value;
			this.graphServ.bgImage.subscribe((value) => {
				this.selectedGraph.targetImage = value;
			});
			if (this.progress == 100) {
				setTimeout(() => {
					this.progress = 0;
				}, 500);
			}
		});
	}

	public removeBackground() {
		this.bgUtils.rmBackground();
	}

	openModalModeAuto() {
		this.scUtils.openModalModeAuto(
			this.ModoAuto,
			this.ordenSeleccionado,
			this.viewType
		);
	}

	public customModeAuto(type: string, value: gNode | any) {
		let listQuiz = [];

		if (this.viewType.nodes && this.viewType.quizzes) {
			//Obtener listado de un power especifico.
			listQuiz = this.ModoAuto.obtenerListado(this.ordenSeleccionado);

			//para poder abris solo los quizzes cuando no hay nodos en el grafo
			if (listQuiz.length === 0) {
				listQuiz = this.ModoAuto.listadoSoloQuiz();
			}
		} else if (this.viewType.nodes) {
			listQuiz = this.ModoAuto.listSoloExplicaciones();
		} else if (this.viewType.quizzes) {
			listQuiz = this.ModoAuto.listadoSoloQuiz();
		}

		if (listQuiz.length > 0) {
			if (type == SIGMA_CONSTANTS.NODE_TYPE.toLowerCase()) {
				this.nodeService
					.getNode(value.idOriginal, this.idCurso, this.idMapa)
					.pipe(
						map((res: any) => res.data[0]),
						finalize(() => (this.isLoading = false))
					)
					.subscribe((res) => {
						const modalRef = this.modalService.open(NodeModeAutoComponent, {
							scrollable: true,
							windowClass: MODAL_DIALOG_TYPES.W100,
							backdrop: "static",
						});

						modalRef.componentInstance.listQuiz = [];
						modalRef.componentInstance.listQuiz = listQuiz;
						modalRef.componentInstance.node = res;
						modalRef.componentInstance.hideBar = true;
						modalRef.componentInstance.idCurso = this.idCurso;
						modalRef.componentInstance.idMapa = this.idMapa;
						modalRef.componentInstance.viewQuiz =
							this.viewType.quizzes && !this.viewType.nodes ? true : false;
						modalRef.componentInstance.soloQuiz =
							this.viewType.quizzes && !this.viewType.nodes ? true : false;
						modalRef.componentInstance.soloNodos =
							!this.viewType.quizzes && this.viewType.nodes ? true : false;
						modalRef.componentInstance.autoTodo =
							this.viewType.quizzes && this.viewType.nodes ? true : false;
						modalRef.componentInstance.currentGraph = listQuiz;
						modalRef.componentInstance.type =
							SIGMA_CONSTANTS.NODE_TYPE.toLowerCase();
						modalRef.componentInstance.course = this.sigmaCanvasService.course;
						modalRef.componentInstance.adjustWindow = true;
						modalRef.componentInstance.allItems = this.ModoAuto;
						modalRef.componentInstance.quiz = QuizModel;

						modalRef.result
							.then((res) => {
								if (res !== undefined) {
									this.lastNodeId = res.id;
									this.initComponent(true);
									this.actionInCanvas(
										this.lastNodeId.toString(),
										(this.anteriorNodo = undefined)
									);
								}
							})
							.catch((res) => {});
					});
			} else if (type == SIGMA_CONSTANTS.QUIZ_TYPE.toLowerCase()) {
				if (value.isMultiplexed === MULTIPLEXEDQUIZZES.ISMULTIPLEXED) {
					if (this.loginService.esProfesor()) {
						// cuando sea profe traemos todos los hijos del quiz padre y mostramos el hijo 1
						let quizM = this.quizService
							.getQuizMultiple(value.idOriginal, this.idCurso, this.idMapa)
							.subscribe((res) => {
								//this.listQM = res.quizzes;
								const idQuizM = res.quizzes[0].quizMultiple.idQuizMultiple;
								this.quizService
									.getQuizMultipleCanvasQuizMultiple(
										idQuizM,
										this.idCurso,
										this.idMapa
									)
									.pipe(finalize(() => (this.isLoading = false)))
									.subscribe((res) => {
										const modalRef = this.modalService.open(
											NodeModeAutoComponent,
											{
												scrollable: true,
												windowClass: MODAL_DIALOG_TYPES.W100,
												backdrop: "static",
											}
										);
										modalRef.componentInstance.quiz = {
											...res.quiz,
											user: value.user,
											idOriginal: value.idOriginal,
											id: value.id,
											originalX: value.originalX,
											originalY: value.originalY,
											size: value.size,
											sizeQuiz: value.sizeQuiz,
											x: value.x,
											y: value.y,
										};
										modalRef.componentInstance.elements = res.elements;
										modalRef.componentInstance.courseId = this.idCurso;
										modalRef.componentInstance.graphId = this.idMapa;
										modalRef.componentInstance.hideBar = true;
										modalRef.componentInstance.listQuiz = [];
										modalRef.componentInstance.listQuiz = listQuiz;
										modalRef.componentInstance.firstQuiz = 1;
										modalRef.componentInstance.idCurso = this.idCurso;
										modalRef.componentInstance.idMapa = this.idMapa;
										modalRef.componentInstance.viewQuiz = true;
										modalRef.componentInstance.soloQuiz =
											this.viewType.quizzes && !this.viewType.nodes
												? true
												: false;
										modalRef.componentInstance.soloNodos =
											!this.viewType.quizzes && this.viewType.nodes
												? true
												: false;
										modalRef.componentInstance.autoTodo =
											this.viewType.quizzes && this.viewType.nodes
												? true
												: false;
										modalRef.componentInstance.currentGraph = listQuiz;
										modalRef.componentInstance.type =
											SIGMA_CONSTANTS.QUIZ_TYPE.toLowerCase();
										modalRef.componentInstance.adjustWindow = true;
										modalRef.componentInstance.course =
											this.sigmaCanvasService.course;
										modalRef.componentInstance.allItems = this.ModoAuto;
										modalRef.result
											.then((color) => {
												this.initComponent(true);
												// this.powerServ.powerActive.subscribe(res => {
												// 	setTimeout(() => {
												// 		this.powerServ.emitPowerActive(res);
												// 	}, 200);
												// })
											})
											.catch((res) => {});
									});
							});
					} else {
						this.quizService
							.getQuizMultipleCanvasQuizMultiple(
								value.idOriginal,
								this.idCurso,
								this.idMapa
							)
							.pipe(finalize(() => (this.isLoading = false)))
							.subscribe((res) => {
								const modalRef = this.modalService.open(NodeModeAutoComponent, {
									scrollable: true,
									windowClass: MODAL_DIALOG_TYPES.W100,
									backdrop: "static",
								});

								modalRef.componentInstance.quiz = {
									...res.quiz,
									user: value.user,
									idOriginal: value.idOriginal,
									id: value.id,
									originalX: value.originalX,
									originalY: value.originalY,
									size: value.size,
									sizeQuiz: value.sizeQuiz,
									x: value.x,
									y: value.y,
								};
								modalRef.componentInstance.elements = res.elements;
								modalRef.componentInstance.courseId = this.idCurso;
								modalRef.componentInstance.graphId = this.idMapa;
								modalRef.componentInstance.hideBar = true;
								modalRef.componentInstance.listQuiz = [];
								modalRef.componentInstance.listQuiz = listQuiz;
								modalRef.componentInstance.firstQuiz = 1;
								modalRef.componentInstance.idCurso = this.idCurso;
								modalRef.componentInstance.idMapa = this.idMapa;
								modalRef.componentInstance.viewQuiz = true;
								modalRef.componentInstance.soloQuiz =
									this.viewType.quizzes && !this.viewType.nodes ? true : false;
								modalRef.componentInstance.soloNodos =
									!this.viewType.quizzes && this.viewType.nodes ? true : false;
								modalRef.componentInstance.autoTodo =
									this.viewType.quizzes && this.viewType.nodes ? true : false;
								modalRef.componentInstance.currentGraph = listQuiz;
								modalRef.componentInstance.type =
									SIGMA_CONSTANTS.QUIZ_TYPE.toLowerCase();
								modalRef.componentInstance.adjustWindow = true;
								modalRef.componentInstance.course =
									this.sigmaCanvasService.course;
								modalRef.componentInstance.allItems = this.ModoAuto;

								modalRef.result
									.then((color) => {
										this.initComponent(true);
										// this.powerServ.powerActive.subscribe(res => {
										// 	setTimeout(() => {
										// 		this.powerServ.emitPowerActive(res);
										// 	}, 200);
										// })
									})
									.catch((res) => {});
							});
					}
				} else {
					this.quizService
						.getQuizSimpleCanvasQuizSimple(
							value.idQuiz ? value.idQuiz : value.idOriginal,
							this.sigmaCanvasService.courseId,
							this.sigmaCanvasService.graphId
						)
						.pipe(finalize(() => (this.isLoading = false)))
						.subscribe((res) => {
							const modalRef = this.modalService.open(NodeModeAutoComponent, {
								scrollable: true,
								windowClass: MODAL_DIALOG_TYPES.W100,
								backdrop: "static",
							});

							modalRef.componentInstance.quiz = {
								...res.quiz,
								promptText: res.promptText,
								user: value.user,
								idOriginal: value.idOriginal,
								id: value.id,
								originalX: value.originalX,
								originalY: value.originalY,
								size: value.size,
								sizeQuiz: value.sizeQuiz,
								x: value.x,
								y: value.y,
							};
							modalRef.componentInstance.rawQuiz = res;
							modalRef.componentInstance.elements = res.elements;
							modalRef.componentInstance.courseId = this.idCurso;
							modalRef.componentInstance.graphId = this.idMapa;
							modalRef.componentInstance.hideBar = true;
							modalRef.componentInstance.listQuiz = [];
							modalRef.componentInstance.listQuiz = listQuiz;
							modalRef.componentInstance.firstQuiz = 1;
							modalRef.componentInstance.idCurso = this.idCurso;
							modalRef.componentInstance.idMapa = this.idMapa;
							modalRef.componentInstance.viewQuiz = true;
							modalRef.componentInstance.soloQuiz =
								this.viewType.quizzes && !this.viewType.nodes ? true : false;
							modalRef.componentInstance.soloNodos =
								!this.viewType.quizzes && this.viewType.nodes ? true : false;
							modalRef.componentInstance.autoTodo =
								this.viewType.quizzes && this.viewType.nodes ? true : false;
							modalRef.componentInstance.currentGraph = listQuiz;
							modalRef.componentInstance.type =
								SIGMA_CONSTANTS.QUIZ_TYPE.toLowerCase();
							modalRef.componentInstance.adjustWindow = true;
							modalRef.componentInstance.course =
								this.sigmaCanvasService.course;
							modalRef.componentInstance.allItems = this.ModoAuto;
							modalRef.result
								.then((color) => {
									this.initComponent(true);
									// this.powerServ.powerActive.subscribe(res => {
									// 	setTimeout(() => {
									// 		this.powerServ.emitPowerActive(res);
									// 	}, 200);
									// })
								})
								.catch((res) => {});
						});
				}
			}
		} else
			this.toaster.success(
				this.translateService.instant("SIGMACOMPONENT.ERRORCONFIG")
			);
	}

	private subscribeChangedProfile(): void {
		this._profileSubscription = this.loginService.currentProfile.subscribe(
			(val) => {
				if (val !== null) {
					if (val.profile !== undefined) {
						if (
							(val.profile.toLowerCase() === Profiles.Student.toLowerCase() ||
								val.profile.toLowerCase() === Profiles.Author.toLowerCase()) &&
							val.sendEvent
						) {
							if (this._sizeInterval) clearInterval(this._sizeInterval);
							this.initComponent(val.sendEvent);
						}
					}
				}
			}
		);
	}

	private showHideLabels(value: boolean) {
		this.isShowLabels = false;
		setTimeout(() => {
			this.isShowLabels = this.utils.labelsStatus.showLabelsGraph = value;
			this.utils.savePadsLabelsStatus(LOCALSTORAGESTRINGS.LABELSLOCALCATEGORY);
		}, 1200);
	}

	asociarNodo(nodo) {
		this.buscarNodo = true;
		this.nodeUtils.associateNode(nodo, this.idCurso, this.idMapa, this.canEdit);
	}

	private initComponent(isKillSigma?: boolean) {
		if (isKillSigma) {
			this.previewNodesSubscription.unsubscribe();
			this.sigmaUtils.sigma.kill();
			this._showModalSubscription.unsubscribe();
			this._updateGraph.unsubscribe();
		}

		this.loading = true;
		this.isLoading = true;
		this.cdr.detectChanges();
		this.sigmaUtils = new SigmaUtils(
			this.sigmaContainer.nativeElement,
			this.nodeCoverPipe,
			this.loginService.getProfile()
				? this.loginService.getProfile().toUpperCase()
				: "",
			this.utils,
			this.loginService
		);
		this.sigmaCanvasService.courseId = this.idCurso;
		this.sigmaCanvasService.graphId = this.idMapa;
		this.sigmaCanvasService.sigmaUtils = this.sigmaUtils;

		try {
			this.courseService.getCourseById(this.idCurso).subscribe((res) => {
				this.canEdit = this.scUtils.userCanEditGraph(res.data.editores);
				this.sigmaCanvasService.course = res.data.courses;
			});

			let obs1$: Observable<any> = null;
			let obs2$: Observable<any> = null;
			let obs3$: Observable<any> = null;
			let obs4$: Observable<any> = null;
			let obs5$: Observable<any> = null;

			obs1$ = this.graphServ.getNodesFromCourseGraph(this.idCurso, this.idMapa).pipe(
				catchError(err => {
					console.error('Error in obs1$', err);
					return of({ nodes: [], edges: [] }); // Default value in case of error
				})
			);

			let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
			const idGrupo = storedGroupInfo ? storedGroupInfo.idGroup : 0;
			obs2$ = this.loginService.esEstudiante()
				? this.graphServ.getQuizzesFromCourseGraphRoleStudent(this.idCurso, this.idMapa, idGrupo).pipe(
						catchError(err => {
							console.error('Error in obs2$', err);
							return of({ nodes: [], edges: [] }); // Default value in case of error
						})
					)
				: this.graphServ.getQuizzesFromCourseGraph(this.idCurso, this.idMapa).pipe(
						catchError(err => {
							console.error('Error in obs2$', err);
							return of({ nodes: [], edges: [] }); // Default value in case of error
						})
					);

			obs3$ = this.stickersServices.loadStickers(this.idCurso, this.idMapa).pipe(
				catchError(err => {
					console.error('Error in obs3$', err);
					return of([]); // Default value in case of error
				})
			);

			obs4$ = this.masterServices.getVersionApi().pipe(
				catchError(err => {
					console.error('Error in obs4$', err);
					return of({ data: 'unknown' }); // Default value in case of error
				})
			);

			obs5$ = this.graphServ.getGraphInfo(this.idMapa).pipe(
				catchError(err => {
					console.error('Error in obs5$', err);
					return of({ data: {} }); // Default value in case of error
				})
			);

			const CALLS = forkJoin([obs1$, obs2$, obs3$, obs4$, obs5$]);

			CALLS.subscribe(
				([o1, o2, o3, o4, o5]) => {
					const res = {
						nodes: o1.nodes.concat(o2.nodes),
						edges: o1.edges.concat(o2.edges),
						stickers: o3,
						selectedGraph: o5.data,
					};

					this.currentGraph = { ...res };
					this.listQuiz = { ...this.currentGraph };
					this.modAutoPrototype(this.listQuiz);
					this.sigmaUtils.addGraph(this.currentGraph);
					this.sigmaUtils.centerGraph();
					this.localStorage.setItem("nodesInCanvas", JSON.stringify(o1.nodes));
					this.version = o4.data;
					this.selectedGraph = { ...res };

					if (this.loginService.esEstudiante()) {
						let canvasVisibility = JSON.parse(localStorage.getItem("canvasVisibility") || 'null');
						this.toolsService.changeVisibility(canvasVisibility);
						this.changeView(canvasVisibility);
						this.quizService.setAutomatic(true);
						//this.setViewType(); //SE COMENTA PARA QUE MANTENGA LAS OPCIONES DE VISUALIZACION POSTERIOR A REALIZAR EL QUIZ O NODO
					} else if (
						this.loginService.esProfesor() &&
						JSON.parse(this.localStorage.getItem("modePractice"))
					) {
						let view = { nodes: false, quizzes: true };
						let canvasVisibility = localStorage.getItem("canvasVisibility");
						this.toolsService.changeVisibility(canvasVisibility);
						this.changeView(canvasVisibility);
						this.changeView(view);
						this.quizService.setAutomatic(true);
						this.defaultViewType = 4;
						this.setViewType();
					}

					if (this.currentGraph.nodes.length || this.currentGraph.stickers.length) {
						this.selectedGraph = res.selectedGraph;
						let clientResolution = window.screen.height * window.devicePixelRatio;
						let userThatFixedZoomResolution = res.selectedGraph.userResolution;
						let zommRatioCorrection = (Number(userThatFixedZoomResolution) / clientResolution) * 165;
						let zoomRatio;
						if (Number(userThatFixedZoomResolution) == clientResolution) {
							zoomRatio = res.selectedGraph.zoomRatio;
						} else if (Number(userThatFixedZoomResolution) < clientResolution) {
							zoomRatio = (res.selectedGraph.zoomRatio * zommRatioCorrection) / 100;
						} else if (Number(userThatFixedZoomResolution) > clientResolution) {
							zoomRatio = res.selectedGraph.zoomRatio * 1.4;
						}
						if (res.selectedGraph.zoomX != 0) {
							this.sigmaCanvasService.zoomX = res.selectedGraph.zoomX;
							this.sigmaCanvasService.zoomY = res.selectedGraph.zoomY;
							this.sigmaCanvasService.zoomRatio = zoomRatio;
							this.sigmaUtils.setCustomZoomAnimation(res.selectedGraph.zoomX, res.selectedGraph.zoomY, zoomRatio);
						} else {
							this.sigmaUtils.setZoomAnimation(SIGMA_CONSTANTS.MAXRATIO);
						}
					}

					this.sigmaUtils.sigma.settings("enableEdgeHovering", this.loginService.esAutor() ? true : false);
				},
				(err) => {
					console.error("ERROR LOADING GRAPH: ", err);
					this.currentGraph = { nodes: [], edges: [], stickers: [] };
					this.loading = false;
				}
			);
		} catch (error) {
			console.error("ERROR LOADING GRAPH: ", error);
		} finally {
			setTimeout(() => {
				this.sigmaUtils.refresh();
			}, 100);
			setTimeout(() => {
				this.loading = false;
				this.isLoading = false;

				this.cdr.detectChanges();
				this.isShowPads = this.utils.padsStatus.showPadsGraph;
				this.isShowLabels = this.utils.labelsStatus.showLabelsGraph;
				this.showHideLabels(this.isShowLabels);
			}, 100);
		}

		this.buscarNodo = JSON.parse(
			localStorage.getItem(LOCALSTORAGESTRINGS.SEARCHNODE)
		);
		this.modoTraerNodo = JSON.parse(localStorage.getItem("modoTraerNodo"));
		this.modoTraerActividad = JSON.parse(
			localStorage.getItem(LOCALSTORAGESTRINGS.GETACTIVITY)
		);
		if (!this.buscarNodo && !this.modoTraerNodo && !this.modoTraerActividad) {
			this.subscribeToolbars();

			//POWER
			this.subscribePowerActive();

			//DISCOVER MODE
			this.subscribeModeDiscover();

			//TRIGGER INIT
			this.triggerInitSigma();

			// JQUERY Events
			this.subscribejQueryClickEvent();
			this.subscribejQueryMouseMoveEvent();
			this.jqueryContextMenu();
			this.subscribeJQueryMouseUp();
			this.subscribeJQueryMouseDown();
			// SIGMAJS Events
			this.subscribeClickNodeEvent();
			this.subscribedoubleClickNodeEvent();
			this.subscribeClickStageEvent();
			this.subscribeOutNodeEvent();
			this.subscribeOverNodeEvent();
			//this.subscribeDoubleClickStageEvent();
			this.subscriberightClickNodeEvent();
			this.subscriberightClickStageEvent();
			this.subscribeDragEvent();
			this.subscribeDropEvent();
			this.subscribeStartDragEvent();

			// CAMERA events
			this.subscribeUpdateCoords();

			// DATABASE subscriptions
			this.subscribeNewNode();
			this.subscribeNewQuiz();

			this.subscribeClickEdgeEvent();
			this.subscribeOutEdgeEvent();
			this.subscribeOverEdgeEvent();
		} else if (this.buscarNodo) {
			this.subscribeJQueryMouseUp();
			this.subscribeJQueryMouseDown();
			this.subscribeOutNodeEvent();
			this.subscribeOverNodeEvent();
			this.subscribeAsociarNodoEvent();
		} else if (this.modoTraerNodo) {
			this.subscribeJQueryMouseUp();
			this.subscribeJQueryMouseDown();
			this.subscribeOutNodeEvent();
			this.subscribeOverNodeEvent();
			this.subscribeAlquilarIdea();
		} else if (this.modoTraerActividad) {
			this.subscribeJQueryMouseUp();
			this.subscribeJQueryMouseDown();
			this.subscribeOutNodeEvent();
			this.subscribeOverNodeEvent();
			this.subscribeAlquilarActividad();
		}

		this._getInfoCourse();

		//PERFIL CAMBIADO
		if (!isKillSigma) this.subscribeChangedProfile();
	}
	subscribeAlquilarActividad() {
		this.eventSubscriptions["clickNodeEvent"] = this.sigmaUtils
			.getemitclickNodeEvent()
			.subscribe((e) => {
				this.clickNode = true;
				this.rightclickNode = false;
				if (
					!this.isDragging &&
					!this.isLoading &&
					e.data.node.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE
				) {
					this.nodeUtils.openModalActividadAlquilarConfirm(e.data.node);
				}
			});
	}
	subscribeAlquilarIdea() {
		this.eventSubscriptions["clickNodeEvent"] = this.sigmaUtils
			.getemitclickNodeEvent()
			.subscribe((e) => {
				this.clickNode = true;
				this.rightclickNode = false;
				if (
					!this.isDragging &&
					!this.isLoading &&
					e.data.node.nodeType === SIGMA_CONSTANTS.NODE_TYPE
				) {
					this.nodeUtils.openModalNodeAlquilarConfirm(e.data.node);
				}
			});
	}

	alquilarIdea() {
		//Guardamos los datos de la url en el locaL storage
		localStorage.setItem(
			LOCALSTORAGESTRINGS.COURSEID,
			JSON.stringify(this.idCurso)
		);
		localStorage.setItem(
			LOCALSTORAGESTRINGS.GRAPHID,
			JSON.stringify(this.idMapa)
		);
		$(".popover, .popover2, .popover3").hide();
		var tempNode = this.sigmaUtils.sigma.graph.nodes("temp");
		localStorage.setItem(LOCALSTORAGESTRINGS.NODEX, tempNode.x); //Guardamos coordenadas
		localStorage.setItem(LOCALSTORAGESTRINGS.NODEY, tempNode.y); //Guardamos coordenadas
		const modalRef = this.modalService.open(ModalCursosListadoComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
		modalRef.componentInstance.modoTraerNodo = true;
		modalRef.componentInstance.modoTraerActividad = false;
	}

	alquilarActividad() {
		//Guardamos los datos de la url en el locaL storage
		localStorage.setItem(
			LOCALSTORAGESTRINGS.COURSEID,
			JSON.stringify(this.idCurso)
		);
		localStorage.setItem(
			LOCALSTORAGESTRINGS.GRAPHID,
			JSON.stringify(this.idMapa)
		);
		$(".popover, .popover2, .popover3").hide();
		var tempNode = this.sigmaUtils.sigma.graph.nodes("temp");
		localStorage.setItem(LOCALSTORAGESTRINGS.NODEX, tempNode.x); //Guardamos coordenadas
		localStorage.setItem(LOCALSTORAGESTRINGS.NODEY, tempNode.y); //Guardamos coordenadas
		const modalRef = this.modalService.open(ModalCursosListadoComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
		modalRef.componentInstance.modoTraerNodo = false;
		modalRef.componentInstance.modoTraerActividad = true;
	}

	certifiedQuiz($ev) {
		$ev.preventDefault();
		$ev.stopImmediatePropagation();
		this.quizUtils.certificarQuiz().subscribe(
			(value) => {
				this.formulario.patchValue({ certificadoQuiz: value });
			},
			(err) => {
				this.formulario.patchValue({ certificadoQuiz: false });
			}
		);
	}

	login() {
		location.href = "https://www.salware.com/auth/login";
	}
	registrarme() {
		location.href = "https://www.salware.com/auth/sign-up";
	}

	cancelarOperacionAlquilar() {
		localStorage.setItem("modoTraerNodo", "false");
		localStorage.setItem("modoTraerActividad", "false");
		this.modoTraerActividad = false;
		this.modoTraerNodo = false;
		let idNode_Course: number = JSON.parse(
			localStorage.getItem(LOCALSTORAGESTRINGS.COURSEID)
		);
		let idNode_Target: number = JSON.parse(
			localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHID)
		);
		this.router.navigate([`/course/${idNode_Course}/graph/${idNode_Target}`]);
		this.toaster.success(
			this.translateService.instant(
				"SIGMACOMPONENT.ALQUILARCANCELADOCORRECTAMENTE"
			)
		);
	}

	emitshowModalCourse(value: string) {
		this.toolsService.changeShowModalCourses(value);
		this.padsUtils.vibratePad();
		this.toolsService.setHideElements();
	}

	showProfileActionsModal() {
		if(this.loginService.getProfile() != "CENTRO" && this.loginService.getProfile() != "ADMIN"){
			let dontLoadMenu = this.localStorage.getItem("dontLoadMenu");
			if (
				(this.utils.loadMenu === true && dontLoadMenu === "false") ||
				dontLoadMenu == null
			) {
				// Determinar la clase del modal según el perfil
				const modalClass =
				this.loginService.getProfile() === "PROFESOR"
						? MODAL_DIALOG_TYPES.W90
						: MODAL_DIALOG_TYPES.W40;

				this.modalService.open(ModalProfileActionsComponent, {
						scrollable: false,
						windowClass: modalClass,
				});
			} else {
				setTimeout(() => {
					localStorage.setItem("dontLoadMenu", "false");
					this.utils.loadMenu = true;
				}, 300);
			}
		} else if(this.loginService.getProfile() == "CENTRO"){
			let dontLoadMenu = this.localStorage.getItem("dontLoadMenu");
			if (
				(this.utils.loadMenu === true && dontLoadMenu === "false") ||
				dontLoadMenu == null
			) {
				this.showControlPanel();
			} else {
				setTimeout(() => {
					localStorage.setItem("dontLoadMenu", "false");
					this.utils.loadMenu = true;
				}, 300);
			}
		} else if(this.loginService.getProfile() == "ADMIN"){
			let dontLoadMenu = this.localStorage.getItem("dontLoadMenu");
			if (
				(this.utils.loadMenu === true && dontLoadMenu === "false") ||
				dontLoadMenu == null
			) {
				this.showControlPanelAdmin();
			} else {
				setTimeout(() => {
					localStorage.setItem("dontLoadMenu", "false");
					this.utils.loadMenu = true;
				}, 300);
			}
		}
	}

	showControlPanel() {
		this.modalService.dismissAll();

		this.modalService.open(ControlPanelComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});

		localStorage.setItem("lastSelectedProfile", this.profiles.Center);
		this.loginService.setProfile(this.profiles.Center);

		//Creamos el log
		const bodyLog = {
			action: "mando de control",
			actionData: "Ha entrado en el mando de control.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.masterServices.createActivityHistory(bodyLog.action, bodyLog.actionData, bodyLog.startDate, bodyLog.endingDate, bodyLog.role, bodyLog.idGroup).subscribe();
	}

	showControlPanelAdmin() {
		this.modalService.dismissAll();

		this.modalService.open(ControlPanelAdminComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});

		localStorage.setItem("lastSelectedProfile", this.profiles.Admin);
		this.loginService.setProfile(this.profiles.Admin);

		//Creamos el log
		const bodyLog = {
			action: "mando de control",
			actionData: "Ha entrado en el mando de control de administrador.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.masterServices.createActivityHistory(bodyLog.action, bodyLog.actionData, bodyLog.startDate, bodyLog.endingDate, bodyLog.role, bodyLog.idGroup).subscribe();
	}

	askForHelp(node){
		this.scUtils.hidePopWindows();
		if((node.isSOS == 1 || this.isSOSClicked) && node.isHelped == 0){
			//si el icono esta amarillo se elimina la solicitud
			this.deleteHelpPetition(node.idOriginal);
		} else{
			const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30,
			});

			modalRef.componentInstance.mensaje = this.translateService.instant(
				"SOS.HELPALERTONCREATE"
			);

			modalRef.result.then((result: boolean) => {
				if (result == true) {
					this.isSOSClicked = !this.isSOSClicked;
					this.sosService
						.createHelpPetition(
							node.idCourseCreation,
							node.idTargetCreation,
							node.idOriginal
						)
						.subscribe(
							(result) => {
								if (result.data) {
									this.toaster.success(
										this.translateService.instant("SOS.HELPASKED")
									);
									const modalRef = this.modalService.open(
										ModalAceptarCancelarComponent,
										{
											scrollable: true,
											windowClass: MODAL_DIALOG_TYPES.W30,
										}
									);

									modalRef.componentInstance.mensaje =
										this.translateService.instant("SOS.ASKEDOKMESSAGE");
									modalRef.componentInstance.disableCancel = true;
									setTimeout(() => {
										this.checkIfUserHasPendingPetitions();
									}, 3000);
								} else
									this.toaster.error(
										this.translateService.instant("SOS.PETITIONEXISTS")
									);
							},
							(err) => {
								this.toaster.error("errorMessage");
							}
						);
				}
			});
		}
	}

	deleteHelpPetition(idNode){
		const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
		modalRef.componentInstance.mensaje = this.translateService.instant(
			"SOS.YOUGONNADELETEHELPPETITION"
		);
		modalRef.result.then((result: boolean) => {
			if (result == true) {
				this.isSOSClicked = !this.isSOSClicked;
				this.sosService.deleteHelpPetitionByIdNode(idNode).subscribe(
					(result) => {
						if (result.data) {
							this.toaster.success(
								this.translateService.instant("SOS.DELETEDHELPPETITION")
							);
						}
					},
					(err) => {
						this.toaster.error("errorMessage");
					}
				);
			}
		});
	}

	checkIfSomeoneWannaHelp() {
		this.sosService.getHelpPetitions().subscribe(
			(result) => {
				if (result.data.sosListGeneral.length) {
					let petitionsFromUSer = result.data.sosListGeneral.filter(
						(item) => item.idUser === this.user.idUser
					);
					petitionsFromUSer.forEach((helpPetition) => {
						if (
							helpPetition.helped === true &&
							helpPetition.connected === false
						) {
							const modalRef = this.modalService.open(
								ModalAceptarCancelarComponent,
								{
									scrollable: true,
									windowClass: MODAL_DIALOG_TYPES.W30,
								}
							);

							this.userIsBeingQuestioned = true;

							modalRef.componentInstance.mensaje = `${this.translateService.instant(
								"SOS.USERWILLINGTOHELP"
							)}: ${helpPetition.tittle} ${this.translateService.instant(
								"SOS.WANNAGOTOCALL"
							)}`;

							modalRef.result.then((result: boolean) => {
								if (result == true) {
									this.sosService.setRoomName(
										helpPetition.idTutor +
											"-" +
											helpPetition.idNode +
											"-" +
											helpPetition.idUser
									);

									this.sosService.currentHelpPetition = helpPetition.idSos;
									let videoCallWindow = this.windowService.open(
										MeetingsComponent,
										{
											title: `${this.translateService.instant(
												"SOS.GIVINGHELPON"
											)}: ${helpPetition.tittle}`,
										}
									);

									videoCallWindow.onClose.subscribe((res) => {
										this.userIsBeingQuestioned = false;
									});
								} else if (result == false) {
									this.sosService
										.getHelpPetitionById(helpPetition.idSos)
										.subscribe((res: any) => {
											if (res.data.connected == false) {
												let updateData = {
													...res.data,
													helped: false,
													idTutor: null,
												};
												this.sosService
													.updateHelpPetition(updateData)
													.subscribe(
														(result) => {
														},
														(err) => {
															console.log(err);
														}
													);
											}
										});
									this.userIsBeingQuestioned = false;
								}
							});
						}
					});
				}
			},
			(err) => {
				//this.toaster.error("errorMessage");
			}
		);
	}

	changeIdeasOrder(selectedGraph) {
		this.scUtils.hidePopWindows();
		const modalRef = this.modalService.open(ModalIdeasOrderComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W50,
		});

		modalRef.componentInstance.ideas = selectedGraph.nodes.filter(
			(node) => node.nodeType == "Node"
		);
		modalRef.componentInstance.idCurso = this.idCurso;
		modalRef.componentInstance.idMapa = this.idMapa;
		modalRef.result.then(
			(data: any) => {
				this.initComponent(true);
				//window.location.reload()
			},
			(reason) => {
				console.error("Can not select the sticker");
			}
		);
	}

	openSalwizar() {
		this.scUtils.hidePopWindows();
		const modalRef = this.modalService.open(SalwizarContenidoComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
	}

	openDesignIdeas() {

		this.scUtils.hidePopWindows();

		let modalSelection = this.modalService.open(ManageIdeasOptionsComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});

		modalSelection.result.then((data: any) => {
			if(data == 'single'){
				//single
				this.registrarNodo()
			} else if(data == 'all'){
				this.sigmaCanvasService.setOpenDesignIdeasWindow(false)
				this.scUtils.hidePopWindows();

				if(this.openedWindow){
					this.openedWindow.close()
				}

				let modalGenerateIdeas = this.modalService.open(ModalGeneratedIdeasComponent, {
					scrollable: true,
					windowClass: MODAL_DIALOG_TYPES.W30,
				});

				modalGenerateIdeas.componentInstance.curso = this.courseInfo.data.courses

			}
		});

	}

	generateQuizzes() {
		this.scUtils.hidePopWindows();
		const modalRef = this.modalService.open(SalwizarQuizzesComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W50,
		});

		modalRef.result.then(
			(data: any) => {
				let IaGeneratedQuizzes = JSON.parse(
					this.localStorage.getItem("IAGeneratedQuizzes")
				);
				IaGeneratedQuizzes.forEach((quiz) => {
					this.quizUtils.createQuizGenerated(quiz).subscribe((res) => {

							this.quizService
								.createEdge(
									`q${res.idOriginal}`,
									`n${quiz.linkTo}`,
									this.idCurso,
									this.idMapa
								)
								.pipe(finalize(() => (this.isLoading = false)))
								.subscribe(
									(res: any) => {
										this.toaster.success(
											this.translateService.instant("NODOS.LINKOK")
										);

										this.sigmaUtils.refresh();
										this.toolsService.changeUpdateGraph(true);
									},
									(err) => {
										this.toaster.error(
											this.translateService.instant("NODOS.LINKNOK")
										);
									}
								);

						quiz.opciones.forEach(opt => {
							this.quizzesService
								.createOrUpdateQuizElement(
									res.idOriginal,
									{
										idQuizzesDataElements:"",
										data: opt.text,
										elementType: 9,
										idQuiz: res.idOriginal,
										responseCheck: opt.correcto ? 1 : null,
										xPosition: 0,
										xSize: 0,
										yPosition: 0,
										ySize:0,
									},
									undefined
								)
								.subscribe(
									(res) => {},
									(err) => console.error(err)
								);
						});

						this.sigmaUtils.addNode(res);
						if (
							this.loginService.getUser().tutorialSW.estado === Estado.PENDIENTE
						) {
							this.countHelp++;
							this.countHelpQuiz++;
							if (this.countHelp === 2) {
								this.mostrarAyuda = true;
							}
						}
					});
				});
			},
			(reason) => {
				console.error("Can not select the sticker");
			}
		);
	}


	autoLinkIdeas(){
		this.sigmaCanvasService.setPreviewNode("none");
		this.scUtils.hidePopWindows();

		let shape = this.localStorage.getItem("SelectedShape");
		let iaGeneratedContent = JSON.parse(this.localStorage.getItem("IAGeneratedIdeas"));
		let arrayShapes = JSON.parse(iaGeneratedContent);
		//from iaGeneratedIdeas we going to get the item that has codigo == to shape
		let selectedIdea = arrayShapes.find((element) => element.codigo == shape);
		let selectedIdeasArray = selectedIdea.Estructura_Ideas;
		//ideas sorted by Numero
		let IAGeneratedIdeas = selectedIdeasArray.sort((a, b) => a.Numero - b.Numero);


		let stackOfIdeas = this.sigmaUtils.allNodes();
		let stackToLoop = stackOfIdeas


		stackToLoop.forEach((idea, index) => {
			if (index === stackOfIdeas.length - 1) return;
			this.nodeService
				.createEdge(
					idea.idOriginal,
					stackOfIdeas[index + 1].idOriginal,
					this.idCurso,
					this.idMapa,
					"line",
					"",
					7,
					"#000000"
				)
				.pipe(finalize(() => (this.isLoading = false)))
				.subscribe(
					(res: any) => {
						this.toaster.success(this.translateService.instant("NODOS.LINKOK"));

						this.sigmaUtils.refresh();
						this.toolsService.changeUpdateGraph(true);
					},
					(err) => {
						this.toaster.error(this.translateService.instant("NODOS.LINKNOK"));
					}
				);
		});
	}


	manageShapeGraph() {
		this.scUtils.hidePopWindows();
		const modalRef = this.modalService.open(ManageGraphShapeComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W50,
		});

		modalRef.result.then(
			(data: any) => {
				//this.initComponent(true);
			},
			(reason) => {
				//console.error("Can not select the sticker");
			}
		);
	}

	calculateCircularPositions(
		ideas,
		radius: number,
		initialX: number,
		initialY: number
	): any {
		const angleIncrement = (2 * Math.PI) / ideas.length;

		ideas.forEach((point, index) => {
			const angle = angleIncrement * index;
			point.x = initialX + radius * Math.cos(angle - Math.PI / 2); // start at the top
			point.y = initialY + radius * Math.sin(angle - Math.PI / 2);
		});

		//this.localStorage.setItem("IAGeneratedIdeas", JSON.stringify(ideas));

		return ideas;
	}

	calculateStarPositions( ideas, radius: number, initialX: number, initialY: number): any {
		const angleIncrement = (2 * Math.PI) / ideas.length;

		ideas.forEach((point, index) => {
			const angle = angleIncrement * index;
			point.x = initialX + radius * Math.cos(angle - Math.PI / 2); // start at the top
			point.y = initialY + radius * Math.sin(angle - Math.PI / 2);
		});

		return ideas;
	}

	calculateVerticalTreePositions(
		ideas,
		startX: number,
		startY: number,
		horizontalSpacing: number,
		verticalSpacing: number
	): any {
		const positionIdea = (idea, x, y) => {
			idea.x = x;
			idea.y = y;

			if (idea.subIdeas && idea.subIdeas.length > 0) {
				const totalWidth = (idea.subIdeas.length - 1) * horizontalSpacing;
				let currentX = x - totalWidth / 2;

				idea.subIdeas.forEach((subIdea) => {
					positionIdea(subIdea, currentX, y - verticalSpacing);
					currentX += horizontalSpacing;
				});
			}
		};

		positionIdea(ideas[0], startX, startY);

		const flattenedIdeas = this.flattenIdeas(ideas[0]);

		return flattenedIdeas;
	}

	flattenIdeas(idea) {
		let result = [idea];
		if (idea.subIdeas && idea.subIdeas.length > 0) {
			idea.subIdeas.forEach((subIdea) => {
				result = result.concat(this.flattenIdeas(subIdea));
			});
		}
		return result;
	}

	calculateLinearPositions(
		ideas,
		startX: number,
		startY: number,
		endX: number,
		endY: number
	): any {
		const totalIdeas = ideas.length;
		ideas.forEach((point, index) => {
			const t = index / (totalIdeas - 1);
			point.x = startX + t * (endX - startX);
			point.y = startY + t * (endY - startY);
		});

		//this.localStorage.setItem("IAGeneratedIdeas", JSON.stringify(ideas));

		return ideas;
	}
}
