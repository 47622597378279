<div class="scroll-container">
	<form class="container" [formGroup]="optionForm" *ngIf="QuizTypes.TEXT && quiz.idMultiplexQuiz === 0">
    <ng-container formArrayName="options">
        <div class="row"  >
            <ng-container [ngSwitch]="quizType">
                <!-- QUIZZ DE INTRODUCIR DATOS -->
                <div class="w-100" *ngSwitchCase="QuizTypes.TEXT">
                    <form [formGroup]="optionFormTexto">
                        <div class="d-flex flex-column">
                            <label>{{'QUIZZES.RESPUESTA' | translate}} {{1}}</label>
                            <input (change)="onFileSelected(0, '', O_TEXTS)" nbInput class="form-control" fullWidth status="basic" fieldSize="small"
														type="text" formControlName="answer" [readonly]="this.answered"  autocomplete="off" />
                        </div>
                    </form>
                </div>

                <div class="row center-aling w-100" *ngSwitchCase="QuizTypes.TEXT">
                    <ng-container *ngFor="let option of  optionsArray.controls; let i = index">
                        <app-loading [isVisible]="isLoading" ></app-loading>
                        <!-- Inputs Ocultos -->
                        <input *ngIf="option.value.type === O_AUDIOS" type="file" id="{{i}}" accept="audio/mp3" (change)="onFileSelected(i, $event, O_AUDIOS)" hidden/>
                        <input *ngIf="option.value.type === O_VIDEOS" type="file" id="{{i}}" accept="video/mp4" (change)="onFileSelected(i, $event, O_VIDEOS)" hidden/>
                        <input *ngIf="option.value.type === O_PICTURES" type="file" id="{{i}}" accept="image/*" (change)="onFileSelected(i, $event, O_PICTURES)" hidden/>
                        <input *ngIf="option.value.type === O_PDFS" type="file" id="{{i}}" accept="application/pdf,application/vnd.ms-excel" (change)="onFileSelected(i, $event, O_PDFS)" hidden/>

                        <ng-container *ngIf="arrayFileView[i].view" class="center-aling">
                            <div class="" >
                                <div class="">
                                    <div class="">
                                        <img *ngIf="option.value.type === O_PICTURES" #imgElement class="file-container-view"   id="{{i}}" [src]='arrayFileView[i].url|safe' />
                                        <audio  *ngIf="arrayFileView[i].view && option.value.type === O_AUDIOS" class="file-container-view"   id="{{i}}" [src]='arrayFileView[i].url|safe' controls>
                                        </audio>
                                        <video *ngIf="arrayFileView[i].view && option.value.type === O_VIDEOS" class="file-container-view"   [src]='arrayFileView[i].url|safe' controls>
                                        </video>
                                        <!--
                                        
                                            iframe *ngIf="arrayFileView[i].view && option.value.type === O_PDFS" #pdfElement  class="file-container-view"  style="max-height: 70% !important;" [src]='arrayFileView[i].url|safe' allowfullscreen></iframe>
                                        -->
											<iframe *ngIf="arrayFileView[i].view && option.value.type === O_PDFS" #pdfElement  [src]='arrayFileView[i].url|safe' allowfullscreen style="min-height: 480px;"></iframe>
                                    </div>
                                    <div class="col-12 center-aling" style="padding-top:3px">
                                    <!--
                                        <span *ngIf="option.value.type === O_VIDEOS">{{'QUIZZES.VIDEO' | translate}}</span>
                                        <span *ngIf="option.value.type === O_PICTURES">{{'QUIZZES.IMAGEN' | translate}}</span>
                                        <span *ngIf="option.value.type === O_AUDIOS">{{'QUIZZES.AUDIO' | translate}}</span>
                                        <span *ngIf="option.value.type === O_PDFS">{{'QUIZZES.DOCUMENTO' | translate}}</span>
                                        &nbsp;
                                        <mat-icon class="edit pointer" ngbTooltip="{{'GLOBALS.EDIT' | translate}}" placement="right" (click)="onFileSelectedReplace(i, option.value)">
                                            edit
                                        </mat-icon>
                                        <mat-icon  *ngIf="arrayFileView[i].view && option.value.type === O_AUDIOS" ngbTooltip="{{'QUIZZES.RECORDNEWAUDIO' | translate}}" class="edit pointer " (click)="grabarAudio(i, option.value)">mic</mat-icon>
                                        <mat-icon  *ngIf="arrayFileView[i].view && option.value.type === O_VIDEOS" ngbTooltip="{{'QUIZZES.RECORDNEWVIDEO' | translate}}" class="edit pointer" (click)="grabarVideo(i, option.value)">camera</mat-icon>
                                        <mat-icon  *ngIf="arrayFileView[i].view && option.value.type === O_PICTURES" ngbTooltip="{{'QUIZZES.NEWPHOTO' | translate}}" class="edit pointer" (click)="tomarFoto(i, option.value)">camera</mat-icon>
                                    -->
                                    </div>
                                </div>

                            </div>
                        </ng-container>
                    <!--
                        <ng-container *ngIf="!arrayFileView[i].view"  class="center-aling">
                            <div class="row mt-4">

                             <div class="col-12">
                                 <div class="row">
                                    <ng-container *ngIf="option.value.type === O_VIDEOS">
                                        <div class="col-4">
                                            <div class="row">
                                                <div class="col-12 center-aling">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <mat-icon  class="icon-mat-type pointer center-aling" id="mat-icon-{{i}}" (click)="openFile(i, option.value)" color="primary">videocam</mat-icon>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 mt-2 center-aling">
                                                    <p class="card-text">
                                                        <span >{{'QUIZZES.VIDEO' | translate}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">

                                            <div class="row">
                                                <div class="col-12 center-aling">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <mat-icon  class="icon-mat-type pointer " id="mat-icon-{{i}}" (click)="grabarVideo(i, option.value)" color="primary">camera</mat-icon>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 mt-2 center-aling">
                                                    <p class="card-text">
                                                        <span>{{'QUIZZES.GRABAR' | translate}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="option.value.type === O_PICTURES">
                                        <div class="col-4">
                                            <div class="row">
                                                <div class="col-12 center-aling">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <mat-icon  class="icon-mat-type pointer center-aling" id="mat-icon-{{i}}" (click)="openFile(i, option.value)" color="primary">image</mat-icon>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 mt-2 center-aling">
                                                    <p class="card-text">
                                                        <span >{{'QUIZZES.IMAGEN' | translate}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">

                                            <div class="row">
                                                <div class="col-12 center-aling">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <mat-icon  class="icon-mat-type pointer " id="mat-icon-{{i}}" (click)="tomarFoto(i, option.value)" color="primary">camera</mat-icon>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 mt-2 center-aling">
                                                    <p class="card-text">
                                                        <span>{{'QUIZZES.FOTO' | translate}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>



                                    <ng-container *ngIf="option.value.type === O_AUDIOS"
                                    >
                                        <div *ngIf="!viewAudio" class="col-4">
                                            <div class="row">
                                                <div class="col-12 center-aling">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <mat-icon  class="icon-mat-type pointer center-aling" id="mat-icon-{{i}}" (click)="openFile(i, option.value)" color="primary">volume_up</mat-icon>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 mt-2 center-aling">
                                                    <p class="card-text">
                                                        <span >{{'QUIZZES.AUDIO' | translate}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="!viewAudio" class="col-4">

                                            <div  class="row m-0 p-0">
                                                <div class="col-12 center-aling">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <mat-icon  class="icon-mat-type pointer " id="mat-icon-{{i}}" (click)="_grabarAudio(i, option.value)" color="primary">mic</mat-icon>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 mt-2 center-aling">
                                                    <p class="card-text">
                                                        <span>{{'QUIZZES.GRABAR' | translate}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="viewAudio" class="col">
                                            <recordar-audio-component [viewElement]="viewAudio" (close)="closeElement($event)"></recordar-audio-component>
                                        </div>
                                    </ng-container>

                                    <div  *ngIf="option.value.type === O_PDFS" class="col-sm-12 col-md-4 col-lg-3 col-xl-2 ">
                                        <div class="row">
                                            <div class="col-12  center-aling ">
                                                <mat-icon  class="icon-mat-type pointer" (click)="openFile(i, option.value)" color="primary">picture_as_pdf</mat-icon>
                                            </div>
                                            <div class="col-12 mt-2  center-aling ">
                                                <p class="card-text">
                                                    <span>{{'QUIZZES.DOCUMENTO' | translate}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                 </div>
                             </div>

                            </div>
                        </ng-container>
                    -->
                    </ng-container>
                </div>
            </ng-container>
        </div>
  </ng-container>
</form>

<form class="container quiz-options" [formGroup]="optionForm">
    <ng-container formArrayName="options">
        <div class="row" *ngFor="let option of optionsArray.controls; let i = index;">
            <ng-container [ngSwitch]="quizType">
                <!-- QUIZZES DE SELECCIÓN ÚNICA -->
                <div class="col-12 flex align-items-start mb-4" [formGroupName]="i" *ngSwitchCase="QuizTypes.SINGLE">
                    <div class="w-100" [formControl]="correctOptionControl" name="option">
                        <div class="w-100 p-2" [disabled]="!option.get('id').value" [value]="option.get('checked').value" [ngClass]="{'bg-correct': (answered && optionsArrayIsCorrect[i].value),
                                                                            'bg-incorrect': (answered && !optionsArrayIsCorrect[i].value), 'bg-selected': (answered && respuestaEstudiante[0].selectedOptions.includes(option.get('id').value))}">
                            <ng-container [ngSwitch]="optionsArrayType[i].value">
                                <div *ngSwitchCase="elementTypes.O_TEXTS">
                                    <p class="option">
                                        {{optionsArrayData[i].value}}
                                    </p>
                                </div>

                                <div class="w-100 h-100 p-2 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_PICTURES">
                                    <img class="mx-auto img-fluid" [style.width]="optionsArrayWidth[i].value ? optionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="optionsArrayHeight[i].value ? optionsArrayHeight[i].value + 'px' : '100%'" [src]="optionsArrayData[i].value| quizElements:'image' |safe" *ngIf="optionsArrayData[i].value" />
                                </div>

                                <div class="w-100 h-100 p-2 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_VIDEOS">
                                    <video class="w-100 mw-100" [style.width]="optionsArrayWidth[i].value ? optionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="optionsArrayHeight[i].value ? optionsArrayHeight[i].value + 'px' : '100%'" controls [src]="optionsArrayData[i].value | quizElements:'video' | safe" *ngIf="optionsArrayData[i].value">
                                    </video>
                                </div>

                                <div class="w-100 h-100 p-2 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_AUDIOS">
                                    <audio class="w-100 d-block" controls [src]="optionsArrayData[i].value | quizElements:'audio' | safe" *ngIf="optionsArrayData[i].value">
                                    </audio>
                                </div>

                                <div class="w-100 h-100 p-2 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_PDFS">
                                    <iframe #pdfElement class="w-100 h-100" [style.width]="optionsArrayWidth[i].value ? optionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="optionsArrayHeight[i].value ? optionsArrayHeight[i].value + 'px' : '100%'" [src]="optionsArrayData[i].value | quizElements:'pdf' | safe" allowfullscreen *ngIf="optionsArrayData[i].value" style="min-height: 920px;"></iframe>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <!-- QUIZZES MULTIPLES -->
                <div class="col-12 d-flex align-items-center" [formGroupName]="i" *ngSwitchCase="QuizTypes.MULTIPLE">
                    <mat-checkbox class="w-100" formControlName="checked"
                    [disabled]="true"
                    [ngClass]="{'bg-correct': (answered && optionsArrayIsCorrect[i].value),
                                                                            'bg-incorrect': (answered && !optionsArrayIsCorrect[i].value)}">
                        <ng-container [ngSwitch]="optionsArrayType[i].value">
                            <div *ngSwitchCase="elementTypes.O_TEXTS">
                                <p class="option">
                                    {{optionsArrayData[i].value}}
                                </p>
                            </div>

                            <div class="w-100 h-100 p-2 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_PICTURES">
                                <img class="mx-auto img-fluid" [style.width]="optionsArrayWidth[i].value ? optionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="optionsArrayHeight[i].value ? optionsArrayHeight[i].value + 'px' : '100%'" [src]="optionsArrayData[i].value| quizElements:'image' |safe" *ngIf="optionsArrayData[i].value" />
                            </div>

                            <div class="w-100 h-100 p-2 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_VIDEOS">
                                <video class="w-100 mw-100" [style.width]="optionsArrayWidth[i].value ? optionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="optionsArrayHeight[i].value ? optionsArrayHeight[i].value + 'px' : '100%'" controls [src]="optionsArrayData[i].value | quizElements:'video' | safe" *ngIf="optionsArrayData[i].value">
                                </video>
                            </div>

                            <div class="w-100 h-100 p-2 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_AUDIOS">
                                <audio class="w-100 d-block" controls [src]="optionsArrayData[i].value | quizElements:'audio' | safe" *ngIf="optionsArrayData[i].value">
                                </audio>
                            </div>

                            <div class="w-100 h-100 p-2 flex justify-content-between align-items-end" *ngSwitchCase="elementTypes.O_PDFS">
                                <iframe #pdfElement class="w-100 h-100" [style.width]="optionsArrayWidth[i].value ? optionsArrayWidth[i].value + 'px' : 'fit-content'" [style.height]="optionsArrayHeight[i].value ? optionsArrayHeight[i].value + 'px' : '100%'" [src]="optionsArrayData[i].value | quizElements:'pdf' | safe" allowfullscreen *ngIf="optionsArrayData[i].value" style="min-height: 920px;"></iframe>
                            </div>
                        </ng-container>
                    </mat-checkbox>
                </div>
            </ng-container>
        </div>
    </ng-container>
</form>
</div>
