<div class="w-100 file-uploader">
	<ng-container *ngIf="loading == true; else default">
		<nb-card-body>
			<nb-progress-bar [value]="value" [status]="status" [displayValue]="true"></nb-progress-bar>
			<h5 style="font-weight: bold; text-align: center;">Generando contenido con IA</h5>
			<p style="text-align: center; font-style: italic;">Puede tardar unos segundos</p>
		</nb-card-body>
	</ng-container>

	<ng-template #default>
		<app-upload-progress-bar [progressValue]="progress" *ngIf="progress"></app-upload-progress-bar>
		<mat-button-toggle type="button" class="h-100 w-100 d-flex" (click)="isTxt ? playFile() : null"
			[style.backgroundImage]="format.background" [style.backgroundSize]="'cover'" [style.backgroundRepeat]="'no-repeat'"
			[style.backgroundPosition]="'center'">

			<ng-container *ngIf="!format.edit && !(isAudio && node.nodesFiles.spotifyAudio)">
				<input class="w-100 h-100" id="{{ format.id }}" type="file" (change)="onFileSelected($event, format.accept)"
					accept="{{ format.accept }}" *ngIf="!isTxt && !node.rented" />
				<label class="w-100 h-100 pointer flex align-items-center justify-content-center flex-column"
					for="{{ format.id }}">
					<mat-icon class="medios pointer" nbTooltip="{{ format.name | translate }}" nbTooltipPlacement="bottom">
						{{ format.icon}}
					</mat-icon>
					<span class="mt-2">{{ "NODEFORMCOMPONENT.ADD" | translate }}
						{{ format.name | translate }}</span>
				</label>
			</ng-container>

			<ng-container *ngIf="!format.edit && (isAudio && node.nodesFiles.spotifyAudio)">
				<label class="w-100 h-100 pointer flex align-items-center justify-content-center flex-column"
					for="{{ format.id }}">
					<mat-icon class="medios pointer" nbTooltip="{{ 'SPOTIFYAUDIO.LISTEN' | translate }}"
						nbTooltipPlacement="bottom" svgIcon="spotify" (click)="goSpotify(node.nodesFiles.spotifyAudio)">
					</mat-icon>
					<span class="mt-2">{{ "SPOTIFYAUDIO.GO" | translate }}</span>
				</label>
			</ng-container>

			<div class="w-100 h-100" style="position: absolute" [innerHtml]="format.background" *ngIf="isTxt"></div>

			<div class="w-100 h-100 flex flex-column align-items-center justify-content-center" (click)="playFile()">
				<div class="w-100 h-100"
					style="
						position: absolute;
						top: 0;
						left: 0;
						padding-right: -17px;
						z-index: 0;" *ngIf="isPdf && format.background">
					<ng-container *ngIf="ext === 'docx'; else embedPdf">
						<ngx-doc-viewer [url]="format.background" viewer="office" class="w-100 h-100"></ngx-doc-viewer>
					</ng-container>
					<ng-template #embedPdf>
						<embed *ngIf="sanitizedUrl" [src]="sanitizedUrl" class="w-100 h-100" />
					</ng-template>
				</div>
				<mat-icon class="medios pointer" nbTooltip="{{ format.name | translate }}" nbTooltipPlacement="bottom"
					style="z-index: 10">{{ format.icon }}
				</mat-icon>
			</div>

			<!-- LATERAL RIGHT ICONS -->
			<div class="lateral-right-icons">
				<ng-container *ngIf="!format.edit; else editIcons">
					<label *ngIf="(isVideo || isImagen) && !node.rented" for="Dispositivo"
						(click)="streamOrHardDisk($event, format.accept)">
						<mat-icon class="edit pointer" [nbTooltip]="'NODEFORMCOMPONENT.CAMARAVIDEOFOTO' | translate"
							nbTooltipPlacement="left">camera</mat-icon>
					</label>
					<label *ngIf="isAudio && !node.rented && !node.nodesFiles.spotifyAudio" for="Dispositivo" (click)="streamOrHardDisk($event, format.accept)">
						<mat-icon svgIcon="mic" class="edit pointer" [nbTooltip]="'NODEFORMCOMPONENT.GRABARAUDIO' | translate"
							nbTooltipPlacement="left">
						</mat-icon>
					</label>
				</ng-container>

				<ng-template #editIcons>
					<div *ngIf="!node.rented">
						<div *ngIf="!isTxt && !format.url" class="d-flex justify-content-center mb-1">
							<input id="{{ format.id }}" #fileEdit type="file" class="file-{{ format.id }}"
								(change)="onFileSelected($event, format.accept)" accept="{{ format.accept }}" />
							<mat-icon class="edit pointer" nbTooltip="Editar" nbTooltipPlacement="left"
								(click)="fileEdit.click()">edit</mat-icon>
						</div>

						<div *ngIf="(isVideo && !format.url) || isImagen" for="Dispositivo"
							(click)="streamOrHardDisk($event, format.accept)" class="d-flex justify-content-center mb-1">
							<mat-icon class="edit pointer" [nbTooltip]="'NODEFORMCOMPONENT.CAMARAVIDEOFOTO' | translate"
								nbTooltipPlacement="left">camera</mat-icon>
						</div>

						<div *ngIf="isAudio" for="Dispositivo" (click)="streamOrHardDisk($event, format.accept)" class="d-flex justify-content-center mb-1">
							<mat-icon svgIcon="mic" class="edit pointer" [nbTooltip]="'NODEFORMCOMPONENT.GRABARAUDIO' | translate"
								nbTooltipPlacement="left"></mat-icon>
						</div>

						<div *ngIf="isVideo && !format.url" class="d-flex justify-content-center mb-1">
							<input #videoPreview id="videoPreview" type="file" (change)="onVideoPreview($event)" accept="image/*" />
							<mat-icon class="edit pointer" [nbTooltip]="'MISC.PREVIEW' | translate" nbTooltipPlacement="left"
								(click)="videoPreview.click()">crop_original</mat-icon>
						</div>

						<div *ngIf="!isTxt && format.fileName && !format.url" class="d-flex justify-content-center mb-1">
							<mat-icon class="edit pointer" nbTooltipPlacement="left" [nbTooltip]="'NODEFORMCOMPONENT.DELETEFILE' | translate"
								(click)="deleteFile(format.type, format.fileName, format.idNodeFile)">delete</mat-icon>
						</div>
						<div *ngIf="isVideo && format.background && !format.url" class="d-flex justify-content-center mb-1">
							<mat-icon class="edit pointer" nbTooltipPlacement="left" [nbTooltip]="'NODEFORMCOMPONENT.DELETEPREVIEW' | translate"
								(click)="deleteFile('videoImage', format.fileName, format.idNodeFile)">delete</mat-icon>
						</div>
						<div *ngIf="isVideo && !format.fileName && !node.rented && !format.isVideoInstagram && !format.isVideoTiktok"	class="d-flex justify-content-center mb-1">
							<mat-icon class="edit pointer" nbTooltipPlacement="left" [nbTooltip]="'GLOBALS.DELETE' | translate"
								(click)="deleteExternalVideo(1)">delete</mat-icon>
						</div>
						<div *ngIf="isVideo && !format.fileName && !node.rented && !format.isVideoYoutube && !format.isVideoTiktok" class="d-flex justify-content-center mb-1">
							<mat-icon class="edit pointer" nbTooltipPlacement="left" [nbTooltip]="'GLOBALS.DELETE' | translate"
								(click)="deleteExternalVideo(2)">delete</mat-icon>
						</div>
						<div *ngIf="isVideo && !format.fileName && !node.rented && !format.isVideoInstagram && !format.isVideoYoutube" class="d-flex justify-content-center mb-1">
							<mat-icon class="edit pointer" nbTooltipPlacement="left" [nbTooltip]="'GLOBALS.DELETE' | translate"
								(click)="deleteExternalVideo(3)">delete</mat-icon>
						</div>
					</div>
				</ng-template>
			</div>
			<!-- END LATERAL ICONS -->

			<!-- LATERAL LEFT ICONS -->
			<div class="lateral-left-icons d-flex flex-column">
				<label *ngIf="
						isVideo &&
						!format.fileName &&
						!node.rented &&
						!format.isVideoInstagram &&
						!format.isVideoTiktok
					" (click)="openModalExternalVideo(1)" class="mb-1">
					<mat-icon svgIcon="youtube" class="tinymce.in pointer"
						[nbTooltip]="'YOUTUBEVIDEO.TITLE' | translate"></mat-icon>
				</label>
				<label *ngIf="
						isVideo &&
						!format.fileName &&
						!node.rented &&
						!format.isVideoYoutube &&
						!format.isVideoTiktok
					" (click)="openModalExternalVideo(2)" class="mb-1">
					<mat-icon svgIcon="instagram" class="tinymce.in pointer"
						[nbTooltip]="'INSTAGRAMVIDEO.TITLE' | translate"></mat-icon>
				</label>
				<label *ngIf="
						isVideo &&
						!format.fileName &&
						!node.rented &&
						!format.isVideoInstagram &&
						!format.isVideoYoutube
					" (click)="openModalExternalVideo(3)">
					<mat-icon svgIcon="tiktok" class="tinymce.in pointer" [nbTooltip]="'TIKTOKVIDEO.TITLE' | translate"></mat-icon>
				</label>

				<label *ngIf="
						isImagen
					" (click)="generateImage($event)">
					<nb-icon icon="bulb-outline" nbTooltip="Generar con IA"></nb-icon>

				</label>

				<label *ngIf="
							isAudio &&
							!format.fileName &&
							!node.rented &&
							!format.isVideoYoutube &&
							!format.isVideoInstagram &&
							!format.isVideoTiktok
						" (click)="openModalSpotifyAudio()" class="mb-1">
					<mat-icon svgIcon="spotify" class="tinymce.in pointer" [nbTooltip]="'SPOTIFYAUDIO.TITLE' | translate"></mat-icon>
				</label>

				<label *ngIf="!isTxt && format.fileName && !format.url">
					<mat-icon class="edit pointer" nbTooltipPlacement="right" [nbTooltip]="'NODEFORMCOMPONENT.DOWNLOADFILE' | translate"
						(click)="downloadFile(format.type, format.fileName)">file_download</mat-icon>
				</label>
			</div>

			<!-- END LATERAL LEFT ICONS -->
		</mat-button-toggle>
	</ng-template>



</div>
