import { AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReportingService } from '../service/reporting.service';
import { LoginService } from 'src/app/core/services/login';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, first, finalize } from 'rxjs/operators';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';
import { Subject } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/models/store.model';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { ModalQuizesMultiplesComponent } from '../modal-quizes-multiples/modal-quizes-multiples.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { RecordarQuizPlayComponent } from '../../quiz-open/quiz-play/quiz-play.component';
import { MastersService } from 'src/app/core/services/masters';

declare var $: any;
@Component({
  selector: 'app-tabla',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.scss']
})
export class TablaComponent implements OnInit, AfterViewInit{
	@Input() dataTable: any[] = [];
	@Input() reportType?: string = '';
	@Input() idGrupo: any;
	@Input() idGrafo?: any;
  @Input() idCourse?: any;
	@Input() listGraphSelected?: any[] = [];
	@Input() listChallengeSelected?: number[] = [];
	@Input() verActividades?: boolean = false;
	@Input() verAciertos?: boolean = false;
	@Input() verFotos?: boolean = false;
	@Input() verK?: boolean = false;
	@Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();

	public rowStudent: any[] = [];
  public rowAciertos: any[] = [];
	public rowtiempo: any[] = [];
	public bodyTable: any[] = [];
	public listUsers: any [] = [];

	public verTabla: boolean = true;

	public dataTable_AZ: any[] = [];
	public dataTable_ZA: any[] = [];
	public dataTable_aciertosDEC: any[] = [];
	public dataTable_aciertosASC: any[] = [];
	public dataTable_tiempoTotalDEC: any[] = [];
	public dataTable_tiempoTotalASC: any[] = [];

	isLoading: boolean = false;
	private destroy$ = new Subject();

  table: any;
	NOIMAGE: string = "../../../../../assets/images/icons/account_circle.svg";

	filtroAzTabla: string = "A-Z";
	filtroAciertos: string = "";
	filtroTiempo: string = "";
	mostrarFlechita: boolean = false;
	profile: any;
	profileTitles = {
		ESTUDIANTE: 'Estudiante',
		AUTOR: 'Editor',
		PROFESOR: 'Profesor',
		PADRE: 'Padre',
		ADMIN: 'Administrador'
	}
	profileTitle: string;

	constructor(
		private reportingService: ReportingService,
		public loginService: LoginService,
		public translateService: TranslateService,
		private imagePipe: ImagenPipe,
		public activeModal: NgbActiveModal,
		private store: Store<State>,
		private toaster: ToasterService,
		private quizService: QuizzesService,
		private modalService: NgbModal,
		private mastersService: MastersService,
	) {}

  ngOnInit() {
		this.rowStudent = [this.dataTable[0]];
		this.rowAciertos = [this.dataTable[1]];
		if(this.reportType == 'target'){
			this.rowtiempo = [this.dataTable[2]];
			this.bodyTable = this.dataTable.slice(3);
		} else if(this.reportType == 'challenge'){
			this.bodyTable = this.dataTable.slice(2);
		} else if(this.reportType == 'control'){
			this.bodyTable = this.dataTable.slice(1);
		}
		this._listUsers();
		this.precargarFiltrosTabla();

		//obtenemos el perfil que esta activo
		this.store.select(store => store.selectedProfile).pipe(takeUntil(this.destroy$)).subscribe((selectedProfile) => {
			this.profile = selectedProfile['selectedProfile']
		});
		this.profile = this.loginService.getProfile();
		this.profileTitle = this.profileTitles[this.profile]
  }

	ngAfterViewInit(){
		if (this.table) {
      this.table.destroy();
    }
		if(!JSON.parse(localStorage.getItem("noConflict"))){
			$.noConflict();
			localStorage.setItem("noConflict", "true");
		}
		this.createTable();
	}

	createTable() {
		setTimeout(() => {
			this.table = $('#myTable').DataTable({
				pageLength: 1000,
				paging: false,
				searching: false,
				language: {
					"sLengthMenu": ""
					/*this.translateService.instant('ASISTENCIA.LENGTHMENU')
					*/,
					"sZeroRecords": this.translateService.instant('ASISTENCIA.ZERORECORDS'),
					"sEmptyTable": this.translateService.instant('ASISTENCIA.EMPTYTABLE'),
					"sInfo": "",
					"sInfoEmpty": "",
					"sInfoFiltered": "",
					"sInfoPostFix": "",
					"sSearch": this.translateService.instant('ASISTENCIA.SEARCH') + " " + this.translateService.instant('GLOBALS.ACTIVITIES') + ":",
					"sUrl": "",
					"sInfoThousands": ",",
					"sLoadingRecords": this.translateService.instant('ASISTENCIA.LOADINGRECORDS'),
					"oPaginate": {
														"sFirst": this.translateService.instant('ASISTENCIA.FIRST'),
														"sLast": this.translateService.instant('ASISTENCIA.LAST'),
														"sNext": this.translateService.instant('ASISTENCIA.NEXT'),
														"sPrevious": this.translateService.instant('ASISTENCIA.PREVIOUS')
												},
					"oAria": {
							"sSortAscending": this.translateService.instant('ASISTENCIA.SORTASCENDING'),
							"sSortDescending": this.translateService.instant('ASISTENCIA.SORTDESCENDING'),
					}
				},
				columnDefs: [
					{
						visible: false,
						targets: 0
					},
					{
						visible: false,
						targets: 2
					},
					{
						orderable: true,
						targets: 2
					},
					{
						orderable: false,
						targets: '_all'
					},
				],
			});
			// Agrega la funcionalidad de redimensionado de columnas
			/*
			$('#myTable').colResizable({
				postbackSafe: true
			});
			*/
		}, 0)
  }

	getUserAvatar(imagen):string{
    let image:string = ''
    imagen!== null && imagen !== ""? image = this.imagePipe.transform(imagen, 'userAvatar') : image = this.NOIMAGE
    return `url('${image}')`
  }

	ngOnDestroy() {
    // Limpia y destruye la tabla DataTable al salir del componente
    if (this.table) {
      this.table.destroy();
    }
  }

  closeModal(sendData?: any) {
    this.destroy$.next(true);
    this.activeModal.close(sendData);
  }

	/* ===== FUNCIONES PARA ORDENAR LOS GRUPOS DE ESTUDIANTES ===== */
	ordenarTabla() {
		let order = this.filtroAzTabla;
		if (order === '') {
			order = 'A-Z';
		} else if (order === 'A-Z') {
			order = 'Z-A';
		} else if (order === 'Z-A') {
			order = 'A-Z';
		}
		this.filtroAzTabla = order;
		this._ordenarTabla(order);
		//this.showElement = false;
		this.filtroAciertos = '';
		this.filtroTiempo = '';
		this.mostrarFlechita = true;
	}
	ordenarTabla2() {
		let order = this.filtroAciertos;
		if (order === '') {
			order = 'aciertosDEC';
		} else if (order === 'aciertosASC') {
			order = 'aciertosDEC';
		} else if (order === 'aciertosDEC') {
			order = 'aciertosASC';
		}
		this.filtroAciertos = order;
		this._ordenarTabla(order);
		//this.showElement = false;
		this.filtroTiempo = '';
		this.filtroAzTabla = 'A-Z'
		this.mostrarFlechita = false;
	}
	ordenarTabla3() {
		let order = this.filtroTiempo;
		if (order === '') {
			order = 'tiempoTotalDEC';
		} else if (order === 'tiempoTotalASC') {
			order = 'tiempoTotalDEC';
		} else if (order === 'tiempoTotalDEC') {
			order = 'tiempoTotalASC';
		}
		this.filtroTiempo = order;
		this._ordenarTabla(order);
		//this.showElement = false;
		this.filtroAciertos = '';
		this.filtroAzTabla = 'A-Z'
		this.mostrarFlechita = false;
	}
	_ordenarTabla(order){
		if (this.table) {
      this.table.destroy();
    }
		switch (order) {
			case 'A-Z':
				this.listUsers.sort((a, b) => (a.name > b.name) ? 1 : -1);
				this.actualizarTabla(this.listUsers);
				break;
			case 'Z-A':
				this.listUsers.sort((a, b) => (a.name < b.name) ? 1 : -1);
				this.actualizarTabla(this.listUsers);
				break;
			case 'aciertosDEC':
				this.listUsers.sort((a, b) => (a.aciertos < b.aciertos) ? 1 : -1);
				this.actualizarTabla(this.listUsers);
				break;
			case 'aciertosASC':
				this.listUsers.sort((a, b) => (a.aciertos > b.aciertos) ? 1 : -1);
				this.actualizarTabla(this.listUsers);
				break;
			case 'tiempoTotalDEC':
				this.listUsers.sort((a, b) => (a.tiempoTotal < b.tiempoTotal) ? 1 : -1);
				this.actualizarTabla(this.listUsers);
				break;
			case 'tiempoTotalASC':
				this.listUsers.sort((a, b) => (a.tiempoTotal > b.tiempoTotal) ? 1 : -1);
				this.actualizarTabla(this.listUsers);
				break;
		}
	}
	actualizarTabla(listUsers: any []){
		this.dataTable = [];
		this.isLoading = true;
		let rol = this.profileTitle === 'Estudiante' ? 2 : 3;
		let usersOrder = [];
		for (let index = 0; index < listUsers.length; index++) {
			usersOrder.push(listUsers[index].id);
		}
		if(this.reportType == 'target'){
			this.reportingService
				.informeDataTableTargets(this.idGrupo, this.listGraphSelected, rol, usersOrder)
				.subscribe(
					(result) => {
					this.dataTable = Object.values(result.data);
					this.rowStudent = [this.dataTable[0]];
					this.rowAciertos = [this.dataTable[1]];
					this.rowtiempo = [this.dataTable[2]];
					this.bodyTable = this.dataTable.slice(3);
					// Recrear la tabla con los nuevos datos
					this.createTable();
					this.isLoading = false;
					},
				(err) =>
					this.toaster.error(
						this.translateService.instant("INFORMES.ERRORQUIZZES")
					)
			);
		} else if(this.reportType == 'challenge'){
			this.reportingService
			.informeDataTableChallenges(this.idGrupo, this.listChallengeSelected, true, usersOrder)
			.subscribe(
				(result) => {
				this.dataTable = Object.values(result.data);
				this.rowStudent = [this.dataTable[0]];
				this.rowAciertos = [this.dataTable[1]];
				this.bodyTable = this.dataTable.slice(2);
				// Recrear la tabla con los nuevos datos
				this.createTable();
				this.isLoading = false;
				},
			(err) =>
				this.toaster.error(
					this.translateService.instant("INFORMES.ERRORQUIZZES")
				)
			);
		} else if(this.reportType == 'control'){
			this.reportingService
				.informeDataTableControl(
					this.idGrupo,
					this.listChallengeSelected,
				)
				.subscribe(
					(result) => {
						this.dataTable = Object.values(result.data);
						this.rowStudent = [this.dataTable[0]];
						this.rowAciertos = [this.dataTable[1]];
						this.bodyTable = this.dataTable.slice(2);
						// Recrear la tabla con los nuevos datos
						this.createTable();
						this.isLoading = false;
					},
					(err) =>
						this.toaster.error(
							this.translateService.instant("INFORMES.ERRORQUIZZES")
						)
				);
		}
	}
	_listUsers(){
		//USAMOS ESTAS LISTAS PARA ORDENAR DESPUES LA TABLA
		this.listUsers = [];
		let listUsersName = [];
		let listUsersId = [];
		let listAciertos = [];
		let listTiempoTotal = [];
		for (let index = 2; index < this.dataTable[0].length; index++) {
			const element = this.dataTable[0][index];
			const element2 = this.dataTable[1][index];
			const element3 = this.dataTable[2][index];
			listUsersName.push(element.nameUser + ' ' + element.surnameUser);
			listUsersId.push(element.idUser);
			listAciertos.push(element2.porcentajeAciertosUser);
			if(this.reportType == 'target'){
				const tiempoString: string = element3.tiempo;
				// Dividir el string en horas, minutos y segundos
				const tiempoArray: string[] = tiempoString.split(':');
				// Convertir cada parte del tiempo a un número entero
				const horas: number = parseInt(tiempoArray[0], 10);
				const minutos: number = parseInt(tiempoArray[1], 10);
				const segundos: number = parseInt(tiempoArray[2], 10);
				// Calcular el tiempo total en segundos
				const tiempoTotalSegundos: number = horas * 3600 + minutos * 60 + segundos;
				listTiempoTotal.push(tiempoTotalSegundos);
			}
		}
		for (let index = 0; index < listUsersId.length; index++) {
			const user = {
				name: listUsersName[index],
				id: listUsersId[index],
				aciertos: listAciertos[index],
				tiempoTotal: this.reportType == 'target' ? listTiempoTotal[index]: null,
			};
			this.listUsers.push(user);
		}
	}

	changeVerAcierto(){
		this.verAciertos = !this.verAciertos;
		if(this.verAciertos){
			this.table.column(2).visible(true);
		} else {
			this.table.column(2).visible(false);
		}
	}

	formatoTiempo(tiempo: string){
		let formatedTime = "0s";
		//dividimos el tiempo en horas, minutos y segundos
		const partes = tiempo.split(':');
		const horas = Number(partes[0]);
		const minutos = Number(partes[1]);
		const segundos = Number(partes[2]);
		//aproximamos las horas y minutos
		if(horas > 0 && minutos > 30){
			const hours = horas + 1;
			formatedTime = hours + "h";
		} else if(horas > 0 && minutos <= 30){
			formatedTime = horas + "h";
		} else if(horas == 0 && minutos > 30){
			formatedTime = "1h";
		} else if(horas == 0 && minutos <= 30 && segundos > 30){
			const minutes = minutos + 1;
			formatedTime = minutes + "m";
		} else if(horas == 0 && minutos <= 30 && minutos > 0 && segundos <= 30){
			formatedTime = minutos + "m";
		} else if(horas == 0 && minutos == 0 && segundos > 30){
			formatedTime = "1m";
		} else if(horas == 0 && minutos == 0 && segundos <= 30 && segundos > 0){
			formatedTime = segundos + "s";
		}
		return formatedTime;
	}

	precargarFiltrosTabla() {
    const list = ['A-Z', 'Z-A', 'aciertosDEC', 'aciertosASC', 'tiempoTotalDEC', 'tiempoTotalASC'];
    const orderFunctions = {
        'A-Z': (a, b) => a.name.localeCompare(b.name),
        'Z-A': (a, b) => b.name.localeCompare(a.name),
        'aciertosDEC': (a, b) => b.aciertos - a.aciertos,
        'aciertosASC': (a, b) => a.aciertos - b.aciertos,
        'tiempoTotalDEC': (a, b) => b.tiempoTotal - a.tiempoTotal,
        'tiempoTotalASC': (a, b) => a.tiempoTotal - b.tiempoTotal,
    };
    for (const order of list) {
        this.listUsers.sort(orderFunctions[order]);
        this.filtrosTabla(this.listUsers, order);
    }
	}

	filtrosTabla(listUsers: any [], order: any){
		//this.dataTable = [];
		this.isLoading = true;
		let rol = this.profileTitle === 'Estudiante' ? 2 : 3;
		let usersOrder = [];
		for (let index = 0; index < listUsers.length; index++) {
			usersOrder.push(listUsers[index].id);
		}
		if(this.reportType == 'target'){
			this.reportingService
				.informeDataTableTargets(this.idGrupo, this.listGraphSelected, rol, usersOrder)
				.subscribe(
					(result) => {
						if(order == 'A-Z'){
							this.dataTable_AZ = Object.values(result.data);
						} else if(order == 'Z-A'){
							this.dataTable_ZA = Object.values(result.data);
						} else if(order == 'aciertosDEC'){
							this.dataTable_aciertosDEC = Object.values(result.data);
						} else if(order == 'aciertosASC'){
							this.dataTable_aciertosASC = Object.values(result.data);
						} else if(order == 'tiempoTotalDEC'){
							this.dataTable_tiempoTotalDEC = Object.values(result.data);
						} else if(order == 'tiempoTotalASC'){
							this.dataTable_tiempoTotalASC = Object.values(result.data);
						}
						this.isLoading = false;
					},
				(err) =>
					this.toaster.error(
						this.translateService.instant("INFORMES.ERRORQUIZZES")
					)
			);
		} else if(this.reportType == 'challenge'){
			this.reportingService
			.informeDataTableChallenges(this.idGrupo, this.listChallengeSelected, true, usersOrder)
			.subscribe(
				(result) => {
					if(order == 'A-Z'){
						this.dataTable_AZ = Object.values(result.data);
					} else if(order == 'Z-A'){
						this.dataTable_ZA = Object.values(result.data);
					} else if(order == 'aciertosDEC'){
						this.dataTable_aciertosDEC = Object.values(result.data);
					} else if(order == 'aciertosASC'){
						this.dataTable_aciertosASC = Object.values(result.data);
					} else if(order == 'tiempoTotalDEC'){
						this.dataTable_tiempoTotalDEC = Object.values(result.data);
					} else if(order == 'tiempoTotalASC'){
						this.dataTable_tiempoTotalASC = Object.values(result.data);
					}
					this.isLoading = false;
				},
			(err) =>
				this.toaster.error(
					this.translateService.instant("INFORMES.ERRORQUIZZES")
				)
			);
		}
	}

	aplicarFiltro(order){
		this.verTabla = false;
		if(this.verAciertos){
			this.changeVerAcierto();
		}
		switch (order) {
			case 'A-Z':
				this.dataTable = this.dataTable_AZ;
				break;
			case 'Z-A':
				this.dataTable = this.dataTable_ZA;
				break;
			case 'aciertosDEC':
				this.dataTable = this.dataTable_aciertosDEC;
				break;
			case 'aciertosASC':
				this.dataTable = this.dataTable_aciertosASC;
				break;
			case 'tiempoTotalDEC':
				this.dataTable = this.dataTable_tiempoTotalDEC;
				break;
			case 'tiempoTotalASC':
				this.dataTable = this.dataTable_tiempoTotalASC;
				break;
		}
		this.rowStudent = [this.dataTable[0]];
		this.rowAciertos = [this.dataTable[1]];
		if(this.reportType == 'target'){
			this.rowtiempo = [this.dataTable[2]];
			this.bodyTable = this.dataTable.slice(3);
		} else if(this.reportType == 'challenge'){
			this.bodyTable = this.dataTable.slice(2);
		}
		if (this.table) {
			this.table.destroy();
		}
		setTimeout(() => {
			this.createTable();
			this.verTabla = true;
		}, 100)
	}

	/*
		ESTO SE EJECUTA CUANDO QUEREMOS COMENTAR EN UN QUIZ
	*/
	viewQuiz(node: any){
		if(this.profile == 'PROFESOR' && node.isNode == 0){
			//Creamos el log
			let storedGroupInfo = JSON.parse(localStorage.getItem('selectedGroupInfo'));
			const bodyLog1 = {
				action: "informes",
				actionData: "Ha revisado el quiz con id " + node.idQuiz + ".",
				startDate: new Date(),
				endingDate: null,
				role: this.loginService.getProfile(),
				idGroup: storedGroupInfo ? storedGroupInfo.idGroup : 0,
			};
			this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
			if (node.multiple === 1) {
				let storedGroupInfo = JSON.parse(localStorage.getItem('selectedGroupInfo'));
					const idGrupo = storedGroupInfo ? storedGroupInfo.idGroup : 0;
				this.quizService.getQuizInformMultipleHijos(node.idQuiz, node.idUser, idGrupo).pipe(finalize(() => this.isLoading = false)).subscribe((res:any) => {

					const modalRef = this.modalService.open(ModalQuizesMultiplesComponent, {scrollable: true, windowClass: MODAL_DIALOG_TYPES.W35, backdrop: 'static' })
					modalRef.componentInstance.quizesMultiplesList = res;
					modalRef.componentInstance.idUser = node.idUser;

					modalRef.result.then(res => {
						if(res){
							this.reload.emit(true);
						}
				})
				});
			} else {
					this.quizService.getQuizInforme(node.idQuiz, node.idUser).pipe(finalize(() => this.isLoading = false)).subscribe((res:any) => {
						const modalRef = this.modalService.open(RecordarQuizPlayComponent, {scrollable: true, windowClass: MODAL_DIALOG_TYPES.W100, backdrop: 'static' })
						//modalRef.componentInstance.quiz = { ...res.quiz, user: res.quiz.user, idOriginal: res.quiz.idOriginal, id: res.quiz.idQuiz, originalX: res.quiz.originalX, originalY: res.quiz.originalY, size: res.quiz.size, sizeQuiz: res.quiz.sizeQuiz, x: res.quiz.x, y: res.quiz.y };;
						modalRef.componentInstance.quiz = res.quiz;
						modalRef.componentInstance.elements = res.elementsQuestion;
						modalRef.componentInstance.respuestaEstudiante = res.elementsAnswer;
						modalRef.componentInstance.courseId = node.idCourse;
						modalRef.componentInstance.graphId = node.idTarget;
						modalRef.componentInstance.isEvaluation = true;

						modalRef.result.then(res => {
							if(true){
								this.reload.emit(true);
							}
					})
				});
				/*
				if(res.quiz.quizType === 1){
					this.quizService.getQuizInforme(node.idQuiz, node.idUser).pipe(finalize(() => this.isLoading = false)).subscribe((res:any) => {
						const modalRef = this.modalService.open(RecordarQuizPlayComponent, {scrollable: true, windowClass: MODAL_DIALOG_TYPES.W100, backdrop: 'static' })
						modalRef.componentInstance.quiz = { ...res.quiz, user: res.quiz.user, idOriginal: res.quiz.idOriginal, id: res.quiz.idQuiz, originalX: res.quiz.originalX, originalY: res.quiz.originalY, size: res.quiz.size, sizeQuiz: res.quiz.sizeQuiz, x: res.quiz.x, y: res.quiz.y };;
						//modalRef.componentInstance.quiz = res.quiz;
						modalRef.componentInstance.elements = res.elementsQuestion;
						modalRef.componentInstance.respuestaEstudiante = res.elementsAnswer;
						modalRef.componentInstance.courseId = node.idCourse;
						modalRef.componentInstance.graphId = node.idTarget;
						modalRef.componentInstance.isEvaluation = true;

						modalRef.result.then(res => {
							if(true){
								this.reload.emit(true);
							}
					})
					});
				} else{
					this.toaster.error(this.translateService.instant("INFORMES.ONLYOPENANSWER")
					)
				}
				*/
			}
		} else if(this.profile == 'ESTUDIANTE' && node.isNode == 0){
			if(node.isMultiplex != 0){
				const curso = node.idCourse === 0 ? this.idCourse : node.idCourse;
				const grafo = node.idTarget === 0 ? this.idGrafo : node.idTarget;
				this.quizService.getQuiz(node.idQuiz, curso, grafo).subscribe(res => {
	
					if(res.quiz.quizType === 1){
						this.quizService.getQuizInforme(node.idQuiz, node.idUser).pipe(finalize(() => this.isLoading = false)).subscribe((res:any) => {
							const modalRef = this.modalService.open(RecordarQuizPlayComponent, {scrollable: true, windowClass: MODAL_DIALOG_TYPES.W100, backdrop: 'static' })
							modalRef.componentInstance.quiz = { ...res.quiz, user: res.quiz.user, idOriginal: res.quiz.idOriginal, id: res.quiz.idQuiz, originalX: res.quiz.originalX, originalY: res.quiz.originalY, size: res.quiz.size, sizeQuiz: res.quiz.sizeQuiz, x: res.quiz.x, y: res.quiz.y };;
							modalRef.componentInstance.elements = res.elementsQuestion;
							modalRef.componentInstance.respuestaEstudiante = res.elementsAnswer;
							modalRef.componentInstance.courseId = node.idCurso;
							modalRef.componentInstance.graphId = node.target;
							modalRef.componentInstance.isEvaluation = false;
	
							modalRef.result.then(res => {
									// Falta enviar la data para cambiar los valores y actualizar el backend
									this.reload.emit(true);
						})
						});
					} else{
						this.toaster.error(this.translateService.instant("INFORMES.ONLYOPENANSWER")
						)
					}
				})
			}
		} else if(this.profile == 'PADRE'){
			// si es un padre no debe hacer nada
		}
  }
	/* =========== */

}
