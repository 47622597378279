import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { LoginService } from 'src/app/core/services/login';
import { MastersService } from 'src/app/core/services/masters';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { LocalStorage } from 'src/app/core/utils';

@Component({
  selector: 'app-modal-alert-pensar-creando',
  templateUrl: './modal-alert-pensar-creando.component.html',
  styleUrls: ['./modal-alert-pensar-creando.component.scss']
})
export class ModalAlertPensarCreandoComponent implements OnInit {

	idGrupo: number = 0;
	group: any;

	constructor(
		public activeModal: NgbActiveModal,
		public translateService: TranslateService,
		public loginService: LoginService,
		private localStorage: LocalStorage,
		private masterService: MastersService,
	){}

	ngOnInit() {
		//definimos variable para tutoriales
		if(this.loginService.esProfesor()){
			this.localStorage.setItem("idTutorialContext", 35);
			if(this.localStorage.getItem("TutorialesON") == "true"){
				this.masterService.openModalTutorial(35);
			}
		}
	}

	closeModal(sendData?: any) {
    this.activeModal.close(false);
  }

	crearTarea(){
		this.activeModal.close("crear");
	}

	verTareas(){
		this.activeModal.close("ver");
	}

}
