import { LOCALSTORAGESTRINGS } from "src/app/core/models/masters/localstorage.enum";
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxCopilotService } from "ngx-copilot";
import { environment } from "src/environments/environment";
import { TutotrialDataLocal } from "../../models/tuturial/tutorial.model";
import { User } from "../../models/users/user.models";
import { LoginService } from "../../services/login";
import { LocalStorage } from "../../utils/local-storage";
import { Utils } from "../../utils/utils";
import { GraphService } from "../../services/graph/graph.service";
import { TargetsService } from "../../services/targets";
import { SigmaToolbarsService } from "../../services/sigma-toolbars/sigma-toolbars.service";
import { ModalTutorialUserComponent } from "src/app/shared/components/modal-tutorial-user/modal-tutorial-user.component";
import { MODAL_DIALOG_TYPES } from "../../utils/modal-dialog-types";
import { NbToggleModule } from "@nebular/theme";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MastersService } from "../../services/masters";
@Component({
	selector: "app-side-bar",
	templateUrl: "./side-bar.component.html",
	styleUrls: ["./side-bar.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class SideBarComponent implements OnInit, OnDestroy {
	public arrayValues: number[] = [];
	public usuario: User;

	public isModalOpen: boolean = false;
	public isVideoModalOpen: boolean = false;
	public videoList: any[] = [];
	public selectedVideoIndex: number | null = null;
	public videoUrl: SafeResourceUrl | null = null;

	modalPosition = { top: 200, left: 15 }; // Modal principal
	videoModalPosition = { top: 350, left: 250 }; // Modal de video

	isDraggingMain = false;
	isDraggingVideo = false;

	dragStartMain = { x: 0, y: 0 };
	dragStartVideo = { x: 0, y: 0 };

	intervalId: NodeJS.Timeout;
	idTutorialContent_aux: number = 0;

	cpPosition: any;
	sidebarData: boolean;
	urlTutorial: string = environment.tutorial;
	graphVisited_Student: [] = [];
	graphVisited_Editor: [] = [];
	lastTarget: number;
	lastTarget_Editor: number;

	public constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private modalService: NgbModal,
		public translateService: TranslateService,
		public loginService: LoginService,
		private localStorage: LocalStorage,
		private copilot: NgxCopilotService,
		private localStorageService: LocalStorage,
		private utils: Utils,
		private graphServ: GraphService,
		public targetsService: TargetsService,
		public toolsService: SigmaToolbarsService,
		private masterService: MastersService,
		private sanitizer: DomSanitizer
	) {
		this.activatedRoute.params.subscribe((params) => {
			// De esta forma cada vez que cambia el parametro lo actualizo
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		});
	}
	public ngOnInit() {
		this._traducirOpciones();
		this.tutorialDisponible();
		// obtenemos la info del grafo visto anteriormente
		this.obtainLastTargetsVisited();

		// Escuchar los cambios en el estado del modal
		this.masterService.tutorialModalData$.subscribe((data) => {
			this.isModalOpen = data.isOpen;
			this.videoList = data.videos;
		});

		this.startInterval();
	}

	public openVideoModal(index: number, url: string): void {
		this.selectedVideoIndex = index;
		this.videoUrl = this.sanitizeUrl(this.getEmbedUrl(url));
		this.isVideoModalOpen = true;
	}

	public closeVideoModal(): void {
		this.selectedVideoIndex = null;
		this.videoUrl = null;
		this.isVideoModalOpen = false;
	}

	// Inicia el arrastre para el modal correspondiente
	startDragging(event: MouseEvent, modalType: "main" | "video") {
		if (modalType === "main") {
			this.isDraggingMain = true;
			this.dragStartMain = {
				x: event.clientX - this.modalPosition.left,
				y: event.clientY - this.modalPosition.top,
			};
			document.addEventListener("mousemove", this.dragModalMain);
			document.addEventListener("mouseup", this.stopDraggingMain);
		} else if (modalType === "video") {
			this.isDraggingVideo = true;
			this.dragStartVideo = {
				x: event.clientX - this.videoModalPosition.left,
				y: event.clientY - this.videoModalPosition.top,
			};
			document.addEventListener("mousemove", this.dragModalVideo);
			document.addEventListener("mouseup", this.stopDraggingVideo);
		}
	}

	// Arrastre del modal principal
	dragModalMain = (event: MouseEvent) => {
		if (!this.isDraggingMain) return;
		this.modalPosition = {
			top: event.clientY - this.dragStartMain.y,
			left: event.clientX - this.dragStartMain.x,
		};
	};

	// Arrastre del modal de video
	dragModalVideo = (event: MouseEvent) => {
		if (!this.isDraggingVideo) return;
		this.videoModalPosition = {
			top: event.clientY - this.dragStartVideo.y,
			left: event.clientX - this.dragStartVideo.x,
		};
	};

	// Detener el arrastre del modal principal
	stopDraggingMain = () => {
		this.isDraggingMain = false;
		document.removeEventListener("mousemove", this.dragModalMain);
		document.removeEventListener("mouseup", this.stopDraggingMain);
	};

	// Detener el arrastre del modal de video
	stopDraggingVideo = () => {
		this.isDraggingVideo = false;
		document.removeEventListener("mousemove", this.dragModalVideo);
		document.removeEventListener("mouseup", this.stopDraggingVideo);
	};

	public toggleVideoPlayer(index: number, url: string | null): void {
		if (this.selectedVideoIndex === index) {
			// Cerrar el reproductor si ya está abierto
			this.selectedVideoIndex = null;
			this.videoUrl = null;
		} else {
			// Abrir el reproductor para el video seleccionado
			this.selectedVideoIndex = index;
			this.videoUrl = url ? this.sanitizeUrl(this.getEmbedUrl(url)) : null;
		}
	}

	public getVideoId(url: string): string {
		const videoIdMatch = url.match(
			/(?:https?:\/\/(?:www\.)?youtube\.com\/watch\?v=|youtu\.be\/)([\w-]+)/
		);
		return videoIdMatch ? videoIdMatch[1] : "";
	}

	public getEmbedUrl(url: string): string {
		const videoId = this.getVideoId(url);
		return `https://www.youtube.com/embed/${videoId}`;
	}

	private sanitizeUrl(url: string): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}

	public closeModal(): void {
		// Cerrar el modal notificando al servicio
		this.masterService.closeModal();
		this.isModalOpen = false;
		this.selectedVideoIndex = null;
		this.videoUrl = null;
	}

	private startInterval(): void {
		this.intervalId = setInterval(() => {
			let idTutorialContext = this.localStorage.getItem("idTutorialContext");
			if (this.isModalOpen && idTutorialContext != this.idTutorialContent_aux) {
				this.idTutorialContent_aux = idTutorialContext;
				// Cerrar el reproductor si ya está abierto
				this.selectedVideoIndex = null;
				// Escuchar los cambios en el estado del modal
				this.masterService
					.getVideosForContext(idTutorialContext)
					.subscribe((res) => {
						if (res.data && res.data.length > 0) {
							// Emitir los datos y abrir el modal
							this.videoList = res.data;
						}
					});
			}
		}, 2000);
	}

	obtainLastTargetsVisited() {
		this.graphVisited_Student =
			JSON.parse(this.localStorage.getItem("graphVisited_Student")) || [];
		this.graphVisited_Editor =
			JSON.parse(this.localStorage.getItem("graphVisited_Editor")) || [];
		this.lastTarget = JSON.parse(this.localStorage.getItem("lastTarget")); //idTarget del grafo actual
		this.lastTarget_Editor = JSON.parse(
			this.localStorage.getItem("lastTarget_Editor")
		);
	}

	ngOnDestroy(): void {}

	tutorialDisponible() {
		if (this.loginService.comprobarTutorial()) {
			let canvasGrafoTutorial = JSON.parse(
				this.localStorageService.getItem(LOCALSTORAGESTRINGS.TUTORIALDATA)
			) as TutotrialDataLocal;
			this.sidebarData = canvasGrafoTutorial.sideBarData;
			this.abrirTutorial();
		} else {
			this.sidebarData = true;
		}
	}

	abrirTutorial() {
		if (!this.sidebarData) {
			setTimeout(() => {
				this.initPosition(10);
			}, 500);
		}
	}

	/*Re initialize in specify step*/
	initPosition = (stepNumber: any) => {
		this.copilot.checkInit(stepNumber);
	};

	nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

	/*Finish*/
	done = () => {
		this.copilot.removeWrapper();
		let cursoTotorial = JSON.parse(
			this.localStorageService.getItem(LOCALSTORAGESTRINGS.TUTORIALDATA)
		) as TutotrialDataLocal;
		cursoTotorial.sideBarData = true;
		this.loginService.updateDataLocalTutorial(cursoTotorial);
	};

	private _traducirOpciones(): void {
		const lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
		this.translateService.use(lang);
	}

	logout(): void {
		this.loginService.logout(true);
	}

	verGrafoAnterior() {
		let targetList: { idGrafo: any; idCurso: any; title: any }[] =
			JSON.parse(this.localStorage.getItem("graphVisited_Student")) || [];
		let previousTarget: any;
		if (targetList.length > 0) {
			let pos = 0;
			//miramos si el elemento esta en la lista y mostramos el grafo anterior (el siguiente en la lista)
			for (let index = 0; index < targetList.length; index++) {
				const element = targetList[index];
				if (this.lastTarget === element.idGrafo) {
					pos = index + 1;
					break;
				}
			}
			let havePrevious = targetList[pos] ? true : false;
			if (pos < targetList.length) {
				previousTarget = targetList[pos];
				//abrimos el grafo
				this.graphServ.getGraphInfo(previousTarget.idGrafo).subscribe((res) => {
					const viewType = res.data.viewType;
					this.graphServ.changedefaultTypeView(res.data.viewType);
					this.router
						.navigateByUrl(
							`/course/${previousTarget.idCurso}/graph/${previousTarget.idGrafo}`
						)
						.then((res) => {
							setTimeout(() => {
								localStorage.setItem("dontLoadMenu", "true");
								this.localStorage.setItem("lastTarget", previousTarget.idGrafo);
								this.utils.loadMenu = false;

								this.toolsService.changeUpdateGraph(true);
								this.obtainLastTargetsVisited();
								//window.location.reload();
								// this.sigmaCanvasService.setTriggerInitSigma(true);
							}, 100);
						});
					this.modalService.dismissAll();
				});
				this.targetsService.registercountopengraph().subscribe((res) => {});
			}
		}
	}

	verGrafoSiguiente() {
		let targetList: { idGrafo: any; idCurso: any; title: any }[] =
			JSON.parse(this.localStorage.getItem("graphVisited_Student")) || [];
		let nextTarget: any;
		if (targetList.length > 0) {
			let pos = 0;
			//miramos si el elemento esta en la lista, si esta mostramos el grafo siguiente (anterior en la lista), si no (o es el segundo) mostramos el primero de la lista
			for (let index = 0; index < targetList.length; index++) {
				const element = targetList[index];
				if (this.lastTarget === element.idGrafo) {
					pos = index - 1;
					break;
				}
			}
			if (pos >= 0) {
				nextTarget = targetList[pos];
				//abrimos el grafo
				this.graphServ.getGraphInfo(nextTarget.idGrafo).subscribe((res) => {
					const viewType = res.data.viewType;
					this.graphServ.changedefaultTypeView(res.data.viewType);
					this.router
						.navigateByUrl(
							`/course/${nextTarget.idCurso}/graph/${nextTarget.idGrafo}`
						)
						.then((res) => {
							setTimeout(() => {
								localStorage.setItem("dontLoadMenu", "true");
								this.localStorage.setItem("lastTarget", nextTarget.idGrafo);
								this.utils.loadMenu = false;
								//window.location.reload();
								this.toolsService.changeUpdateGraph(true);
								this.obtainLastTargetsVisited();
								// this.sigmaCanvasService.setTriggerInitSigma(true);
							}, 100);
						});
					this.modalService.dismissAll();
				});
				this.targetsService.registercountopengraph().subscribe((res) => {});
			}
		}
	}

	verGrafoAnteriorEditor() {
		let targetList: { idGrafo: any; idCurso: any; title: any }[] =
			JSON.parse(this.localStorage.getItem("graphVisited_Editor")) || [];
		let previousTarget: any;
		if (targetList.length > 0) {
			let pos = 0;
			//miramos si el elemento esta en la lista y mostramos el grafo anterior (el siguiente en la lista)
			for (let index = 0; index < targetList.length; index++) {
				const element = targetList[index];
				if (this.lastTarget_Editor === element.idGrafo) {
					pos = index + 1;
					break;
				}
			}
			let havePrevious = targetList[pos] ? true : false;
			if (pos < targetList.length) {
				previousTarget = targetList[pos];
				//abrimos el grafo
				this.graphServ.getGraphInfo(previousTarget.idGrafo).subscribe((res) => {
					const viewType = res.data.viewType;
					this.graphServ.changedefaultTypeView(res.data.viewType);
					this.router
						.navigateByUrl(
							`/course/${previousTarget.idCurso}/graph/${previousTarget.idGrafo}`
						)
						.then((res) => {
							setTimeout(() => {
								localStorage.setItem("dontLoadMenu", "true");
								this.localStorage.setItem(
									"lastTarget_Editor",
									previousTarget.idGrafo
								);
								this.utils.loadMenu = false;
								this.toolsService.changeUpdateGraph(true);
								this.obtainLastTargetsVisited();
								//window.location.reload();
								// this.sigmaCanvasService.setTriggerInitSigma(true);
							}, 100);
						});
					this.modalService.dismissAll();
				});
				this.targetsService.registercountopengraph().subscribe((res) => {});
			}
		}
	}

	verGrafoSiguienteEditor() {
		let targetList: { idGrafo: any; idCurso: any; title: any }[] =
			JSON.parse(this.localStorage.getItem("graphVisited_Editor")) || [];
		let nextTarget: any;
		if (targetList.length > 0) {
			let pos = 0;
			//miramos si el elemento esta en la lista, si esta mostramos el grafo siguiente (anterior en la lista), si no (o es el segundo) mostramos el primero de la lista
			for (let index = 0; index < targetList.length; index++) {
				const element = targetList[index];
				if (this.lastTarget_Editor === element.idGrafo) {
					pos = index - 1;
					break;
				}
			}
			if (pos >= 0) {
				nextTarget = targetList[pos];
				//abrimos el grafo
				this.graphServ.getGraphInfo(nextTarget.idGrafo).subscribe((res) => {
					const viewType = res.data.viewType;
					this.graphServ.changedefaultTypeView(res.data.viewType);
					this.router
						.navigateByUrl(
							`/course/${nextTarget.idCurso}/graph/${nextTarget.idGrafo}`
						)
						.then((res) => {
							setTimeout(() => {
								localStorage.setItem("dontLoadMenu", "true");
								this.localStorage.setItem(
									"lastTarget_Editor",
									nextTarget.idGrafo
								);
								this.utils.loadMenu = false;
								this.toolsService.changeUpdateGraph(true);
								this.obtainLastTargetsVisited();
								//window.location.reload();
								// this.sigmaCanvasService.setTriggerInitSigma(true);
							}, 100);
						});
					this.modalService.dismissAll();
				});
				this.targetsService.registercountopengraph().subscribe((res) => {});
			}
		}
	}

	verTutoriales() {
		let idTutorialContext = this.localStorage.getItem("idTutorialContext");
		this.masterService.openModalTutorial(idTutorialContext);
	}
}
