import { map } from 'rxjs/operators';
import { MaterialModule } from './../../../pages/material.module';
import { CommonModule } from "@angular/common";
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from "@angular/core";
import {
	NgbActiveModal,
	NgbModal,
	NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { ModalAceptarCancelarComponent } from "../modal/modal-aceptar-cancelar/modal-aceptar-cancelar.component";
import { SocketService } from "src/app/core/services/socket/socket-service.service";
import {
	GruposService,
	SOCKETMESSAGES,
} from "src/app/core/services/groups/grupos.service";
import { LoginService } from "src/app/core/services/login";
import { User } from "src/app/core/models/users/user.models";
import { DragAndDropModule } from "angular-draggable-droppable";
import { Subject, Subscription, finalize, take, takeUntil } from "rxjs";
import { ChallengesHostWaitingComponent } from "../challenges-host-waiting/challenges-host-waiting.component";
import { ModalListadoCursosComponent } from "../grupos/modal-listado-cursos/modal-listado-cursos.component";
import { GroupModel } from "src/app/core/models/groups/groups-model";
import { Utils } from "src/app/core/utils/utils";
import { SocketQuizPreviewComponent } from "../socket-quiz-preview/socket-quiz-preview.component";
import { QuizzesService } from "src/app/core/services/quizzes";
import { CourseListModel } from "../cursos/modal-cursos-listado/interface/modal-cursos-listado";
import { Clipboard } from "@angular/cdk/clipboard";
import { CompetitionsService } from "src/app/core/services/competitions/competitions.service";
import { ModalCompetitionResultsComponent } from '../modal-competition-results/modal-competition-results.component';
import { LocalStorage } from 'src/app/core/utils';
import { MastersService } from 'src/app/core/services/masters';
@Component({
	selector: "app-challenges-create-room",
	templateUrl: "./challenges-create-room.component.html",
	styleUrls: ["./challenges-create-room.component.scss"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default,
})
export class ChallengesCreateRoomComponent {
	@Input() group: GroupModel;
	@Input() onlineUsers: number;
	roomCode: string = "";
	currentIdArrayQuiz: number = 0;
	timeBeforeStart: number = 5;
	isPrivateRoom: boolean = false;
	contentSetted = false;
	selectedType = SOCKETMESSAGES.OPEN;
	nameSession: string = `partida ${this.roomCode}`;
	listQuizzes: CourseListModel[] = [];
	isLoading = false;
	idCourse: number;
	maxUsers: number = 10;
	idTarget: number;
	option: number;
	user: User;
	private subscriptions: Subscription[] = [];
	private destroy$ = new Subject();
	teams: any = [
		{
			name: "Equipo 1",
			users: [],
			usersInfo: [],
			teamEditing: false,
			show: true,
			textoBoton: 'Editar',
		},
		{
			name: "Equipo 2",
			users: [],
			usersInfo: [],
			teamEditing: false,
			show: true,
			textoBoton: 'Editar',
		},
		{
			name: "Sin Equipo",
			users: [],
			usersInfo: [],
			teamEditing: false,
			show: false,
			textoBoton: 'Editar',
		},
	];

	auxTeams: any = [];

	usersInGroupCounter: any;
	usersInGroup: any;
	usersInGroupAux: any;
	lastUserDragged: any;
	usersOfAGroup: any;
	target: any;
	isTeacher: boolean;
	notAddTeams: boolean = false;
	courseSetted: any;
	gameMode: number = 1;
	acceptDragging: boolean;
	constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private socketService: SocketService,
		private groupService: GruposService,
		public loginService: LoginService,
		public utils: Utils,
		private quizzesService: QuizzesService,
		private clipboard: Clipboard,
		private competitionsService: CompetitionsService,
		private localStorage: LocalStorage,
		private masterService: MastersService,
	) {}

	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.competitionsService.generateRandomHexCode();
		this.competitionsService.getRoomCode().subscribe((code) => {
			this.roomCode = code;
		});
		this.user = this.loginService.getUser();
		this.isTeacher = this.loginService.getProfile() === "PROFESOR" ? true : false;
		//definimos variable para tutoriales
		if(this.loginService.esEstudiante()){
			this.localStorage.setItem("idTutorialContext", 70);
			if(this.localStorage.getItem("TutorialesON") == "true"){
				this.masterService.openModalTutorial(70);
			}
		}	
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		if (this.group != null) {
			this.groupService
				.getListEstudiantes(this.group.idGroup)
				.subscribe((members) => {
					members.data.map((user) => {
						this.teams[2].users.push(user.idUser);
					});
					this.usersInGroupCounter = members.data.length;
					this.usersInGroup = members.data;
					this.usersInGroupAux = members.data;
				});
		}else{
			this.teams[2].users.push(this.user.idUser);
		}
	}

	generateSessionToPlay() {
		this.isLoading = true;

		let modal: NgbModalRef;

		//room props para guardar en la bd
		let roomProps = JSON.stringify({
			nameRoom: this.nameSession,
			roomCode: this.roomCode,
			isPrivateRoom: this.isPrivateRoom,
			hostUser: this.user.idUser,
			maxUsers: this.maxUsers,
			connectedUsers: [
				{
					firstName: this.user.firstName,
					id: this.user.idUser,
					pictureUser: this.user.pictureUser,
					surname: this.user.surname,
				},
			],
			gameIsActive: true,
			gameMode: this.gameMode,
			gameSubject: this.target.tituloAsignatura,
		});
		//Añado un equipo vacio para que el usuario pueda añadir más equipos
		let trimmedTeams = this.teams.map((team) => {
			const { usersInfo, teamEditing, ...newTeam } = team;
			return newTeam;
		});
		this.socketService.createSocketForPlayRoom(this.roomCode); //Creo el socket

		const openSocketSub = this.socketService.openSocket.subscribe((ev) => {
			//Cuando se cree el socket, procedemos a guardar la sesion en el servidor
			this.groupService
				.createGroupSession(this.group ? this.group.idGroup : 0, this.roomCode)
				.subscribe(
					(result) => {
						//Si se guarda correctamente la sesion, tenemos que abrir el modal con los cursos en modo lista
						this.socketService.idSession = result.data.idSession;

						this.competitionsService
							.createCompetitionSession(
								this.user.idUser,
								this.group ? this.group.idGroup : 0,
								this.roomCode,
								false,
								result.data.idSession,
								trimmedTeams,
								roomProps
							)
							.subscribe(
								(result) => {
									this.competitionsService.setCompetitionStarted(true);
									const modalS = this.modalService.open(
										SocketQuizPreviewComponent,
										{
											scrollable: false,
											windowClass: `${MODAL_DIALOG_TYPES.W100} h-90`,
										}
									);
									modalS.componentInstance.idGroup = this.group
										? this.group.idGroup
										: 0;
									modalS.componentInstance.onlineUsers = 1;
									modalS.componentInstance.roomCode = this.roomCode;
									modalS.componentInstance.competitionStarted = true;
									modalS.result
										.then(
											(method) => {
												if (method != "manual") {
													this.competitionResults();
												}
											},
											(err) => {}
										)
										.finally(() => {
											setTimeout(() => {
												this.competitionsService.setCompetitionStarted(false);
												this._removeSocket(this.group ? this.group.idGroup : 0);
											}, 300);
										});

									setTimeout(() => {
										const modalRef = this.modalService.open(
											ChallengesHostWaitingComponent,
											{
												scrollable: true,
												windowClass: `${MODAL_DIALOG_TYPES.W100} h-90`,
											}
										);
										modalRef.componentInstance.hex = this.roomCode;

										modalRef.componentInstance.usersOfTheGroup = this
											.usersInGroupAux
											? this.usersInGroupAux.filter(
													(user) => user.idUser != this.user.idUser
											  )
											: [];
										modalRef.componentInstance.teams = this.teams;
										modalRef.componentInstance.group = this.group;
										modalRef.componentInstance.timeBeforeStart =
											this.timeBeforeStart;
										modalRef.componentInstance.target = this.target;
										this.isLoading = false;
										this.closeModal("lobbyGenerated");

										modalRef.result.then((result: any) => {
											if(result === "manualClosed") {
												modalS.close("manual");
											}
											setTimeout(() => {
												//modalS.close();
											}, 50);
										});
									}, 100);
								},
								(err) => {
									modal.close();
								}
							);
						openSocketSub.unsubscribe();
					},
					(err) => {
						modal.close();
					}
				);
		});
		this.subscriptions.push(openSocketSub);
	}

	private _removeSocket(idGroup) {
		this.socketService.removeSocket(); //Si ha habido error al guardar la sesión, tenemos que destruir el socket y cerrar el modal
		this.groupService.deleteGroupSession(idGroup).subscribe(); //Elimino la sesión de la bd
		this.subscriptions.forEach((s) => s.unsubscribe()); //Elimino las suscripciones a eventos
	}

	dragEnd(user, event) {
		this.lastUserDragged = user;
		setTimeout(() => {
			if(this.acceptDragging == true) {
				//remove user from usersInGroup
				this.usersInGroup = this.usersInGroup.filter(
					(u) => u.idUser !== user.idUser
				);
			}
		}, 50);

	}

	onDrop(event: DragEvent, targetIdentifier: string): void {

		// Ensure event.target is an HTMLElement to access classList
		if (event.target instanceof HTMLElement) {
			// Check if event.target has the class matching targetIdentifier
			const hasMatchingClass =
				event.target.classList.contains(targetIdentifier);
			// Update condition to also check that event.target does not have the matching class
			if (
				(targetIdentifier === "team" || targetIdentifier === "group") &&
				!hasMatchingClass
			) {
				this.acceptDragging = true;
			} else {
				this.acceptDragging = false;
			}
		} else {
			// If event.target is not an HTMLElement, dragging is not accepted
			this.acceptDragging = false;
		}
	}

	userDroppedOnTeam(data) {
		//check every team to see if user is already in a team
		let userInTeam = false;
		this.teams.forEach((team) => {
			if (team.users.includes(this.lastUserDragged.idUser)) {
				userInTeam = true;
			}
		});
		//if user is already in a team, remove from team
		if (userInTeam) {
			this.teams.forEach((team) => {
				if (team.users.includes(this.lastUserDragged.idUser)) {
					team.users = team.users.filter(
						(u) => u !== this.lastUserDragged.idUser
					);
					team.usersInfo = team.usersInfo.filter(
						(u) => u.idUser !== this.lastUserDragged.idUser
					);
				}
			});
		}

		//add user to team
		let team = this.teams.find((t) => t.name === data.name);
		//check if user is already in team
		if (team.users.includes(this.lastUserDragged.idUser)) {
			//if user is already in team, remove from team
			team.users = team.users.filter((u) => u !== this.lastUserDragged.idUser);
			team.usersInfo = team.usersInfo.filter(
				(u) => u.idUser !== this.lastUserDragged.idUser
			);
		}

		team.users.push(this.lastUserDragged.idUser);
		team.usersInfo.push(this.lastUserDragged);
		this.auxTeams.push();
	}

	userDroppedOnUsersInGroup(user) {
		let lastDraggedUser = this.lastUserDragged;
		//remove lastDraggedUser from whatever team he was in
		this.teams.forEach((team) => {
			if (team.users.includes(lastDraggedUser.idUser)) {
				team.users = team.users.filter((u) => u !== lastDraggedUser.idUser);
				team.usersInfo = team.usersInfo.filter(
					(u) => u.idUser !== lastDraggedUser.idUser
				);
			}
		});
		//add user to usersInGroup
		this.usersInGroup.push(lastDraggedUser);

		//remove duplicate users to double check
		this.usersInGroup = this.usersInGroup.filter(
			(v, i, a) => a.findIndex((t) => t.idUser === v.idUser) === i
		);
	}

	selectContent() {
		const modalRef = this.modalService.open(ModalListadoCursosComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});

		modalRef.componentInstance.cameFromCompetitions = true;
		modalRef.componentInstance.targetSelected =
			this.target != null ? [this.target] : [];
		if (this.group == null) {
			modalRef.componentInstance.group = { idGroup: 0 };
		} else {
			modalRef.componentInstance.group = this.group;
		}

		modalRef.result.then((data) => {
			this.contentSetted = true;
			this.target = data.grafo;
		});
	}

	addTeam() {
		let teamCount = this.teams.filter((team) => team.show).length;
		this.teams.push({
			name: `Equipo ${teamCount + 1}`,
			users: [],
			usersInfo: [],
			show: true,
		});
	}


	autoFillTeams() {
		if (this.usersInGroup.length == 0) {
			return;
		}
		this.usersInGroup = [];
		let users = this.usersInGroupAux;
		let teamsToShow = this.teams.filter((team) => team.show);

		// Distribute users to teams one by one in a round-robin fashion
		for (let i = 0; i < users.length; i++) {
			let team = teamsToShow[i % teamsToShow.length];
			if (!team.users.includes(users[i].idUser)) {
				team.users.push(users[i].idUser);
				team.usersInfo.push(users[i]);
			}
		}

		//double check that all users are only in one team
		for (let team of this.teams) {
			for (let user of team.users) {
				for (let t of this.teams) {
					if (t.name !== team.name) {
						if (t.users.includes(user)) {
							t.users = t.users.filter((u) => u !== user);
							t.usersInfo = t.usersInfo.filter((u) => u.idUser !== user);
						}
					}
				}
			}
		}
	}

	clearAllTeamsOfUsers() {
		this.teams.forEach((team) => {
			team.users = [];
			team.usersInfo = [];
		});

		this.usersInGroup = this.usersInGroupAux;
	}

	gameModeChange(event) {
		if (event == 0) {
			this.notAddTeams = true;
			this.gameMode = 0;
		} else if (event == 1) {
			this.notAddTeams = false;
			this.gameMode = 1;
		}
	}

	switchEditTeamName(pos) {
		this.teams[pos].teamEditing = !this.teams[pos].teamEditing;
		this.teams[pos].textoBoton = this.teams[pos].teamEditing ? 'Guardar' : 'Editar';
	}

	getUserAvatar(pictureUser): string {
		return this.utils.getUserAvatar(pictureUser);
	}

	close() {
		this.socketService.setSyncChallenge(false);
		this.activeModal.close();
		localStorage.removeItem("challengeOn");
		localStorage.removeItem("challengeType");
	}

	closeModal(sendData?: any) {
		//this.destroy$.next(true);
		this.activeModal.close(sendData);
	}

	copyRoomCode() {
		const textToCopy = this.roomCode;
		this.clipboard.copy(textToCopy);
	}

	competitionResults() {
		let modalResults = this.modalService.open(
			ModalCompetitionResultsComponent,
			{
				scrollable: false,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			}
		);
		modalResults.result.then((result: boolean) => {});
	}
}
