<div>
	<ng-template class="w-100" #step10>
		<div class="copilot-custom copilop-info edit-sidebar-cop" style="position: sticky;">
			<div class="row">
				<div class="col-12 flex justify-content-center align-items-center">
					<h3 class="title-info">
						{{'TUTORIALINICIO.TUTORIAL_TEXTO_00'| translate}}
					</h3>
				</div>
				<div class="col-12 ">
					<p class="text-info-help">
						<!-- {{'TUTORIALINICIO.TUTORIAL_TEXTO_01'| translate}}-->
          </p>
				</div>
			</div>
			<div class="footer-btn">
				<div class="row  flex justify-content-center align-items-center">
					<div class="col-2">
						<button class="btn btn-block btn-themecolor btn-sm button-ok" (click)="done()" type="button">
							{{'TUTORIALINICIO.BOTONES.ACEPTAR'| translate}}
						</button>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</div>

<div class="gif_container" hidden>
	<div class="dedo">
		<div class="row">
			<div class="col-6">
				<img class="img_dedo_nodo" [src]="urlTutorial + 'dedodellado.gif'" alt="">
			</div>
			<div class="col-6">
				<img class="img_dedo_quiz" [src]="urlTutorial + 'dedodellado.gif'" alt="">
			</div>
			<div class="col-12">
				<img [src]="urlTutorial + 'dedodesdeabajo.gif'" alt="">
			</div>
		</div>
	</div>
</div>

<aside class="left-sidebar show-avatar">
	<!-- <app-center-icon></app-center-icon> -->
	<app-user-account></app-user-account>
	<!-- <nb-icon ngbTooltip="{{ 'SIDEBAR.LOGOUT' | translate }}" (click)="logout()" icon="power-outline" class="mr-2 icon-sign-out"></nb-icon> -->

	<!-- <app-sidebarmenu></app-sidebarmenu> -->
</aside>

<div class="opt-float show-avatar" *ngIf="loginService.esEstudiante() || loginService.esProfesor() || loginService.esAutor()">
	<!-- BOTON PARA VER LOS TUTORIALES -->
	<button nbButton class="btn-tutoriales" nbTooltip="Ver Tutoriales" nbTooltipPlacement="right" (click)="verTutoriales()">
		<!-- <nb-icon icon="monitor-outline"></nb-icon> -->
		<dotlottie-player src="https://lottie.host/466c0d63-bcd0-4911-9ceb-1822ec78ebac/hpaPfd47al.lottie" background="transparent" speed="1" loop autoplay></dotlottie-player>
	</button>

	<div class="d-flex flex-column align-items-center" *ngIf="loginService.esEstudiante() || loginService.esProfesor()">
		<div class="d-flex align-items-center">
			<nb-icon ngbTooltip="{{ 'PADS.PREVIOUSTARGET' | translate }}" placement="right" icon="arrow-back-outline"
				style="height: 30px; width: 30px; cursor: pointer;" (click)="verGrafoAnterior()"
				*ngIf="this.graphVisited_Student.length > 1 && this.graphVisited_Student[this.graphVisited_Student.length - 1].idGrafo != this.lastTarget"></nb-icon>
			<nb-icon ngbTooltip="{{ 'PADS.NEXTTARGET' | translate }}" placement="right" icon="arrow-forward-outline"
				style="height: 30px; width: 30px; cursor: pointer; margin-left: 5px;" (click)="verGrafoSiguiente()"
				*ngIf="this.graphVisited_Student.length > 1 && this.graphVisited_Student[0].idGrafo != this.lastTarget"></nb-icon>
		</div>
	</div>

	<div class="d-flex flex-column align-items-center" *ngIf="loginService.esAutor()">
		<div class="d-flex align-items-center">
			<nb-icon ngbTooltip="{{ 'PADS.PREVIOUSTARGET' | translate }}" placement="right" icon="arrow-back-outline"
				style="height: 30px; width: 30px; cursor: pointer;" (click)="verGrafoAnteriorEditor()"
				*ngIf="this.graphVisited_Editor.length > 1 && this.graphVisited_Editor[this.graphVisited_Editor.length - 1].idGrafo != this.lastTarget_Editor"></nb-icon>
			<nb-icon ngbTooltip="{{ 'PADS.NEXTTARGET' | translate }}" placement="right" icon="arrow-forward-outline"
				style="height: 30px; width: 30px; cursor: pointer; margin-left: 5px;" (click)="verGrafoSiguienteEditor()"
				*ngIf="this.graphVisited_Editor.length > 1 && this.graphVisited_Editor[0].idGrafo != this.lastTarget_Editor"></nb-icon>
		</div>
	</div>
</div>

<!-- MODAL PARA MOSTRAR EL LISTADO DE VIDEOS -->
<nb-card *ngIf="isModalOpen && (loginService.esEstudiante() || loginService.esProfesor() || loginService.esAutor())"
	class="container-tutorial small-video-tutorial mb-0" [ngStyle]="{ top: modalPosition.top + 'px', left: modalPosition.left + 'px' }"
	(mousedown)="startDragging($event, 'main')">
	<nb-card-header class="border-bottom-0 p-1 background_themecolor">
		<div class="d-flex justify-content-center align-content-center position-relative">
			<img class="icon-drag_indicator" src="../../../../assets/images/icons/drag_indicator.svg">
		</div>
	</nb-card-header>

	<div class="closeModal-tutorial">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal()"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>

	<nb-card-body class="p-0">
		<nb-card class="mb-0 no-edge-card background_themecolor">
			<nb-card-body class="px-3 py-0">
				<div class="d-flex justify-content-center flex-wrap gap-3">
					<!-- Lista de videos -->
					<div class="card card-video" *ngFor="let video of videoList; let i = index">
						<div class="video-overlay" [class.overlay-active]="isVideoModalOpen && selectedVideoIndex === i">
							<img (click)="openVideoModal(i, video.urlVideo)" class="card-img-video pointer"
								[src]="'https://img.youtube.com/vi/' + getVideoId(video.urlVideo) + '/hqdefault.jpg'"
								alt="{{ video.title }}">
							<div (click)="openVideoModal(i, video.urlVideo)" class="d-flex detail-tutorial align-items-center justify-content-between p-2 pointer">
								<p class="title-video" nbTooltip="{{ video.title }}" nbTooltipPlacement="top">{{ video.title }}</p>
								<img class="icon-youtube" src="../../../../assets/images/icons/youtube.png" nbTooltip="Abrir Video" nbTooltipPlacement="right">
							</div>
							<!-- Icono de reproducción superpuesto -->
							<!-- <nb-icon *ngIf="isVideoModalOpen && selectedVideoIndex === i" class="overlay-icon" icon="play-circle-outline"></nb-icon> -->
							<img *ngIf="isVideoModalOpen && selectedVideoIndex === i" class="overlay-icon" src="../../../../assets/images/icons/sonido-de-onda.png">
						</div>
					</div>
				</div>
			</nb-card-body>
		</nb-card>
	</nb-card-body>

	<nb-card-footer class="p-2 background_themecolor border-top-0"></nb-card-footer>
</nb-card>

<!-- MODAL PARA MOSTRAR EL VIDEO -->
<nb-card *ngIf="isVideoModalOpen && (loginService.esEstudiante() || loginService.esProfesor() || loginService.esAutor())"
	class="container-tutorial medium-video-tutorial mb-0" [ngStyle]="{ top: videoModalPosition.top + 'px', left: videoModalPosition.left + 'px' }"
	(mousedown)="startDragging($event, 'video')">
	<nb-card-header class="border-bottom-0 p-1 background_themecolor">
		<div class="d-flex justify-content-center align-content-center position-relative">
			<img class="icon-drag_indicator" src="../../../../assets/images/icons/drag_indicator.svg">
		</div>
	</nb-card-header>

	<div class="closeModal-tutorial">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }" (click)="closeVideoModal()"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>

	<nb-card-body class="p-0">
		<nb-card class="mb-0 no-edge-card background_themecolor">
			<nb-card-body class="px-3 py-0">
				<div class="iframe-container">
					<iframe [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
				</div>
			</nb-card-body>
		</nb-card>
	</nb-card-body>

	<nb-card-footer class="p-2 background_themecolor border-top-0"></nb-card-footer>
</nb-card>
