import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_KEY_CHAT_GTP, PROMPT_SHAPE_GENERATIONS } from 'src/app/core/models/masters/masters.enum';

@Injectable({
  providedIn: 'root'
})
export class OpenAIService {
  private readonly apiUrl = 'https://api.openai.com/v1';
  private readonly apiKey = API_KEY_CHAT_GTP; // Reemplaza con tu clave de OpenAI
	httpClient: HttpClient;
	headers = new HttpHeaders({
		"Content-Type": "application/json",
		Authorization: `Bearer ${this.apiKey}`,
	});

  constructor(public http: HttpClient,handler: HttpBackend,) {
		this.httpClient = new HttpClient(handler);
	}


	private _isGeneratingImages: boolean = false;

	get isGeneratingImages(): boolean {
		return this._isGeneratingImages;
	}

	set isGeneratingImages(value: boolean) {
		this._isGeneratingImages = value;
	}

  // Subir un archivo
  uploadFile(file: File): Observable<any> {
		const headers = new HttpHeaders({
			Authorization: `Bearer ${this.apiKey}`,
		});
    const formData = new FormData();
    formData.append('file', file);
    formData.append('purpose', 'assistants');

    return this.httpClient.post(`${this.apiUrl}/files`, formData, {
      headers
    });
  }


  // Crear un thread
  createThread(): Observable<any> {
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.apiKey}`,
		});
    return this.httpClient.post(`${this.apiUrl}/beta/threads`, {}, {
      headers
    });
  }

  // Crear o buscar un asistente
  getAssistant(): Observable<any> {

    return this.httpClient.get(`${this.apiUrl}/beta/assistants`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.apiKey}`
      })
    });
  }

  createAssistant(): Observable<any> {
    const payload = {
      model: 'gpt-4o',
      description: 'You are a PDF retrieval assistant.',
      instructions: "You are a helpful assistant designed to output only JSON. Find information from the text and files provided.",
      tools: [{ type: 'file_search' }],
      name: 'My Assistant Name'
    };

    return this.httpClient.post(`${this.apiUrl}/beta/assistants`, payload, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.apiKey}`,
        'Content-Type': 'application/json'
      })
    });
  }

  // Crear mensaje con el prompt
  createMessage(threadId: string, fileId: string, prompt: string): Observable<any> {
    const payload = {
      thread_id: threadId,
      role: 'user',
      content: prompt,
      attachments: [{
        file_id: fileId,
        tools: [{ type: 'file_search' }]
      }]
    };

    return this.httpClient.post(`${this.apiUrl}/beta/threads/messages`, payload, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.apiKey}`,
        'Content-Type': 'application/json'
      })
    });
  }

  // Correr el thread
  runThread(threadId: string, assistantId: string): Observable<any> {
    const payload = {
      thread_id: threadId,
      assistant_id: assistantId,
      timeout: 300
    };

    return this.httpClient.post(`${this.apiUrl}/beta/threads/runs/create_and_poll`, payload, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.apiKey}`,
        'Content-Type': 'application/json'
      })
    });
  }

  // Obtener mensajes del thread
  listMessages(threadId: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/beta/threads/messages?thread_id=${threadId}`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.apiKey}`
      })
    });
  }

  // Eliminar archivo
  deleteFile(fileId: string): Observable<any> {
    return this.httpClient.delete(`${this.apiUrl}/files/${fileId}`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.apiKey}`
      })
    });
  }
}
