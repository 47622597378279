import { CommonModule } from '@angular/common';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbButtonModule, NbCardModule, NbFormFieldModule, NbIconModule, NbProgressBarModule, NbRadioModule, NbSelectModule, NbSpinnerModule, NbTooltipModule, NbWindowRef, NbWindowService } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { API_KEY_CHAT_GTP } from 'src/app/core/models/masters/masters.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { LocalStorage } from 'src/app/core/utils';
import { ComponentsModule } from '../components.module';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { MatTableDataSource as MatTableDataSource, MatTableModule as MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { SalwizarContenidoComponent } from '../salwizar-contenido/salwizar-contenido.component';
import { PROMPT_SHAPE_GENERATIONS } from 'src/app/core/models/masters/masters.enum';
import { ModalGeneratedIdeasComponent } from '../salwizar-contenido/modal-generated-ideas/modal-generated-ideas.component';
export interface ShapeElement {
	description: string;
	shape: string; // URL or path to the image of the shape
	number?: number;
}

const ELEMENT_DATA: ShapeElement[] = [
	{ description: 'Jerarquía en estrella', shape: 'assets/shapes/shape1.png', number: 2 },
	{ description: 'Jerarquía en árbol', shape: 'assets/shapes/shape2.png', number: 3 },
	{ description: 'Conclusion', shape: 'assets/shapes/shape1.png', number: 3 },
	{ description: 'Clasificación hacia abajo', shape: 'assets/shapes/shape2.png', number: 3 },
	{ description: 'Clasificación hacia la derecha', shape: 'assets/shapes/shape1.png' },
	{ description: 'Ciclo', shape: 'assets/shapes/shape2.png', number: 7 },
	{ description: 'Secuencia física lineal', shape: 'assets/shapes/shape1.png', number: 9 },
	{ description: 'Secuencia temporal corta horizontal', shape: 'assets/shapes/shape2.png', number: 9 },
	{ description: 'Secuencia temporal ascendente', shape: 'assets/shapes/shape1.png', number: 9 },
	// Add more shapes as needed
];
@Component({
	selector: 'app-manage-graph-shape',
	standalone: true,
	imports: [
		CommonModule,
		NbFormFieldModule,
		NbCardModule,
		NbIconModule,
		NbTooltipModule,
		NbSelectModule,
		NbButtonModule,
		FormsModule,
		ReactiveFormsModule,
		NbSpinnerModule,
		TranslateModule,
		MatTableModule,
		MatButtonModule,
		NbProgressBarModule,
		NbRadioModule,
	],
	templateUrl: "./manage-graph-shape.component.html",
	styleUrls: ["./manage-graph-shape.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default,
})


export class ManageGraphShapeComponent {
	value: number = 0; // Initialize the value property
	ProposedShapes: any;
	shapeSelected: number = 0;
	selectedShape: number;
	salwizarIsOpen: boolean = false;
	shapeGenerationStarted: boolean = false;
	invalidShapes: boolean = false;
	setValue(newValue) {
		this.value = Math.min(Math.max(newValue, 0), 100)
	}

	get status() {
		if (this.value <= 25) {
			return 'danger';
		} else if (this.value <= 50) {
			return 'warning';
		} else if (this.value <= 75) {
			return 'info';
		} else {
			return 'success';
		}
	}

	private httpClient: HttpClient;

	displayedColumns: string[] = ['description', 'shape'];
	dataSource = new MatTableDataSource(ELEMENT_DATA);
	selectedOption: 'manual' | 'ai' | null = null;
	loading: boolean = false;

	constructor(private formBuild: FormBuilder,
		private activeModal: NgbActiveModal,
		public http: HttpClient,
		private modalService: NgbModal,
		private localStorage: LocalStorage,
		private toaster: ToasterService,
		handler: HttpBackend) { this.httpClient = new HttpClient(handler); }

	selectOption(option: 'manual' | 'ai') {
		this.loading = true;
		this.selectedOption = option;
		if (option === 'ai') {
			const modalRef = this.modalService.open(SalwizarContenidoComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W50,
			});

			modalRef.result.then(
				() => this.makeShapePromptPetition()
			);
		}
	}

	isNumber(val): boolean { return typeof val === 'number'; }
	isString(val): boolean { return typeof val === 'string'; }
	isArray(val): boolean { return Array.isArray(val); }

	setSelectedShape(shape: number, manual?: boolean) {
		// Set the selected shape on local storage
		this.shapeSelected = shape;
		this.selectedShape = shape;


		if(manual) {
			this.localStorage.setItem("ShapedGeneratedByIa", "false");
			this.localStorage.setItem("SelectedShape", shape);
		}else{
			this.localStorage.setItem("ShapedGeneratedByIa", "true");
			this.localStorage.setItem("SelectedShape", shape);
		}
	}

	openSalwizarContenido() {
		this.salwizarIsOpen = true;

		const modalRef = this.modalService.open(SalwizarContenidoComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W50,
		});

		modalRef.result.then(
			() => {
				this.shapeGenerationStarted = true;
				const iaGeneratedContent = this.localStorage.getItem("IAGeneratedIdeas");
				let arrayShapes = [];

				if (iaGeneratedContent) {
					try {
						arrayShapes = JSON.parse(iaGeneratedContent);
						this.ProposedShapes = arrayShapes;
					} catch (error) {

						console.error("Invalid JSON:", error);
						// Handle invalid JSON
					}
				} else {
					console.error("IAGeneratedIdeas is null or undefined");
					// Handle null or undefined value
				}

				// this.makeShapePromptPetition();
				this.salwizarIsOpen = false;
			}
		);

	}


	async makeShapePromptPetition() {
		// Make the request to the API
		this.loading = true;
		this.value = 0;
		const interval = setInterval(() => {
			if (this.value < 95) {
				this.value += 5;
			}
		}, 1000);
		const apiKey = API_KEY_CHAT_GTP;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${apiKey}`,
		});

		let ideas = this.localStorage.getItem("IAGeneratedIdeas");


		const prompt = PROMPT_SHAPE_GENERATIONS + ideas;
		const apiEndpoint = "https://api.openai.com/v1/chat/completions";
		const body = {
			model: "gpt-4o",
			messages: [
				{
					role: "system",
					content: prompt.replace(/(\r\n|\n|\r|\t)/gm, " "),
				},
			],
			max_tokens: 4096, // Cantidad máxima de tokens en la respuesta
			temperature: 0.7,
		};
		try {
			const respuesta = await this.httpClient
				.post<any>(apiEndpoint, body, { headers })
				.toPromise();
			if (respuesta) {
				clearInterval(interval);
				this.value = 100;
				this.loading = false;
				// Expresión regular para extraer el JSON
        const jsonMatch = respuesta.choices[0].message.content.match(/\[\s*{[\s\S]*}\s*\]/);
				if (jsonMatch) {
					try {
						const jsonArray = JSON.parse(jsonMatch[0]);

						this.ProposedShapes = jsonArray;
						// Guardar el JSON en el LocalStorage
						this.localStorage.setItem("ShapeProposals", JSON.stringify(jsonArray));

						console.log(jsonArray);
					} catch (error) {
						console.error("Error al parsear el JSON:", error);
					}
				} else {
					console.log("No se encontró un bloque JSON en el string.");
				}

			}
		} catch (error) {
			clearInterval(interval);
			this.value = 0;
			this.loading = false;
			console.error("Error al hacer la consulta a ChatGPT:", error.message);
		}


	}

	confirm() {
		this.activeModal.close('');
	}

	cancel(sendData?: any): void {
		this.activeModal.close(sendData);
	}

	closeModal(sendData?: any): void {
		this.activeModal.close(sendData);
	}

	openDesignIdeasModal() {

		this.closeModal();

		const modalRef = this.modalService.open(ModalGeneratedIdeasComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W50,
		});

		modalRef.result.then(
			() => {
				//do something after the modal is closed
			}
		);
	}
}
