import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideBarComponent } from './side-bar/side-bar.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BaseLayoutComponent } from './base-layout/base-layout.component';
import { SettingsLayoutComponent } from './settings-layout/settings-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { NgxCopilotModule } from 'ngx-copilot';
import { UserAccountComponent } from './side-bar/user-account/user-account.component';
import { SidebarmenuComponent } from './side-bar/sidebarmenu/sidebarmenu.component';
import { NbLayoutModule, NbCardModule, NbIconModule, NbTooltipModule, NbListModule, NbPopoverModule, NbMenuModule, NbContextMenuModule, NbTabsetModule, NbToggleModule } from '@nebular/theme';


@NgModule({
    declarations: [
        SideBarComponent,
        BaseLayoutComponent,
        SettingsLayoutComponent,
        AuthLayoutComponent,
        UserAccountComponent,
        SidebarmenuComponent,
				HomeLayoutComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgxCopilotModule,
				NbLayoutModule,
				NbCardModule,
				NbTabsetModule,
				NbIconModule,
				NbTooltipModule,
				NbListModule,
				NbToggleModule,
				NbPopoverModule,
				NbMenuModule.forRoot(),
				NbContextMenuModule
    ],
    exports: [
        SideBarComponent,
				HomeLayoutComponent
    ]
})
export class LayoutModule { }
