import { CommonModule } from "@angular/common";
import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
	OnInit,
	Input,
	HostListener
} from "@angular/core";
import {
	NgbActiveModal,
	NgbModal,
	NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { ChallengesCreateRoomComponent } from "../challenges-create-room/challenges-create-room.component";
import {
	GruposService,
	SOCKETMESSAGES,
} from "src/app/core/services/groups/grupos.service";
import { Subject, Subscription, take, takeUntil } from "rxjs";
import { SocketService } from "src/app/core/services/socket/socket-service.service";
import { LoginService } from "src/app/core/services/login";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { TranslateService } from "@ngx-translate/core";
import { SocketQuizPreviewComponent } from "../socket-quiz-preview/socket-quiz-preview.component";
import { GroupModel } from "src/app/core/models/groups/groups-model";
import { ModalAceptarCancelarComponent } from "../modal/modal-aceptar-cancelar/modal-aceptar-cancelar.component";
import { ChallengesHostWaitingComponent } from "../challenges-host-waiting/challenges-host-waiting.component";
import { title } from "process";
import { ModalInputRoomCodeComponent } from "../modal-input-room-code/modal-input-room-code.component";
import { User } from "src/app/core/models/users/user.models";
import { GetDataService } from "src/app/core/services/get-data/get-data.service";
import { MateriaModel } from "src/app/core/models/masters";
import { CompetitionsService } from "src/app/core/services/competitions/competitions.service";
import { ModalCompetitionResultsComponent } from "../modal-competition-results/modal-competition-results.component";
import { isFormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { LocalStorage } from "src/app/core/utils";
import { MastersService } from "src/app/core/services/masters";

@Component({
	selector: "app-challenges-lobby",
	templateUrl: "./challenges-lobby.component.html",
	styleUrls: ["./challenges-lobby.component.scss"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default,
})
export class ChallengesLobbyComponent {
	@Input() group: GroupModel;
	isLoading: boolean = false;
	showActInst = false;
	private destroy$ = new Subject();
	activeGames = [];
	displayedColumns: string[] = ["name", "subject", "mode", "join"];
	dataSource: MatTableDataSource<unknown>;
	hexCode = "";
	hexCodeComplete: boolean = false;
	subjectsList: MateriaModel[] = this.getDataService.appSubjects;
	private subscriptions: Subscription[] = [];
	actualHexToPlay: any;
	usersInGroupCounter: any;
	usersInGroup: any;
	usersInGroupAux: any;
	user: User;
	gameIsAlreadyStarted: boolean = false;
	intervalId: NodeJS.Timeout;
	isMobile: boolean = false;
	showElement: boolean = false;
	constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private groupService: GruposService,
		private socketService: SocketService,
		public loginService: LoginService,
		private competitionsService: CompetitionsService,
		public getDataService: GetDataService,
		public translateService: TranslateService,
		private toaster: ToasterService,
		private localStorage: LocalStorage,
		private masterService: MastersService,
	) {
		//this.inactivityService.startInactivityTimer();
	}

	ngOnInit(): void {
		this.user = this.loginService.getUser();
		this.competitionsService.getCompetitionStarted().subscribe((res) => {
			this.gameIsAlreadyStarted = res;
		});
		this.getActiveGames();
		this.intervalId = setInterval(() => {
			this.getActiveGames(true);
		}, 3000); // Fetches every 5 seconds

		if (this.group != null) {
			this.groupService
				.getListEstudiantes(this.group.idGroup)
				.subscribe((members) => {
					this.usersInGroupCounter = members.data.length;
					this.usersInGroup = members.data;
					this.usersInGroupAux = members.data;
				});
		}

		let modal: NgbModalRef;
		let modal2: NgbModalRef;
		this.socketService.msgFromServer
			.pipe(takeUntil(this.destroy$))
			.subscribe((data) => {
				if (this.gameIsAlreadyStarted) {
					return;
				}
				//Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
				const dataArray = data.split(":");
				const type = dataArray[0];
				const onlineUsers: number = parseInt(dataArray[1]);
				switch (type) {
					case SOCKETMESSAGES.USERSCOUNTER:
						if (this.loginService.esEstudiante()) {
							//Si es estudiante, debo abrir el modal de espera para que se vayan abriendo los quizzes según decida el profesor
							this.groupService
								.getIfSessionExists(
									this.group ? this.group.idGroup : 0,
									this.actualHexToPlay
								)
								.pipe(takeUntil(this.destroy$), take(1))
								.subscribe((result) => {
									if (result.data.idSession != 0) {
										return;
									}
									this.showActInst = result.data.existSesion;
									if (
										this.showActInst &&
										onlineUsers > 1 &&
										!this.gameIsAlreadyStarted
									) {
										this.competitionsService.setCompetitionStarted(true);
										this.competitionsService.setRoomCode(this.actualHexToPlay);
										modal = this.modalService.open(SocketQuizPreviewComponent, {
											scrollable: false,
											windowClass: `${MODAL_DIALOG_TYPES.W100} h-90`,
										});
										modal.componentInstance.idGroup = this.group
											? this.group.idGroup
											: 0;
										modal.componentInstance.onlineUsers = onlineUsers;
										modal.componentInstance.roomCode = this.actualHexToPlay;
										modal.componentInstance.competitionStarted = true;

										modal.result
											.then(
												(method) => {
													if (method != "manual") {
														this.competitionResults();
													}
												},
												(err) => {}
											)
											.finally(() => {
												//Si se cierra el modal, procedemos a eliminar la sesión y el socket

												setTimeout(() => {
													this.competitionsService.setCompetitionStarted(false);
													this._removeSocket(
														this.group ? this.group.idGroup : 0
													);
												}, 300);
											});

										modal2 = this.modalService.open(
											ChallengesHostWaitingComponent,
											{
												scrollable: false,
												windowClass: `${MODAL_DIALOG_TYPES.W100} h-90`,
											}
										);
										modal2.componentInstance.hex = this.actualHexToPlay;
										modal2.componentInstance.usersOfTheGroup =
											this.usersInGroupAux;
										modal2.componentInstance.group = this.group;
										modal2.componentInstance.cameFromLobby = true;
										this.closeModal("lobbyGenerated");
										modal2.result.then((result: any) => {
											if (result === "manualClosed") {
												modal.close("manual");
											}
											this.competitionsService
												.getCompetitionSession(this.actualHexToPlay)
												.subscribe((data) => {
													let {
														idCompetitionSession,
														creationDate,
														idGroup,
														idSession,
														idUser,
														nameSession,
														teams,
														roomProps,
														teacher,
													} = data.data;
													let modifyRoomProps = JSON.parse(roomProps);
													let userInAnyTeam = teams.some((team) =>
														team.users.includes(this.user.idUser)
													);

													if (!userInAnyTeam) {
														teams.forEach((team) => {
															if (team.name == "Sin Equipo") {
																team.users.push(this.user.idUser);
															}
														});
													}

													//if user is in modifyRoomProps.connectedUsers, remove it
													let userIndex =
														modifyRoomProps.connectedUsers.findIndex(
															(user) => user.id === this.user.idUser
														);
													if (userIndex > -1) {
														modifyRoomProps.connectedUsers.splice(userIndex, 1);
													}

													roomProps = JSON.stringify(modifyRoomProps);

													this.competitionsService
														.updateCompetitionSession(
															idCompetitionSession,
															creationDate,
															idGroup,
															idSession,
															idUser,
															teacher,
															nameSession,
															teams,
															roomProps
														)
														.subscribe((result) => {
															// this.competitionsService.setCompetitionStarted(
															// 	false
															// );
															//modal.close();
														});
												});

											//this._removeSocket(this.group ? this.group.idGroup : 0);
											//this.destroy$.next(true);
										});
									}
								});
						}
						break;
				}
			});
		//this.subscriptions.push(msgSubs);

		this.isMobile = window.innerWidth < 768;
		//definimos variable para tutoriales
		if(this.loginService.esEstudiante()){
			this.localStorage.setItem("idTutorialContext", 69);
			if(this.localStorage.getItem("TutorialesON") == "true"){
				this.masterService.openModalTutorial(69);
			}
		}		
	}

	ngDoCheck(): void {
		//Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
		//Add 'implements DoCheck' to the class.
		this.hexCodeComplete = this.hexCode.length === 6;
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
	}

	refreshActiveGames() {
		//avoiding multiple clicks
		this.isLoading = true;
		this.activeGames = [];
		this.getActiveGames();
	}

	getActiveGames(cameFromInterval?: boolean) {
		if (cameFromInterval) {
			this.competitionsService.getCompetitionSessionList().subscribe((res) => {
				this.isLoading = false;
				res.data.forEach((element) => {
					let idGroup = element.idGroup;
					let roomProps = JSON.parse(element.roomProps);
					if (
						roomProps.isPrivateRoom == false &&
						roomProps.gameIsActive == true &&
						idGroup == (this.group ? this.group.idGroup : 0)
					) {
						//clean activeGames array
						this.activeGames = this.activeGames.filter(
							(game) => game.hex !== roomProps.roomCode
						);
						this.activeGames.push({
							nameSession: roomProps.nameRoom,
							hex: roomProps.roomCode,
							onlineUsers: roomProps.connectedUsers.length,
							maxUsers: roomProps.maxUsers,
							gameSubject: roomProps.gameSubject,
							gameMode: roomProps.gameMode,
						});
						this.dataSource = new MatTableDataSource(this.activeGames.reverse());
					}
				});
			});
		} else {
			this.activeGames = [];
			this.competitionsService.getCompetitionSessionList().subscribe((res) => {
				this.isLoading = false;
				res.data.forEach((element) => {
					let idGroup = element.idGroup;
					let roomProps = JSON.parse(element.roomProps);
					if (
						roomProps.isPrivateRoom == false &&
						roomProps.gameIsActive == true &&
						idGroup == (this.group ? this.group.idGroup : 0)
					) {
						this.activeGames.push({
							nameSession: roomProps.nameRoom,
							hex: roomProps.roomCode,
							onlineUsers: roomProps.connectedUsers.length,
							maxUsers: roomProps.maxUsers,
							gameSubject: roomProps.gameSubject,
							gameMode: roomProps.gameMode,
						});

						this.dataSource = new MatTableDataSource(
							this.activeGames.reverse()
						);
					}
				});
			});
		}
	}

	joinGame(hex) {	
		this.actualHexToPlay = hex;
		this.competitionsService.setRoomCode(hex);
		const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
		let modalMessage = `${this.translateService.instant(
			"COMPETITION.YOUWILLJOINGAME"
		)}`;
		modalRef.componentInstance.mensaje = modalMessage;

		modalRef.result.then((result: boolean) => {
			if (result) {
				//definimos variable para tutoriales
				if(this.loginService.esEstudiante()){
					this.localStorage.setItem("idTutorialContext", 72);
					if(this.localStorage.getItem("TutorialesON") == "true"){
						this.masterService.openModalTutorial(72);
					}
				}
				this.competitionsService
					.getCompetitionSession(hex)
					.subscribe((data) => {
						let {
							idCompetitionSession,
							creationDate,
							idGroup,
							idSession,
							idUser,
							nameSession,
							teams,
							roomProps,
							teacher,
						} = data.data;
						let modifyRoomProps = JSON.parse(roomProps);

						//push user to connected user only if it is not already in the list
						let userAlreadyInList = modifyRoomProps.connectedUsers.find(
							(user) => user.id === this.user.idUser
						);

						//if gameMode is 0 and connectedUsers.length is > 1, then user can't join
						if (modifyRoomProps.gameMode == 0 && modifyRoomProps.connectedUsers.length >= 2) {
							this.toaster.error("COMPETITION.CANTJOIN");
							return;
						}


						if (!userAlreadyInList) {
							modifyRoomProps.connectedUsers.push({
								firstName: this.user.firstName,
								id: this.user.idUser,
								pictureUser: this.user.pictureUser,
								surname: this.user.surname,
							});
						}

						roomProps = JSON.stringify(modifyRoomProps);

						this.competitionsService
							.updateCompetitionSession(
								idCompetitionSession,
								creationDate,
								idGroup,
								idSession,
								idUser,
								teacher,
								nameSession,
								teams,
								roomProps
							)
							.subscribe((result) => {
								this.socketService.createSocketForPlayRoom(hex); //Creo el socket
								const openSocketSub = this.socketService.openSocket
									.pipe(take(1))
									.subscribe((ev) => {
										//Cuando se cree el socket, procedemos a guardar la sesion en el servidor

										this.groupService
											.getIfSessionExists(
												this.group ? this.group.idGroup : 0,
												hex
											)
											.subscribe((res) => {
												if (res.data) {
													this.groupService
														.deleteGroupSession(
															this.group ? this.group.idGroup : 0
														)
														.subscribe((res) => {
															this.groupService
																.createGroupSession(
																	this.group ? this.group.idGroup : 0,
																	hex
																)
																.subscribe(
																	(result) => {
																		//Si se guarda correctamente la sesion, tenemos que abrir el modal con los cursos en modo lista
																		this.socketService.idSession =
																			result.data.idSession;
																		openSocketSub.unsubscribe();
																	},
																	(err) => {
																		//modal.close()
																	}
																);
														});
												} else {
													this.groupService
														.createGroupSession(
															this.group ? this.group.idGroup : 0,
															hex
														)
														.subscribe(
															(result) => {
																//Si se guarda correctamente la sesion, tenemos que abrir el modal con los cursos en modo lista
																this.socketService.idSession =
																	result.data.idSession;
																openSocketSub.unsubscribe();
															},
															(err) => {
																//modal.close()
															}
														);
												}
											});
									});
								this.subscriptions.push(openSocketSub);
							});
					});
			}
		});
	}
	closeModal(sendData?: any) {
		this.destroy$.next(true);
		this.activeModal.close(sendData);
	}

	createRoom() {
		const modalRef = this.modalService.open(ChallengesCreateRoomComponent, {
			scrollable: true,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
		modalRef.componentInstance.group = this.group;

		modalRef.result.then((result) => {
			if (result) {
			}
		});

		this.closeModal();
	}

	private _removeSocket(idGroup) {
		this.socketService.removeSocket(); //Si ha habido error al guardar la sesión, tenemos que destruir el socket y cerrar el modal
		this.groupService.deleteGroupSession(idGroup).subscribe(); //Elimino la sesión de la bd
		this.subscriptions.forEach((s) => s.unsubscribe()); //Elimino las suscripciones a eventos
	}

	openJoinPrivateRoom() {
		const modalRef = this.modalService.open(ModalInputRoomCodeComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});

		modalRef.componentInstance.group = this.group;

		modalRef.result.then((result: boolean) => {
			if (result) {
			}
		});
	}

	filterGamesListBySubject(subject) {
		this.isLoading = true;
		if (subject == 0) {
			this.activeGames = [];
			this.getActiveGames();
		} else {
			this.competitionsService.getCompetitionSessionList().subscribe((res) => {
				this.isLoading = false;
				this.activeGames = [];
				res.data.forEach((element) => {
					setTimeout(() => {
						let roomProps = JSON.parse(element.roomProps);
						if (
							roomProps.isPrivateRoom == false &&
							roomProps.gameIsActive == true &&
							String(roomProps.gameSubject) === String(subject)
						) {
							this.activeGames.push({
								nameSession: roomProps.nameRoom,
								hex: roomProps.roomCode,
								onlineUsers: roomProps.connectedUsers.length,
								maxUsers: roomProps.maxUsers,
								gameSubject: roomProps.gameSubject,
							});
						}
					}, 500);
				});
			});
		}
	}

	filterGamesListByGameMode(gameMode) {
		this.isLoading = true;
		if (gameMode == "all") {
			this.activeGames = [];
			this.getActiveGames();
		} else {
			this.competitionsService.getCompetitionSessionList().subscribe((res) => {
				this.isLoading = false;
				res.data.forEach((element) => {
					setTimeout(() => {
						this.activeGames = [];
						let roomProps = JSON.parse(element.roomProps);
						if (
							roomProps.isPrivateRoom == false &&
							roomProps.gameIsActive == true &&
							Number(roomProps.gameMode) == Number(gameMode)
						) {
							this.activeGames.push({
								nameSession: roomProps.nameRoom,
								hex: roomProps.roomCode,
								onlineUsers: roomProps.connectedUsers.length,
								maxUsers: roomProps.maxUsers,
								gameSubject: roomProps.gameSubject,
							});
						}
					}, 500);
				});
			});
		}
	}

	competitionResults() {
		let modalResults = this.modalService.open(
			ModalCompetitionResultsComponent,
			{
				scrollable: false,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			}
		);
		modalResults.result.then((result: boolean) => {});
	}

	@HostListener("window:resize", ["$event"])
	onResize(event: any): void {
		if (window.innerWidth <= 768) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	}

	toggleFilters() {
		this.showElement = !this.showElement;
	}

	pasteCode() {
		navigator.clipboard.readText().then((text) => {
			this.hexCode = text;
		});
	}
}
