<div class="pad-left-container" [ngClass]="isShow ? 'show-pad' : 'hide-pad'">
    <div class="pad-buttons-left d-flex justify-content-center flex-column">
        <div class="d-flex align-items-center justify-content-between">
            <div class="position-relative d-flex align-items-center mr-2">

            </div>
            <div class="position-relative d-flex align-items-center">
                <div *ngIf="loginService.esProfesor() && idCurso != 0 && idMapa != 0" style="justify-items: center;">
                    <div class="text-center" (click)="verInforme()">
                        <div class="icon-option icon-informes pointer"></div>
                    </div>
                    <div class="mt-8p not-show-mobile">
                        <p class="info-course">{{('MENUACTIONS.TEACHERTITLE5' | translate)}}</p>
                    </div>
                </div>
                <div class="flex" style="margin-left: 100px;" (click)="emitshowModalCourse('verLista')">
                    <nb-icon icon="list"></nb-icon>
                </div>
            </div>
        </div>
    </div>
</div>

