<div [ngClass]="showInContainer ? 'files-container' : 'files-container-2'">
	<form class="h-100 flex justify-content-center align-items-center main-content">
		<!-- YOUTUBE -->
		<app-youtube-player class="h-100 w-100 p-0" [height]="'100%'" [videoId]="videoId"
			[startSeconds]="startSeconds" [endSeconds]="endSeconds" *ngIf="
						format === VIDEO_TYPE && !cargando && !inicial && youtubeSource
					"></app-youtube-player>
		<!-- END YOUTUBE -->

		<!-- INSTAGRAM VIDEO -->
		<ng-container *ngIf="
						format === VIDEO_TYPE && !cargando && !inicial && instagramSource
					">
			<iframe [src]="instagramSource" class="iframeVideo"></iframe>
		</ng-container>
		<!--END  INSTAGRAM VIDEO -->

		<!-- TIKTOK VIDEO -->
		<ng-container *ngIf="format === VIDEO_TYPE && !cargando && !inicial && tiktokSource">
			<iframe [src]="tiktokSource" class="iframeVideo"></iframe>
		</ng-container>
		<!-- END TIKTOK VIDEO -->

		<!-- SPOTIFY AUDIO -->
		<ng-container *ngIf="format === AUDIO_TYPE && !cargando && !inicial && spotifySource">
			<iframe style="border-radius:12px"
				[src]="spotifySource" width="60%" height="60%"
				frameBorder="0" allowfullscreen=""
				allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy">
			</iframe>
		</ng-container>
		<!-- END SPOTIFY AUDIO -->

		<!-- VIDEO -->
		<div class="h-100 w-100 p-0" *ngIf="format === VIDEO_TYPE && videoSource && !cargando && !inicial">
			<video style="width: 100%; height: 100%; object-fit: contain;" #videoElement controls autoplay
				[src]="videoSource | safe"></video>
		</div>
		<!-- END VIDEO -->

		<!-- IMAGE -->
		<div class="full-image w-100" [style.width.px]="windowWidth" [style.height.px]="windowHeight"
			*ngIf="format === IMAGE_TYPE && imageSource && !cargando && !inicial"
			[style.background-image]="'url(' + imageSource + ')'" (click)="openFullImage()" nbTooltip="Clic para hacer zoom" nbTooltipPlacement="top"></div>
		<!-- END IMAGE -->

		<!-- FULL IMAGE CONTAINER -->
		<div class="full-image-container" *ngIf="showFullImage">
			<div class="closeModal" (click)="closeFullImage()">
				<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
					ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
				</nb-icon>
			</div>
			<img [src]="imageSource" alt="Full Image" />
		</div>
		<!-- END FULL IMAGE CONTAINER -->

		<!-- AUDIO -->
		<div class="flex w-100 justify-content-center" [ngClass]="{ 'align-items-end': !showInContainer }"
			*ngIf="format === AUDIO_TYPE && audioSource && !cargando && !inicial">
			<audio style="width: 70%;" #audioPlayer [src]="audioSource | safe" controls></audio>
			<button nbButton class="btn-themecolor" nbTooltip="Bajar velocidad de reproducción" nbTooltipStatus="primary"
				(click)="changeSpeed()">
				<nb-icon [icon]="speedIndex == 0 ? 'arrow-ios-downward-outline' : 'play-circle-outline' "></nb-icon>
			</button>
		</div>
		<!-- END AUDIO -->

		<!-- PDF -->
		<div *ngIf="format === PDF_TYPE && pdfSource && !cargando && !inicial" class="flex w-100"
			style="height: 100%;">
			<!-- <div class="position-absolute" *ngIf="isLoadFile">
						<mat-spinner [diameter]="50"></mat-spinner>
					</div> -->
			<ng-container *ngIf="ext === 'docx'; else embedPdf">
				<ngx-doc-viewer [url]="pdfSource" viewer="office" style="width: 100%; height: auto;"></ngx-doc-viewer>
			</ng-container>
			<ng-template #embedPdf>
				<embed *ngIf="sanitizedUrl" [src]="sanitizedUrl" style="width: 100%; height: auto;" />
			</ng-template>

		</div>
		<!-- END PDF -->

		<!-- TXT -->
		<div *ngIf="format === TXT_TYPE && !cargando && !inicial" class="h-100 w-100 p-0">
			<div class="h-100 ql-editor bg-white rounded text-node" style="overflow-y: auto" [innerHTML]="textSource"></div>
		</div>
		<!-- END TXT -->
	</form>
	<!-- LATERAL COLUMN FILES -->
	<div class="cont-icon-hide" [ngClass]="{'in': isMenuOpen}" (click)="toggleMenu()">
		<mat-icon class="pointer">
			{{ isMenuOpen ? 'arrow_right' : 'arrow_left' }}
		</mat-icon>
	</div>

	<div *ngIf="isMenuOpen" class="header-close-ismenuOpen">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			 nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeNode()">
		</nb-icon>
		<span class="background-forIcon"></span>
	</div>

	<div class="right-files" [ngClass]="{'open': isMenuOpen}">
		<mat-button-toggle-group class="h-100 flex flex-column border-0">
			<ng-container *ngFor="let format of formats; let last = last; let i = index">
				<div class="row h-100 file-button">
					<div [ngClass]="{ 'mb-2': !last }" class="col-10 pr-0" style="flex: 1"
						[style.visibility]="format.visible ? 'visible' : 'hidden'">
						<app-node-detail-files-player [node]="node" [format]="format" (filesUploaderPlayFile)="playFile($event)">
						</app-node-detail-files-player>
					</div>
				</div>
			</ng-container>
		</mat-button-toggle-group>
	</div>
	<!-- END LATERAL COLUMN FILES -->
</div>
