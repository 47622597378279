export class QuizHistorialModel {
	idQuizHistorial: number;
	idCourse: number;
	idTarget: number;
	idQuiz: number;
    quizType: number;
	idUser: number;
	fecha: Date;
	tiempoTotalQuiz: number;
}
