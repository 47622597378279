<nb-card class="mb-0" *ngIf="loginService.esProfesor()">
	<nb-card-header class="flex justify-content-between border-bottom-0">
		<div class="col-11">
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}"
			nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal()">
		</nb-icon>
	</div>
	<nb-card-body>
		<nb-card class="background_themecolor mb-4">
			<nb-card-body class="container container-teacher">
				<div class="cards cards-teacher p-2">
					<div class="card-item" (click)="crearTarea()">
						<div class="card-info">
							<h6 class="card-title">
								{{ "PENSARCREANDO.CREARTAREA" | translate }}
							</h6>
							<p class="paragraph"></p>
						</div>
					</div>
				</div>
				<div class="cards cards-teacher p-2">
					<div class="card-item" (click)="verTareas()">
						<div class="card-info">
							<h6 class="card-title">
								{{ "PENSARCREANDO.VERTAREA" | translate }}
							</h6>
							<p class="paragraph"></p>
						</div>
					</div>
				</div>
			</nb-card-body>
		</nb-card>
	</nb-card-body>
</nb-card>
