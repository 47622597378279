<nb-card class="no-edge-card background_themecolor modal-height-100-62 mb-0">
	<nb-card-header class="px-3 py-0 flex flex-column border-bottom-0">
		<!-- Titulo de la pantalla -->
		<div class="row p-4 background_white">
			<div class="col-12 col-md-7 col-lg-8 modal-title px-2">
				<h4>{{ 'TUTORIAL.TUTORIALMANAGER' | translate }}</h4>
			</div>
		</div>
	</nb-card-header>
	<!-- Boton que cierra el modal -->
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>

	<nb-card-body>
		<div class="row min-h-100">
			<!-- ===== LISTA DE CONTEXTOS ===== -->
			<div *ngIf="!isMobile;" class="col-4 py-4 pl-4 pr-0 container-tree background_white">
				<div class="d-flex align-items-center pr-5">
					<h5>
						Listado de modulos
					</h5>
				</div>
				<div class="tree pr-4">
					<ul *ngIf="contextList.length > 0">
						<li *ngFor="let context of contextList; let i = index;"
							[class.active]="context === selectedContext">
							<div class="d-flex pointer py-2 pl-4">
								<div class="d-flex w-100 align-items-center gap-2" (click)="loadVideoList(context, i)">
									<span>
										{{context.context}}
									</span>
									<span style="width: 80px;">
										(Videos: {{context.videoList.length}})
									</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<!-- ===== SECCION DONDE ESTAN LOS 3 PASOS ===== -->
			<div class="col-12 py-0 pl-0 pr-0 container-tree" [ngClass]="{'col-xl-8 pl-xl-4': !isMobile }">
				<div class="background_white h-100 py-4 px-3">
					<div class="w-100 height-max">
						<!-- ===== PANTALLA 1 - LISTADO DE VIDEOS AGREGADOS ===== -->
						<div *ngIf="step == 1" class="w-100 h-100">
							<!-- Videos existentes -->
							<div *ngIf="videoList.length > 0" class="d-flex justify-content-center flex-wrap gap-4">
								<div *ngFor="let video of videoList; let i = index;" class="card card-video">
									<!-- Miniatura -->
									<img class="card-img-video" [src]="'https://img.youtube.com/vi/' + getVideoId(video.urlVideo) + '/hqdefault.jpg'" alt="{{video.title}}">
									<div class="d-flex align-items-center justify-content-between p-3">
										<!-- Título del video -->
										<p class="title-video" nbTooltip="{{ video.title }}" nbTooltipPlacement="top">{{ video.title }}</p>
										<!-- Iconos -->
										<div class="d-flex gap-1">
											<a [href]="video.urlVideo" target="_blank" class="text-decoration-none">
												<nb-icon class="pointer" icon="monitor-outline" nbTooltip="Abrir Video"></nb-icon>
											</a>
											<nb-icon class="pointer text-danger" icon="trash-2-outline" nbTooltip="Eliminar Video"
												(click)="onDeleteContextVideo(video)">
											</nb-icon>
										</div>
									</div>
								</div>
								<!-- Agregar video -->
								<div *ngIf="selectedContext" class="card card-video flex-column justify-content-center align-items-center">
									<h6>Agrega un video</h6>
									<nb-icon class="pointer" icon="plus-circle-outline" nbTooltip="Agregar Video"
										(click)="verVideosDisponibles(selectedContext)">
									</nb-icon>
								</div>
							</div>
							<!-- Opcion para agregar un video cuando no existe al menos 1 -->
							<div *ngIf="selectedContext && videoList.length === 0" class="d-flex flex-column justify-content-center align-items-center w-100 h-100">
								<h5>Agrega un video</h5>
								<nb-icon class="pointer" icon="plus-circle-outline" nbTooltip="Agregar Video"
									(click)="verVideosDisponibles(selectedContext)">
								</nb-icon>
							</div>
							<!-- Mensaje cuando el usuario no ha seleccionado nada -->
							<div *ngIf="!selectedContext" class="d-flex justify-content-center align-items-center w-100 h-100">
								<h5>Seleciona un elemento de la lista</h5>
							</div>
						</div>
						<!-- ===== PANTALLA 2 - AGREGAR VIDEOS AL CONTEXTO ===== -->
						<div *ngIf="step == 2" class="w-100 h-100">
							<!-- Boton para devolverse al paso 2 -->
							<div class="closeModal">
								<nb-icon class="m-1" icon="arrow-circle-left" [options]="{ animation: { type: 'zoom' } }"
									(click)="backToStep1()" nbTooltip="{{ 'REGISTRO.VOLVER' | translate }}" nbTooltipPlacement="left">
								</nb-icon>
							</div>
							<div *ngIf="videosDisponibles.length > 0" class="d-flex flex-column gap-5">
								<!-- videos disponibles -->
								<div class="d-flex flex-column gap-3">
									<div class="d-flex w-100 p-2">
										<h5 class="text-center">
											Videos disponibles
										</h5>
									</div>
									<div class="d-flex justify-content-center flex-wrap gap-4">
										<div *ngFor="let video of videosDisponibles" class="card card-video">
											<!-- Miniatura -->
											<img class="card-img-video" [src]="'https://img.youtube.com/vi/' + getVideoId(video.urlVideo) + '/hqdefault.jpg'" alt="{{video.title}}">
											<div class="d-flex align-items-center justify-content-between p-3">
												<!-- Título del video -->
												<p class="title-video" nbTooltip="{{ video.title }}" nbTooltipPlacement="top">{{ video.title }}</p>
												<!-- Iconos -->
												<div class="d-flex gap-1">
													<a [href]="video.urlVideo" target="_blank" class="text-decoration-none">
														<nb-icon class="pointer" icon="monitor-outline" nbTooltip="Abrir Video"></nb-icon>
													</a>
													<nb-icon class="pointer" icon="plus-circle-outline" nbTooltip="Agregar Video"
														(click)="onAddVideo(video)">
													</nb-icon>
													<nb-icon class="pointer text-danger" icon="trash-2-outline" nbTooltip="Eliminar Video"
														(click)="onDeleteVideo(video)">
													</nb-icon>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- Opcion cuando el usuario no encuentra lo que busca -->
								<div class="d-flex w-100 justify-content-center align-items-center p-2">
									<button nbButton class="btn-themecolor" size="small" (click)="goToStep3()">
										¿No encuentras el vídeo? Ingresalo aquí
									</button>
								</div>
							</div>
							<!-- Mensaje cuando no hay videos disponibles -->
							<div *ngIf="videosDisponibles.length === 0" class="d-flex flex-column justify-content-center align-items-center w-100 h-100">
								<h5>No hay videos disponibles, agrega uno</h5>
								<div class="d-flex w-100 justify-content-center align-items-center p-2">
									<nb-icon class="pointer" icon="plus-circle-outline" nbTooltip="Agregar Video"
										(click)="goToStep3()">
									</nb-icon>
								</div>
							</div>
						</div>
						<!-- ===== PANTALLA 3 - AGREGAR VIDEOS DESDE YOUTUBE ===== -->
						<div *ngIf="step == 3" class="w-100 h-100">
							<!-- Boton para devolverse al paso 2 -->
							<div class="closeModal">
								<nb-icon class="m-1" icon="arrow-circle-left" [options]="{ animation: { type: 'zoom' } }"
									(click)="backToStep2()" nbTooltip="{{ 'REGISTRO.VOLVER' | translate }}" nbTooltipPlacement="left">
								</nb-icon>
							</div>

							<div class="d-flex flex-column gap-3">
								<div class="d-flex w-100 p-2">
									<h5 class="text-center">
										Inserta la url de YouTube
									</h5>
								</div>
								<!-- Formulario para agregar video de youtube -->
								<div class="d-flex flex-wrap gap-4 justify-content-center">
									<!-- Titulo del video -->
									<div class="d-flex flex-column">
										<label>Título</label>
										<input nbInput fullWidth class="form-control" fieldSize="small" placeholder="Vídeo Tutorial de prueba" [(ngModel)]="videoTitle" (ngModelChange)="validateTitle()"/>
										<div *ngIf="videoTitle && videoTitle.length < 5" class="text-danger mt-1">
											El título debe tener más de 5 caracteres.
										</div>
									</div>
									<!-- url del video -->
									<div class="d-flex flex-column">
										<label>Url</label>
										<input nbInput fullWidth class="form-control" fieldSize="small" placeholder="https://www.youtube.com/..." [(ngModel)]="videoUrl" (ngModelChange)="validateUrl()"/>
										<div *ngIf="videoUrl && !isValidUrl" class="text-danger mt-1">
											Por favor, ingresa una URL válida de YouTube.
										</div>
										<div *ngIf="isValidUrl" class="text-success mt-1">
											¡URL válida!
										</div>
									</div>
								</div>
								<!-- Miniatura -->
								<div *ngIf="videoUrl && isValidUrl" class="d-flex w-100 justify-content-center align-items-center">
									<div class="card">
										<img class="card-img-video" [src]="'https://img.youtube.com/vi/' + getVideoId(videoUrl) + '/hqdefault.jpg'">
									</div>
								</div>

								<div class="d-flex w-100 justify-content-center align-items-center p-2">
									<button nbButton class="btn-themecolor" size="small" [disabled]="!isValidTitle || !isValidUrl" (click)="saveUrl()">
										Guardar vídeo
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>
