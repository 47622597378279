<form [formGroup]="optionForm" *ngIf="QuizTypes.TEXT && quiz.idMultiplexQuiz === 0">
	<ng-container formArrayName="options">
		<div class="row justify-content-center">
			<ng-container [ngSwitch]="quizType">
				<!-- QUIZZ DE INTRODUCIR DATOS -->
				<div class="col-sm-12 col-md-10" [formGroupName]="1" *ngSwitchCase="QuizTypes.TEXT">
					<form [formGroup]="optionFormTexto">
						<textarea (blur)="readText()" autocomplete="off" nbInput fullWidth
							placeholder="{{ 'QUIZZES.RESPUESTA' | translate }} {{ 1 }}" formControlName="answer" [disabled]="answered"
							maxlength="250"></textarea>

						<!-- <mat-form-field appearance="standard" class="full-width">
                            <mat-label>{{'QUIZZES.RESPUESTA' | translate}} {{1}}</mat-label>
                            <input (blur)="readText()" autocomplete="off"  matInput type="text" formControlName="answer" [readonly]="this.answered" />
                        </mat-form-field> -->
					</form>
				</div>

				<div class="col-sm-12 col-md-10" [formGroupName]="1" *ngSwitchCase="QuizTypes.IA">
					<form [formGroup]="optionFormTexto">
						<textarea (blur)="readText()" autocomplete="off" nbInput fullWidth
							placeholder="{{ 'QUIZZES.RESPUESTA' | translate }} {{ 1 }}" formControlName="answer" [disabled]="answered"
							maxlength="250">
						</textarea>

						<!-- <mat-form-field appearance="standard" class="full-width">
                            <mat-label>{{'QUIZZES.RESPUESTA' | translate}} {{1}}</mat-label>
                            <input (blur)="readText()" autocomplete="off"  matInput type="text" formControlName="answer" [readonly]="this.answered" />
                        </mat-form-field> -->
					</form>
				</div>

				<div class="d-flex gap-4" *ngSwitchCase="QuizTypes.TEXT">
					<ng-container *ngFor="let option of optionsArray.controls; let i = index">
						<!-- Inputs Ocultos -->
						<input *ngIf="option.value.type === O_AUDIOS" type="file" id="{{ i }}" accept="audio/mp3"
							(change)="onFileSelected(i, $event, O_AUDIOS)" hidden />
						<input *ngIf="option.value.type === O_VIDEOS" type="file" id="{{ i }}" accept="video/mp4"
							(change)="onFileSelected(i, $event, O_VIDEOS)" hidden />
						<input *ngIf="option.value.type === O_PICTURES" type="file" id="{{ i }}" accept="image/*"
							(change)="onFileSelected(i, $event, O_PICTURES)" hidden />
						<input *ngIf="option.value.type === O_PDFS" type="file" id="{{ i }}"
							accept="application/pdf,application/vnd.ms-excel" (change)="onFileSelected(i, $event, O_PDFS)" hidden />

						<ng-container *ngIf="arrayFileView[i].view" class="center-aling">
							<div class="col-xs-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 container-file">
								<div class="row">
									<div class="col-12 center-aling" style="padding-left: 0px; padding-right: 0px">
										<img *ngIf="option.value.type === O_PICTURES" #imgElement class="file-container-view" id="{{ i }}"
											[src]="arrayFileView[i].url | safe" />
										<audio *ngIf="
												arrayFileView[i].view && option.value.type === O_AUDIOS
											" class="file-container-view" id="{{ i }}" [src]="arrayFileView[i].url | safe" controls></audio>
										<video *ngIf="
												arrayFileView[i].view && option.value.type === O_VIDEOS
											" class="file-container-view" [src]="arrayFileView[i].url | safe" controls></video>
										<iframe *ngIf="
												arrayFileView[i].view && option.value.type === O_PDFS
											" #pdfElement class="file-container-view" style="max-height: 70% !important"
											[src]="arrayFileView[i].url | safe" allowfullscreen></iframe>
									</div>
									<div class="col-12 center-aling" style="padding-top: 3px">
										<span *ngIf="option.value.type === O_VIDEOS">{{
											"QUIZZES.VIDEO" | translate
											}}</span>
										<span *ngIf="option.value.type === O_PICTURES">{{
											"QUIZZES.IMAGEN" | translate
											}}</span>
										<span *ngIf="option.value.type === O_AUDIOS">{{
											"QUIZZES.AUDIO" | translate
											}}</span>
										<span *ngIf="option.value.type === O_PDFS">{{
											"QUIZZES.DOCUMENTO" | translate
											}}</span>
										&nbsp;
										<mat-icon class="edit pointer" ngbTooltip="{{ 'GLOBALS.EDIT' | translate }}" placement="right"
											(click)="onFileSelectedReplace(i, option.value)">
											edit
										</mat-icon>
										<mat-icon *ngIf="
												arrayFileView[i].view && option.value.type === O_AUDIOS
											" ngbTooltip="{{ 'QUIZZES.RECORDNEWAUDIO' | translate }}" class="edit pointer"
											(click)="grabarAudio(i, option.value)">mic</mat-icon>
										<mat-icon *ngIf="
												arrayFileView[i].view && option.value.type === O_VIDEOS
											" ngbTooltip="{{ 'QUIZZES.RECORDNEWVIDEO' | translate }}" class="edit pointer"
											(click)="grabarVideo(i, option.value)">camera</mat-icon>
										<mat-icon *ngIf="
												arrayFileView[i].view &&
												option.value.type === O_PICTURES
											" ngbTooltip="{{ 'QUIZZES.NEWPHOTO' | translate }}" class="edit pointer"
											(click)="tomarFoto(i, option.value)">camera</mat-icon>
									</div>
								</div>
							</div>
						</ng-container>

						<ng-container *ngIf="!arrayFileView[i].view">
							<div class="d-flex w-100 align-items-center justify-content-center gap-4 flex-wrap mt-4"
								*ngIf="(option.value.type === O_VIDEOS && !viewAudio) || (option.value.type === O_PICTURES && !viewAudio) || (option.value.type === O_PDFS && !viewAudio) || option.value.type === O_AUDIOS">

								<ng-container *ngIf="option.value.type === O_VIDEOS">
									<div class="d-flex w-100 align-items-center justify-content-center gap-4" [ngClass]="{ disabled: hideResponse }">
										<!-- SUBIR VIDEO -->
										<div class="opt-quiz-icon" nbTooltip="Cargar video" (click)="openFile(i, option.value)">
											<div class="d-flex flex-column align-items-center gap-1">
												<span>{{ "QUIZZES.VIDEO" | translate }}</span>
												<nb-icon icon="video-outline"
													id="mat-icon-{{ i }}">
												</nb-icon>
											</div>
										</div>
										<!-- GRABAR VIDEO -->
										<div class="opt-quiz-icon" nbTooltip="Grabar video" (click)="grabarVideo(i, option.value)">
											<div class="d-flex flex-column align-items-center gap-1">
												<span>{{ "QUIZZES.GRABAR" | translate }}</span>
												<nb-icon icon="camera-outline"
													id="mat-icon-{{ i }}">
												</nb-icon>
											</div>
										</div>
									</div>
								</ng-container>

								<ng-container *ngIf="option.value.type === O_PICTURES">
									<div class="d-flex w-100 align-items-center justify-content-center gap-4" [ngClass]="{ disabled: hideResponse }">
										<!-- CARGAR IMAGEN -->
										<div class="opt-quiz-icon" nbTooltip="Cargar imagen" (click)="openFile(i, option.value)">
											<div class="d-flex flex-column align-items-center gap-1">
												<span>{{ "QUIZZES.IMAGEN" | translate }}</span>
												<nb-icon icon="image-outline"
													id="mat-icon-{{ i }}">
												</nb-icon>
											</div>
										</div>
										<!-- CAPTURAR IMAGEN -->
										<div class="opt-quiz-icon" nbTooltip="Sacar foto" (click)="tomarFoto(i, option.value)">
											<div class="d-flex flex-column align-items-center gap-1">
												<span>{{ "QUIZZES.FOTO" | translate }}</span>
												<nb-icon icon="camera-outline"
													id="mat-icon-{{ i }}">
												</nb-icon>
											</div>
										</div>
									</div>
								</ng-container>

								<ng-container *ngIf="option.value.type === O_PDFS">
									<div class="d-flex w-100 align-items-center justify-content-center gap-4" [ngClass]="{ disabled: hideResponse }">
										<!-- CARGAR PDF -->
										<div class="opt-quiz-icon" nbTooltip="Cargar PDF" (click)="openFile(i, option.value)">
											<div class="d-flex flex-column align-items-center gap-1">
												<span>{{ "QUIZZES.DOCUMENTO" | translate }}</span>
												<nb-icon icon="file-add-outline"
													id="mat-icon-{{ i }}">
												</nb-icon>
											</div>
										</div>
									</div>
								</ng-container>

								<ng-container *ngIf="option.value.type === O_AUDIOS">
									<div *ngIf="!viewAudio" class="d-flex w-100 align-items-center justify-content-center gap-4" [ngClass]="{ disabled: hideResponse }">
										<!-- CARGAR AUDIO -->
										<div class="opt-quiz-icon" nbTooltip="Cargar audio" (click)="openFile(i, option.value)">
											<div class="d-flex flex-column align-items-center gap-1">
												<span>{{ "QUIZZES.AUDIO" | translate }}</span>
												<nb-icon icon="camera-outline"
													id="mat-icon-{{ i }}">
												</nb-icon>
											</div>
										</div>
										<!-- GRABAR AUDIO -->
										<div class="opt-quiz-icon" nbTooltip="Grabar audio" (click)="_grabarAudio(i, option.value)">
											<div class="d-flex flex-column align-items-center gap-1">
												<span>{{ "QUIZZES.GRABAR" | translate }}</span>
												<nb-icon icon="mic-outline"
													id="mat-icon-{{ i }}">
												</nb-icon>
											</div>
										</div>
									</div>
									<div *ngIf="viewAudio" class="d-flex align-items-center justify-content-center w-100">
										<audio-component [viewElement]="viewAudio" (emitAudio)="emitAudioEvent($event)"
											(close)="closeElement($event)">
										</audio-component>
									</div>
								</ng-container>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</ng-container>
</form>

<form class="quiz-options" [formGroup]="optionForm">
	<ng-container formArrayName="options">
		<div class="row justify-content-center" *ngFor="let option of optionsArray.controls; let i = index">
			<ng-container [ngSwitch]="quizType">
				<!-- QUIZZES DE SELECCIÓN ÚNICA -->
				<div class="col-sm-12 col-md-10 flex align-items-start mb-4 justify-content-center" [formGroupName]="i"
					*ngSwitchCase="QuizTypes.SINGLE">
					<div class="count mr-3 align-self-center" *ngIf="answered && !hideCounter && !loadingResponses">
						{{ option.get("totalResponses").value }}
					</div>
					<nb-radio-group class="w-100 option-bordered" [formControl]="correctOptionControl" [ngClass]="{
							'bg-correct': answered == true && optionsArrayIsCorrect[i].value,
							'bg-incorrect':
								answered == true && !optionsArrayIsCorrect[i].value,
							'radio-selected':
								correctOptionControl.value === option.get('id').value
						}" [class.bg-correct]="answered && optionsArrayIsCorrect[i].value" (click)="
							clickedOptionDiv(
								correctOptionControl,
								option.get('id'),
								option.get('id').value
							)
						">
						<nb-radio class="mr-2 pl-2" [disabled]="answered || hideResponse" [value]="option.get('id').value"
							[checked]="option.get('id').touched">
							<ng-container [ngSwitch]="optionsArrayType[i].value">
								<div *ngSwitchCase="elementTypes.O_TEXTS">
									<p class="option">
										{{ optionsArrayData[i].value }}
									</p>
								</div>

								<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
									*ngSwitchCase="elementTypes.O_PICTURES">
									<img class="mx-auto img-fluid" [style.width]="
											optionsArrayWidth[i].value
												? optionsArrayWidth[i].value + 'px'
												: 'fit-content'
										" [style.height]="
											optionsArrayHeight[i].value
												? optionsArrayHeight[i].value + 'px'
												: '100%'
										" [src]="
											optionsArrayData[i].value | quizElements : 'image' | safe
										" *ngIf="optionsArrayData[i].value" />
								</div>

								<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
									*ngSwitchCase="elementTypes.O_VIDEOS">
									<video class="w-100 mw-100" [style.width]="
											optionsArrayWidth[i].value
												? optionsArrayWidth[i].value + 'px'
												: 'fit-content'
										" [style.height]="
											optionsArrayHeight[i].value
												? optionsArrayHeight[i].value + 'px'
												: '100%'
										" controls [src]="
											optionsArrayData[i].value | quizElements : 'video' | safe
										" *ngIf="optionsArrayData[i].value"></video>
								</div>

								<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
									*ngSwitchCase="elementTypes.O_AUDIOS">
									<audio class="w-100 d-block" controls [src]="
											optionsArrayData[i].value | quizElements : 'audio' | safe
										" *ngIf="optionsArrayData[i].value"></audio>
								</div>

								<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
									*ngSwitchCase="elementTypes.O_PDFS">
									<iframe #pdfElement class="w-100 h-100" [style.width]="
											optionsArrayWidth[i].value
												? optionsArrayWidth[i].value + 'px'
												: 'fit-content'
										" [style.height]="
											optionsArrayHeight[i].value
												? optionsArrayHeight[i].value + 'px'
												: '100%'
										" [src]="
											optionsArrayData[i].value | quizElements : 'pdf' | safe
										" allowfullscreen *ngIf="optionsArrayData[i].value" style="min-height: 920px"></iframe>
								</div>
							</ng-container>
						</nb-radio>
					</nb-radio-group>
				</div>

				<!-- QUIZZES MULTIPLES -->
				<div class="col-sm-12 col-md-10 d-flex align-items-center" [formGroupName]="i"
					*ngSwitchCase="QuizTypes.MULTIPLE">
					<nb-checkbox status="primary" class="mr-2 w-100 largerCheckbox" [disabled]="answered"
						formControlName="checked" [ngClass]="{
							'bg-correct': answered && optionsArrayIsCorrect[i].value,
							'bg-incorrect': answered && !optionsArrayIsCorrect[i].value
						}">
						<ng-container [ngSwitch]="optionsArrayType[i].value">
							<div *ngSwitchCase="elementTypes.O_TEXTS">
								<p class="option">
									{{ optionsArrayData[i].value }}
								</p>
							</div>

							<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
								*ngSwitchCase="elementTypes.O_PICTURES">
								<img class="mx-auto img-fluid" [style.width]="
										optionsArrayWidth[i].value
											? optionsArrayWidth[i].value + 'px'
											: 'fit-content'
									" [style.height]="
										optionsArrayHeight[i].value
											? optionsArrayHeight[i].value + 'px'
											: '100%'
									" [src]="
										optionsArrayData[i].value | quizElements : 'image' | safe
									" *ngIf="optionsArrayData[i].value" />
							</div>

							<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
								*ngSwitchCase="elementTypes.O_VIDEOS">
								<video class="w-100 mw-100" [style.width]="
										optionsArrayWidth[i].value
											? optionsArrayWidth[i].value + 'px'
											: 'fit-content'
									" [style.height]="
										optionsArrayHeight[i].value
											? optionsArrayHeight[i].value + 'px'
											: '100%'
									" controls [src]="
										optionsArrayData[i].value | quizElements : 'video' | safe
									" *ngIf="optionsArrayData[i].value"></video>
							</div>

							<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
								*ngSwitchCase="elementTypes.O_AUDIOS">
								<audio class="w-100 d-block" controls [src]="
										optionsArrayData[i].value | quizElements : 'audio' | safe
									" *ngIf="optionsArrayData[i].value"></audio>
							</div>

							<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
								*ngSwitchCase="elementTypes.O_PDFS">
								<iframe #pdfElement class="w-100 h-100" [style.width]="
										optionsArrayWidth[i].value
											? optionsArrayWidth[i].value + 'px'
											: 'fit-content'
									" [style.height]="
										optionsArrayHeight[i].value
											? optionsArrayHeight[i].value + 'px'
											: '100%'
									" [src]="
										optionsArrayData[i].value | quizElements : 'pdf' | safe
									" allowfullscreen *ngIf="optionsArrayData[i].value" style="min-height: 920px"></iframe>
							</div>
						</ng-container>
					</nb-checkbox>
				</div>
			</ng-container>
		</div>
	</ng-container>
</form>
