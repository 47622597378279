<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="border-bottom-0">
		<div class="d-flex gap-2 justify-content-between align-items-center">
			<!-- TITULO DEL GRUPO -->
			<div class="d-flex">
				<h2>{{ "GRUPOS.MISGRUPOS" | translate }}</h2>
			</div>
			<!-- BOTONES -->
			<div class="d-flex gap-3 align-items-center justify-content-center pr-5">
				<!-- SELECCIONAR TODOS -->
				<div class="d-flex align-items-center justify-content-center">
					<div *ngIf="profile === 'CENTRO'" class="d-flex align-items-center justify-content-center">
						<button nbButton class="btn-themecolor" size="small" (click)="selectAllGroups()">
							{{ 'MODALGROUPSEDIT.SELECTALL' | translate }}
						</button>
					</div>
				</div>
				<!-- SALIR DEL GRUPO -->
				<div *ngIf="storedGroupInfo && profile != 'CENTRO'" class="d-flex align-items-center justify-content-center">
					<button nbButton ghost class="btn-red" size="small" (click)="noGroup()"
						nbTooltip="{{ profile === 'ESTUDIANTE' ? ('MODALGROUPSEDIT.SELFSTUDY' | translate) : ('MODALGROUPSEDIT.SELFEDUCATE' | translate) }}"
						nbTooltipPlacement="bottom">
						<nb-icon icon="log-in-outline"></nb-icon>
					</button>
				</div>
				<!-- BUSCAR GRUPO -->
				<div *ngIf="profile === studentProfile" class="d-flex align-items-center justify-content-center">
					<button nbButton ghost class="btn-themecolor" size="small" (click)="openSearchGroups()"
						nbTooltip="{{ 'MODALGROUPSEDIT.SEARCHGROUPS' | translate }}" nbTooltipPlacement="bottom">
						<nb-icon icon="search-outline"></nb-icon>
					</button>
				</div>
				<!-- NOTIFICACIONES -->
				<div *ngIf="profile === studentProfile" class="d-flex align-items-center justify-content-center position-relative">
					<div *ngIf="numberOfInvited >= 1; else disabledInviteButton">
						<button nbButton ghost class="btn-themecolor" size="small" [nbPopover]="notificationsTemplate"
							nbPopoverPlacement="bottom" nbTooltip="{{ 'MODALGROUPSEDIT.INVITATIONS' | translate }}" nbTooltipPlacement="bottom">
							<nb-icon icon="bell-outline"></nb-icon>
						</button>
						<span class="background-icon-action-red">{{ numberOfInvited }}</span>
					</div>

					<ng-template #disabledInviteButton>
						<button nbButton ghost class="btn-themecolor" size="small" [nbPopover]="notificationsTemplate"
							nbPopoverPlacement="bottom" disabled nbTooltip="{{ 'MODALGROUPSEDIT.INVITATIONS' | translate }}" nbTooltipPlacement="bottom">
							<nb-icon icon="bell-outline"></nb-icon>
						</button>
						<span class="background-icon-action-red">{{numberOfInvited}}</span>
					</ng-template>

					<ng-template #notificationsTemplate>
						<nb-card class="no-edge-card mb-0">
							<nb-list *ngIf="numberOfInvited >= 1; else noInvitations">
								<nb-list-item class="border-bottom-0 border-top-0" *ngFor="let item of invitacionesList">
									<span class="font-weight-bold mr-1">
										{{ item.groupTitle }}
									</span>
									<div class="ml-1">
										<nb-icon class="mr-1 icon-action-blue" icon="checkmark-circle-2" style="color: green;"
											[options]="{ animation: { type: 'pulse' } }" (click)="aceptar(item)"></nb-icon>
										<nb-icon class="mr-1 icon-action-red" icon="close-circle" [options]="{ animation: { type: 'pulse' } }"
											(click)="rechazar(item)"></nb-icon>
									</div>
								</nb-list-item>
							</nb-list>
							<ng-template #noInvitations>
								<div class="alert alert-info" role="alert">
									{{ "MODALGROUPSEDIT.NOINVITATIONSYET" | translate }}
								</div>
							</ng-template>
						</nb-card>
					</ng-template>
				</div>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left"
			[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"></nb-icon>
	</div>
	<nb-card-body>
		<app-loading [isVisible]="cargando"></app-loading>

		<nb-actions class="mt-3 not-show-desktop" size="medium" fullWidth>
			<nb-action icon="search" (click)="openSearchGroups()"></nb-action>
			<nb-action>
				<div *ngIf="profile === studentProfile" class="dropdown">
					<div *ngIf="numberOfInvited >= 1; else disabledInviteButton">
						<button nbButton class="btn-themecolor" [nbPopover]="notificationsTemplate" nbPopoverPlacement="bottom">
							<span class="font-weight-bold">{{ "MODALGROUPSEDIT.INVITATIONS" | translate }}</span>
							<span class="badge background-icon-action-red ml-2">{{ numberOfInvited }}</span>
						</button>
					</div>
					<ng-template #disabledInviteButton>
						<button nbButton class="btn-themecolor" [nbPopover]="notificationsTemplate" nbPopoverPlacement="bottom" disabled>
							<span class="font-weight-bold">{{ "MODALGROUPSEDIT.INVITATIONS" | translate }}</span>
							<span class="badge background-icon-action-red ml-2">{{ numberOfInvited }}</span>
						</button>
					</ng-template>

					<ng-template #notificationsTemplate>
						<nb-card class="no-edge-card mb-0">
							<nb-card-header>{{ "MODALGROUPSEDIT.INVITATIONS" | translate }}</nb-card-header>
							<nb-list *ngIf="numberOfInvited >= 1; else noInvitations">
								<nb-list-item *ngFor="let item of invitacionesList">
									<span class="font-weight-bold mr-1">
										{{ item.groupTitle }}
									</span>
									<div class="ml-1">
										<nb-icon class="mr-1 icon-action-blue" icon="checkmark-circle-2"
											[options]="{ animation: { type: 'pulse' } }" (click)="aceptar(item)"></nb-icon>
										<nb-icon class="mr-1 icon-action-red" icon="close-circle"
											[options]="{ animation: { type: 'pulse' } }" (click)="rechazar(item)"></nb-icon>
									</div>
								</nb-list-item>
							</nb-list>
							<ng-template #noInvitations>
								<div class="alert alert-info" role="alert">
									{{ "MODALGROUPSEDIT.NOINVITATIONSYET" | translate }}
								</div>
							</ng-template>
						</nb-card>
					</ng-template>
				</div>
			</nb-action>
		</nb-actions>
		<div class="d-flex flex-wrap gap-5 justify-content-center">
			<div *ngIf="gruposList.length === 0 && profile === studentProfile" class="d-flex justify-content-center">
				<div class="alert alert-info" role="alert">
					{{ "MODALGROUPSEDIT.NOTBELONGGROUP" | translate }}
				</div>
			</div>

			<div *ngFor="let grupo of gruposList | sortBy : 'asc' : 'tittle'" class="card-Mygroup">
				<nb-card class="pointer mb-0"
				[ngClass]="{
					'group-info': isProfesor || profile === 'CENTRO',
					'group-info_student': profile === studentProfile,
					'border-body-select': idGrupoSelect === grupo.idGroup,
					'border-body': idGrupoSelect !== grupo.idGroup
					}">
					<nb-card-header class="p-0 position-relative" [ngClass]="{'border-header-select2': idGrupoSelect === grupo.idGroup, 'border-header2': idGrupoSelect !== grupo.idGroup}">
						<div *ngIf="profile === centerProfile" class="d-flex justify-content-center align-items-center checkbox-position">
							<nb-checkbox class="m-0" (change)="addOrRemoveGroup(grupo)"></nb-checkbox>
						</div>
						<div style="position: relative;" class="d-flex flex-column justify-content-center gap-2 p-1">
							<div class="d-flex justify-content-center">
								<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;" [text]="grupo.nivel + ' ' + grupo.letras"
									[style.background]="getNivelesColor(grupo.nivel)">
								</nb-tag>
							</div>
							<div class="d-flex justify-content-center">
								<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;" [text]="getSubjectTitle(grupo.idSubject)"
									[style.background]="getSubjectColor(grupo.idSubject)">
								</nb-tag>
							</div>
						</div>
					</nb-card-header>
					<nb-card-header class="p-2 image-header" [ngClass]="{'border-header-select': idGrupoSelect === grupo.idGroup, 'border-header': idGrupoSelect !== grupo.idGroup}"
					 (click)="verDetalleGrupo(grupo)" [style.background-image]="'url(' + (grupo.imagen | imagen : 'group') + ')'" *ngIf="networkSpeed >= 1"
					 style="border-radius: 0%;" >
					</nb-card-header>
					<nb-card-header class="p-2 image-header" [ngClass]="{'border-header-select': idGrupoSelect === grupo.idGroup, 'border-header': idGrupoSelect !== grupo.idGroup}"
					 (click)="verDetalleGrupo(grupo)" [style.background-image]="'url(../../../../assets/images/no-image.png)'" *ngIf="networkSpeed < 1"
					 style="border-radius: 0%;" >
					</nb-card-header>
					<nb-card-body class="p-2" [ngClass]="{'background-body-select': idGrupoSelect === grupo.idGroup, 'background-body': idGrupoSelect !== grupo.idGroup}"
						(click)="verDetalleGrupo(grupo)">
						<div>
							<p class="courseTittle"
								[ngClass]="{
									'courseTittle_teacher': isProfesor || profile === 'CENTRO',
									'courseTittle_student': !isProfesor
								}">
								{{ grupo.title | titlecase | truncate : [50, "..."] }}
							</p>
							<p *ngIf="profile != studentProfile" class="m-0 pt-2 courseTittle">
								Código: {{ grupo.identification | truncate : [50, "..."] }}
							</p>
						</div>
					</nb-card-body>
					<nb-card-footer class="flex flex-row justify-content-end pt-0 pl-1 pr-1 pb-1" [ngClass]="{'background-footer-select': idGrupoSelect === grupo.idGroup, 'background-footer': idGrupoSelect !== grupo.idGroup}" *ngIf="profile === studentProfile">
						<span class="codeTitle">
							{{ grupo.identification | truncate : [50, "..."] }}
						</span>
						<mat-icon class="pointer color-red-text" nbTooltip="{{ 'MODALGROUPSEDIT.SALIRGRUPO' | translate }}" (click)="salirGrupo(grupo)">delete</mat-icon>
					</nb-card-footer>
					<nb-card-footer class="flex flex-column p-1" *ngIf="profile != studentProfile" [ngClass]="{'background-footer-select': idGrupoSelect === grupo.idGroup, 'background-footer': idGrupoSelect !== grupo.idGroup}">
						<div class="flex flex-wrap gap-2 w-100 align-items-center justify-content-around">
							<div class="cont-img" style="cursor: pointer !important;" nbTooltip="{{ 'MODALGROUPSEDIT.ESTUDIANTESASIGNADOS' | translate }}" (click)="listadoAlumnos(grupo)">
								<span>{{ grupo.assignedStudents }}</span>
								<img src="../../../../assets/images/icons/EstudianteLineaBlanco.svg">
							</div>
							<div class="cont-img" style="cursor: pointer !important;" nbTooltip="{{ 'MODALGROUPSEDIT.PROYECTOSASIGNADOS' | translate }}" (click)="listadoCursos(grupo)">
								<span>{{ grupo.assignedProjects }} </span>
								<img src="../../../../assets/images/icons/ProyectoComoMuchosGrafos.svg">
							</div>
							<div class="cont-img" style="cursor: pointer !important;" nbTooltip="{{ 'MODALGROUPSEDIT.DESAFIOSREALIZADOS' | translate }}" (click)="instantActivity(grupo)">
								<span>{{ grupo.challengesRealized }}</span>
								<img src="../../../../assets/images/icons/desafios_icon.svg">
							</div >
							<div class="cont-img" style="cursor: pointer !important;" nbTooltip="{{ 'GRUPOS.AJUSTES' | translate }}" (click)="modificarGrupo(grupo)">
								<img src="../../../../assets/images/icons/AjustesLineaBlanco.svg">
							</div>
						</div>

						<div *ngIf="!isStudent && grupo.numberOfInvited >= 1; else disabledInviteButton" class="content-alert" [nbPopover]="notificationsActionsTemplate" nbPopoverPlacement="bottom">
							<nb-icon icon="bell"></nb-icon>
							<span class="badge background-icon-action-red">{{grupo.numberOfInvited}}</span>

							<ng-template #disabledInviteButton>
								<!-- <button nbButton class="btn-themecolor" [nbPopover]="notificationsActionsTemplate" nbPopoverPlacement="bottom" disabled>
																					<span class="font-weight-bold">{{'MODALGROUPSEDIT.INVITATIONS' | translate}} </span>  <span class="badge badge-danger ml-2">{{grupo.numberOfInvited}}</span>
																				</button> -->
							</ng-template>

							<ng-template #notificationsActionsTemplate>
								<nb-card class="no-edge-card mb-0">
									<nb-card-header class="p-2">{{ "MODALGROUPSEDIT.ESTUDIANTESASIGNADOS2" | translate }}</nb-card-header>
									<nb-list *ngIf="grupo.numberOfInvited >= 1; else noInvitationsActions">
										<nb-list-item class="margin-bottom-0" *ngFor="let item of grupo.invitacionesList">
											<span class="font-weight-bold mr-1">
												{{ item.firstName }}
											</span>
											<div class="ml-1">
												<nb-icon class="mr-1 icon-action-blue" icon="checkmark-circle-2"
													[options]="{ animation: { type: 'pulse' } }" (click)="aceptarTeacher(grupo.idGroup, item)"></nb-icon>
												<nb-icon class="mr-1 icon-action-red" icon="close-circle" [options]="{ animation: { type: 'pulse' } }"
													(click)="rechazarTeacher(grupo.idGroup, item)"></nb-icon>
											</div>
										</nb-list-item>
										<ng-template #noInvitationsActions>
											<div class="alert alert-info" role="alert">
												No tienes ninguna invitación
											</div>
										</ng-template>
									</nb-list>
								</nb-card>
							</ng-template>
						</div>
					</nb-card-footer>
				</nb-card>
			</div>
			<!-- ADD NEW GROUP -->
			<div *ngIf="profile !== studentProfile && profile !== centerProfile" class="d-flex card-Mygroup pointer"
				title="{{ 'GRUPOS.NUEVOGRUPO' | translate }}" (click)="nuevoGrupo()">
				<div class="card d-flex justify-content-center">
					<div class="img-container">
						<img src="../../../../../assets/images/new-item.png" class="img-fluid" />
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>

