import { selectedProfile } from "./../../../store/selectors/profiles.selector";
import { MastersService } from "src/app/core/services/masters";
import { Component, HostListener, DoCheck, OnInit, ViewChild, TemplateRef } from "@angular/core";
import {
	NgbActiveModal,
	NgbModal,
	NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { Profiles } from "src/app/core/utils/profiles.enum";
import { TranslateService } from "@ngx-translate/core";
import { ModalListadoEstudiantesComponent } from "../grupos/modal-listado-estudiantes/modal-listado-estudiantes.component";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import {
	GruposService,
	SOCKETMESSAGES,
} from "src/app/core/services/groups/grupos.service";
import { debounceTime, finalize, take, takeUntil } from "rxjs/operators";
import { ModalAceptarCancelarComponent } from "../modal/modal-aceptar-cancelar/modal-aceptar-cancelar.component";
import { ModalListadoCursosComponent } from "../grupos/modal-listado-cursos/modal-listado-cursos.component";
import { ReportingComponent } from "../reporting/reporting.component";
import { SocketService } from "src/app/core/services/socket/socket-service.service";
import { ModalSocketCoursesComponent } from "../grupos/modal-socket-courses/modal-socket-courses.component";
import { Subject, Subscription } from "rxjs";
import { User } from "src/app/core/models/users/user.models";
import { LoginService } from "src/app/core/services/login";
import { UsersService } from "src/app/core/services/users";
import { ModalAlertChallengesComponent } from "./modal-alert-challenges/modal-alert-challenges.component";
import { SocketQuizPreviewComponent } from "../socket-quiz-preview/socket-quiz-preview.component";
import { ModalAjustesUsuarioComponent } from "../modal-ajustes-usuario/modal-ajustes-usuario.component";
import { TableInformeComponent } from "src/app/pages/table-informe/table-informe.component";
import { InfoEstudianteComponent } from "../info-estudiante/info-estudiante.component";
import { TableInformeIndividualComponent } from "src/app/pages/table-informe-individual/table-informe-individual.component";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { loggedInUser } from "src/app/store/models/user.model";
import { State } from "src/app/store/models/store.model";
import { QuizzesService } from "src/app/core/services/quizzes";
import { RecordarQuizPlayComponent } from "../quiz-open/quiz-play/quiz-play.component";
import { RecordarQuizPlayMultipleComponent } from "../quiz-open/quiz-play-multiple/quiz-play-multiple.component";
import { ModalAlertFlashComponent } from "./modal-alert-flash/modal-alert-flash.component";
import { InactivityService } from "src/app/core/services/inactivity/inactivity.service";
import { AppVersions } from "src/app/core/models/masters/masters.enum";
import { MeetingsListComponent } from "src/app/pages/meetings-list/meetings-list.component";
import { ChallengesLobbyComponent } from "../challenges-lobby/challenges-lobby.component";
import { LocalStorage } from "src/app/core/utils";
import { ModalGruposEditarComponent } from "../modal-grupos-editar/modal-grupos-editar.component";
import { ModalAlertAsistenciaComponent } from "./modal-alert-asistencia/modal-alert-asistencia.component";
import { ModalAlertPensarCreandoComponent } from "./modal-alert-pensar-creando/modal-alert-pensar-creando.component";
import { ModalStructureComponent } from "../modal-structure/modal-structure.component";
import { ModalStudentWaitingComponent } from "../grupos/modal-student-waiting/modal-student-waiting.component";
import { NbDialogService, NbPopoverDirective, NbPosition, NbTrigger } from "@nebular/theme";
import { ModalSchedulesComponent } from "../modal-schedules/modal-schedules.component";
import { ControlPanelComponent } from '../control-panel/control-panel.component';
import { InformesCentroComponent } from "../informes-centro/informes-centro.component";
import { CalendarEvent, CalendarView, DAYS_OF_WEEK } from "angular-calendar";
import {
	startOfDay,
	endOfDay,
	subDays,
	addDays,
	endOfMonth,
	isSameDay,
	isSameMonth,
	addHours,
} from "date-fns";
import { EventColor } from "calendar-utils";
import { SurveysService } from "src/app/core/services/surveys/surveys.service";
import { MatomoAnalyticsUtils } from "src/app/core/utils/matomo-analytics.utils";
import { SurveysDetailsComponent } from "../surveys/surveys-details/surveys-details.component";
import { SurveysAnswerComponent } from "../surveys/surveys-answer/surveys-answer.component";
import { ModalCreateStructureComponent } from "../modal-create-structure/modal-create-structure.component";
import { ModalTutorialManagerComponent } from "../modal-tutorial-manager/modal-tutorial-manager.component";
interface NavigatorWithConnection extends Navigator {
	connection?: {
		downlink: number;
	};
}

const colors: Record<string, EventColor> = {
	red: {
		primary: "#dc1431",
		secondary: "#d04545",
	},
	blue: {
		primary: "#fcec00",
		secondary: "#000080",
	},
	green: {
		primary: "#00a650",
		secondary: "#9cce04",
	},
};

@Component({
	selector: "app-modal-profile-actions",
	templateUrl: "./modal-profile-actions.component.html",
	styleUrls: ["./modal-profile-actions.component.scss"],
})
export class ModalProfileActionsComponent implements OnInit, DoCheck {
	@ViewChild("calendarTempalte", { read: TemplateRef })
	calendarTemplate: TemplateRef<any>;
	@ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

	//calendarTemplate: TemplateRef<any>;
	profile: any;
	user: User;
	profileSelected: Profiles;
	profileTitle: string;

	numberOfInvited = 0;
	invitacionesList: any;
	cargando = true;
	selectedGroupInfo: any;
	pendingSelectGroup: boolean;
	private subscriptions: Subscription[] = [];
	notificationCount: number = 0;
	idGrupo: number;
	isEditor: boolean = false;
	showActInst = false;
	ModoAuto: any;
	isMobile: boolean = false;

	private destroy$ = new Subject();
	private stopSocketInstantActivity$ = new Subject();

	profileTitles = {
		ESTUDIANTE: "Estudiante",
		AUTOR: "Editor",
		PROFESOR: "Profesor",
		PADRE: "Padre",
		ADMIN: "Administrador",
		CENTRO: "Centro",
	};
	disableInstantActivityButton: boolean = false;
	versionAPI: string = AppVersions.API;
	realVersionAPI: string;
	versionAPIisCorrect: boolean = true;
	networkSpeed = 1;
	gameIsAlreadyStarted: boolean = false;
	disableActivityButton: boolean = false;

	intervalId: NodeJS.Timeout;
	listeningActivitiesSubscription: Subscription;

	claseInvertida: boolean = false;
	noDataUser: boolean = false;
	noDataTooltipMsg: string = "";
	infoTitle: string = "";
	infoText: string = "";

	usuariosOnline: any[] = [];
	usuariosOffline: any[] = [];
	offlineNames: any[] = [];
	usersInGroup: any[];
	onlineUsers: number = 0;
	usersInGroupCounter: number = 0;
	viewDate = new Date();
	locale: string = "es";
	weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
	weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
	view: CalendarView = CalendarView.Month;
	CalendarView = CalendarView;
	excludeDays: number[] = [0, 6]; // Exclude Sunday (0) and Saturday (6)

	trigger = NbTrigger.CLICK;
	position = NbPosition.BOTTOM;
	gruposIdList: any[];
	professorEvents: CalendarEvent[] = [];
	private isFetchingEvents: boolean = false;
	userManuallyClosedSockectWindow = false;
	socketQuizPreviewIsOpen: boolean = false;
	modalAcceptOrDeclineIsOpen: boolean = false;
	sockeOnlineUsers: number = 0;

	constructor(
		public activeModal: NgbActiveModal,
		private MastersService: MastersService,
		public translateService: TranslateService,
		private modalService: NgbModal,
		private groupService: GruposService,
		private toaster: ToasterService,
		private socketService: SocketService,
		public loginService: LoginService,
		public userService: UsersService,
		private store: Store<State>,
		private quizService: QuizzesService,
		private inactivityService: InactivityService,
		private localStorage: LocalStorage,
		private mastersService: MastersService,
		private dialogService: NbDialogService,
		public surveysService: SurveysService,
		private ma: MatomoAnalyticsUtils,
		private masterService: MastersService,
	) {
		//this.inactivityService.startInactivityTimer();
	}
	// Escuchar eventos de actividad en el componente y reiniciar el temporizador.
	@HostListener("document:click", ["$event"])
	//@HostListener('document:mousemove', ['$event'])
	@HostListener("document:keypress", ["$event"])
	onActivity(event: MouseEvent | KeyboardEvent) {
		this.inactivityService.resetInactivityTimer();
	}
	ngOnInit() {
		this.subscriptions.forEach((s) => s.unsubscribe());
		//MIRAMOS QUE LA VERSION DE LA API CORRESPONDA A LA ULTIMA. SI NO ES ASI OBLIGAMOS A RECARGAR LA PAGINA
		this.loginService.getApiVersion().subscribe((res) => {
			this.realVersionAPI = res.data;
			this.versionAPIisCorrect =
				this.realVersionAPI === this.versionAPI ? true : false;
		});
		this.user = this.loginService.getUser();
		this.store
			.select((store) => store.selectedProfile)
			.pipe(takeUntil(this.destroy$))
			.subscribe((selectedProfile) => {
				//this.profile = selectedProfile["selectedProfile"];
				this.profile = this.loginService.getProfile();
				this.profileTitle = this.profileTitles[this.profile];
			});
		this._isEditor();
		this.setSelectedGroupInfo();
		this.idGrupo = this.selectedGroupInfo
			? this.selectedGroupInfo.idGroup
			: null;

		if(this.selectedGroupInfo){
			if(this.loginService.esEstudiante()){
				this.handleSocketConnectionWhenGroupSelected();
			}

		}
		//obtenemos la velocidad de conexion
		this.networkSpeed = this.getNetworkSpeed();
		this.getIfUserHasData();
		this.noDataTooltipMsg = this.translateService.instant(
			"MENUACTIONS.REGISTERTOOLTIPMSG"
		);
		this.localStorage.setItem("conectionVelocity", this.networkSpeed);
		this.socketService.getacompetitionStarted().subscribe((res) => {
			this.gameIsAlreadyStarted = res;
		});
		this.localStorage.removeItem("modePractice");
		//this.initSocketToListenFroInstantActivity();
		//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
		if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
			this.llamadoAsistencia();
		}

		this.gruposIdList = [];
    this.groupService
			.getGrupos()
			.pipe(take(1))
			.subscribe((res) => {
				this.gruposIdList = res.data.map((grupo) => grupo.idGroup);
				this.fetchEventsProfesor();
			});

		this.startInterval();

		this.isMobile = window.innerWidth < 1200;
		//definimos variable para tutoriales
		let idTutorialContext = 0;
		switch (this.profile) {
			case 'PROFESOR':
				idTutorialContext = 5;
				break;
			case 'ESTUDIANTE':
				idTutorialContext = 51;
				break;
			case 'AUTOR':
				idTutorialContext = 83;
				break;
			default:
				break;
		}
		this.localStorage.setItem("idTutorialContext", idTutorialContext);
		if(this.localStorage.getItem("TutorialesON") == "true"){
			this.masterService.openModalTutorial(idTutorialContext);
		}
	}

	fetchEventsProfesor(idGroup?) {
		if (this.isFetchingEvents) {
			return;
		}

		this.isFetchingEvents = true;

		this.groupService.getEventsProfesor(idGroup? idGroup : this.gruposIdList).subscribe(
			(res) => {
				this.countEventsInMonth(res.data);
				this.fillEventsWithPulledEvents(res.data);
				this.isFetchingEvents = false;
			},
			(error) => {
				console.error("Error fetching events:", error);
				this.isFetchingEvents = false;
			}
		);
	}

	countEventsInMonth(events) {
		this.notificationCount = 0;
		const today = new Date();
		const currentMonth = today.getMonth();
		const currentYear = today.getFullYear();

		events.forEach((event) => {
			const start = new Date(event.fecha_Inicio);
			const end = new Date(event.fecha_Fin);

			// Check if the event starts or ends in the current month and year
			if (
				(start.getMonth() === currentMonth && start.getFullYear() === currentYear) ||
				(end.getMonth() === currentMonth && end.getFullYear() === currentYear)
			) {
				this.notificationCount++;
			}
		});
	}

	fillEventsWithPulledEvents(events) {
		this.professorEvents = events.map((event) => {
			return {
				id: event.idEvent,
				level: event.nivel,
				idGroup: event.idGroup,
				idCourse: event.idCourse,
				idSubject: event.idSubject,
				idTarget: event.idTarget,
				idSurvey: event.idSurvey,
				realizado: event.realizado ? 1 : 0,
				idUser: event.idUser,
				start: addDays(new Date(event.fecha_Inicio), 1),
				end: addDays(new Date(event.fecha_Fin), 1),
				title: event.name,
				color:
					event.colour == "rojo"
						? colors.red
						: event.colour == "azul"
						? colors.blue
						: colors.green,
				actions: null,
				resizable: {
					beforeStart: true,
					afterEnd: true,
				},
				draggable: true,
			};
		});
	}

	private startInterval(): void {
		if (this.selectedGroupInfo && this.profile === "PROFESOR") {
			this.intervalId = setInterval(() => {
				this.groupService
					.getListEstudiantes(this.selectedGroupInfo?.idGroup)
					.subscribe((members) => {
						this.usersInGroupCounter = members.data.length;
						this.usersInGroup = members.data;
					});

				this.groupService.getClientsInRoom(this.selectedGroupInfo?.idGroup).subscribe((res) => {
					this.usuariosOnline = res.data;

					if (this.usersInGroupCounter > 0) {
						this.onlineUsers = this.usuariosOnline.length;
						// Remove the students from offline users array
						this.usuariosOffline = this.usersInGroup.filter(
							(user) => !this.usuariosOnline.includes(user.idUser.toString())
						);
						// this.usuariosOnline is an array of this.usersInGroup but
						// only with the users that are in this.onlineUsers
						this.usuariosOnline = this.usersInGroup.filter(
							(user) => this.usuariosOnline.includes(user.idUser.toString())
						);
					}
				}
				);


			}, 2000);
		}
	}

	private clearInterval(): void {
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
	}

	prefillOfflineUsers() {
		if (this.usuariosOffline.length == 0) {
			this.usersInGroup.forEach((usuario: any) => {
				// Agregar el usuario al array correspondiente
				this.offlineNames.push(`${usuario.firstName} ${usuario.surname}`);
				this.usuariosOffline.push(usuario);
			});
		}
	}

	/*
	*** SE HA DESACTIVADO PORQUE DA PROBLEMAS AL INICIAR. A VECES DICE QUE HAY DOS USUARIOS ONLINE CUANDO NO ES ASI
			DICE QUE EL PROFE HA INICIADO UNA ACTIVIDAD SIENDO ESTO FALSO.
			QUEDA PENDIENTE POR REVISAR 24-may-2024
	***
	initSocketToListenFroInstantActivity() {
		this.stopSocketInstantActivity$ = new Subject();
		if (this.selectedGroupInfo != null && this.profile === "ESTUDIANTE") {
			this.listeningActivitiesSubscription =
				this.socketService.msgFromServer.subscribe((data) => {
					//Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
					const dataArray = data.split(":");
					const type = dataArray[0];
					const onlineUsers: number = parseInt(dataArray[1]);
					switch (type) {
						case SOCKETMESSAGES.USERSCOUNTER:
							if (this.loginService.esEstudiante() && onlineUsers == 2) {
								// informamos que hay una actividad activa para este grupo, y preguntamos si se quiere unir
								const modalRef = this.modalService.open(
									ModalAceptarCancelarComponent,
									{
										scrollable: true,
										windowClass: MODAL_DIALOG_TYPES.W30,
									}
								);
								modalRef.componentInstance.disableCancel = false;
								modalRef.componentInstance.groupSelected = this
									.selectedGroupInfo
									? true
									: false;

								modalRef.componentInstance.optionalTitle =
									"Actividad instantánea en curso.";

								modalRef.componentInstance.mensaje =
									"¿Deseas unirte a la actividad instantánea?";

								modalRef.result.then((result: any) => {
									if (result === true) {
										this.instantActivityStudent(true);
										this.stopSocketToListenFroInstantActivity();
									} else {
										this.stopSocketToListenFroInstantActivity();
									}
								});
							}
							break;
					}
				});
			this.socketService.createSocket(this.selectedGroupInfo.idGroup); //Creo el socket
		}
	}
	*/

	stopSocketToListenFroInstantActivity() {
		if (this.listeningActivitiesSubscription) {
			this.listeningActivitiesSubscription.unsubscribe();
			this.listeningActivitiesSubscription = null;
			this.socketService.removeSocket();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
		this.clearInterval();
	}

	ngDoCheck(): void {
		//Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
		//Add 'implements DoCheck' to the class.
		this.setSelectedGroupInfo();
	}

	getNetworkSpeed() {
		const navigatorWithConnection = navigator as NavigatorWithConnection;
		if (navigatorWithConnection.connection) {
			return navigatorWithConnection.connection.downlink;
		} else {
			return 10; // No se pudo obtener la velocidad de conexión
		}
	}

	getIfUserHasData() {
		if (this.user.mail === "" || this.user.mail === null) {
			this.noDataUser = true;
		}
	}

	_isEditor() {
		this.userService.getListUserProfile().subscribe((res) => {
			let profiles = res.data;
			for (let i = 0; i < profiles.length; i++) {
				if (profiles[i].idProfile === 1) {
					this.isEditor = true;
					break;
				}
			}
		});
	}

	checkSelectedGroup(moduleToOpen?: string, fromInforme?: boolean) {
		this.setSelectedGroupInfo();
		if (this.selectedGroupInfo) {
			this.pendingSelectGroup = false;
		} else {
			const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30,
			});
			modalRef.componentInstance.disableCancel = true;
			modalRef.componentInstance.groupSelected = this.selectedGroupInfo
				? true
				: false;
			modalRef.componentInstance.fromInforme = fromInforme;

			modalRef.componentInstance.optionalTitle =
				this.profile === "ESTUDIANTE"
					? this.translateService.instant(
							"ALERTCHALLENGENODE.SELECTGROUPTITLEESU"
					  )
					: this.translateService.instant(
							"ALERTCHALLENGENODE.SELECTGROUPTITLE"
					  );

			modalRef.componentInstance.mensaje =
				this.profile === "ESTUDIANTE"
					? this.translateService.instant(
							"ALERTCHALLENGENODE.SELECTGROUPMESSAGESTUDENTE"
					  )
					: this.translateService.instant(
							"ALERTCHALLENGENODE.SELECTGROUPMESSAGE"
					  );

			modalRef.result.then((result: any) => {
				this.disableInstantActivityButton = false;
				this.disableActivityButton = false;
				if (result === true) {
					this.MastersService.openGroups(this.profile);
				} else if (result === "sinGrupo") {
					if (moduleToOpen === "Challenges") {
						const modalRef = this.modalService.open(ChallengesLobbyComponent, {
							scrollable: false,
							windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
						});
					} else {
						const modalRef = this.modalService.open(ReportingComponent, {
							scrollable: true,
							windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
						});
						modalRef.componentInstance.group = this.selectedGroupInfo.group;
					}
				}
			});
			this.pendingSelectGroup = true;
		}
	}

	setSelectedGroupInfo() {
		let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		if (storedGroupInfo) {
			if (storedGroupInfo.idUser != this.user.idUser) {
				localStorage.removeItem("selectedGroupInfo");
			}
		}
		this.selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);

	}

	closeModal(sendData?: any) {
		//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
		if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
			this.llamadoAsistencia();
		}
		this.destroy$.next(true);
		this.activeModal.close(sendData);
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
	}

	goModule(module: string) {
		//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
		if (
			this.loginService.esEstudiante() &&
			this.selectedGroupInfo &&
			module != "edit"
		) {
			this.llamadoAsistencia();
		}
		localStorage.removeItem("challengeOn"); // eliminamos la variable que indica si estamos en un desafio
		switch (module) {
			case "groups":
				//this._removeSocket(this.selectedGroupInfo?.idGroup);
				//this.MastersService.openGroups(this.profile);
				const modalGrupos = this.modalService.open(ModalGruposEditarComponent, {
					windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
				});
				this.stopSocketToListenFroInstantActivity();
				this.clearInterval()
				modalGrupos.result.then((result) => {
					this.setSelectedGroupInfo();

					if(this.loginService.esProfesor()){
						if(this.selectedGroupInfo){
							this.fetchEventsProfesor([this.selectedGroupInfo?.idGroup]);
						}else{
							this.fetchEventsProfesor();
						}

					}
					//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
					if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
						this.llamadoAsistencia();
					}
					//this.initSocketToListenFroInstantActivity();
					this.handleSocketConnectionWhenGroupSelected();
					this.startInterval();




				});
				//Creamos el log
				const bodyLog1 = {
					action: "grupos",
					actionData: "Ha entrado en el menú de Grupos.",
					startDate: new Date(),
					endingDate: null,
					role: this.loginService.getProfile(),
					idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
				};
				this.mastersService
					.createActivityHistory(
						bodyLog1.action,
						bodyLog1.actionData,
						bodyLog1.startDate,
						bodyLog1.endingDate,
						bodyLog1.role,
						bodyLog1.idGroup
					)
					.subscribe();
				break;
			case "projects":
				if (this.profile === "ESTUDIANTE") {
					this.checkSelectedGroup();
					if (this.pendingSelectGroup) {
						return;
					} else{
						this.MastersService.verListadoCursos(
							null,
							Profiles.Student,
							JSON.parse(localStorage.getItem("selectedGroupInfo")),
							"explorar"
						);
					}
				} else {
					this.profile === "AUTOR"
						? localStorage.setItem("fromEditor", "true")
						: localStorage.removeItem("fromEditor");
					this.MastersService.verListadoCursos(
						null,
						Profiles.Teacher,
						0, //se pone en cero para que siempre muestre todos los proyectos
						"explorar"
					);
				}
				//Creamos el log
				const bodyLog3 = {
					action: "proyectos",
					actionData: "Ha entrado en el menú de Proyectos.",
					startDate: new Date(),
					endingDate: null,
					role: this.loginService.getProfile(),
					idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
				};
				this.mastersService
					.createActivityHistory(
						bodyLog3.action,
						bodyLog3.actionData,
						bodyLog3.startDate,
						bodyLog3.endingDate,
						bodyLog3.role,
						bodyLog3.idGroup
					)
					.subscribe();
				break;
			case "settings":
				this.MastersService.openSettings(this.profile);
				//Creamos el log
				const bodyLog = {
					action: "ajustes",
					actionData: "Ha entrado en el menú de Ajustes.",
					startDate: new Date(),
					endingDate: null,
					role: this.loginService.getProfile(),
					idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
				};
				this.mastersService
					.createActivityHistory(
						bodyLog.action,
						bodyLog.actionData,
						bodyLog.startDate,
						bodyLog.endingDate,
						bodyLog.role,
						bodyLog.idGroup
					)
					.subscribe();
				break;
			case "feedbacks":
				//this.MastersService.informeEstudiante(this.profile);
				// const modalRef = this.modalService.open(InfoEstudianteComponent, { scrollable: false, windowClass: MODAL_DIALOG_TYPES.W100 });
				// modalRef.componentInstance.idGrupo = this.selectedGroupInfo.idGroup;
				// modalRef.componentInstance.group = this.selectedGroupInfo.group;
				break;
			case "edit":
				//localStorage.setItem('dontLoadMenu', 'true'); //Este provoca que no se muestre el menú al cambiar de perfil --> REVISAR REY

				//=== ============================================================================= ===
				//=== el primero abre la version anterior, el segundo la nueva version (con modo lista) ===
				//this.MastersService.verListadoCursos("editar", Profiles.Author);
				this.MastersService.verListadoCursosEditor();
				//=== ============================================================================= ===

				this.closeModal(true);
				//Creamos el log
				let info = this.profile == "PROFESOR" ? "Crear Contenido." : "Editar.";
				const bodyLog2 = {
					action: this.profile == "PROFESOR" ? "crear contenido" : "editar",
					actionData: "Ha entrado en el menú de " + info,
					startDate: new Date(),
					endingDate: null,
					role: this.loginService.getProfile(),
					idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
				};
				this.mastersService
					.createActivityHistory(
						bodyLog2.action,
						bodyLog2.actionData,
						bodyLog2.startDate,
						bodyLog2.endingDate,
						bodyLog2.role,
						bodyLog2.idGroup
					)
					.subscribe();
				break;
			case "new":
				this.MastersService.nuevoCurso(Profiles.Author);
				this.closeModal(true);
				//Creamos el log
				const bodyLog5 = {
					action: "nuevo proyecto",
					actionData: "Ha entrado en el menú de Nuevo Proyecto.",
					startDate: new Date(),
					endingDate: null,
					role: this.loginService.getProfile(),
					idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
				};
				this.mastersService
					.createActivityHistory(
						bodyLog5.action,
						bodyLog5.actionData,
						bodyLog5.startDate,
						bodyLog5.endingDate,
						bodyLog5.role,
						bodyLog5.idGroup
					)
					.subscribe();
			case "actividadesFlash":
				this.MastersService.verListadoCursosActividadesFlash(
					null,
					this.profile
				);
				//Creamos el log
				const bodyLog4 = {
					action: "actividades flash",
					actionData: "Ha entrado en el menú de Actividades Flash.",
					startDate: new Date(),
					endingDate: null,
					role: this.loginService.getProfile(),
					idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
				};
				this.mastersService
					.createActivityHistory(
						bodyLog4.action,
						bodyLog4.actionData,
						bodyLog4.startDate,
						bodyLog4.endingDate,
						bodyLog4.role,
						bodyLog4.idGroup
					)
					.subscribe();
				break;
			case "encuestas":
				this.MastersService.verListadoEncuestas(Profiles.Author);
				this.closeModal(true);
				//Creamos el log
				const bodyLog6 = {
					action: "Encuestas",
					actionData: "Ha entrado en la pantalla de listado de encuestas",
					startDate: new Date(),
					endingDate: null,
					role: this.loginService.getProfile(),
					idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
				};
				this.mastersService
					.createActivityHistory(
						bodyLog6.action,
						bodyLog6.actionData,
						bodyLog6.startDate,
						bodyLog6.endingDate,
						bodyLog6.role,
						bodyLog6.idGroup
					)
					.subscribe();
				break;
		}
	}

	explorarContenido(){
		this.MastersService.verListadoCursos(
			null,
			Profiles.Student,
			0,
			"explorar"
		);
	}

	explicacionAsociativa() {
		this.profile === "AUTOR"
			? localStorage.setItem("fromEditor", "true")
			: localStorage.removeItem("fromEditor");
		this.MastersService.verListadoCursos(
			null,
			Profiles.Teacher,
			//this.idGrupo, **se pone a cero el idGroup para que traiga todo
			0,
			"explicacionAsociativa"
		);
		//Creamos el log
		const bodyLog = {
			action: "explicacion asociativa",
			actionData: "Ha entrado en el menú de Explicacion Asociativa.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	ABPyCasos() {
		this.profile === "AUTOR"
			? localStorage.setItem("fromEditor", "true")
			: localStorage.removeItem("fromEditor");
		this.MastersService.verListadoCursos(
			null,
			Profiles.Teacher,
			//this.idGrupo, **se pone a cero el idGroup para que traiga todo
			0,
			"abpCasos"
		);
		//Creamos el log
		const bodyLog = {
			action: "abp",
			actionData: "Ha entrado en el menú de ABP y Casos.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	pensarCreando() {
		//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
		if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
			this.llamadoAsistencia();
		}
		this.checkSelectedGroup();
		if (this.pendingSelectGroup) {
			return;
		} else if (this.loginService.esProfesor()) {
			const modalRef = this.modalService.open(
				ModalAlertPensarCreandoComponent,
				{
					scrollable: true,
					windowClass: MODAL_DIALOG_TYPES.W30,
				}
			);
			modalRef.componentInstance.idGrupo = this.selectedGroupInfo.idGroup;
			modalRef.componentInstance.group = this.selectedGroupInfo.group;
			modalRef.result.then((result: string) => {
				if (result == "crear") {
					this.profile === "AUTOR"
						? localStorage.setItem("fromEditor", "true")
						: localStorage.removeItem("fromEditor");
					this.MastersService.verListadoCursos(
						null,
						Profiles.Teacher,
						//this.idGrupo, **se pone a cero el idGroup para que traiga todo
						0,
						"pensarCreando"
					);
				} else if (result == "ver") {
					this.profile === "AUTOR"
						? localStorage.setItem("fromEditor", "true")
						: localStorage.removeItem("fromEditor");
					this.MastersService.verListadoCursos(
						null,
						Profiles.Teacher,
						//this.idGrupo, **se pone a cero el idGroup para que traiga todo
						0,
						"pensarCreando(ver)"
					);
				}
			});
		} else if (this.loginService.esEstudiante()) {
			this.profile === "AUTOR"
				? localStorage.setItem("fromEditor", "true")
				: localStorage.removeItem("fromEditor");
			this.MastersService.verListadoCursos(
				null,
				Profiles.Student,
				//this.idGrupo, **se pone a cero el idGroup para que traiga todo
				0,
				"pensarCreando(ver)"
			);
		}
		//Creamos el log
		const bodyLog = {
			action: "pensar creando",
			actionData: "Ha entrado en el menú de Pensar Creando.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	practicar() {
		this.localStorage.setItem("modePractice", "true");
		this.profile === "AUTOR"
			? localStorage.setItem("fromEditor", "true")
			: localStorage.removeItem("fromEditor");
		this.MastersService.verListadoCursos(
			null,
			Profiles.Teacher,
			//this.idGrupo, **se pone a cero el idGroup para que traiga todo
			0,
			"practicar"
		);
		//Creamos el log
		const bodyLog = {
			action: "practicar",
			actionData: "Ha entrado en el menú de Practicar.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	pruebaNivel() {
		this.localStorage.setItem("modePractice", "true");
		this.profile === "AUTOR"
			? localStorage.setItem("fromEditor", "true")
			: localStorage.removeItem("fromEditor");
		this.MastersService.verListadoCursos(
			null,
			Profiles.Teacher,
			//this.idGrupo, **se pone a cero el idGroup para que traiga todo
			0,
			"pruebaNivel"
		);
		//Creamos el log
		const bodyLog = {
			action: "prueba nivel",
			actionData: "Ha entrado en el menú de Prueba Nivel.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	listadoAlumnos() {
		this.checkSelectedGroup("listadoAlumnos");
		if (this.pendingSelectGroup) {
			return;
		} else {
			this.setSelectedGroupInfo();
			const modalRef = this.modalService.open(
				ModalListadoEstudiantesComponent,
				{ scrollable: false, windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` }
			);
			modalRef.componentInstance.id = this.selectedGroupInfo.idGroup;
			modalRef.componentInstance.group = this.selectedGroupInfo.group;
		}
		//Creamos el log
		const bodyLog = {
			action: "asignar estudiantes",
			actionData: "Ha entrado en el menú de Asignar Estudiantes.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	listadoCursos() {
		this.checkSelectedGroup();
		if (this.pendingSelectGroup) {
			return;
		} else {
			this.setSelectedGroupInfo();
			const modalRef = this.modalService.open(ModalListadoCursosComponent, {
				scrollable: false,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			});
			modalRef.componentInstance.id = this.selectedGroupInfo.idGroup;
			modalRef.componentInstance.group = this.selectedGroupInfo.group;

			modalRef.result.then((result) => {
				if (result) {
					this.listadoAlumnos();
				}
			});
		}
		//Creamos el log
		const bodyLog = {
			action: "asignar proyectos",
			actionData: "Ha entrado en el menú de Asignar Proyectos.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	informesStudentGrupo() {
		//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
		if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
			this.llamadoAsistencia();
		}
		this.checkSelectedGroup("", true);
		if (this.pendingSelectGroup) {
			return;
		} else {
			this.setSelectedGroupInfo();
			const modalRef = this.modalService.open(ReportingComponent, {
				scrollable: false,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			});
			modalRef.componentInstance.idGrupo = this.selectedGroupInfo.idGroup;
			modalRef.componentInstance.group = this.selectedGroupInfo.group;
		}
		//Creamos el log
		const bodyLog = {
			action: "informes",
			actionData: "Ha entrado en el menú de Informes Guia.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	informesGrupo(claseInvertida?: boolean) {
		this.claseInvertida = claseInvertida; //true para clase invertida, false para informes
		this.checkSelectedGroup();
		if (this.pendingSelectGroup) {
			return;
		}
		if (
			this.selectedGroupInfo.assignedProjects === 0 ||
			this.selectedGroupInfo.assignedStudents === 0
		) {
			let modal: NgbModalRef;
			modal = this.modalService.open(ModalAlertChallengesComponent, {
				scrollable: true,
				windowClass: `${MODAL_DIALOG_TYPES.W30}`,
			});
			modal.componentInstance.assignedProjects =
				this.selectedGroupInfo.assignedProjects;
			modal.componentInstance.assignedStudents =
				this.selectedGroupInfo.assignedStudents;
			modal.componentInstance.action = "report";
			modal.componentInstance.group = this.selectedGroupInfo.group;
			modal.result.then(
				(result) => {
					switch (result) {
						case 1:
							this.listadoCursos();
							break;
						case 2:
							this.listadoCursos();
							break;
						case 3:
							this.listadoAlumnos();
							break;
					}
				},
				(reason) => {}
			);
		} else {
			this.checkSelectedGroup();
			if (this.pendingSelectGroup) {
				return;
			} else {
				this.setSelectedGroupInfo();
				const modalRef = this.modalService.open(ReportingComponent, {
					scrollable: false,
					windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
				});
				modalRef.componentInstance.idGrupo = this.selectedGroupInfo.idGroup;
				modalRef.componentInstance.group = this.selectedGroupInfo.group;
				modalRef.componentInstance.claseInvertida = this.claseInvertida;
			}
		}
		//Creamos el log
		let info = claseInvertida ? "Clase Invertida." : "Informe Guia.";
		const bodyLog = {
			action: claseInvertida ? "clase invertida" : "informes",
			actionData: "Ha entrado en el menú de " + info,
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	asistenciaGrupo() {
		this.checkSelectedGroup();
		if (this.pendingSelectGroup) {
			return;
		} else {
			const modalRef = this.modalService.open(ModalAlertAsistenciaComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30,
			});
			modalRef.componentInstance.idGrupo = this.selectedGroupInfo.idGroup;
			modalRef.componentInstance.group = this.selectedGroupInfo.group;
		}
		//Creamos el log
		const bodyLog = {
			action: "asistencia",
			actionData: "Ha entrado en el menú de Asistencia.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	llamadoAsistencia() {
		this.groupService
			.receiveCallAttendance(this.selectedGroupInfo.idGroup, 2)
			.subscribe((resp) => {
				if (resp.data != null && resp.data[0].status == 1) {
					const modalRef = this.modalService.open(
						ModalAlertAsistenciaComponent,
						{
							scrollable: true,
							windowClass: MODAL_DIALOG_TYPES.W30,
						}
					);
					modalRef.componentInstance.idGrupo = this.selectedGroupInfo.idGroup;
					modalRef.componentInstance.group = this.selectedGroupInfo.group;
					modalRef.componentInstance.fechaLlamada = resp.data[0].fecha;
				}
			});
	}

	ajustes() {
		//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
		if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
			this.llamadoAsistencia();
		}
		this.modalService.open(ModalAjustesUsuarioComponent, {
			scrollable: true,
			windowClass: `${MODAL_DIALOG_TYPES.W90}`,
		});
		//Creamos el log
		let info = this.profile == "PADRE" ? "Añadir Hijo." : "Ajustes.";
		const bodyLog = {
			action: this.profile == "PADRE" ? "añadir hijo" : "ajustes",
			actionData: "Ha entrado en el menú de " + info,
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	informesPadre() {
		//obtenemos el listado de hijos
		this.userService.getChildrenListByParent().subscribe((res) => {
			if (res.data.length > 0) {
				const modalRef = this.modalService.open(ReportingComponent, {
					scrollable: false,
					windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
				});
				modalRef.componentInstance.idGrupo = 0;
				modalRef.componentInstance.childrenList = res.data;
			} else {
				this.toaster.error(
					this.translateService.instant("USERDATA.NOHAYNINGUNHIJOREGISTRADO")
				);
			}
		});
		//Creamos el log
		const bodyLog = {
			action: "informes",
			actionData: "Ha entrado en el menú de Informes Guia.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	instantActivityStudent(cameFromInterval?: boolean): void {
		//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
		if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
			this.llamadoAsistencia();
		}
		if (!cameFromInterval) {
			this.disableActivityButton = true;
		}
		this.checkSelectedGroup();
		if (this.pendingSelectGroup) {
			return;
		}
		if (
			this.selectedGroupInfo.assignedProjects === 0 &&
			!this.loginService.esEstudiante
		) {
			this.toaster.error(
				this.translateService.instant("INFORMES.WITHOUTPROJECTS")
			);
		} else {
			console.log("selectedGroupInfo", this.selectedGroupInfo);
			this.cargando = false;
			//Opción para crear actividades instantáneas. Si es un profesor, se debe crear; si es un estudiante, tiene que poder entrar para conectarse con websocket
				//Si es estudiante, tengo que sacar el modal para esperar los movimientos del websocket
				let modal: NgbModalRef;
				if (this.loginService.esEstudiante()) {
					//Si es estudiante, debo abrir el modal de espera para que se vayan abriendo los quizzes según decida el profesor
					this.groupService
						.getIfSessionExists(this.selectedGroupInfo.idGroup)
						.pipe(takeUntil(this.destroy$))
						.subscribe((result) => {
							this.cargando = false;
							this.showActInst = result.data.existSesion;
							this.groupService.getGroupSession(this.selectedGroupInfo.idGroup).subscribe((session) => {
								let usersOfSession = session.data;
								let teacherInSession = null;
								if(usersOfSession.length){
									teacherInSession = usersOfSession.find((user) => user.isTeacher == true);
								}

								if(teacherInSession){
									console.log("teacher in session", teacherInSession);
									this.socketService.idSession = teacherInSession.idSession;
								this.groupService
									.getIfSessionExistsByUser(
										this.selectedGroupInfo.idGroup
									)
									.pipe(takeUntil(this.destroy$), take(1))
									.subscribe((res) => {
										if (res.data) {
											console.log("user session exists", result.data);
											modal = this.modalService.open(
												SocketQuizPreviewComponent,
												{
													scrollable: false,
													windowClass: `${MODAL_DIALOG_TYPES.W100} h-100`,
												}
											);
											modal.componentInstance.idGroup =
												this.selectedGroupInfo.idGroup;
											modal.componentInstance.onlineUsers =
											this.sockeOnlineUsers;
											this.socketQuizPreviewIsOpen = true;
											this.socketService.setcompetitionStarted(
												true
											);
											modal.result
												.then(
													() => {
														if (result == 'manual') {

															this.userManuallyClosedSockectWindow = true;
															this.socketQuizPreviewIsOpen = false;
															this.gameIsAlreadyStarted = false;
														}
														this.disableActivityButton = false;
														this.socketService.setcompetitionStarted(
															false
														);

														//this.initSocketToListenFroInstantActivity();
													},
													(err) => {}
												)
												.finally(() => {});
										}else{
											this.groupService
												.createGroupSession(this.selectedGroupInfo.idGroup, null, false)
												.subscribe((result) => {
													console.log("user session created", result.data);
													let modal = this.modalService.open(SocketQuizPreviewComponent, {
														scrollable: false,
														windowClass: `${MODAL_DIALOG_TYPES.W100} h-100`,
													});
													modal.componentInstance.idGroup = this.selectedGroupInfo.idGroup;
													modal.componentInstance.onlineUsers = this.sockeOnlineUsers;

													this.socketQuizPreviewIsOpen = true;
													modal.result.then((result) => {
														this.disableActivityButton = false;
														if (result == 'manual') {
															this.gameIsAlreadyStarted = false;
															this.userManuallyClosedSockectWindow = true;
															this.socketQuizPreviewIsOpen = false;
														}
													});
												});
										}
									});
								}else{
									this.disableActivityButton = false;
									if (!this.gameIsAlreadyStarted) {
										this.disableActivityButton = false;
										if (!cameFromInterval) {
											this.toaster.error(
												this.translateService.instant(
													"INSTANTACTIVITIES.NOHAYDESAFIOS"
												)
											);
										}
									}
								}
							});





						});
				} else {
					modal = this.modalService.open(
						ModalSocketCoursesComponent,
						{
							scrollable: true,
							windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
						}
					); // Modal con el listado de cursos, para que el profesor pueda elegir los quizzes a realizar
					modal.componentInstance.group = this.selectedGroupInfo;
					modal.componentInstance.onlineUsers = this.sockeOnlineUsers;
					modal.result
						.then(
							() => {
								this.destroy$.next(true);
								this._removeSocket(this.selectedGroupInfo.idGroup);
							},
							(err) => {}
						)
						.finally(() => {});

				}

		}
		//Creamos el log
		const bodyLog = {
			action: "desafios",
			actionData: "Ha entrado en el menú de Desafios.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	private isModalOpen: boolean = false;

modalStudentWaiting(cameFromInterval?: boolean): void {
  if (this.isModalOpen) {
    return;
  }


  if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
    this.llamadoAsistencia();
  }

  this.checkSelectedGroup();

  if (this.pendingSelectGroup) {
    this.isModalOpen = false;
    return;
  }

  if (
    this.selectedGroupInfo.assignedProjects === 0 &&
    !this.loginService.esEstudiante()
  ) {
    this.toaster.error(
      this.translateService.instant("INFORMES.WITHOUTPROJECTS")
    );
    this.isModalOpen = false;
    return;
  }

  this.groupService
    .getIfSessionExists(this.selectedGroupInfo.idGroup)
    .pipe(takeUntil(this.destroy$), take(1))
    .subscribe(
      (result) => {
        this.cargando = false;
        this.showActInst = result.data.existSesion;
        const idGroup = this.selectedGroupInfo.idGroup;

        if (!this.socketService.isSocketConnected()) {
          this.socketService.createSocket(idGroup);
        }

        const msgSubs = this.socketService.msgFromServer
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            const dataArray = data.split(":");
            const type = dataArray[0];
            const onlineUsers: number = parseInt(dataArray[1]);

            if (type === SOCKETMESSAGES.USERSCOUNTER) {
              if (this.loginService.esEstudiante()) {
                this.cargando = false;
                this.showActInst = result.data.existSesion;
                this.groupService
                  .getIfSessionExistsByUser(this.selectedGroupInfo.idGroup)
                  .pipe(takeUntil(this.destroy$), take(1))
                  .subscribe((res) => {
                    if (!res.data) {
                      if (!this.isModalOpen) {
                        const modalRef = this.modalService.open(
                          ModalStudentWaitingComponent,
                          {
                            scrollable: false,
                            windowClass: `${MODAL_DIALOG_TYPES.W100} h-100`,
                          }
                        );
												this.isModalOpen = true;
                        modalRef.componentInstance.idGroup =
                          this.selectedGroupInfo.idGroup;
                        modalRef.componentInstance.onlineUsers = onlineUsers;

                        modalRef.result
                          .then(
                            () => {
                              this.disableActivityButton = false;
                              this.socketService.setcompetitionStarted(false);
                              this.destroy$.next(true);
                              this._removeSocket(idGroup);
															this.isModalOpen = false;
                            },
                            (err) => {}
                          )
                          .finally(() => {
                            this.isModalOpen = false;
                          });
                      }
                    }
                  });
              }
            }
          });

        this.subscriptions.push(msgSubs);

        const openSocketSub = this.socketService.openSocket.subscribe(
          (ev) => {
            this.groupService
              .createGroupSession(idGroup, null, false)
              .subscribe(
                (result) => {
                  this.socketService.idSession = result.data.idSession;
                  openSocketSub.unsubscribe();
                },
                (err) => {
                  this.isModalOpen = false;
                }
              );
          }
        );

        this.subscriptions.push(openSocketSub);
      },
      (error) => {
        this.isModalOpen = false;
      }
    );

  const bodyLog = {
    action: "estoy en clase",
    actionData: "Ha entrado en el menú Estoy en Clase.",
    startDate: new Date(),
    endingDate: null,
    role: this.loginService.getProfile(),
    idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
  };
  this.mastersService
    .createActivityHistory(
      bodyLog.action,
      bodyLog.actionData,
      bodyLog.startDate,
      bodyLog.endingDate,
      bodyLog.role,
      bodyLog.idGroup
    )
    .subscribe();
}

	Competitions() {
		//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
		if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
			this.llamadoAsistencia();
		}
		this.stopSocketToListenFroInstantActivity();
		this.checkSelectedGroup("Challenges", true);
		if (this.pendingSelectGroup) {
			return;
		} else {
			//this.setSelectedGroupInfo();
			const modalRef = this.modalService.open(ChallengesLobbyComponent, {
				scrollable: false,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			});
			// modalRef.componentInstance.idGrupo = this.selectedGroupInfo.idGroup;
			modalRef.componentInstance.group = this.selectedGroupInfo.group;
			modalRef.result.then((result) => {
				if (result) {
					//this.listadoAlumnos();
				}
			});
		}
		//Creamos el log
		const bodyLog = {
			action: "competicion",
			actionData: "Ha entrado en el menú de Competicion.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	instantActivity(): void {
		this.disableInstantActivityButton = true;
		//ver si tiene grupo seleccionado si no abrir modal para seleccionar grupo
		this.checkSelectedGroup();
		if (this.pendingSelectGroup) {
			return;
		}
		if (
			this.selectedGroupInfo.assignedProjects === 0 ||
			this.selectedGroupInfo.assignedStudents === 0
		) {
			let modal: NgbModalRef;
			modal = this.modalService.open(ModalAlertChallengesComponent, {
				scrollable: true,
				windowClass: `${MODAL_DIALOG_TYPES.W30}`,
			});
			modal.componentInstance.assignedProjects =
				this.selectedGroupInfo.assignedProjects;
			modal.componentInstance.assignedStudents =
				this.selectedGroupInfo.assignedStudents;
			modal.componentInstance.action = "challenge";
			modal.componentInstance.group = this.selectedGroupInfo.group;
			modal.result.then(
				(result) => {
					switch (result) {
						case 1:
							this.listadoCursos();
							break;
						case 2:
							this.listadoCursos();
							break;
						case 3:
							this.listadoAlumnos();
							break;
					}
				},
				(reason) => {}
			);
		} else {
			this.checkSelectedGroup();
			if (this.pendingSelectGroup) {
				return;
			} else {
				this.setSelectedGroupInfo();
				let modal: NgbModalRef;
				const idGroup = this.selectedGroupInfo.idGroup;
				const msgSubs = this.socketService.msgFromServer.subscribe((data) => {
					//Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
					const dataArray = data.split(":");
					const type = dataArray[0];
					const onlineUsers: number = parseInt(dataArray[1]);

					switch (type) {
						case SOCKETMESSAGES.USERSCOUNTER:
							modal = this.modalService.open(ModalSocketCoursesComponent, {
								scrollable: true,
								windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
							}); // Modal con el listado de cursos, para que el profesor pueda elegir los quizzes a realizar

							modal.componentInstance.group = this.selectedGroupInfo.group;
							modal.componentInstance.onlineUsers = onlineUsers;
							modal.componentInstance.fromNode = false;
							modal.result
								.then(
									() => {},
									(err) => {}
								)
								.finally(() => {
									this.disableInstantActivityButton = false;
									this._removeSocket(idGroup);
								});
							msgSubs.unsubscribe();
							break;
					}
				});
				this.subscriptions.push(msgSubs);

				this.socketService.createSocket(idGroup); //Creo el socket

				const openSocketSub = this.socketService.openSocket.subscribe((ev) => {
					//Cuando se cree el socket, procedemos a guardar la sesion en el servidor

					this.groupService.createGroupSession(idGroup, null, true).subscribe(
						(result) => {
							if (result.data == null) {
								this.groupService
									.getIfSessionExists(this.selectedGroupInfo.idGroup)
									.pipe(takeUntil(this.destroy$), take(1))
									.subscribe((result) => {
										if (result.data.existSesion == true) {
											this.groupService
												.getGroupSession(this.selectedGroupInfo.idGroup)
												.subscribe((result) => {
													this.socketService.idSession =
														result.data[0].idSession;
													openSocketSub.unsubscribe();
												});
										}
									});
							} else {
								this.socketService.idSession = result.data.idSession;
								openSocketSub.unsubscribe();
							}
							//console.log("result jajajajajajajaja", result);
							//Si se guarda correctamente la sesion, tenemos que abrir el modal con los cursos en modo lista
						},
						(err) => {
							modal.close();
						}
					);
				});
				this.subscriptions.push(openSocketSub);
			}
		}
		let modal: NgbModalRef;
		const idGroup = this.selectedGroupInfo.idGroup;
		const msgSubs = this.socketService.msgFromServer.subscribe((data) => {
			//Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
			const dataArray = data.split(":");
			const type = dataArray[0];
			const onlineUsers: number = parseInt(dataArray[1]);
			switch (type) {
				case SOCKETMESSAGES.USERSCOUNTER:
					if (this.loginService.esEstudiante() && onlineUsers > 1) {
						//Si es estudiante, debo abrir el modal de espera para que se vayan abriendo los quizzes según decida el profesor
						this.groupService
							.getIfSessionExists(idGroup)
							.pipe(finalize(() => (this.cargando = false)))
							.subscribe((result) => {
								this.showActInst = result.data.existSesion;
							});
						if (this.showActInst && onlineUsers > 1) {
							modal = this.modalService.open(SocketQuizPreviewComponent, {
								scrollable: false,
								windowClass: `${MODAL_DIALOG_TYPES.W100} h-100`,
							});
							modal.componentInstance.idGroup = idGroup;
							modal.componentInstance.onlineUsers = onlineUsers;
							modal.result
								.then(
									() => {},
									(err) => {}
								)
								.finally(() => {
									this._removeSocket(idGroup);
								});
							msgSubs.unsubscribe();
							break;
						} else {
							this.toaster.error(
								this.translateService.instant("INSTANTACTIVITIES.NOHAYDESAFIOS")
							);
						}
						modal.componentInstance.onlineUsers = onlineUsers;
						modal.result
							.then(
								() => {},
								(err) => {}
							)
							.finally(() => {
								this._removeSocket(idGroup);
							});
						msgSubs.unsubscribe();
					}
					break;
			}
		});
		//COMENTO ESTO POR QUE NO SE QUE ESTABA HACIENDO AQUI PERO SE SOLUCIONA EL ERROR DE DOBLE SESSION EN LA TABLA GROUPSESSIONS
		// this.subscriptions.push(msgSubs)
		// this.socketService.createSocket(idGroup)	//Creo el socket
		// const openSocketSub = this.socketService.openSocket.subscribe(ev => { //Cuando se cree el socket, procedemos a guardar la sesion en el servidor
		// 	this.groupService.createGroupSession(idGroup).subscribe(result => { //Si se guarda correctamente la sesion, tenemos que abrir el modal con los cursos en modo lista
		// 		this.socketService.idSession = result.data.idSession
		// 		openSocketSub.unsubscribe()
		// 	}, err => {
		// 		//modal.close()
		// 	})
		// })
		// this.subscriptions.push(openSocketSub)
		//Creamos el log
		const bodyLog = {
			action: "desafios",
			actionData: "Ha entrado en el menú de Desafios.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	private _removeSocket(idGroup) {
		this.socketService.removeSocket(); //Si ha habido error al guardar la sesión, tenemos que destruir el socket y cerrar el modal
		this.groupService.deleteGroupSession(idGroup).subscribe(); //Elimino la sesión de la bd
		this.subscriptions.forEach((s) => s.unsubscribe()); //Elimino las suscripciones a eventos
	}

	openTable() {
		this.modalService.open(TableInformeComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
		//Creamos el log
		const bodyLog = {
			action: "globales",
			actionData: "Ha entrado en el menú de Globales.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	openTableIndividual() {
		this.modalService.open(TableInformeIndividualComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
		//Creamos el log
		const bodyLog = {
			action: "individuales",
			actionData: "Ha entrado en el menú de Individuales.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	openModalStructure() {
		this.modalService.open(ModalCreateStructureComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W40,
		});
		//Creamos el log
		const bodyLog = {
			action: "estructura",
			actionData: "Ha entrado en el menú de Estructura.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService.createActivityHistory(bodyLog.action, bodyLog.actionData, bodyLog.startDate, bodyLog.endingDate, bodyLog.role, bodyLog.idGroup).subscribe();
	}

	openModalTutorial() {
		this.modalService.open(ModalTutorialManagerComponent, {
			scrollable: true,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
	}

	openSchedules(){
		this.modalService.open(ModalSchedulesComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
	//Creamos el log
		const bodyLog = {
			action: "Programaciones",
			actionData: "Ha entrado en el menú de Programaciones.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService.createActivityHistory(bodyLog.action, bodyLog.actionData, bodyLog.startDate, bodyLog.endingDate, bodyLog.role, bodyLog.idGroup).subscribe();
	}

	noGroup() {
		//Creamos el log
		const bodyLog1 = {
			action: "grupos",
			actionData:
				"Ha salido del grupo con id " + this.selectedGroupInfo
					? this.selectedGroupInfo.idGroup
					: 0 + ".",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog1.action,
				bodyLog1.actionData,
				bodyLog1.startDate,
				bodyLog1.endingDate,
				bodyLog1.role,
				bodyLog1.idGroup
			)
			.subscribe();
		localStorage.removeItem("selectedGroupInfo");
		this.stopSocketToListenFroInstantActivity();
		this._removeSocket(this.selectedGroupInfo.idGroup);
		if(this.loginService.esProfesor){
			this.fetchEventsProfesor();
		}
	}

	sos() {
		//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
		if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
			this.llamadoAsistencia();
		}
		//this.windowService.open(MeetingsComponent, { title: `Window` });
		this.modalService.open(MeetingsListComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
		//Creamos el log
		const bodyLog = {
			action: "ayudar",
			actionData: "Ha entrado en el menú de Ayudar.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.selectedGroupInfo ? this.selectedGroupInfo.idGroup : 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	openInfo(dialog: TemplateRef<any>, option: number) {
		switch (option) {
			case 1:
				this.infoTitle = this.translateService.instant(
					"MENUACTIONS.TEACHERTITLE15"
				);
				this.infoText = this.translateService.instant(
					"INFOMENUPROFE.EXPLICACIONASOCIATIVA"
				);
				break;
			case 2:
				this.infoTitle = this.translateService.instant(
					"MENUACTIONS.TEACHERTITLE7"
				);
				this.infoText = this.translateService.instant("INFOMENUPROFE.DESAFIOS");
				break;
			case 3:
				this.infoTitle = this.translateService.instant(
					"MENUACTIONS.TEACHERTITLE16"
				);
				this.infoText = this.translateService.instant(
					"INFOMENUPROFE.CLASEINVERTIDA"
				);
				break;
			case 4:
				this.infoTitle = this.translateService.instant(
					"MENUACTIONS.TEACHERTITLE17"
				);
				this.infoText = this.translateService.instant(
					"INFOMENUPROFE.PRACTICAR"
				);
				break;
			case 5:
				this.infoTitle = this.translateService.instant(
					"MENUACTIONS.TEACHERTITLE18"
				);
				this.infoText = this.translateService.instant(
					"INFOMENUPROFE.PENSARCREANDO"
				);
				break;
			case 6:
				this.infoTitle = this.translateService.instant(
					"MENUACTIONS.TEACHERTITLE19"
				);
				this.infoText = this.translateService.instant("INFOMENUPROFE.ABP");
				break;
			case 7:
				this.infoTitle = this.translateService.instant(
					"MENUACTIONS.TEACHERTITLE21"
				);
				this.infoText = this.translateService.instant("INFOMENUPROFE.PRUEBA");
				break;
		}
		this.dialogService.open(dialog);
	}

	openInformeCentro() {
		this.modalService.open(InformesCentroComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
		//Creamos el log
		const bodyLog = {
			action: "informes",
			actionData: "Ha entrado en el menú de Informes.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	openControlPanel() {
		this.modalService.open(ControlPanelComponent, {
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
		//Creamos el log
		const bodyLog = {
			action: "mando de control",
			actionData: "Ha entrado en el mando de control.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	dayClicked(action: string, event?: any): void {
		if (this.popover) {
			this.popover.hide();
		}
		let modal = this.modalService.open(ModalSchedulesComponent, {
			windowClass: MODAL_DIALOG_TYPES.W90,
		});

		modal.componentInstance.cameWithEvents = true;
		modal.componentInstance.preLoadedEvents = this.professorEvents;
	}

	handleEvent(action: string, event?: any): void {
		if (action == "Clicked") {
			this.popover.hide();
			this.modalService.open(ModalSchedulesComponent, {
				windowClass: MODAL_DIALOG_TYPES.W90,
			});
		}

		// this.modalData = { this.editingEvent, action };
	}

	/**
	 * ESTO ES PROVISIONAL, AL TERMINAR LA TAREA DE LAS ENCUESTAS BORRAR ESTE CODIGO
	 * O DEJAR MENSAJE PARA SABER QUE ES PARA PRUEBAS
	 */
	pruebaEncuesta(){
		this.surveysService.getSurveyToAnswer(15).subscribe(res => {
			if(res.data){
				this.ma.event("click", "view_item", "Curso");
				const modalRef = this.modalService.open(SurveysAnswerComponent, {
					scrollable: false,
					windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
				});
				let idEvent = 1504;
				let anonymous = 0;
				modalRef.componentInstance.survey = res.data;
				modalRef.componentInstance.idEvent = idEvent; // identificador del evento
				modalRef.componentInstance.anonymous = anonymous; //si la encuesta es anonima
				modalRef.result.then(
					(resp) => {
						console.log(resp);
					},
					(err) => {}
				);
			}
		});
	}
	//***************************************************************************** */

	handleSocketConnectionWhenGroupSelected() {


		if (this.loginService.esEstudiante()) {
			this.cargando = false;

			// Sujeto a cambios
			this.socketService.createSocket(this.selectedGroupInfo.idGroup);

			this.socketService.setcompetitionStarted(false);


			this.groupService
				.getIfSessionExistsByUser(this.selectedGroupInfo.idGroup)
				.pipe(takeUntil(this.destroy$), take(1))
				.subscribe((res) => {
					if (res.data == false) {
						this.groupService
						.createGroupSession(this.selectedGroupInfo.idGroup, null, false)
						.pipe(takeUntil(this.destroy$) ,take(1))
						.subscribe((result) => {
							this.socketService.idSession = result.data.idSession;
							const msgSubs = this.socketService.msgFromServer
								.pipe(takeUntil(this.destroy$) ,debounceTime(300)) // Add debounce time of 300ms
								.subscribe((data) => {
									console.log('data socket', data);
									// Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
									const dataArray = data.split(':');
									let type = '';
									this.sockeOnlineUsers = 0;
									if (dataArray[0] == 'counter_users') {
										this.sockeOnlineUsers = parseInt(dataArray[1]);
									}

									if (dataArray[0] == 'MsgToRoomClients') {
										type = dataArray[1];
									} else {
										type = dataArray[0];
									}

									if (type === SOCKETMESSAGES.CLOSESOCKET) {
										this.socketQuizPreviewIsOpen = false;
										return;
									}

									if (type === SOCKETMESSAGES.CONTROLTOSTART) {
										this.modalAcceptOrDeclineIsOpen = false;

										let modal = this.modalService.open(SocketQuizPreviewComponent, {
											scrollable: false,
											windowClass: `${MODAL_DIALOG_TYPES.W100} h-100`,
										});
										modal.componentInstance.idGroup = this.selectedGroupInfo.idGroup;
										modal.componentInstance.onlineUsers = this.sockeOnlineUsers;

										this.socketQuizPreviewIsOpen = true;

										modal.result.then((result) => {
											if (result == 'manual') {
												this.userManuallyClosedSockectWindow = true;
												this.socketQuizPreviewIsOpen = false;
											}
										});

										this.socketService.setcompetitionStarted(true);
									}

									if (
										(type == 'act_about_to_start' &&
											this.socketQuizPreviewIsOpen == false &&
											this.modalAcceptOrDeclineIsOpen == false) ||
										((type == 'quiz_about_to_start' || type == 'order_mod_auto') &&
											this.socketQuizPreviewIsOpen == false &&
											this.modalAcceptOrDeclineIsOpen == false)
									) {
										if (this.loginService.esEstudiante() && this.modalAcceptOrDeclineIsOpen == false) {
											this.modalAcceptOrDeclineIsOpen = true;
											const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
												scrollable: true,
												windowClass: MODAL_DIALOG_TYPES.W30,
											});
											//this.modalAcceptOrDeclineIsOpen = true;
											let modalMessage = `${this.translateService.instant(
												'COMPETITION.ACTIVITYABOUTTOSTART'
											)}`;

											let modalMessageForQuiz = `${this.translateService.instant(
												'COMPETITION.QUIZABOUTTOSTART'
											)}`;

											if (type == SOCKETMESSAGES.QUIZTOSTART) {
												modalMessage = modalMessageForQuiz;
											}

											modalRef.componentInstance.mensaje = modalMessage;

											modalRef.result
												.then((result: boolean) => {
													if (result == true) {
														this.modalAcceptOrDeclineIsOpen = false;
														this.groupService
															.getIfSessionExistsByUser(this.selectedGroupInfo.idGroup)
															.pipe(takeUntil(this.destroy$), take(1))
															.subscribe((res) => {
																if (res.data == false) {
																	this.groupService
																		.createGroupSession(this.selectedGroupInfo.idGroup, null, false)
																		.subscribe((result) => {
																			let modal = this.modalService.open(SocketQuizPreviewComponent, {
																				scrollable: false,
																				windowClass: `${MODAL_DIALOG_TYPES.W100} h-100`,
																			});
																			modal.componentInstance.idGroup = this.selectedGroupInfo.idGroup;
																			modal.componentInstance.onlineUsers = this.sockeOnlineUsers;

																			this.socketQuizPreviewIsOpen = true;
																			modal.result.then((result) => {
																				if (result == 'manual') {
																					this.userManuallyClosedSockectWindow = true;
																					this.socketQuizPreviewIsOpen = false;
																				}
																			});
																		});
																} else {
																	let modal = this.modalService.open(SocketQuizPreviewComponent, {
																		scrollable: false,
																		windowClass: `${MODAL_DIALOG_TYPES.W100} h-100`,
																	});
																	modal.componentInstance.idGroup = this.selectedGroupInfo.idGroup;
																	modal.componentInstance.onlineUsers = this.sockeOnlineUsers;

																	this.socketQuizPreviewIsOpen = true;
																	modal.result.then((result) => {
																		if (result == 'manual') {
																			this.userManuallyClosedSockectWindow = true;
																			this.socketQuizPreviewIsOpen = false;
																		}
																	});
																}
															});

														this.socketService.setcompetitionStarted(true);
													} else {
														this.modalAcceptOrDeclineIsOpen = false;
													}
												}).finally(() => {
													this.modalAcceptOrDeclineIsOpen = false; // Ensure the flag is reset
												});

										}
									}
								});

							this.subscriptions.push(msgSubs);
						});
					} else {
						this.groupService
							.getGroupSession(this.selectedGroupInfo.idGroup)
							.subscribe((result) => {
								this.socketService.idSession = result.data[0].idSession;
								const msgSubs = this.socketService.msgFromServer
								.pipe(takeUntil(this.destroy$) ,debounceTime(300)) // Add debounce time of 300ms
								.subscribe((data) => {
									console.log('data socket', data);
									// Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
									const dataArray = data.split(':');
									let type = '';
									this.sockeOnlineUsers = 0;
									if (dataArray[0] == 'counter_users') {
										this.sockeOnlineUsers = parseInt(dataArray[1]);
									}

									if (dataArray[0] == 'MsgToRoomClients') {
										type = dataArray[1];
									} else {
										type = dataArray[0];
									}

									if (type === SOCKETMESSAGES.CLOSESOCKET) {
										this.socketQuizPreviewIsOpen = false;
										return;
									}

									if (type === SOCKETMESSAGES.CONTROLTOSTART) {
										this.modalAcceptOrDeclineIsOpen = false;

										let modal = this.modalService.open(SocketQuizPreviewComponent, {
											scrollable: false,
											windowClass: `${MODAL_DIALOG_TYPES.W100} h-100`,
										});
										modal.componentInstance.idGroup = this.selectedGroupInfo.idGroup;
										modal.componentInstance.onlineUsers = this.sockeOnlineUsers;

										this.socketQuizPreviewIsOpen = true;

										modal.result.then((result) => {
											if (result == 'manual') {
												this.userManuallyClosedSockectWindow = true;
												this.socketQuizPreviewIsOpen = false;
											}
										});

										this.socketService.setcompetitionStarted(true);
									}

									if (
										(type == 'act_about_to_start' &&
											this.socketQuizPreviewIsOpen == false &&
											this.modalAcceptOrDeclineIsOpen == false) ||
										((type == 'quiz_about_to_start' || type == 'order_mod_auto') &&
											this.socketQuizPreviewIsOpen == false &&
											this.modalAcceptOrDeclineIsOpen == false)
									) {
										if (this.loginService.esEstudiante() && this.modalAcceptOrDeclineIsOpen == false) {
											this.modalAcceptOrDeclineIsOpen = true;
											const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
												scrollable: true,
												windowClass: MODAL_DIALOG_TYPES.W30,
											});
											//this.modalAcceptOrDeclineIsOpen = true;
											let modalMessage = `${this.translateService.instant(
												'COMPETITION.ACTIVITYABOUTTOSTART'
											)}`;

											let modalMessageForQuiz = `${this.translateService.instant(
												'COMPETITION.QUIZABOUTTOSTART'
											)}`;

											if (type == SOCKETMESSAGES.QUIZTOSTART) {
												modalMessage = modalMessageForQuiz;
											}

											modalRef.componentInstance.mensaje = modalMessage;

											modalRef.result
												.then((result: boolean) => {
													if (result == true) {
														this.modalAcceptOrDeclineIsOpen = false;
														this.groupService
															.getIfSessionExistsByUser(this.selectedGroupInfo.idGroup)
															.pipe(takeUntil(this.destroy$), take(1))
															.subscribe((res) => {
																if (res.data == false) {
																	this.groupService
																		.createGroupSession(this.selectedGroupInfo.idGroup, null, false)
																		.subscribe((result) => {
																			let modal = this.modalService.open(SocketQuizPreviewComponent, {
																				scrollable: false,
																				windowClass: `${MODAL_DIALOG_TYPES.W100} h-100`,
																			});
																			modal.componentInstance.idGroup = this.selectedGroupInfo.idGroup;
																			modal.componentInstance.onlineUsers = this.sockeOnlineUsers;

																			this.socketQuizPreviewIsOpen = true;
																			modal.result.then((result) => {
																				if (result == 'manual') {
																					this.userManuallyClosedSockectWindow = true;
																					this.socketQuizPreviewIsOpen = false;
																				}
																			});
																		});
																} else {
																	let modal = this.modalService.open(SocketQuizPreviewComponent, {
																		scrollable: false,
																		windowClass: `${MODAL_DIALOG_TYPES.W100} h-100`,
																	});
																	modal.componentInstance.idGroup = this.selectedGroupInfo.idGroup;
																	modal.componentInstance.onlineUsers = this.sockeOnlineUsers;

																	this.socketQuizPreviewIsOpen = true;
																	modal.result.then((result) => {
																		if (result == 'manual') {
																			this.userManuallyClosedSockectWindow = true;
																			this.socketQuizPreviewIsOpen = false;
																		}
																	});
																}
															});

														this.socketService.setcompetitionStarted(true);
													} else {
														this.modalAcceptOrDeclineIsOpen = false;
													}
												}).finally(() => {
													this.modalAcceptOrDeclineIsOpen = false; // Ensure the flag is reset
												});

										}
									}
								});

							this.subscriptions.push(msgSubs);
							});
					}
				}
				);

		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any): void {
		if (window.innerWidth <= 1200) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	}

}
