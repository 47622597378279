<div class="h-100 w-100 modal-body" id="node">
	<app-loading [isVisible]="isLoading"></app-loading>
	<!--<app-node-menu [padStatus]="padStatus" [labelStatus]="labelStatus" [showFiles]="isShowFiles" [modeAuto]="modeAuto"
		[course]="course" [node]="node" [quiz]="quiz" [idCurso]="idCurso" [idMapa]="idMapa" [canEdit]="canEdit"
		(showPads)="showPads($event)" (showLabels)="showLabels($event)"></app-node-menu>-->
	<!-- <app-node-pad-controller [isShow]="isShowPads" [isShowLabels]="isShowLabels" [idCurso]="idCurso" [idTarget]="idMapa" [idQuiz]="quiz.idQuiz" (action)="getControllerAction($event)" (onHoverElement)="showFiles($event)"></app-node-pad-controller> -->
	<!-- <ng-template [ngIf]="!viewQuiz">
        <app-node-pad-buttons [isShow]="isShowPads" [isShowLabels]="isShowLabels" [isTeacher]="loginService.esProfesor()" (action)="getActionNodePad($event)" (onHoverElement)="showFiles($event)"></app-node-pad-buttons>
    </ng-template> -->

	<div class="full-width" *ngIf="!viewQuiz">
		<app-loading [isVisible]="cargando"></app-loading>
		<div class="header-close">
			<nb-icon *ngIf="!modeAuto || hideBar" class="m-1" icon="close-circle"
				[options]="{ animation: { type: 'zoom' } }" (click)="closeNode()" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
			</nb-icon>
			<span class="background-forIcon"></span>
		</div>
		<div (mousemove)="showFiles(true)" (click)="showFiles(true)">
			<app-node-detail-files [modeAuto]="modeAuto" [adjustWindow]="adjustWindow" [showFilesContainer]="isShowFiles"
				[node]="node" [inicial]="inicial" [mensaje]="mensaje" [onSaveClicked$]="saveClicked$" [canEdit]="canEdit"
				(nodeFilesTypeChanged)="onNodeFilesTypeChanged($event)" (nodeFileAdded)="onNodeFileAdded($event)"
				(onHoverElements)="showFiles($event)">
			</app-node-detail-files>
		</div>
		<div id="toolbar-bottom-nodes">
			<div class="cont-btn-footer">
				<app-pad-controller class="glass" (action)="getControllerAction($event)" [upAndDownOff]="true"
					[isModalOpen]="isModalOpen" [isShowLabels]="isShowLabels" [listNodeAuto]="ModoAuto" [isShow]="isShowPads"
					[canEdit]="changedToEditor" [NodeViewPads]="zoomNode" [idCurso]="idCurso" [idMapa]="idMapa" [node]="node"
					></app-pad-controller>
				<app-pad-center class="glass" [courseId]="idCurso" [graphId]="idMapa" [quiz]="quiz" [node]="node"
					[nodeView]="true" [alertCloseQuiz]="true" (modeAuto)="openModalModeAuto()" [courseName]="courseTitle"
					[graphTitle]="graphTitle" [isShow]="isShowPads" [isShowLabels]="isShowLabels"
					[canEdit]="changedToEditor"></app-pad-center>
				<app-node-pad-buttons class="glass" *ngIf="loginService.esProfesor()" [isShow]="isShowPads" [isShowLabels]="isShowLabels" [isTeacher]="loginService.esProfesor()"
				(action)="getActionNodePad($event)" (onHoverElement)="showFiles($event)"></app-node-pad-buttons>
				<app-pad-buttons class="glass" [alertCloseQuiz]="true" (modeAuto)="openModalModeAuto()"
					[courseName]="courseTitle" [isShow]="isShowPads" [isShowLabels]="isShowLabels" [canEdit]="changedToEditor"></app-pad-buttons>
				<!-- <app-graph-buttons (action)="getActionFromPads($event)" [canEdit]="changedToEditor"></app-graph-buttons> -->
			</div>
		</div>
	</div>

	<div *ngIf="viewQuiz">
		<div *ngIf="quiz && quiz.idMultiplexQuiz === 0" id="quiz" (mousemove)="showFiles(true)" (click)="showFiles(true)">
			<app-quiz-play-header [modeAuto]="modeAuto" [hideBar]="hideBar" [quiz]="quiz" [idCurso]="idCurso"
				[idMapa]="idMapa" (close)="onClose()"></app-quiz-play-header>

			<div class="body-container hide-scrollbars px-4" (scroll)="onScroll($event)" id="container">
				<app-quiz-play-body [disabledButton]="disabledButton" [modeAuto]="modeAuto" [courseId]="idCurso"
					[graphId]="idMapa" [quiz]="quiz" [rawQuiz]="rawQuiz" [elements]="elements" [answered]="answered"
					[modeSocket]="false" (result)="onViewGif($event)" (notResponseGif)="hidenGif($event)"
					(answeredChange)="onAnswered()" (colorChange)="onColorChanged($event)">
				</app-quiz-play-body>
			</div>

		</div>
		<div *ngIf="quiz && quiz.idMultiplexQuiz === 1" id="quiz" (mousemove)="showFiles(true)" (click)="showFiles(true)">
			<app-quiz-play-multiple-header [modeAuto]="modeAuto" [hideBar]="hideBar" [quiz]="quiz" [idCurso]="idCurso"
				[idMapa]="idMapa" (close)="onClose()"></app-quiz-play-multiple-header>
			<div class="body-container hide-scrollbars px-4">
				<!-- ********** ********** ********** -->
				<!-- BOTONES PARA IR CAMBIANDO ENTRE QUIZZES MULTIPLES COMO PROFESOR -->
				<div id="botones-multiples" *ngIf="loginService.esProfesor()" class="d-flex justify-content-center align-items-center flex-wrap mt-3">
					<button *ngFor="let hijo of listQM; let i = index"
									[ngClass]="{'boton-qM': true, 'boton-qM-selected': i === selectedButtonIndex}"
									[class.flex-grow-1]="true"
									(click)="selectButton(i, hijo.quizMultiple.idQuizMultiple)">
						{{ i + 1 }}
					</button>
				</div>
				<!-- ********** ********** ********** -->

				<!-- SE HARDCODEA (result)="onViewGif($event)" PARA QUE NO MUESTRE LOS GIF-->
				<app-quiz-play-multiple-body [disabledButton]="disabledButton" [modeAuto]="modeAuto" [courseId]="idCurso"
					[graphId]="idMapa" [quiz]="quiz" [elements]="elements" [answered]="answered" (result)="null"
					(notResponseGif)="hidenGif($event)" (answeredChange)="onAnswered()" (colorChange)="onColorChanged($event)"
					[listQM]="listQM">
				</app-quiz-play-multiple-body>

			</div>
		</div>

		<ng-template [ngIf]="viewQuiz">
			<!-- <app-quiz-pad-buttons  [isShow]="isShowPads" [isShowLabels]="isShowLabels" (action)="getActionQuizPad($event)" (onHoverElement)="showFiles($event)"></app-quiz-pad-buttons> -->
			<div id="toolbar-bottom">
				<div class="cont-btn-footer">
					<app-pad-controller class="glass" (action)="getControllerAction($event)" [upAndDownOff]="true"
						[isModalOpen]="isModalOpen" [isShowLabels]="isShowLabels" [listNodeAuto]="ModoAuto" [isShow]="isShowPads"
						[canEdit]="changedToEditor" [NodeViewPads]="zoomNode"
						(action)="getActionFromPads($event)"></app-pad-controller>
					<app-pad-center class="glass" [courseId]="idCurso" [graphId]="idMapa" [quiz]="quiz" [alertCloseQuiz]="true"
						(modeAuto)="openModalModeAuto()" [courseName]="courseTitle" [graphTitle]="graphTitle" [isShow]="isShowPads"
						[isShowLabels]="isShowLabels" [canEdit]="changedToEditor"></app-pad-center>
					<app-node-pad-buttons *ngIf="loginService.esProfesor()" [isShow]="isShowPads" [isShowLabels]="isShowLabels" [isTeacher]="loginService.esProfesor()"
						(action)="getActionNodePad($event)" (onHoverElement)="showFiles($event)"></app-node-pad-buttons>
					<app-pad-buttons class="glass" [alertCloseQuiz]="true" (modeAuto)="openModalModeAuto()"
						[courseName]="courseTitle" [isShow]="isShowPads" [isShowLabels]="isShowLabels"
						[canEdit]="changedToEditor"></app-pad-buttons>
					<!-- <app-graph-buttons (action)="getActionFromPads($event)" [canEdit]="changedToEditor"></app-graph-buttons> -->
				</div>
			</div>
		</ng-template>

		<div *ngIf="viewQuiz">
			<div class="scroll-arrow" *ngIf="showArrow" (click)="scrollToTop()">
				<mat-icon>{{ arrowIcon }}</mat-icon>
			</div>
		</div>

	</div>
</div>
