import { Profiles } from 'src/app/core/utils/profiles.enum';
import { CourseListModel, NEWELEMENTTYPES } from './../interface/modal-cursos-listado';
import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation, OnChanges, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { CoursesService } from 'src/app/core/services/courses';
import { LoginService } from 'src/app/core/services/login';
import { UtilsService } from 'src/app/core/services/shared/utils.service';
import { QuizzesstackService } from 'src/app/core/services/quizzesstack/quizzesstack.service';
import { LocalStorage } from 'src/app/core/utils';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { QuizzesService } from 'src/app/core/services/quizzes';
declare var $
@Component({
	selector: "app-mat-tree-cursos",
	templateUrl: "./mat-tree-cursos.component.html",
	styleUrls: ["./mat-tree-cursos.component.scss"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default,
})
export class MatTreeCursosComponent implements OnChanges {
	@Input() idCourse: number = null; // Input con el id del curso selecciona desde el pad del grafo; nulo por defecto si no seleccionamos ninguno
	@Input() treeCourses: CourseListModel[] = [];
	@Input() treeGraphs: CourseListModel[] = [];
	@Input() graphView: string = '';
	@Input() optionSelected: number; //Numero de la opción seleccionada para el filtrado de cursos y elementos
	@Input() idGroup: number;
	@Input() type: string;
	@Input() lastQuizStack: any;
	@Input() fromNode?: boolean;
	@Input() idCurso?: number;
	@Input() idGrafo?: number;
	@Input() idNodo?: number;
	@Input() fromMover?: boolean;
	@Input() myCourses?: boolean;
	@Input() coursesInGroup?: boolean;

	@Input() isHelpList?: boolean = false;
	@Input() verIdeasEnLista?: boolean;

	@Output() clickElement = new EventEmitter<CourseListModel>();
	@Output() createNewElement = new EventEmitter<NEWELEMENTTYPES>();
	isAuthor: boolean = this.loginService.esAutor() || false;
	@ViewChild("scrollableParent", { static: true })
	private scrollableParent: ElementRef;
	listListQuizzesStack = [];
	hasResponsesArray = [];
	elementFromNode: CourseListModel[] = [];
	challengeType: string = "0";

	constructor(
		public coursesService: CoursesService,
		public loginService: LoginService,
		public utilsService: UtilsService,
		public quizzesStackService: QuizzesstackService,
		private localStorage: LocalStorage,
		private quizService: QuizzesService
	) {}

	ngOnInit(): void {
		this.challengeType =
			localStorage.getItem("challengeType") === null
				? "0"
				: localStorage.getItem("challengeType");
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.treeCourses.forEach((course) => {
			this.hasResponsesArray.push(false);
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		let currentCourse: CourseListModel = null;
		setTimeout(() => {
			this.scrollableParent.nativeElement.scrollTop = Math.trunc(
				this.utilsService.scrollMeasureTreeActivities
			);
		}, 300);

		if (!this.fromNode && changes.treeCourses) {
			if (this.idCourse) {
				//Obtengo del array el elemento con el id del curso y llamo para obtener sus hijos
				const courses: CourseListModel[] = changes.treeCourses
					.currentValue as CourseListModel[];
				courses.forEach((course) => {
					if (course.idCourse === this.idCourse) currentCourse = course;
				});
				this.loadMoreContent(currentCourse, this.idCourse);
			}
		}
		
		if(this.verIdeasEnLista && this.treeGraphs.length > 0){
			this.loadMoreContent(this.treeGraphs[0], this.treeGraphs[0].idTarget);
			this.verIdeasEnLista = false;
		}

		this.fillStack();
		//SI EL DESAFIO SE ABRE DESDE UN NODO
		if (this.fromNode && this.treeCourses.length > 0) {
			this.loadContentFromNode();
		}
	}

	ngDoCheck(): void {
		this.fillStack();
	}

	fillStack() {
		this.listListQuizzesStack =
			this.quizzesStackService.responseListQuizzesStackChallenges;
		if (this.listListQuizzesStack.length > 0) {
			//Si hay elementos en el array, es que hay respuestas
			this.listListQuizzesStack.forEach((listQuizzesStack, ind) => {
				this.treeCourses.forEach((element, index) => {
					if (element.idQuiz === listQuizzesStack.idQuiz) {
						element.hasResponse = true;
					}
				});
				this.hasResponsesArray[ind] = true;
			});
		}
	}

	loadMoreContent(element: CourseListModel, currentId: number): void {
		this.challengeType =
			localStorage.getItem("challengeType") === null
				? "0"
				: localStorage.getItem("challengeType");
		//Hacemos llamada para recuperar y cargar los elementos
		//const rol: string = this.loginService.esAutor() ? Profiles.Editor.toLowerCase() : Profiles.Student.toLowerCase()
		const datos = JSON.parse(
			this.localStorage.getItem(LOCALSTORAGESTRINGS.USER)
		);
		let rol: string =
			datos.profile.toLowerCase() === "autor"
				? "editor"
				: datos.profile.toLowerCase();
		if ($(`#${currentId}`).hasClass("open")) {
			$(`#${currentId}`).removeClass("open");
			$(`#${currentId} > ul`).removeClass("openList").addClass("hide");
		} else {
			setTimeout(() => {
				$(`#${currentId}`).addClass("open");
				$(`#${currentId} > ul`).addClass("openList").removeClass("hide");
			}, 0);
		}

		//Si ya tiene contenido, no volvemos a hacer la llamada; simplemente desplegamos el contenido
		if (this.isHelpList == true) {
			if (!element.children.length) {
				if (this.optionSelected) {
					this.coursesService
						.childrensListModeHelp(element.idCourse, element.idTarget)
						.subscribe((result) => {
							element.children = result;
						});
				} else
					this.coursesService
						.childrensListModeHelp(element.idCourse, element.idTarget)
						.subscribe((result) => {
							element.children = result;
						});
			}
		} else {
			if (element.children.length === 0) {
				if (this.optionSelected) {
					this.coursesService
						.newChildrensListMode(
							this.optionSelected,
							this.idGroup,
							element.idCourse,
							element.idTarget,
							element.idNode
						)
						.subscribe((result) => {
							element.children = result;
						});
				} else
					this.coursesService
						.childrensListMode(
							element.idCourse,
							element.idTarget,
							element.idNode,
							rol
						)
						.subscribe((result) => {
							element.children = result;
						});
			}
		}
	}

	loadContentFromNode() {
		let obj_curso = this.treeCourses.find(
			(objeto) => objeto.idCourse === this.idCurso
		);
		this.treeCourses = [obj_curso];
		for (let index = 0; index < this.treeCourses.length; index++){
			this.coursesService.getTreeElementListFromNode(
				this.idCurso,
				this.idGrafo,
				this.idNodo
			).subscribe(res => {
				if(res){
					this.treeCourses[index].children = res;
				}
			})
		}
		//abrimos el arbol
		setTimeout(() => {
			//curso
			$(`#${this.idCurso}`).addClass("open");
			$(`#${this.idCurso} > ul`).addClass("openList").removeClass("hide");
			setTimeout(() => {
				//grafo
				$(`#${this.idGrafo}`).addClass("open");
				$(`#${this.idGrafo} > ul`).addClass("openList").removeClass("hide");
				setTimeout(() => {
					//nodo
					$(`#${this.idNodo}`).addClass("open");
					$(`#${this.idNodo} > ul`).addClass("openList").removeClass("hide");
				}, 300);
			}, 300);
		}, 300);

		/**
		//PRIMERO ABRIMOS EL CURSO
		for (let index = 0; index < this.treeCourses.length; index++) {
			if (this.treeCourses[index].idCourse === this.idCurso) {
				this.loadMoreContent(this.treeCourses[index], this.idCurso);
				this.coursesService
					.newChildrensListMode(
						this.optionSelected,
						this.idGroup,
						this.treeCourses[index].idCourse,
						this.treeCourses[index].idTarget,
						this.treeCourses[index].idNode
					)
					.subscribe((result) => {
						let obj_grafo = result.find(
							(objeto) => objeto.idTarget === this.idGrafo
						);
						this.treeCourses[index].children = [obj_grafo];
						//AHORA ABRIMOS EL GRAFO
						for (
							let index2 = 0;
							index2 < this.treeCourses[index].children.length;
							index2++
						) {
							if (
								this.treeCourses[index].children[index2].idTarget ===
								this.idGrafo
							) {
								this.loadMoreContent(
									this.treeCourses[index].children[index2],
									this.idGrafo
								);
								this.coursesService
									.newChildrensListMode(
										this.optionSelected,
										this.idGroup,
										this.treeCourses[index].idCourse,
										this.treeCourses[index].children[index2].idTarget,
										this.treeCourses[index].idNode
									)
									.subscribe((result) => {
										let obj_nodo = result.find(
											(objeto) => objeto.idNode === this.idNodo
										);
										this.treeCourses[index].children[index2].children = [
											obj_nodo,
										];
										//AHORA ABRIMOS EL NODO
										for (
											let index3 = 0;
											index3 <
											this.treeCourses[index].children[index2].children.length;
											index3++
										) {
											if (
												this.treeCourses[index].children[index2].children[
													index3
												].idNode === this.idNodo
											) {
												this.loadMoreContent(
													this.treeCourses[index].children[index2].children[
														index3
													],
													this.idNodo
												);
												break;
											}
										}
									});
								break;
							}
						}
					});
				break;
			}
		}
		*/
	}

	goTo(element: CourseListModel): void {
		// console.log(element);
		//Envio el evento al padre, para mostrar en el lateral unas cosas u otras
		this.utilsService.scrollMeasureTreeActivities =
			this.scrollableParent.nativeElement.scrollTop;
		this.clickElement.emit(element);
	}

	newElement(type: NEWELEMENTTYPES) {
		this.createNewElement.emit(type);
	}

	setSelectedQuiz(e, i) {
		this.treeGraphs[0].children[e].children.forEach((item) => {
			item.isSelected = false;
		});
		this.treeGraphs[0].children[e].children[i].hasResponse = true;
		this.treeGraphs[0].children[e].children[i].isSelected = true;
	}

	setSelectedQuizAlt(c, g, n, i) {
		this.treeCourses[c].children[0].children[n].children.forEach((item) => {
			item.isSelected = false;
		});

		this.quizService.getQuizGotResponse().subscribe((quiz: any) => {
			if (
				quiz.idQuiz ==
				this.treeCourses[c].children[0].children[n].children[i].idQuiz
			) {
				this.treeCourses[c].children[0].children[n].children[i].hasResponse =
					true;
			}
		});
		this.treeCourses[c].children[0].children[n].children[i].isSelected = true;
	}
}
