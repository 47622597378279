<nb-card class="mb-0 no-edge-card">
	<nb-card-header class="border-bottom-0">
		<div class="row">
			<div class="col-10 modal-title">
				<h4>{{ 'STICKERS.SELECT' | translate }}</h4>
			</div>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}"
			nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-body>
    <form [formGroup]="stickerForm" novalidate (ngSubmit)="saveSticker()" class="formSticker d-flex flex-column" #thisForm="ngForm">
			<div class="form-group" *ngIf="tempNode">
					<label for="formGroupExampleInput">{{ 'STICKERS.FILE' | translate }}</label>
					<input type="file" formControlName="file" nbInput class="form-control" fullWidth status="basic" fieldSize="small"
					 (change)="onFileSelected($event)" accept="image/*" />
					<mat-error *ngFor="let validation of validationMessages.file" class="mt-2">
							<mat-error class="error-message"
									*ngIf="stickerForm.get('file')?.hasError(validation.type) && (stickerForm.get('file')?.dirty || stickerForm.get('file')?.touched || thisForm.submitted)">
									{{validation.message}}</mat-error>
					</mat-error>
			</div>

			<div class="form-group">
					<div class="d-flex flex-column">
							<label>{{ 'STICKERS.SIZE' | translate }}</label>

							<input nbInput class="form-control" fullWidth status="basic" fieldSize="small" type="number" formControlName="size" name="size" />
							<mat-error *ngFor="let validation of validationMessages.size" class="mt-2">
									<mat-error class="error-message"
											*ngIf="stickerForm.get('size')?.hasError(validation.type) && (stickerForm.get('size')?.dirty || stickerForm.get('size')?.touched || thisForm.submitted)">
											{{validation.message}}</mat-error>
							</mat-error>
					</div>

					<div class="d-flex flex-column">
						<label>{{ 'STICKERS.LINK' | translate }}</label>

						<input nbInput class="form-control" fullWidth status="basic" fieldSize="small" type="text" formControlName="link" name="link" />
						<mat-error *ngFor="let validation of validationMessages.link" class="mt-2">
								<mat-error class="error-message"
										*ngIf="stickerForm.get('link')?.hasError(validation.type) && (stickerForm.get('link')?.dirty || stickerForm.get('link')?.touched || thisForm.submitted)">
										{{validation.message}}</mat-error>
						</mat-error>
				</div>
				<div class="mt-3">
					<span>{{ 'STICKERS.YOUCANTOO' | translate }}</span>
				</div>
				<div class="d-flex justify-content-center gap-4 mt-2">
					<button nbButton size="small" (click)="cancelar()" status="danger">
						{{'SIGMACOMPONENT.CANCEL' | translate}}
					</button>
					<button nbButton size="small" (click)="buscarProyecto()" class="btn-themecolor">
						{{'STICKERS.SEARCHPROJECT' | translate}}
					</button>
					<button nbButton size="small" (click)="buscarGrafo()" class="btn-themecolor">
						{{'STICKERS.SEARCHGRAPH' | translate}}
					</button>
				</div>

			</div>

			<!-- <button mat-raised-button type="submit" [disabled]="saving">{{ node ? ('STICKERS.EDIT' | translate) : ('STICKERS.UPLOAD' | translate)   }}</button> -->
			<button mat-raised-button class="mt-2 mat-raised-button mat-button-base mat-warn" type="button" *ngIf="node" (click)="deleteSticker()">{{ 'STICKERS.DELETE' | translate }}</button>

			<mat-progress-bar mode="determinate" value="{{progress}}" *ngIf="progress" class="mt-2"></mat-progress-bar>

	</form>
	</nb-card-body>
</nb-card>
